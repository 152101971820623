import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import { api as abonementsApi } from "../../../utils/api/api.abonements";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "flatpickr/dist/themes/light.css";
import Select from "react-select";

export default function AbonementForm(props) {
  const userContext = React.useContext(UserContext);

  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [validated, setValidated] = useState(false);
  const [abonement, setAbonement] = useState({
    _id: "",
    name: "",
    dances: [],
    type: 0,
    days: 0,
    count: 0,
    price: 0,
    comment: "",
    isActive: true,
    owner: "",
  });
  const [owner, setOwner] = useState({});
  const [dances, setDances] = useState([]);
  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    setAbonement({ ...abonement, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
      props.setIsLoading(true);
      abonementsApi
        .getCreationData()
        .then((res) => {
          setDances(
            res.dances.map((element) => {
              return {
                value: element._id,
                label: element.name,
              };
            })
          );
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      setStatus(2);
      props.setIsLoading(true);
      abonementsApi
        .getAbonement(id)
        .then((res) => {
          setAbonement({
            _id: res.abonementInfo._id,
            name: res.abonementInfo.name,
            dances: res.abonementInfo.dances,
            type: res.abonementInfo.type,
            days: res.abonementInfo.days,
            count: res.abonementInfo.count,
            price: res.abonementInfo.price,
            comment: res.abonementInfo.comment,
            isActive: res.abonementInfo.isActive,
            owner: res.abonementInfo.owner,
          });
          setOwner(
            res.users.find((user) => user._id === res.abonementInfo.owner)
          );
          setDances(
            res.dances.map((element) => {
              return {
                value: element._id,
                label: element.name,
              };
            })
          );
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, []);

  const onSubmit = (e) => {
    if (e.currentTarget.checkValidity() === true) {
      e.preventDefault();
      props.setIsLoading(true);
      if (status === 1) {
        abonementsApi
          .createAbonement(abonement)
          .then((res) => {
            console.log(res);
            navigation("/admin/abonements/" + res.newAbonement._id);
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else if (status === 3) {
        abonementsApi
          .updateAbonement(abonement)
          .then((res) => {
            abonementsApi
              .getAbonement(res.abonement._id)
              .then((res) => {
                setAbonement({
                  _id: res.abonementInfo._id,
                  name: res.abonementInfo.name,
                  dances: res.abonementInfo.dances,
                  type: res.abonementInfo.type,
                  days: res.abonementInfo.days,
                  count: res.abonementInfo.count,
                  price: res.abonementInfo.price,
                  comment: res.abonementInfo.comment,
                  isActive: res.abonementInfo.isActive,
                  owner: res.abonementInfo.owner,
                });
              })
              .catch((rej) => {
                props.setIsError(true);
                props.setError(rej);
              });
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <Form onSubmit={onSubmit} validated={validated}>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row className="g-2" style={{ maxWidth: 630 }}>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Название</Form.Label>
                  <Form.Control
                    required
                    value={abonement.name}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                status === 2 ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Направления
              </Form.Label>
              <Col sm={10}>
                <Select
                  required
                  placeholder=""
                  noOptionsMessage={() => "Нет групп"}
                  options={dances}
                  value={
                    abonement.dances === ""
                      ? ""
                      : abonement.dances.map((id) => {
                          return {
                            value: dances.find((el) => el.value === id).value,
                            label: dances.find((el) => el.value === id).label,
                          };
                        })
                  }
                  type="text"
                  name="dances"
                  isMulti={true}
                  onChange={(data) =>
                    setAbonement({
                      ...abonement,
                      dances: data.map((el) => el.value),
                    })
                  }
                  isDisabled={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Тип абонемента
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  required
                  name="type"
                  value={abonement.type}
                  onChange={handleChange}
                  disabled={status !== 1 ? true : false}
                  plaintext={status !== 1 ? true : false}
                >
                  <option value=""></option>
                  <option value="1">По количеству</option>
                  <option value="2">По сроку</option>
                  <option value="3">По количеству и сроку</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className={`mb-3 ${
                abonement.type === "1" ||
                abonement.type === "3" ||
                abonement.type === 1 ||
                abonement.type === 3
                  ? "d-flex"
                  : "d-none"
              }`}
            >
              <Form.Label column sm={2}>
                Количество занятий
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="count"
                  value={abonement.count}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className={`mb-3 ${
                abonement.type === "2" ||
                abonement.type === "3" ||
                abonement.type === 2 ||
                abonement.type === 3
                  ? "d-flex"
                  : "d-none"
              }`}
            >
              <Form.Label column sm={2}>
                Срок действия(дней)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="days"
                  value={abonement.days}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Цена
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  required
                  type="text"
                  name="price"
                  value={abonement.price}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Комментарий
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="comment"
                  onChange={handleChange}
                  value={abonement.comment}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Активность
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  required
                  name="isActive"
                  value={abonement.isActive}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                >
                  <option value="true">Да</option>
                  <option value="false">Нет</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Card.Text>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className={`${status === 2 ? "d-none" : "d-block"}`}
            >
              Сохранить
            </Button>
          </div>
        </Card.Body>
        <Form.Group className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}>
          <Form.Label>Создал пользователь</Form.Label>
          <Form.Control
            value={owner !== {} ? owner.firstName + " " + owner.secondName : ""}
            type="text"
            name="owner"
            disabled
            plaintext
          />
        </Form.Group>
      </Card>
    </Form>
  );
}
