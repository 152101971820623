import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/themes/light.css";

export default function PlanModal({ isOpen, setIsOpen, handleCreatePlan }) {
  const [date, setDate] = useState(new Date(new Date().setDate(new Date().getDate() + 2)));
  const [comment, setComment] = useState(null);

  const handleSubmit = (e) => {
    handleCreatePlan(date, comment);
  };

  return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Запланировать повтор</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Дата</Form.Label>
            <Flatpickr
              data-enable-time
              className="form-control"
              style={{ backgroundColor: "transparent" }}
              value={date}
              options={{
                time_24hr: true,
                locale: Russian,
                disableMobile: true,
                dateFormat: "d.m.Y H:i",
              }}
              name="date"
              onChange={([date]) => {
                setDate(date);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Комментарий</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              name="comment"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsOpen(false)}>
          Закрыть
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
