import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import { api as usersApi } from "../../../utils/api/api.users";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/themes/light.css";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

export default function UsersForm(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [user, setUser] = useState({
    _id: "",
    login: "",
    password: "",
    firstName: "",
    secondName: "",
    photo: "",
    email: "",
    type: 1,
    job: "",
    unit: "",
    access: "",
    notifications: "",
    isActive: "",
    owner: "",
  });
  const [birthday, setBirthday] = useState("");
  const [needUpdate, setNeedUpdate] = useState(true);
  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChangeAccess = (e) => {
    setUser({
      ...user,
      access: {
        ...user.access,
        [e.target.name]: !Boolean(user.access[e.target.name]),
      },
    });
  };

  const handleChangeNotifications = (e) => {
    setUser({
      ...user,
      notifications: {
        ...user.notifications,
        [e.target.name]: !Boolean(user.notifications[e.target.name]),
      },
    });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
    } else {
      if (needUpdate) {
        setStatus(2);
        props.setIsLoading(true);
        usersApi
          .getUser(id)
          .then((res) => {
            setUser({
              _id: res.user._id,
              login: res.user.login,
              password: "",
              firstName: res.user.firstName,
              secondName: res.user.secondName,
              photo: res.user.photo,
              email: res.user.email,
              type: res.user.type,
              job: res.user.job,
              unit: res.user.unit,
              access: res.user.access,
              notifications: res.user.notifications,
              isActive: res.user.isActive,
              owner: res.user.owner,
            });
            setBirthday(res.user.birthday);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
        setNeedUpdate(false);
      }
    }
  }, [needUpdate]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    if (status === 1) {
      usersApi
        .createUser({ ...user, birthday: birthday })
        .then((res) => {
          navigation("/admin/users/" + res._id);
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else if (status === 3) {
      usersApi
        .updateUser({ ...user, birthday: birthday })
        .then((res) => {
          usersApi
            .getUser(user._id)
            .then((res) => {
              setUser({
                _id: res.user._id,
                login: res.user.login,
                password: "",
                firstName: res.user.firstName,
                secondName: res.user.secondName,
                photo: res.user.photo,
                email: res.user.email,
                type: res.user.type,
                job: res.user.job,
                unit: res.user.unit,
                access: res.user.access,
                notifications: res.user.notifications,
                isActive: res.user.isActive,
                owner: res.user.owner,
              });
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            });
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Card>
        <Card.Body>
          <Card.Title>
            {/* <i
                    className="bi bi-chevron-compact-left me-3"
                    style={{ fontSize: 30, cursor: "pointer" }}
                    onClick={() => navigation(-1)}
                  /> */}
            <Row className="g-2" style={{ maxWidth: 630 }}>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Имя</Form.Label>
                  <Form.Control
                    value={user.firstName}
                    type="text"
                    name="firstName"
                    placeholder="Имя"
                    onChange={handleChange}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Фамилия</Form.Label>
                  <Form.Control
                    type="text"
                    name="secondName"
                    placeholder="Фамилия"
                    onChange={handleChange}
                    value={user.secondName}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                status === 2 ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Тип
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="isActive"
                  value={user.type}
                  onChange={handleChange}
                  disabled={true}
                  plaintext={true}
                >
                  <option value=" "> </option>
                  <option value="1">Администратор</option>
                  <option value="2">Ученик</option>
                  <option value="3">Родитель</option>
                  <option value="4">Система</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Логин
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="login"
                  value={user.login}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Пароль
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                E-mail
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={user.email}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                День рождения
              </Form.Label>
              <Col sm={10}>
                <Flatpickr
                  className={`form-control ${
                    status === 2 && "form-control-plaintext"
                  }`}
                  style={{ backgroundColor: "transparent" }}
                  value={birthday}
                  options={{
                    time_24hr: true,
                    locale: Russian,
                    disableMobile: true,
                    dateFormat: "d.m.Y",
                  }}
                  name="birthday"
                  onChange={([date]) => {
                    setBirthday(date);
                  }}
                  disabled={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Должность
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="job"
                  onChange={handleChange}
                  value={user.job}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            {(user.type !== 1) & (user.type !== 4) ? (
              <Form.Group
                as={Row}
                className={`mb-3 ${
                  (user.type !== 1) & (user.type !== 4) ? "d-flex" : "d-none"
                }`}
              >
                <Form.Label column sm={2}>
                  Юнит
                </Form.Label>
                <Col sm={10}>
                  <Link
                    to={
                      user.type === 2
                        ? "/admin/teachers/" + user.unit
                        : user.type === 3
                        ? "/admin/students/" + user.unit
                        : "" 
                    }
                  >
                    Перейти
                  </Link>
                </Col>
              </Form.Group>
            ) : (
              ""
            )}

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Активность
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="isActive"
                  value={user.isActive}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                >
                  <option value="true">Да</option>
                  <option value="false">Нет</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Accordion className="mb-2" defaultActiveKey={["1"]} alwaysOpen>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Доступ</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex flex-column">
                    <Form.Check
                      className={`${
                        userContext.access.abonement ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Абонементы"
                      name="abonement"
                      checked={user.access.abonement === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.accounts ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Счета"
                      name="accounts"
                      checked={user.access.accounts === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.actionsCRM ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Действия CRM"
                      name="actionsCRM"
                      checked={user.access.actionsCRM === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.dances ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Направления"
                      name="dances"
                      checked={user.access.dances === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.discounts ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Скидки"
                      name="discounts"
                      checked={user.access.discounts === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.documents ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Документы"
                      name="documents"
                      checked={user.access.documents === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.groups ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Группы"
                      name="groups"
                      checked={user.access.groups === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.leads ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Лиды"
                      name="leads"
                      checked={user.access.leads === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.lessons ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Занятия"
                      name="lessons"
                      checked={user.access.lessons === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.salaries ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Зарплаты"
                      name="salaries"
                      checked={user.access.salaries === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.settings ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Настройки"
                      name="settings"
                      checked={user.access.settings === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.students ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Ученики"
                      name="students"
                      checked={user.access.students === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.teachers ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Педагоги"
                      name="teachers"
                      checked={user.access.teachers === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.users ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Пользователи"
                      name="users"
                      checked={user.access.users === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.bonuceOperations
                          ? "d-block"
                          : "d-none"
                      } m-2`}
                      type="switch"
                      label="Операции по бонусам"
                      name="bonuceOperations"
                      checked={
                        user.access.bonuceOperations === true ? "on" : ""
                      }
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.creditOperations
                          ? "d-block"
                          : "d-none"
                      } m-2`}
                      type="switch"
                      label="Операции по долгам"
                      name="creditOperations"
                      checked={
                        user.access.creditOperations === true ? "on" : ""
                      }
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.moneyOperations
                          ? "d-block"
                          : "d-none"
                      } m-2`}
                      type="switch"
                      label="Операции по деньгам"
                      name="moneyOperations"
                      checked={user.access.moneyOperations === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.lessonsOperations
                          ? "d-block"
                          : "d-none"
                      } m-2`}
                      type="switch"
                      label="Операции по занятиям"
                      name="lessonsOperations"
                      checked={
                        user.access.lessonsOperations === true ? "on" : ""
                      }
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.financeOperations
                          ? "d-block"
                          : "d-none"
                      } m-2`}
                      type="switch"
                      label="Операции по финансам"
                      name="financeOperations"
                      checked={
                        user.access.financeOperations === true ? "on" : ""
                      }
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.superAdmin ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="СуперАдмин"
                      name="superAdmin"
                      checked={user.access.superAdmin === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.tasks ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Задачи"
                      name="tasks"
                      checked={user.access.tasks === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.whatsapp ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Рассылки в WhatsApp"
                      name="whatsapp"
                      checked={user.access.whatsapp === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Доступ к отчетам</Accordion.Header>
                <Accordion.Body>
                <Form.Check
                      className={`${
                        userContext.access.REPworkTime ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Отчет рабочего времени"
                      name="REPworkTime"
                      checked={user.access.REPworkTime === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.REPfinanceStatistic ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label="Финансовый отчет"
                      name="REPfinanceStatistic"
                      checked={user.access.REPfinanceStatistic === true ? "on" : ""}
                      onChange={handleChangeAccess}
                      disabled={status === 2 ? true : false}
                    />
                </Accordion.Body>
                </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Уведомления</Accordion.Header>
                <Accordion.Body>
                <Form.Check
                      type="switch"
                      label="Уведомления о новой заявке на сайте"
                      name="siteRequest"
                      className="m-2"
                      checked={user.notifications.siteRequest === true ? "on" : ""}
                      onChange={handleChangeNotifications}
                      disabled={status === 2 ? true : false}
                    />
                    <Form.Check
                      type="switch"
                      label="Уведомления о запланированных действиях в CRM"
                      name="plannedActions"
                      className="m-2"
                      checked={user.notifications.plannedActions === true ? "on" : ""}
                      onChange={handleChangeNotifications}
                      disabled={status === 2 ? true : false}
                    />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Text>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className={`${status === 2 ? "d-none" : "d-block"}`}
            >
              Сохранить
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Form>
  );
}
