import React from 'react';
import { useNavigate } from "react-router-dom";

export default function NavigateButton(props) {
    const navigate = useNavigate();

  return (
    <i
        className="bi bi-eye-fill"
        style={{ cursor: "pointer", fontSize: 28, color: "black" }}
        onClick={() => {
            navigate(props.link._id)
            props.setScheduleState(props.scheduleState)
        }}
      />
  )
}
