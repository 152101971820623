import React, { useEffect, useState } from "react";
import { api as bonuceApi } from "../../../../utils/api/api.bonuces";
import AddMoney from "./AddBonuces";
import SellMoney from "./SellBonuces";
import { useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";


export default function BonucesForm(props) {
  const [type, setType] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [data, setData] = useState(null);

  const navigation = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (id === "new") {
      setIsNew(true);
    } else {
      bonuceApi
        .getOperation(id)
        .then((res) => {
          setData(res.operationInfo._id);
          setType(res.operationInfo.type);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, []);
  return (
    <>
      {isNew ? (
        type === 0 ? (
          <Modal show={true} onHide={() => navigation(-1)} fullscreen>
            <Modal.Header closeButton>
              <Modal.Title>Выберите тип операции</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value=""> </option>
                <option value={1}>Начисление</option>
                <option value={2}>Списание</option>
              </Form.Select>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => navigation(-1)}>
                Закрыть
              </Button>
            </Modal.Footer>
          </Modal>
        ) : type === "1" ? (
          <AddMoney
            setIsLoading={props.setIsLoading}
            setIsError={props.setIsError}
            setError={props.setError}
            data={null}
            navigation={navigation}
          />
        ) : (
          <SellMoney
            setIsLoading={props.setIsLoading}
            setIsError={props.setIsError}
            setError={props.setError}
            data={null}
            navigation={navigation}
          />
        )
      ) : type === 1 ? (
        <AddMoney
          setIsLoading={props.setIsLoading}
          setIsError={props.setIsError}
          setError={props.setError}
          data={data}
          navigation={navigation}
        />
      ) : (
        <SellMoney
          setIsLoading={props.setIsLoading}
          setIsError={props.setIsError}
          setError={props.setError}
          data={data}
          navigation={navigation}
        />
      )}
    </>
  );
}
