import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Kanban from "../../../vendor/kanban/index";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import { api as actionsApi } from "../../../utils/api/api.actionscrm";

export default function LeadsKanBan(props) {
  const [leads, setLeads] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [types, setTypes] = useState(null);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(!props.kanbanState);
  const [finder, setFinder] = useState({
    f_type: "",
    f_lead: "",
    f_responsible: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");

  useEffect(() => {
    if (!props.kanbanState) return;
    setFinder(props.kanbanState.finder);
    setF_datef(props.kanbanState.f_datef);
    setF_datet(props.kanbanState.f_datet);
    setNeedUpdate(true);
  }, [props.kanbanState]);

  useEffect(() => {
    console.log(needUpdate)
    console.log(finder)
  }, [needUpdate]);

  const navigation = useNavigate();

  useEffect(() => {
    if (needUpdate) {
      props.setIsLoading(true);
      actionsApi
        .getActions({ p: 1, s: 1000000000000000, ...finder, f_datet, f_datef })
        .then((res) => {
          const kanban = {};
          kanban.cards = res.actions.map((item) => {
            return {
              id: item._id,
              title:
                res.directory.directory.crmActions.find(
                  (el) => el._id === item.type
                ).value +
                " " +
                new Date(item.date).toLocaleString("ru-RU", {
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }),
              lead:
                res.leads.find((ld) => ld._id === item.lead).name +
                (res.leads.find((ld) => ld._id === item.lead).secondName
                  ? " " +
                    res.leads.find((ld) => ld._id === item.lead).secondName
                  : ""),
              status: item.status,
              responsible:
                res.users.find((us) => us._id === item.responsible).firstName +
                " " +
                res.users.find((us) => us._id === item.responsible).secondName,
              type: item.type,
              date: item.date,
              comment: item.comment,
            };
          });
          kanban.columns = {};
          res.directory.directory.crmStatus.forEach((item) => {
            kanban.columns[item._id] = {
              id: item._id,
              title: item.value,
              cardIds: res.actions
                .filter((le) => le.status === item._id)
                .map((le) => le._id),
            };
          });
          kanban.columnOrder = res.directory.directory.crmStatus.map(
            (item) => item._id
          );
          setData(kanban);
          setLeads(res.leads);
          setUsers(res.users);
          setStatuses(res.directory.directory.crmStatus);
          setTypes(res.directory.directory.crmActions);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          setNeedUpdate(false);
        });
    }
  }, [needUpdate]);

  const statusChange = (actionInfo) => {
    actionsApi
      .updateAction({
        _id: actionInfo.draggableId,
        status: actionInfo.destination.droppableId,
      })
      .then(() => setNeedUpdate(true))
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    actionsApi
      .getActions({
        ...finder,
        f_datet,
        f_datef,
        p: 1,
        s: 1000000000000000,
      })
      .then((res) => {
        const kanban = {};
        kanban.cards = res.actions.map((item) => {
          return {
            id: item._id,
            title:
              res.directory.directory.crmActions.find(
                (el) => el._id === item.type
              ).value +
              " " +
              new Date(item.date).toLocaleString("ru-RU", {
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }),
            lead:
              res.leads.find((ld) => ld._id === item.lead).name +
              (res.leads.find((ld) => ld._id === item.lead).secondName
                ? " " + res.leads.find((ld) => ld._id === item.lead).secondName
                : ""),
            status: item.status,
            responsible:
              res.users.find((us) => us._id === item.responsible).firstName +
              " " +
              res.users.find((us) => us._id === item.responsible).secondName,
            type: item.type,
            date: item.date,
            comment: item.comment,
          };
        });
        kanban.columns = {};
        res.directory.directory.crmStatus.forEach((item) => {
          kanban.columns[item._id] = {
            id: item._id,
            title: item.value,
            cardIds: res.actions
              .filter((le) => le.status === item._id)
              .map((le) => le._id),
          };
        });
        kanban.columnOrder = res.directory.directory.crmStatus.map(
          (item) => item._id
        );
        setData(kanban);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    actionsApi
      .getActions({
        p: 1,
        s: 1000000000000000,
      })
      .then((res) => {
        setFinder({
          f_type: "",
          f_lead: "",
          f_responsible: "",
        });
        setF_datef("");
        setF_datet("");
        const kanban = {};
        kanban.cards = res.actions.map((item) => {
          return {
            id: item._id,
            title:
              res.directory.directory.crmActions.find(
                (el) => el._id === item.type
              ).value +
              " " +
              new Date(item.date).toLocaleString("ru-RU", {
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }),
            lead:
              res.leads.find((ld) => ld._id === item.lead).name +
              (res.leads.find((ld) => ld._id === item.lead).secondName
                ? " " + res.leads.find((ld) => ld._id === item.lead).secondName
                : ""),
            status: item.status,
            responsible:
              res.users.find((us) => us._id === item.responsible).firstName +
              " " +
              res.users.find((us) => us._id === item.responsible).secondName,
            type: item.type,
            date: item.date,
            comment: item.comment,
          };
        });
        kanban.columns = {};
        res.directory.directory.crmStatus.forEach((item) => {
          kanban.columns[item._id] = {
            id: item._id,
            title: item.value,
            cardIds: res.actions
              .filter((le) => le.status === item._id)
              .map((le) => le._id),
          };
        });
        kanban.columnOrder = res.directory.directory.crmStatus.map(
          (item) => item._id
        );
        setData(kanban);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <>
      {data !== null ? (
        <Fade>
          <>
            <Accordion className="mb-2">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Поиск</Accordion.Header>
                <Accordion.Body>
                  <Form onSubmit={tableFilter}>
                    <Row className="mb-3">
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Срок с</Form.Label>
                          <Flatpickr
                            className="form-control"
                            style={{ backgroundColor: "transparent" }}
                            value={f_datef}
                            options={{
                              time_24hr: true,
                              locale: Russian,
                              dateFormat: "d.m.Y",
                              disableMobile: true,
                            }}
                            onChange={([date]) => {
                              setF_datef(new Date(date));
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Срок по</Form.Label>
                          <Flatpickr
                            className="form-control"
                            style={{ backgroundColor: "transparent" }}
                            value={f_datet}
                            options={{
                              time_24hr: true,
                              locale: Russian,
                              dateFormat: "d.m.Y",
                              disableMobile: true,
                            }}
                            onChange={([date]) => {
                              setF_datet(new Date(date));
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Лид
                      </Form.Label>
                      <Col sm={10}>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет лидов"}
                          options={
                            leads === null
                              ? {}
                              : leads.map((el) => {
                                  return {
                                    value: el._id,
                                    label:
                                      el.name +
                                      (el.secondName
                                        ? " " + el.secondName
                                        : ""),
                                  };
                                })
                          }
                          value={
                            finder.f_lead === ""
                              ? {}
                              : {
                                  value: leads.find(
                                    (el) => el._id === finder.f_lead
                                  )._id,
                                  label:
                                    leads.find((el) => el._id === finder.f_lead)
                                      .name +
                                    " " +
                                    (leads.find(
                                      (el) => el._id === finder.f_lead
                                    ).secondName
                                      ? leads.find(
                                          (el) => el._id === finder.f_lead
                                        ).secondName
                                      : ""),
                                }
                          }
                          type="text"
                          name="f_lead"
                          onChange={(data) =>
                            setFinder({
                              ...finder,
                              f_lead: data.value,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Тип действия
                      </Form.Label>
                      <Col sm={10}>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет типов"}
                          options={
                            types === null
                              ? {}
                              : types.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.value,
                                  };
                                })
                          }
                          value={
                            finder.f_type === ""
                              ? {}
                              : {
                                  value: types.find(
                                    (el) => el._id === finder.f_type
                                  )._id,
                                  label: types.find(
                                    (el) => el._id === finder.f_type
                                  ).value,
                                }
                          }
                          type="text"
                          name="f_type"
                          onChange={(data) =>
                            setFinder({
                              ...finder,
                              f_type: data.value,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Ответственный
                      </Form.Label>
                      <Col sm={10}>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет пользователей"}
                          options={
                            users === null
                              ? {}
                              : users.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.firstName + " " + el.secondName,
                                  };
                                })
                          }
                          value={
                            finder.f_responsible === ""
                              ? {}
                              : {
                                  value: users.find(
                                    (el) => el._id === finder.f_responsible
                                  )._id,
                                  label:
                                    users.find(
                                      (el) => el._id === finder.f_responsible
                                    ).firstName +
                                    " " +
                                    users.find(
                                      (el) => el._id === finder.f_responsible
                                    ).secondName,
                                }
                          }
                          type="text"
                          name="f_responsible"
                          onChange={(data) =>
                            setFinder({
                              ...finder,
                              f_responsible: data.value,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Button
                      className="me-2"
                      variant="outline-secondary"
                      type="button"
                      onClick={resetFilter}
                    >
                      Очистить
                    </Button>
                    <Button variant="primary" type="submit">
                      Поиск
                    </Button>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Kanban
              data={data}
              onDragEnd={(statuses) => statusChange(statuses)}
              renderer={(card) => {
                return (
                  <Card key={card.id}>
                    <Card.Body className="p-0">
                      <Card.Title className="p-0">
                        <i
                          className="bi bi-eye-fill"
                          style={{ cursor: "pointer", fontSize: 18 }}
                          onClick={() => {
                            props.setKanbanState({ f_datet, f_datef, finder });
                            navigation(card.id);
                          }}
                        />{" "}
                        {card.title}
                      </Card.Title>
                      <p className="m-0">{card.lead}</p>
                      <p className="m-0">{card.responsible}</p>
                      <p
                        className="m-0 fw-light"
                        style={{ maxHeight: 190, overflow: "scroll" }}
                      >
                        {card.comment}
                      </p>
                    </Card.Body>
                  </Card>
                );
              }}
            ></Kanban>
          </>
        </Fade>
      ) : (
        ""
      )}
    </>
  );
}
