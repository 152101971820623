import React, { useEffect, useState } from "react";
import { api as leadsApi } from "../../../utils/api/api.leads";
import { Link } from "react-router-dom";
import { FunnelChart } from "react-funnel-pipeline";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Fade from "react-reveal/Fade";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

export default function LeadsFunnel(props) {
  const [leadsStatus, setLeadsStatus] = useState(null);
  const [leadsSources, setLeadsSources] = useState(null);
  const [leads, setLeads] = useState(null);
  const [data, setData] = useState(null);
  const [colors, setColors] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [finder, setFinder] = useState({
    f_source: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    let TotalAmount = 0;
    leadsApi
      .getFunnel({})
      .then((res) => {
        setData(
          res.funnelInfo.map((el) => {
            return {
              name: res.directory.directory.leadStatus.find(
                (st) => st._id === el.status
              ).value,
              ids: el.ids,
              value: el.ids.length,
              statusId: res.directory.directory.leadStatus.find(
                (st) => st._id === el.status
              )._id,
            };
          })
        );
        res.funnelInfo.forEach((el) => (TotalAmount += el.ids.length));
        setTotalAmount(TotalAmount);
        setColors(res.directory.directory.leadStatus.map((el) => el.color));
        setLeadsStatus(res.directory.directory.leadStatus);
        setLeadsSources(res.directory.directory.leadSources);
        setLeads(res.leads);
        setTotalAmount(TotalAmount);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const tableFilter = (e) => {
    e.preventDefault();
    let TotalAmount = 0;
    props.setIsLoading(true);
    leadsApi
      .getFunnel({
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(
          res.funnelInfo.map((el) => {
            return {
              name: res.directory.directory.leadStatus.find(
                (st) => st._id === el.status
              ).value,
              ids: el.ids,
              value: el.ids.length,
              statusId: res.directory.directory.leadStatus.find(
                (st) => st._id === el.status
              )._id,
            };
          })
        );
        res.funnelInfo.forEach((el) => (TotalAmount += el.ids.length));
        setTotalAmount(TotalAmount);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    let TotalAmount = 0;
    props.setIsLoading(true);
    leadsApi
      .getFunnel({})
      .then((res) => {
        setFinder({
          f_source: "",
        });
        setF_datef("");
        setF_datet("");
        setData(
          res.funnelInfo.map((el) => {
            return {
              name: res.directory.directory.leadStatus.find(
                (st) => st._id === el.status
              ).value,
              ids: el.ids,
              value: el.ids.length,
              statusId: res.directory.directory.leadStatus.find(
                (st) => st._id === el.status
              )._id,
            };
          })
        );
        res.funnelInfo.forEach((el) => (TotalAmount += el.ids.length));
        setTotalAmount(TotalAmount);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <Fade>
      <>
        <Accordion className="mb-2 mt-2">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Поиск</Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={tableFilter}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Источник
                  </Form.Label>
                  <Col sm={10}>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет источников"}
                      options={
                        leadsSources === null
                          ? {}
                          : leadsSources.map((el) => {
                              return {
                                value: el._id,
                                label: el.value,
                              };
                            })
                      }
                      value={
                        finder.f_source === ""
                          ? {}
                          : {
                              value: leadsSources.find(
                                (el) => el._id === finder.f_source
                              )._id,
                              label: leadsSources.find(
                                (el) => el._id === finder.f_source
                              ).value,
                            }
                      }
                      type="text"
                      name="f_source"
                      onChange={(data) =>
                        setFinder({
                          ...finder,
                          f_source: data.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Дата создания c
                  </Form.Label>
                  <Col sm={10}>
                    <Flatpickr
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={f_datef}
                      options={{
                        time_24hr: true,
                        locale: Russian,
                        dateFormat: "d.m.Y",
                        disableMobile: true,
                      }}
                      onChange={([date]) => {
                        setF_datef(new Date(date));
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Дата по
                  </Form.Label>
                  <Col sm={10}>
                    <Flatpickr
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={f_datet}
                      options={{
                        time_24hr: true,
                        locale: Russian,
                        dateFormat: "d.m.Y",
                        disableMobile: true,
                      }}
                      onChange={([date]) => {
                        setF_datet(new Date(date));
                      }}
                    />
                  </Col>
                </Form.Group>

                <Button
                  className="me-2"
                  variant="outline-secondary"
                  type="button"
                  onClick={resetFilter}
                >
                  Очистить
                </Button>
                <Button variant="primary" type="submit">
                  Поиск
                </Button>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Row className="p-3">
          <Col sm={7}>
            <Card className="align-items-center justify-content-center">
              {data !== null ? (
                <FunnelChart
                  style={{ maxWidth: 700, width: "100%" }}
                  data={data}
                  pallette={colors}
                  showRunningTotal={true}
                  onRowClick={(status) => setSelectedStatus(status)}
                />
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col sm={5}>
            <Card style={{ height: "100%" }}>
              {selectedStatus !== null ? (
                <>
                  <h3
                    style={{
                      color: leadsStatus.find(
                        (el) => el._id === selectedStatus.statusId
                      ).color,
                    }}
                    className="text-center"
                  >
                    {selectedStatus.name}
                  </h3>
                  <p className="fs-4 text-center mt-2">
                    В статусе {selectedStatus.value} (
                    {Math.round((selectedStatus.value / totalAmount) * 100)}%)
                    лидов
                  </p>
                  <ul>
                    {selectedStatus.ids.map((ld) => (
                      <li>
                        <Link to={"/admin/leads/table/" + ld}>
                          {leads.find((lead) => lead._id === ld).name +
                            (leads.find((lead) => lead._id === ld).secondName
                              ? " " +
                                leads.find((lead) => lead._id === ld).secondName
                              : "")}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <h3 className="fw-light text-center mt-4">
                  Выберите статус из воронки
                </h3>
              )}
            </Card>
          </Col>
        </Row>
      </>
    </Fade>
  );
}
