import React, { useState, useEffect } from "react";
import { api as accountsApi } from "../../../../../utils/api/api.accounts";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import LessonsTable from "./LessonsTable";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import FreezeModal from "./FreezeModal";
import SellModal from "./SellModal";

export default function AccountForm(props) {
  const [needUpdate, setNeedUpdate] = useState(true);
  const [status, setStatus] = useState(0);
  const [abonement, setAbonement] = useState(null);
  const [abonements, setAbonements] = useState(null);
  const [data, setData] = useState(null);
  const [choisenAbonement, setChoisenAbonement] = useState(null);
  const [newWillPayed, setNewWillPayed] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [isModalFreezeOpen, setIsModalFreezeOpen] = useState(false);
  const [isModalSellOpen, setIsModalSellOpen] = useState(false);

  useEffect(() => {
    if (needUpdate === true) {
      if ((props.data !== null) & (props.data !== undefined)) {
        props.setIsLoading(true);
        setStatus(2);
        accountsApi
          .getAccount(props.data)
          .then((res) => {
            setData(res.accountInfo);
            setAbonement(res.abonementInfo);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            setNeedUpdate(false);
            props.setIsLoading(false);
          });
      } else if (props.data === null) {
        props.setIsLoading(true);
        setStatus(1);
        accountsApi
          .getCreationData()
          .then((res) => {
            setAbonements(
              res.abonements.map((el) => {
                return { value: el._id, label: el.name, price: el.price };
              })
            );
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            setNeedUpdate(false);
            props.setIsLoading(false);
          });
      }
    }
  }, [needUpdate]);

  useEffect(() => {
    setNeedUpdate(true);
  }, [props.data]);

  const closeModal = () => {
    props.setModalOpen(false);
    props.setSelectedOperation(null);
    setData(null);
    setChoisenAbonement(null);
    setNewWillPayed(false);
    setStartDate(null);
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    accountsApi
      .createEmptyAccount({
        abonement: choisenAbonement.value,
        student: props.studentId,
      })
      .then((res) => {
        if (newWillPayed) {
          props.setIsLoading(true);
          accountsApi
            .createPayedAccount({
              accountId: res.account._id,
              studentId: res.account.student,
              abonementId: res.account.abonement,
              startDate: startDate,
              date: new Date(),
            })
            .then(() => {
              props.setNeedUpdate(true);
              closeModal();
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            })
            .finally(() => props.setIsLoading(false));
        } else {
          closeModal();
          props.setNeedUpdate(true);
        }
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const freezeAccount = (date) => {
    if (data !== undefined) {
      if (data.isFrozen === true) {
        accountsApi
          .unfreezeAccount({
            accountId: data._id,
            startDate: date,
          })
          .then(() => {
            props.setNeedUpdate(true);
            setNeedUpdate(true);
            setIsModalFreezeOpen(false);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else if (data.isFrozen === false) {
        accountsApi
          .freezeAccount({
            accountId: data._id,
            startDate: date,
          })
          .then(() => {
            props.setNeedUpdate(true);
            setNeedUpdate(true);
            setIsModalFreezeOpen(false);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      }
    }
  };

  const sellAbonement = (date) => {
    if (data !== undefined) {
      accountsApi
        .createPayedAccount({
          accountId: data._id,
          studentId: data.student,
          abonementId: data.abonement,
          startDate: date,
          date: new Date(),
        })
        .then(() => {
          props.setNeedUpdate(true);
          setNeedUpdate(true);
          setIsModalSellOpen(false);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };

  const changeActive = (date) => {
    if (data !== undefined) {
      accountsApi
        .changeActivityAccount({
          accountId: data._id,
          isActive: !data.isActive,
        })
        .then(() => {
          props.setNeedUpdate(true);
          setNeedUpdate(true);
          setIsModalSellOpen(false);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={props.modalOpen}
        onHide={closeModal}
        fullscreen="lg-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {status !== 1
              ? abonement !== null
                ? abonement.name
                : ""
              : "Новый счет"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(status === 1) & (abonements !== null) ? (
            <Form className={` ${status === 2 ? "d-none" : "d-block"}`}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  Абонемент
                </Form.Label>
                <Col sm={9}>
                  <Select
                    placeholder=""
                    options={abonements}
                    value={abonements.find((el) => el._id === choisenAbonement)}
                    type="text"
                    name="choisenAbonement"
                    onChange={(data) => setChoisenAbonement(data)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Col sm={10}>
                  <Form.Check
                    type="switch"
                    label="Пополнить абонемент"
                    name="newWillPayed"
                    checked={newWillPayed}
                    onChange={() => setNewWillPayed(!newWillPayed)}
                  />
                </Col>
              </Form.Group>
              {newWillPayed & (choisenAbonement !== null) ? (
                <>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Дата начала
                    </Form.Label>
                    <Col sm={10}>
                      <Flatpickr
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={startDate}
                        options={{
                          time_24hr: true,
                          disableMobile: true,
                          locale: Russian,
                        }}
                        name="birthday"
                        onChange={([date]) => {
                          setStartDate(date);
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Text muted>
                    Со счета будет списано {choisenAbonement.price}
                  </Form.Text>
                </>
              ) : (
                ""
              )}
            </Form>
          ) : (status === 2) & (data !== null) ? (
            <>
              <p className="mb-2">Баланс:</p>
              {(data.type === 1 || data.type === 3) && (
                <p className="m-0 mt-0">
                  Занятий:{" "}
                  <span
                    className={`fw-bold ${
                      data.balance === 0 ? "text-danger" : "text-success"
                    }`}
                  >
                    {data.balance}
                  </span>
                </p>
              )}
              {(data.type === 2 || data.type === 3) && (
                <p className="mt-0 mb-3">
                  Срок действия:{" "}
                  {data.period.length === 0 ? (
                    <span className="text-danger fw-bold">
                      Нет оплаченных периодов
                    </span>
                  ) : (
                    <span
                      className={`fw-bold ${
                        new Date() >
                        new Date(
                          new Date(
                            data.period[data.period.length - 1].startDate
                          ).setDate(
                            new Date(
                              data.period[data.period.length - 1].startDate
                            ).getDate() +
                              data.period[data.period.length - 1].amountDays
                          )
                        )
                          ? "text-danger"
                          : "text-success"
                      }`}
                    >
                      {new Date(
                        new Date(
                          data.period[data.period.length - 1].startDate
                        ).setDate(
                          new Date(
                            data.period[data.period.length - 1].startDate
                          ).getDate() +
                            data.period[data.period.length - 1].amountDays
                        )
                      ).toLocaleString("ru-RU", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </span>
                  )}
                </p>
              )}
              <ButtonGroup
                className={` mt-2 mb-3 ${status === 1 ? "d-none" : "d-block"}`}
              >
                {/* <Button
                  variant={data.isActive === true ? "danger" : "success"}
                  onClick={changeActive}
                >
                  {data.isActive === true
                    ? "Деактивировать"
                    : "Активировать"}
                </Button> */}
                {data.isFrozen ? (
                  <Button
                    variant="info"
                    onClick={() => setIsModalFreezeOpen(true)}
                  >
                    Разморозить
                  </Button>
                ) : (
                  <Button
                    variant="info"
                    onClick={() => setIsModalFreezeOpen(true)}
                  >
                    Заморозить
                  </Button>
                )}
                {data.isFrozen ? (
                  ""
                ) : (
                  <Button
                    variant="success"
                    onClick={() => setIsModalSellOpen(true)}
                  >
                    Пополнить
                  </Button>
                )}
              </ButtonGroup>
              <Accordion className="mb-2" defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Операции</Accordion.Header>
                  <Accordion.Body>
                    <LessonsTable
                      setIsLoading={props.setIsLoading}
                      setIsError={props.setIsError}
                      setError={props.setError}
                      accountId={data._id}
                      needUpdate={needUpdate}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Закрыть
          </Button>
          {status === 1 ? (
            <Button variant="primary" type="submit" onClick={handlerSubmit}>
              Создать
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      <FreezeModal
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        data={data}
        isOpen={isModalFreezeOpen}
        close={setIsModalFreezeOpen}
        submit={freezeAccount}
      />
      <SellModal
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        data={data}
        isOpen={isModalSellOpen}
        close={setIsModalSellOpen}
        submit={sellAbonement}
      />
    </>
  );
}
