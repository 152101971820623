import React, { useState, useEffect } from "react";
import {
  Accordion,
  Form,
  Button,
  Col,
  Row,
  Table,
  Popover,
  OverlayTrigger,
  ListGroup,
  Card,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import { api as financeReport } from "../../../../utils/api/Reports/api.finance";

export default function FinanceStatistic(props) {
  const [data, setData] = useState(null);

  const [f_paymentt, setF_paymentt] = useState("");
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");

  const [paymentTypes, setPaymentTypes] = useState(null);

  useEffect(() => {
    props.setIsLoading(true);
    financeReport
      .getCreationData()
      .then(({ paymentTypes }) => {
        setPaymentTypes(paymentTypes[0].directory.paymentTypes);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const resetFilter = (e) => {
    setF_paymentt("");
    setData(null);
    setF_datef("");
    setF_datet("");
  };

  const handleFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    financeReport
      .getReport({ f_paymentt, f_datef, f_datet })
      .then(({ report }) => {
        setData(report);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <>
      <Accordion
        className="mb-2 mt-2"
        defaultActiveKey={["0"]}
        alwaysOpen={"0"}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Параметры отчета</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={handleFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Способ расчета
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет способов расчета"}
                    options={
                      paymentTypes === null
                        ? {}
                        : paymentTypes.map((el) => {
                            return {
                              value: el._id,
                              label: el.value,
                            };
                          })
                    }
                    value={
                      f_paymentt === ""
                        ? {}
                        : {
                            value: paymentTypes.find(
                              (el) => el._id === f_paymentt
                            )._id,
                            label: paymentTypes.find(
                              (el) => el._id === f_paymentt
                            ).value,
                          }
                    }
                    type="text"
                    name="f_paymentt"
                    onChange={(data) => {
                      setF_paymentt(data.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата c
                </Form.Label>
                <Col sm={4}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datef}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      dateFormat: "d.m.Y",
                      disableMobile: true,
                    }}
                    onChange={([date]) => {
                      setF_datef(new Date(date));
                    }}
                  />
                </Col>
                <Form.Label column sm={2}>
                  Дата по
                </Form.Label>
                <Col sm={4}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datet}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      dateFormat: "d.m.Y",
                      disableMobile: true,
                    }}
                    onChange={([date]) => {
                      setF_datet(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {data ? (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h2 className="mb-0">Финансовые показатели</h2>
          <h4 className="mt-1">
            {" "}
            с{" "}
            {new Date(f_datef).toLocaleString("ru-RU", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}{" "}
            по{" "}
            {new Date(f_datet).toLocaleString("ru-RU", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </h4>
          <h3 className="mt-3">Общая статистика</h3>
          <div className="d-flex" style={{ width: "80%" }}>
            <Card
              style={{ width: "50%" }}
              className="justify-content-center flex-column align-items-center"
            >
              <h4 className="fw-light text-success">Доходы</h4>
              <h4 className="fw-bold text-success">
                {data.total.plus.toFixed(2)}
              </h4>
            </Card>
            <Card
              style={{ width: "50%" }}
              className="justify-content-center flex-column align-items-center"
            >
              <h4 className="fw-light text-danger">Расходы</h4>
              <h4 className="fw-bold text-danger">
                {data.total.minus.toFixed(2)}
              </h4>
            </Card>
          </div>
          <Card
            style={{ width: "80%" }}
            className="justify-content-center flex-column align-items-center"
          >
            <h4
              className={`fw-light text-${
                data.total.total > 0 ? "success" : "danger"
              }`}
            >
              Прибыль
            </h4>
            <h4
              className={`fw-bold text-${
                data.total.total > 0 ? "success" : "danger"
              }`}
            >
              {data.total.total.toFixed(2)}
            </h4>
          </Card>
          <h3 className="mt-3">Продажи абонементов</h3>
          <Card style={{ width: "80%" }}>
            <ListGroup variant="flush">
              <ListGroup.Item className="text-center">
                Пополнено балансов на: <b>{data.money.sumPayed.toFixed(2)}</b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                С учетом скидок: <b>{data.money.sumPayedWithDisc.toFixed(2)}</b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  rootClose={true}
                  overlay={
                    <Popover placement="auto">
                      <Popover.Body>
                        Разница между пополнением и суммой, которую заплатили
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span className="text-decoration-underline" style={{cursor: 'pointer'}}>Расходы по скидкам</span>
                </OverlayTrigger>
                : <b>{data.money.discDiff.toFixed(2)}</b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  rootClose={true}
                  overlay={
                    <Popover placement="auto">
                      <Popover.Body>Сумма без скидок</Popover.Body>
                    </Popover>
                  }
                >
                  <span className="text-decoration-underline" style={{cursor: 'pointer'}}>Средствами баланса оплачено на</span>
                </OverlayTrigger>
                : <b>{data.money.sumSpent.toFixed(2)}</b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
               
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  rootClose={true}
                  overlay={
                    <Popover placement="auto">
                      <Popover.Body>
                        Балансы пополнены, но абонементы не куплены
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span className="text-decoration-underline" style={{cursor: 'pointer'}}> Лежит на балансах</span>
                </OverlayTrigger>
                :{" "}
                <b>
                  {data.money.sumPayed.toFixed(2) -
                    data.money.sumSpent.toFixed(2)}
                </b>
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <h3 className="mt-3">Зарплаты</h3>
          <Card style={{ width: "80%" }}>
            <ListGroup variant="flush">
              <ListGroup.Item className="text-center">
                Начислено: <b>{data.salaries.sumToPay.toFixed(2)}</b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                Выплачено: <b>{data.salaries.sumPayed.toFixed(2)}</b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  rootClose={true}
                  overlay={
                    <Popover placement="auto">
                      <Popover.Body>
                        Занятия проведены, средства начислены, но не выплачены.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span className="text-decoration-underline" style={{cursor: 'pointer'}}>Еще не выплачено</span>
                </OverlayTrigger>
                : <b>{data.salaries.notPayed.toFixed(2)}</b>
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <h3 className="mt-3">
            <OverlayTrigger
              trigger="click"
              placement="right"
              rootClose={true}
              overlay={
                <Popover placement="auto">
                  <Popover.Body>Операции из раздела финансы</Popover.Body>
                </Popover>
              }
            >
              <span className="text-decoration-underline" style={{cursor: 'pointer'}}> Другие операции</span>
            </OverlayTrigger>
          </h3>
          <Card style={{ width: "80%" }}>
            <ListGroup variant="flush">
              <ListGroup.Item className="text-center text-success">
                Доход: <b>{data.finance.plus.toFixed(2)}</b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center text-danger">
                Расход: <b>{data.finance.minus.toFixed(2)}</b>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
