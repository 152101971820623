import React, { useState, useEffect } from "react";
import {
  Accordion,
  Form,
  Button,
  Col,
  Row,
  Table,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Timeline from "react-calendar-timeline";
import moment from "moment";

import { api as reportApi } from "../../../../utils/api/Reports/api.worktime";

export default function Worktime(props) {
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [worktime, setWorktime] = useState(null);
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [f_date, setF_date] = useState("");
  const [finder, setFinder] = useState({
    f_user: "",
    f_userty: "",
  });

  const typesOfUser = [
    { value: 1, label: "Администратор" },
    { value: 2, label: "Педагог" },
    { value: 3, label: "Родитель" },
  ];

  const timeRender = ({ s, m, h, d }) => {
    return (
      <>
        <p className="m-0">{h ? <p className="m-0">{h + " часов"}</p> : ""}</p>
        <p className="m-0">{m ? <p className="m-0">{m + " минут"}</p> : ""}</p>
        <p className="m-0">{s ? <p className="m-0">{s + " секунд"}</p> : ""}</p>
      </>
    );
  };

  useEffect(() => {
    props.setIsLoading(true);
    reportApi
      .getCreationData()
      .then(({ users }) => {
        setUsers(users);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const methodToHuman = (method) => {
    if (!method) return "";
    const methods = {
      POST: "Создание",
      PUT: "Проведение операции",
      PATCH: "Обновление",
      DELETE: "Удаление",
      GET: "Чтение",
    };
    return methods[method];
  };

  const urlToHuman = (url) => {
    if (!url) return "";
    let readble;
    if (url.match("leads")) readble = <p className="m-0">Модуль: Лиды</p>;
    else if (url.match("students"))
      readble = <p className="m-0">Модуль: Ученики</p>;
    else if (url.match("settings"))
      readble = <p className="m-0">Модуль: Настройки</p>;
    else if (url.match("user"))
      readble = <p className="m-0">Модуль: Пользователи</p>;
    else if (url.match("dances"))
      readble = <p className="m-0">Модуль: Направления</p>;
    else if (url.match("teachers"))
      readble = <p className="m-0">Модуль: Педагоги</p>;
    else if (url.match("salaries"))
      readble = <p className="m-0">Модуль: Зарплаты</p>;
    else if (url.match("actionscrm"))
      readble = <p className="m-0">Модуль: Действия CRM</p>;
    else if (url.match("tasks"))
      readble = <p className="m-0">Модуль: Задачи</p>;
    else if (url.match("discounts"))
      readble = <p className="m-0">Модуль: Скидки</p>;
    else if (url.match("groups"))
      readble = <p className="m-0">Модуль: Группы</p>;
    else if (url.match("abonements"))
      readble = <p className="m-0">Модуль: Абонементы</p>;
    else if (url.match("accounts"))
      readble = <p className="m-0">Модуль: Счета</p>;
    else if (url.match("lessons"))
      readble = <p className="m-0">Модуль: Занятия</p>;
    else if (url.match("documents"))
      readble = <p className="m-0">Модуль: Документы</p>;
    else if (url.match("operations/money"))
      readble = <p className="m-0">Модуль: Операции/Деньги</p>;
    else if (url.match("operations/bonuce"))
      readble = <p className="m-0">Модуль: Операции/Бонусы</p>;
    else if (url.match("operations/credits"))
      readble = <p className="m-0">Модуль: Операции/Долги</p>;
    else if (url.match("operations/finance"))
      readble = <p className="m-0">Модуль: Операции/Финансы</p>;
    else if (url.match("operations/finance"))
      readble = <p className="m-0">Модуль: Операции/Финансы</p>;
    else if (url.match("operations/lessons"))
      readble = <p className="m-0">Модуль: Операции/Счета</p>;
    else if (url.match("/operations/"))
      readble = <p className="m-0">Модуль: Операции</p>;
    else if (url.match("/reports/"))
      readble = <p className="m-0">Модуль: Отчеты</p>;
    else if (url.match("/wap"))
      readble = <p className="m-0">Модуль: WhatsApp</p>;
    else readble = <p className="m-0">{url}</p>;
    return readble;
  };

  const handleFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    reportApi
      .getReport({ ...finder, f_date, f_datef, f_datet })
      .then(({ report, users, workTime }) => {
        setUsersData(
          users.map((el) => {
            return {
              id: el._id,
              title: (
                <>
                  <p className="m-0">{`${el.firstName} ${el.secondName}`}</p>
                  {el.job ? <p className="m-0 fw-light">{el.job}</p> : ""}
                  <p className="m-0">
                    Итого:
                    {report.find((rp) => rp.user._id === el._id).total
                      ? timeRender(
                          report.find((rp) => rp.user._id === el._id).total
                        )
                      : "0"}
                  </p>
                </>
              ),
            };
          })
        );
        setData(report ? (report.length !== 0 ? report : null) : null);
        setWorktime(
          workTime.map((el) => {
            return {
              id: el._id,
              group: el.user,
              title: `${methodToHuman(el.method)} | ${el.url}`,
              start_time: moment(el.date),
              end_time: moment(el.date).add(3, "minutes"),
              method: methodToHuman(el.method),
              url: urlToHuman(el.url),
              orUrl: el.url,
              userAgent: el.userAgentPars,
              userAgentUnparse: el.userAgent,
            };
          })
        );
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    setUsersData(null);
    setData(null);
    setWorktime(null);
    setFinder({
      f_user: "",
      f_userty: "",
    });
    setF_date("");
    setF_datef("");
    setF_datet("");
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected
      ? itemContext.dragging
        ? "red"
        : item.selectedBgColor
      : item.bgColor;
    const borderColor = itemContext.resizing ? "red" : item.color;
    return (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose={true}
        overlay={
          <Popover>
            <Popover.Header as="h3">{item.method}</Popover.Header>
            <Popover.Body>
              {item.url}
              {item.userAgent ? (
                <p className="m-0">
                  {item.userAgent.browser.name
                    ? item.userAgent.browser.name + " | "
                    : ""}
                  {item.userAgent.os.name ? item.userAgent.os.name + " | " : ""}
                  {item.userAgent.device.model
                    ? item.userAgent.device.model + " | "
                    : ""}
                </p>
              ) : (
                ""
              )}

              <OverlayTrigger
                trigger="click"
                placement="auto"
                rootClose={true}
                overlay={
                  <Popover>
                    <Popover.Body>{item.userAgentUnparse}</Popover.Body>
                  </Popover>
                }
              >
                <p className="text-decoration-underline m-0">UA</p>
              </OverlayTrigger>
              <OverlayTrigger
                trigger="click"
                placement="auto"
                rootClose={true}
                overlay={
                  <Popover>
                    <Popover.Body>{item.orUrl}</Popover.Body>
                  </Popover>
                }
              >
                <p className="text-decoration-underline m-0">URL</p>
              </OverlayTrigger>
            </Popover.Body>
          </Popover>
        }
      >
        <div
          {...getItemProps({
            style: {
              backgroundColor,
              color: item.color,
              borderColor,
              borderStyle: "solid",
              borderWidth: 1,
              borderRadius: 4,
              borderLeftWidth: itemContext.selected ? 3 : 1,
              borderRightWidth: itemContext.selected ? 3 : 1,
            },
          })}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: "hidden",
              paddingLeft: 3,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {itemContext.title}
          </div>

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <Accordion
        className="mb-2 mt-2"
        defaultActiveKey={["0"]}
        alwaysOpen={"0"}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Параметры отчета</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={handleFilter}>
              {(finder.f_userty !== "") & (finder.f_user === "") ? (
                ""
              ) : (
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Пользователь
                  </Form.Label>
                  <Col sm={10}>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет пользователей"}
                      options={
                        users === null
                          ? {}
                          : users.map((el) => {
                              return {
                                value: el._id,
                                label:
                                  el.firstName +
                                  " " +
                                  el.secondName +
                                  (el.job ? ", " + el.job : " "),
                              };
                            })
                      }
                      value={
                        finder.f_user === ""
                          ? {}
                          : {
                              value: users.find(
                                (el) => el._id === finder.f_user
                              )._id,
                              label:
                                users.find((el) => el._id === finder.f_user)
                                  .firstName +
                                " " +
                                users.find((el) => el._id === finder.f_user)
                                  .secondName +
                                (users.find((el) => el._id === finder.f_user)
                                  .job
                                  ? ", " +
                                    users.find((el) => el._id === finder.f_user)
                                      .job
                                  : ""),
                            }
                      }
                      type="text"
                      name="f_user"
                      onChange={(data) =>
                        setFinder({
                          ...finder,
                          f_user: data.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              )}

              {(finder.f_user !== "") & (finder.f_userty === "") ? (
                ""
              ) : (
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Группа пользователей
                  </Form.Label>
                  <Col sm={10}>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет пользователей"}
                      options={typesOfUser}
                      value={
                        finder.f_userty === ""
                          ? {}
                          : {
                              value: typesOfUser.find(
                                (el) => el.value === finder.f_userty
                              ).value,
                              label: typesOfUser.find(
                                (el) => el.value === finder.f_userty
                              ).label,
                            }
                      }
                      type="text"
                      name="f_userty"
                      onChange={(data) =>
                        setFinder({
                          ...finder,
                          f_userty: data.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              )}

              {(f_date !== "") & (f_datef === "" || f_datet === "") ? (
                ""
              ) : (
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Дата c
                  </Form.Label>
                  <Col sm={4}>
                    <Flatpickr
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={f_datef}
                      options={{
                        time_24hr: true,
                        locale: Russian,
                        dateFormat: "d.m.Y",
                        disableMobile: true,
                      }}
                      onChange={([date]) => {
                        setF_datef(new Date(date));
                      }}
                    />
                  </Col>
                  <Form.Label column sm={2}>
                    Дата по
                  </Form.Label>
                  <Col sm={4}>
                    <Flatpickr
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={f_datet}
                      options={{
                        time_24hr: true,
                        locale: Russian,
                        dateFormat: "d.m.Y",
                        disableMobile: true,
                      }}
                      onChange={([date]) => {
                        setF_datet(new Date(date));
                      }}
                    />
                  </Col>
                </Form.Group>
              )}
              {(f_datef !== "" || f_datet !== "") & (f_date === "") ? (
                ""
              ) : (
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Дата
                  </Form.Label>
                  <Col sm={10}>
                    <Flatpickr
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={f_date}
                      options={{
                        time_24hr: true,
                        locale: Russian,
                        dateFormat: "d.m.Y",
                        disableMobile: true,
                      }}
                      onChange={([date]) => {
                        setF_date(new Date(date));
                      }}
                    />
                  </Col>
                </Form.Group>
              )}
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {data ? (
        f_datef !== "" || f_datet !== "" ? (
          <div style={{ overflow: "scroll" }}>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Сотрудник</th>
                  {data[0].days.map((el, index) => (
                    <th key={index}>
                      {new Date(el.date).toLocaleString("ru-RU", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {el.user.firstName} {el.user.secondName}
                      {el.user.job ? ", " + el.user.job : ""}
                    </td>
                    {el.days.map((day, ind) => (
                      <td key={ind}>
                        {day.total ? timeRender(day.total) : "0"}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <Timeline
            groups={usersData}
            items={worktime}
            canMove={false}
            stackItems
            canResize={false}
            defaultTimeStart={moment().add(-12, "hour")}
            defaultTimeEnd={moment().add(12, "hour")}
            itemRenderer={itemRenderer}
          />
        )
      ) : (
        ""
      )}
    </>
  );
}
