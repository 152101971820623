import React from "react";
import { UserContext } from "../../../context/UserContext";
import TotalTime from "./TotalTime";
import Lessons from "./Lessons";
import ActionsCRM from "./ActionsCRM";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tasks from "./Tasks";
import Finance from "./Finance";
import Credits from "./Credits";

const Dashboard = (props) => {
  const userContext = React.useContext(UserContext);
  return (
    <div className="p-3">
      <h3>
        {new Date().getHours() < 5
          ? "Доброй ночи"
          : (new Date().getHours() > 5) & (new Date().getHours() < 11)
          ? "Доброе утро"
          : (new Date().getHours() > 11) & (new Date().getHours() < 16)
          ? "Добрый день"
          : (new Date().getHours() > 16) & (new Date().getHours() < 23)
          ? "Добрый вечер"
          : (new Date().getHours() > 23) & (new Date().getHours() < 24)
          ? "Доброй ночи"
          : "Здраствуйте"}
        ,{" "}
        {userContext.userName !== undefined
          ? userContext.userName
          : "Пользователь"}
        !
      </h3>
      <Row>
        {userContext.access.REPfinanceStatistic ? (
          <Col md={6} xl={4}>
            <Finance setIsError={props.setIsError} setError={props.setError} />
          </Col>
        ) : (
          ""
        )}
        {userContext.access.lessons ? (
          <Col md={6} xl={4}>
            <Lessons setIsError={props.setIsError} setError={props.setError} />
          </Col>
        ) : (
          ""
        )}
        {userContext.access.creditOperations ? (
          <Col md={6} xl={4}>
            <Credits setIsError={props.setIsError} setError={props.setError} />
          </Col>
        ) : (
          ""
        )}
        {userContext.access.actionsCRM ? (
          <Col md={6} xl={4}>
            <ActionsCRM
              setIsError={props.setIsError}
              setError={props.setError}
            />
          </Col>
        ) : (
          ""
        )}
        {userContext.access.tasks ? (
          <Col md={6} xl={4}>
            <Tasks setIsError={props.setIsError} setError={props.setError} />
          </Col>
        ) : (
          ""
        )}
        <Col md={6} xl={4}>
          <TotalTime setIsError={props.setIsError} setError={props.setError} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
