import React, {useState} from "react";
import Nav from "react-bootstrap/Nav";
import { UserContext } from "../../../context/UserContext";
import ActionCRMCalendar from "./ActionCRMCalendar";
import ActionCRMTable from "./ActionCRMTable";
import ActionCRMForm from "./ActionCRMForm";
import ActionsCRMKanBan from "./ActionsCRMKanBan";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

const ActionCRM = (props) => {
  const userContext = React.useContext(UserContext);
  const [tableState, setTableState] = useState(null);
  const [scheduleState, setScheduleState] = useState(null);
  const [kanbanState, setKanbanState] = useState(null);

  const location = useLocation();
  const navigation = useNavigate();

  const handleSelect = (eventKey) => {
    navigation(eventKey);
  };

  return (
    <>
      <Nav
        variant="tabs"
        onSelect={handleSelect}
        defaultActiveKey="schedule"
        activeKey={
          location.pathname.includes("calendar")
            ? "calendar"
            : location.pathname.includes("table")
            ? "table"
            : location.pathname.includes("kanban")
            ? "kanban"
            : ""
        }
      >
        <Nav.Item>
          <Nav.Link eventKey="calendar">Календарь</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="table">Таблица</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="kanban">Канбан</Nav.Link>
        </Nav.Item>
      </Nav>
      <Routes>
        <Route
          path="/table/"
          element={
            <ActionCRMTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              setTableState={setTableState}
              tableState={tableState}
            />
          }
        />
        <Route
          path="/calendar/"
          element={
            <ActionCRMCalendar
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              scheduleState={scheduleState}
              setScheduleState={setScheduleState}
            />
          }
        />
        <Route
          path="/kanban/"
          element={
            <ActionsCRMKanBan
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              kanbanState={kanbanState}
              setKanbanState={setKanbanState}
            />
          }
        />
        <Route
          path="/table/:id"
          element={
            <ActionCRMForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/calendar/:id"
          element={
            <ActionCRMForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/kanban/:id"
          element={
            <ActionCRMForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default ActionCRM;
