import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import { api as teachersApi } from "../../../utils/api/api.teachers";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/themes/light.css";
import Accordion from "react-bootstrap/Accordion";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Salaries from "./Salaries/SalariesTable";

export default function TeachersForm(props) {
  const userContext = useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [teacher, setTeacher] = useState({
    _id: "",
    firstName: "",
    secondName: "",
    photo: "",
    email: "",
    mobile: "",
    user: "",
    moneyBalance: "",
    recommendSalary: "",
    isActive: "",
    owner: "",
    comment: "",
  });
  const [birthday, setBirthday] = useState("");
  const [users, setUsers] = useState([]);
  const [owner, setOwner] = useState({});
  const [needUpdate, setNeedUpdate] = useState(true);
  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    setTeacher({ ...teacher, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
    } else {
      if (needUpdate) {
        setStatus(2);
        props.setIsLoading(true);
        teachersApi
          .getTeacher(id)
          .then((res) => {
            setTeacher({
              _id: res.teacher._id,
              firstName: res.teacher.firstName,
              secondName: res.teacher.secondName,
              photo: res.teacher.photo,
              email: res.teacher.email,
              birthday: res.teacher.birthday,
              lead: res.teacher.lead,
              mobile: res.teacher.mobile,
              moneyBalance: res.teacher.moneyBalance,
              recommendSalary: res.teacher.recommendSalary,
              user: res.teacher.user,
              isActive: res.teacher.isActive,
              owner: res.teacher.owner,
            });
            setBirthday(res.teacher.birthday);
            setUsers(res.users);
            setOwner(res.users.find((user) => user._id === res.teacher.owner));
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
        setNeedUpdate(false);
      }
    }
  }, [needUpdate]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    if (status === 1) {
      teachersApi
        .postTeacher({ ...teacher, birthday: birthday })
        .then((res) => {
          navigation("/admin/teachers/" + res.newTeacher._id);
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else if (status === 3) {
      teachersApi
        .editTeacher({ ...teacher, birthday: birthday })
        .then((res) => {
          teachersApi
            .getTeacher(teacher._id)
            .then((res) => {
              setTeacher({
                _id: res.teacher._id,
                firstName: res.teacher.firstName,
                secondName: res.teacher.secondName,
                photo: res.teacher.photo,
                email: res.teacher.email,
                birthday: res.teacher.birthday,
                lead: res.teacher.lead,
                mobile: res.teacher.mobile,
                moneyBalance: res.teacher.moneyBalance,
                recommendSalary: res.teacher.recommendSalary,
                user: res.teacher.user,
                isActive: res.teacher.isActive,
                owner: res.teacher.owner,
              });
              console.log(res.teacher);
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            });
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };

  return (
    <Accordion className="mb-2" defaultActiveKey={["0"]} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Ученик</Accordion.Header>
        <Accordion.Body>
          <Form onSubmit={onSubmit}>
            <Card>
              <Card.Body>
                <Card.Title>
                  {/* <i
                    className="bi bi-chevron-compact-left me-3"
                    style={{ fontSize: 30, cursor: "pointer" }}
                    onClick={() => navigation(-1)}
                  /> */}
                  <Row className="g-2" style={{ maxWidth: 630 }}>
                    <Col sm>
                      <Form.Group className="mb-3">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                          value={teacher.firstName}
                          type="text"
                          name="firstName"
                          placeholder="Имя"
                          onChange={handleChange}
                          disabled={status === 2 ? true : false}
                          plaintext={status === 2 ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm>
                      <Form.Group className="mb-3">
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control
                          type="text"
                          name="secondName"
                          placeholder="Фамилия"
                          onChange={handleChange}
                          value={teacher.secondName}
                          disabled={status === 2 ? true : false}
                          plaintext={status === 2 ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <i
                    className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                      status === 2 ? "d-flex" : "d-none"
                    }`}
                    style={{ fontSize: 36, cursor: "pointer" }}
                    onClick={() => setStatus(3)}
                  ></i>
                </Card.Title>
                <Card.Text>
                  <Form.Group
                    as={Row}
                    className={`mb-3 ${status === 2 ? "d-flex" : "d-none"}`}
                  >
                    <Form.Label column sm={3}>
                      Баланс
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        value={teacher.moneyBalance}
                        name="moneyBalance"
                        plaintext
                        disabled
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                      E-mail
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        name="email"
                        onChange={handleChange}
                        value={teacher.email}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                      День рождения
                    </Form.Label>
                    <Col sm={9}>
                      <Flatpickr
                        className={`form-control ${
                          status === 2 && "form-control-plaintext"
                        }`}
                        style={{ backgroundColor: "transparent" }}
                        value={birthday}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          disableMobile: true,
                          dateFormat: "d.m.Y",
                        }}
                        name="birthday"
                        onChange={([date]) => {
                          setBirthday(date);
                        }}
                        disabled={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  {userContext.access.salaries ? (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={3}>
                        Зарплата <small>(рекомендованная)</small>
                      </Form.Label>
                      <Col sm={9} className="d-flex">
                        <FloatingLabel
                          label="Группы"
                          className="me-3"
                        >
                          <Form.Control
                            type="text"
                            name="recommendedSalaryGroup"
                            placeholder="Группы"
                            onChange={(e) => {
                                setTeacher({ ...teacher, recommendSalary: { ...teacher.recommendSalary, group: e.target.value} });
                            }}
                            value={teacher.recommendSalary.group}
                            disabled={status === 2 ? true : false}
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          label="Инд."
                          className="me-3"
                        >
                          <Form.Control
                            type="text"
                            name="recommendedSalaryGroup"
                            placeholder="Инд."
                            onChange={(e) => {
                                setTeacher({ ...teacher, recommendSalary: { ...teacher.recommendSalary, individual: e.target.value} });
                            }}
                            value={teacher.recommendSalary.individual}
                            disabled={status === 2 ? true : false}
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          label="Другое"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Другое"
                            name="recommendedSalaryGroup"
                            onChange={(e) => {
                                setTeacher({ ...teacher, recommendSalary: { ...teacher.recommendSalary, other: e.target.value} });
                            }}
                            value={teacher.recommendSalary.other}
                            disabled={status === 2 ? true : false}
                          />
                        </FloatingLabel>
                      </Col>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                      Номер телефона
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        name="mobile"
                        onChange={handleChange}
                        value={teacher.mobile}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  {/* <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                      Пользователь
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        name="user"
                        onChange={handleChange}
                        value={teacher.user}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group> */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                      Комментарий
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        as="textarea"
                        type="text"
                        name="comment"
                        onChange={handleChange}
                        value={teacher.comment}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                      Активность
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select
                        name="isActive"
                        value={teacher.isActive}
                        onChange={handleChange}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      >
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Card.Text>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    className={`${status === 2 ? "d-none" : "d-block"}`}
                  >
                    Сохранить
                  </Button>
                </div>
              </Card.Body>
              <Form.Group
                className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}
              >
                <Form.Label>Создал пользователь</Form.Label>
                <Form.Control
                  value={
                    owner !== {} ? owner.firstName + " " + owner.secondName : ""
                  }
                  type="text"
                  name="owner"
                  disabled
                  plaintext
                />
              </Form.Group>
            </Card>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
      {userContext.access.teachers & (status === 2) ? (
        <Accordion.Item eventKey="1">
          <Accordion.Header>Начисления</Accordion.Header>
          <Accordion.Body>
            <Salaries
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              teacherId={teacher._id}
              setNeedUpdate={setNeedUpdate}
            />
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        ""
      )}
    </Accordion>
  );
}
