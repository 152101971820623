import React from "react";
import Flatpickr from "react-flatpickr";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Russian } from "flatpickr/dist/l10n/ru.js";

export default function DaySelector(props) {
  return (
    <div className="d-flex justify-content-center">
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} className="fw-bold">
          Дата
        </Form.Label>
        <Col sm={6}>
          <Form.Select
            name="monthSelector"
            value={new Date(props.dateFrom).getMonth()}
            onChange={(e) => {
              props.setDateFrom(
                new Date(props.dateFrom).setMonth(Number(e.target.value), 1)
              );
              props.setDateTo(
                new Date(props.dateFrom).setMonth(
                  Number(e.target.value) === 11
                    ? 0
                    : Number(e.target.value) + 1,
                  1
                )
              );
              props.setNeedUpdate(true);
            }}
          >
            <option value={0}>Январь</option>
            <option value={1}>Февраль</option>
            <option value={2}>Март</option>
            <option value={3}>Апрель</option>
            <option value={4}>Май</option>
            <option value={5}>Июнь</option>
            <option value={6}>Июль</option>
            <option value={7}>Август</option>
            <option value={8}>Сентябрь</option>
            <option value={9}>Октябрь</option>
            <option value={10}>Ноябрь</option>
            <option value={11}>Декабрь</option>
          </Form.Select>
          </Col>
          <Col sm={4}>
          <Form.Control
            required
            value={new Date(props.dateFrom).getFullYear()}
            type="text"
            name="monthSelectorYear"
            onChange={(e) => {
              props.setDateFrom(
                new Date(new Date(props.dateTo).setFullYear(Number(e.target.value)))
              );
              props.setDateTo(
                new Date(new Date(props.dateFrom).setFullYear(Number(e.target.value)))
              );
              props.setNeedUpdate(true);
            }}
          />
        </Col>
      </Form.Group>
    </div>
  );
}
