import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { api as leadsApi } from "../../../utils/api/api.leads";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Fade from "react-reveal/Fade";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const DateFormatter = (obj) => {
  return new Date(obj.value).toLocaleString("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const LeadsTable = (props) => {
  const [finder, setFinder] = useState({
    f_name: "",
    f_secondname: "",
    f_firstnamest: "",
    f_secondnamest: "",
    f_mobile: "",
    f_email: "",
    f_source: "",
    f_status: "",
  });
  const [data, setData] = useState([]);
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [dateColumns] = useState(["birthday"]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50, 100]);
  const [leadsStatus, setLeadsStatus] = useState(null);
  const [leadsSources, setLeadsSources] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [sorting, setSorting] = useState({
    sort_by: "creationDate",
    sort_dir: "-1",
  });
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "name", width: 150 },
    { columnName: "mobile", width: 150 },
    { columnName: "firstNameStudent", width: 170 },
    { columnName: "email", width: 170 },
    { columnName: "source", width: 140 },
    { columnName: "status", width: 170 },
    { columnName: "comment", width: 170 },
    { columnName: "creationDate", width: 170 },
  ]);

  const navigation = useNavigate();

  useEffect(() => {
    if (!props.tableState) return;
    setCurrentPage(props.tableState.currentPage);
    setPageSize(props.tableState.pageSize);
    setSorting(props.tableState.sorting);
    setFinder(props.tableState.finder);
    setF_datef(props.tableState.f_datef);
    setF_datet(props.tableState.f_datet);
    setNeedUpdate(true);
  }, [props.tableState]);

  const goToLead = (id) => {
    navigation(id);
    props.setTableState({
      currentPage,
      pageSize,
      sorting,
      finder,
      f_datef,
      f_datet,
    });
  }

  const Cell = (props) => {
    const {column, row} = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => goToLead(row._id)}
          />
        </td>
      );
    }
    if (column.name === "source") {
      return (
        <td>
          {(row.source !== undefined) & (row.source !== "")
            ? leadsSources.find((el) => el._id === row.source).value
            : ""}
        </td>
      );
    }
    if (column.name === "creationDate") {
      return (
        <td>
          {new Date(row.creationDate).toLocaleString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </td>
      );
    }
    if (column.name === "status") {
      return (
        <td
          style={{
            color: leadsStatus.find((el) => el._id === row.status).color,
            fontWeight: "bold",
          }}
        >
          {leadsStatus.find((el) => el._id === row.status).value}
        </td>
      );
    }
    if (column.name === "name") {
      return (
        <td>{`${row.name}${row.secondName ? " " + row.secondName : ""}`}</td>
      );
    }
    if (column.name === "firstNameStudent") {
      return (
        <td>
          {`${row.firstNameStudent ? row.firstNameStudent : ""}${
            row.secondNameStudent ? " " + row.secondNameStudent : ""
          }`}
        </td>
      );
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    leadsApi
      .getLeads({ p: currentPage + 1, s: pageSize, ...sorting, ...finder })
      .then((res) => {
        setLeadsStatus(res.directory.directory.leadStatus);
        setLeadsSources(res.directory.directory.leadSources);
        setData(res.leads);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false)
        setNeedUpdate(false)
      });
  }, [needUpdate]);

  const changePage = (page) => {
    props.setIsLoading(true);
    leadsApi
      .getLeads({
        p: page + 1,
        s: pageSize,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.leads);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    leadsApi
      .getLeads({ p: 1, s: size, ...sorting, ...finder, f_datef, f_datet })
      .then((res) => {
        setData(res.leads);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    leadsApi
      .getLeads({
        p: 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.leads);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    leadsApi
      .getLeads({
        p: 1,
        s: pageSize,
        ...finder,
        ...sorting,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.leads);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    leadsApi
      .getLeads({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_name: "",
          f_secondname: "",
          f_firstnamest: "",
          f_secondnamest: "",
          f_mobile: "",
          f_email: "",
          f_source: "",
          f_status: "",
        });
        setF_datef("");
        setF_datet("");
        setData(res.leads);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "name", title: "Имя" },
    { name: "mobile", title: "Телефон" },
    { name: "firstNameStudent", title: "Ученик" },
    { name: "email", title: "E-mail" },
    { name: "source", title: "Источник" },
    { name: "status", title: "Статус" },
    { name: "comment", title: "Комментарий" },
    { name: "creationDate", title: "Дата создания" },
  ];

  return (
    <>
      <Fade>
        <>
          <div className="d-flex justify-content-end">
            <Button
              className="m-2"
              variant="outline-primary"
              type="button"
              onClick={() => navigation("new")}
            >
              Создать
            </Button>
          </div>
          <Accordion className="mb-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Поиск</Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={tableFilter}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Имя
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="f_name"
                        value={finder.f_name}
                        onChange={finderHandlerChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Фамилия
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="f_secondname"
                        value={finder.f_secondname}
                        onChange={finderHandlerChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Имя ученика
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="f_firstnamest"
                        value={finder.f_firstnamest}
                        onChange={finderHandlerChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Фамилия ученика
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="f_secondnamest"
                        value={finder.f_secondnamest}
                        onChange={finderHandlerChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      E-mail
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="f_email"
                        value={finder.f_email}
                        onChange={finderHandlerChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Телефон
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="f_mobile"
                        value={finder.f_mobile}
                        onChange={finderHandlerChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Источник
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет источников"}
                        options={
                          leadsSources === null
                            ? {}
                            : leadsSources.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.value,
                                };
                              })
                        }
                        value={
                          finder.f_source === ""
                            ? {}
                            : {
                                value: leadsSources.find(
                                  (el) => el._id === finder.f_source
                                )._id,
                                label: leadsSources.find(
                                  (el) => el._id === finder.f_source
                                ).value,
                              }
                        }
                        type="text"
                        name="f_source"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_source: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Статус
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет статусов"}
                        options={
                          leadsStatus === null
                            ? {}
                            : leadsStatus.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.value,
                                };
                              })
                        }
                        value={
                          finder.f_status === ""
                            ? {}
                            : {
                                value: leadsStatus.find(
                                  (el) => el._id === finder.f_status
                                )._id,
                                label: leadsStatus.find(
                                  (el) => el._id === finder.f_status
                                ).value,
                              }
                        }
                        type="text"
                        name="f_status"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_status: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Дата создания c
                    </Form.Label>
                    <Col sm={10}>
                      <Flatpickr
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={f_datef}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          dateFormat: "d.m.Y",
                          disableMobile: true,
                        }}
                        onChange={([date]) => {
                          setF_datef(new Date(date));
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Дата по
                    </Form.Label>
                    <Col sm={10}>
                      <Flatpickr
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={f_datet}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          dateFormat: "d.m.Y",
                          disableMobile: true,
                        }}
                        onChange={([date]) => {
                          setF_datet(new Date(date));
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Button
                    className="me-2"
                    variant="outline-secondary"
                    type="button"
                    onClick={resetFilter}
                  >
                    Очистить
                  </Button>
                  <Button variant="primary" type="submit">
                    Поиск
                  </Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="card">
            <Grid rows={data} columns={columns}>
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={changePage}
                pageSize={pageSize}
                onPageSizeChange={changeSize}
              />
              <DateTypeProvider for={dateColumns} />
              <SortingState onSortingChange={tableSort} />
              <CustomPaging totalCount={totalCount} />
              <Table
                cellComponent={Cell}
                columnExtensions={tableColumnExtensions}
              />
              <TableHeaderRow showSortingControls />
              <PagingPanel
                pageSizes={pageSizes}
                messages={pagingPanelMessages}
              />
            </Grid>
          </div>
        </>
      </Fade>
    </>
  );
};

export default LeadsTable;
