import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import { api as actionApi } from "../../../utils/api/api.actionscrm";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import MonthSelector from "./MonthSelector";
import WeekSelector from "./WeekSelector";
import DaySelector from "./DaySelector";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";

export default function ActionCRMCalendar(props) {
  const [data, setData] = useState(null);
  const [finder, setFinder] = useState({
    f_type: "",
    f_lead: "",
    f_responsible: "",
    f_status: "",
  });
  const [viewMode, setViewMode] = useState("Day");
  const [needUpdate, setNeedUpdate] = useState(!props.scheduleState);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [timeSetups, setTimeSetups] = useState([]);
  const [leads, setLeads] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [types, setTypes] = useState(null);
  const [users, setUsers] = useState(null);

  const navigation = useNavigate();

  useEffect(() => {
    if (!props.scheduleState) return;
    setDateFrom(props.scheduleState.dateFrom);
    setDateTo(props.scheduleState.dateTo);
    setViewMode(props.scheduleState.viewMode);
    setFinder(props.scheduleState.finder);
    setNeedUpdate(true);
  }, [props.scheduleState]);

  useEffect(() => {
    if (needUpdate) {
      props.setIsLoading(true);
      actionApi
        .getActions({
          ...finder,
          p: 1,
          s: 100000000000000,
          f_datef: new Date(dateFrom),
          f_datet: new Date(dateTo),
          time_setups: true,
        })
        .then((res) => {
          setLeads(res.leads);
          setTypes(res.directory.directory.crmActions);
          setStatuses(res.directory.directory.crmStatus);
          setUsers(res.users);
          setData({
            actions: res.actions.map((action) => {
              return {
                ...action,
                title:
                  res.directory.directory.crmActions.find(
                    (el) => el._id === action.type
                  ).value +
                  " " +
                  new Date(action.date).toLocaleString("ru-RU", {
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }),
                startDate: new Date(action.date),
                endDate: new Date(
                  new Date(action.date).setMinutes(
                    new Date(action.date).getMinutes() + 15
                  )
                ),
                id: action._id,
                leadId: action.lead,
                typeId: action.type,
                statusId: action.status,
                responsibleId: action.responsible,
              };
            }),
            resources: [
              {
                fieldName: "typeId",
                title: "Тип",
                instances: res.directory.directory.crmActions.map((item) => {
                  return {
                    text: item.value,
                    id: item._id,
                  };
                }),
              },
              {
                fieldName: "statusId",
                title: "Тип",
                instances: res.directory.directory.crmStatus.map((item) => {
                  return {
                    text: item.value,
                    id: item._id,
                  };
                }),
              },
              {
                fieldName: "leadId",
                title: "Лид",
                instances: res.leads.map((item) => {
                  return {
                    id: item._id,
                    text:
                      item.name +
                      (item.secondName ? " " + item.secondName : ""),
                  };
                }),
              },
              {
                fieldName: "responsibleId",
                title: "Ответственный",
                instances: res.users.map((item) => {
                  return {
                    text: item.firstName + " " + item.secondName,
                    id: item._id,
                  };
                }),
              },
            ],
          });
          setTimeSetups(res.timeSettings.setups);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          setNeedUpdate(false);
        });
    }
  }, [needUpdate]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Nav
          className="m-2"
          variant="pills"
          activeKey={viewMode}
          onSelect={(selectedKey) => {
            setViewMode(selectedKey);
            if (selectedKey === "Week") {
              setDateFrom(
                new Date(
                  new Date(dateFrom).setDate(
                    new Date(dateFrom).getDate() -
                      new Date(dateFrom).getDay() +
                      (new Date(dateFrom).getDay() === 0 ? -6 : 1)
                  )
                )
              );
              setDateTo(
                new Date(
                  new Date(dateFrom).setDate(
                    new Date(dateFrom).getDate() -
                      new Date(dateFrom).getDay() +
                      (new Date(dateFrom).getDay() === 0 ? -6 : 1) +
                      6
                  )
                )
              );
              setNeedUpdate(true);
            }
            if (selectedKey === "Month") {
              setDateFrom(new Date(new Date(dateFrom).setDate(1)));
              setDateTo(new Date(new Date(dateFrom).setDate(32)));
              setNeedUpdate(true);
            }
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="Day">День</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Week">Неделя</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Month">Месяц</Nav.Link>
          </Nav.Item>
        </Nav>
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      {viewMode === "Day" ? (
        <DaySelector
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          setNeedUpdate={setNeedUpdate}
        />
      ) : viewMode === "Week" ? (
        <WeekSelector
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          setNeedUpdate={setNeedUpdate}
          dateTo={dateTo}
        />
      ) : viewMode === "Month" ? (
        <MonthSelector
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          setNeedUpdate={setNeedUpdate}
          dateTo={dateTo}
        />
      ) : (
        ""
      )}
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                setNeedUpdate(true);
              }}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Лид
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет лидов"}
                    options={
                      leads === null
                        ? {}
                        : leads.map((el) => {
                            return {
                              value: el._id,
                              label:
                                el.name +
                                (el.secondName ? " " + el.secondName : ""),
                            };
                          })
                    }
                    value={
                      finder.f_lead === ""
                        ? {}
                        : {
                            value: leads.find((el) => el._id === finder.f_lead)
                              ._id,
                            label:
                              leads.find((el) => el._id === finder.f_lead)
                                .name +
                              " " +
                              (leads.find((el) => el._id === finder.f_lead)
                                .secondName
                                ? leads.find((el) => el._id === finder.f_lead)
                                    .secondName
                                : ""),
                          }
                    }
                    type="text"
                    name="f_lead"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_lead: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип действия
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет типов"}
                    options={
                      types === null
                        ? {}
                        : types.map((el) => {
                            return {
                              value: el._id,
                              label: el.value,
                            };
                          })
                    }
                    value={
                      finder.f_type === ""
                        ? {}
                        : {
                            value: types.find((el) => el._id === finder.f_type)
                              ._id,
                            label: types.find((el) => el._id === finder.f_type)
                              .value,
                          }
                    }
                    type="text"
                    name="f_type"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_type: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Статус
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет статус"}
                    options={
                      statuses === null
                        ? {}
                        : statuses.map((el) => {
                            return {
                              value: el._id,
                              label: el.value,
                            };
                          })
                    }
                    value={
                      finder.f_status === ""
                        ? {}
                        : {
                            value: statuses.find(
                              (el) => el._id === finder.f_status
                            )._id,
                            label: statuses.find(
                              (el) => el._id === finder.f_status
                            ).value,
                          }
                    }
                    type="text"
                    name="f_status"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_status: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Ответственный
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет пользователей"}
                    options={
                      users === null
                        ? {}
                        : users.map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                    }
                    value={
                      finder.f_responsible === ""
                        ? {}
                        : {
                            value: users.find(
                              (el) => el._id === finder.f_responsible
                            )._id,
                            label:
                              users.find(
                                (el) => el._id === finder.f_responsible
                              ).firstName +
                              " " +
                              users.find(
                                (el) => el._id === finder.f_responsible
                              ).secondName,
                          }
                    }
                    type="text"
                    name="f_responsible"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_responsible: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={() => {
                  setFinder({
                    f_type: "",
                    f_lead: "",
                    f_responsible: "",
                    f_status: "",
                  });
                  setNeedUpdate(true);
                }}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {data !== null ? (
        <Calendar
          data={data}
          viewMode={viewMode}
          dateFrom={dateFrom}
          timeSetups={timeSetups}
          setScheduleState={props.setScheduleState}
          scheduleState={{ dateFrom, dateTo, viewMode, finder }}
        />
      ) : (
        ""
      )}
    </div>
  );
}
