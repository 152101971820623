class Api {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  getLessons(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        (params[key] !== undefined) &
        (params[key] !== "") &
        (params[key] !== null)
      ) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/lessons/" + querry, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getLesson(id) {
    return fetch("/api/lessons/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getCreationData() {
    return fetch("/api/lessons/create-data", {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  postLesson(data) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] === undefined || data[key] === "") {
        delete data[key];
      }
    });
    return fetch("/api/lessons", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  editLesson(data) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] === undefined) {
        delete data[key];
      }
    });
    return fetch("/api/lessons", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleteLesson(data) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] === undefined || data[key] === "") {
        delete data[key];
      }
    });
    return fetch("/api/lessons", {
      method: "DELETE",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  postStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined || student[key] === "") {
        delete student[key];
      }
    });
    return fetch("/api/lessons/student", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  editStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined) {
        delete student[key];
      }
    });
    return fetch("/api/lessons/student", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  deleteStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined || student[key] === "") {
        delete student[key];
      }
    });
    return fetch("/api/lessons/student", {
      method: "DELETE",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  compliteLesson(data) {
    return fetch("/api/lessons/compliter", {
      method: "PUT",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }
  
  uncompliteLesson(data) {
    return fetch("/api/lessons/undocompliter", {
      method: "PUT",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  percompliteLesson(data) {
    return fetch("/api/lessons/precompliter", {
      method: "PUT",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
