import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { api as tasksApi } from "../../../utils/api/api.tasks";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Fade from "react-reveal/Fade";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { UserContext } from "../../../context/UserContext";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const TasksTable = (props) => {
  const userContext = React.useContext(UserContext);
  const [finder, setFinder] = useState({
    f_title: "",
    f_lead: "",
    f_student: "",
    f_creator: "",
    f_responsible: "",
    f_status: "",
    f_priority: "",
  });
  const [data, setData] = useState([]);
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [f_datef_to, setF_datef_to] = useState("");
  const [f_datet_to, setF_datet_to] = useState("");
  const [users, setUsers] = useState(null);
  const [students, setStudents] = useState(null);
  const [leads, setLeads] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50, 100]);
  const [tasksStatus, setTasksStatus] = useState(null);
  const [tasksPriorities, setTasksPriorities] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState({ sort_by: "", sort_dir: "" });
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "date", width: 130 },
    { columnName: "dateTo", width: 130 },
    { columnName: "title", width: 150 },
    { columnName: "status", width: 130 },
    { columnName: "priority", width: 130 },
    { columnName: "lead", width: 150 },
    { columnName: "student", width: 150 },
    { columnName: "creator", width: 150 },
    { columnName: "responsible", width: 150 },
  ]);
  const navigation = useNavigate();

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => navigation(row._id)}
          />
        </td>
      );
    }
    if (column.name === "priority") {
      return (
        <td
          style={{
            color: tasksPriorities.find((el) => el._id === row.priority).color,
            fontWeight: "bold",
          }}
        >
          {tasksPriorities.find((el) => el._id === row.priority).value}
        </td>
      );
    }
    if (column.name === "date") {
      return (
        <td>
          {new Date(row.date).toLocaleString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </td>
      );
    }
    if (column.name === "dateTo") {
      if ((row.dateTo !== undefined) & (row.dateTo !== "")) {
        return (
          <td>
            {new Date(row.dateTo).toLocaleString("ru-RU", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </td>
        );
      }
    }
    if (column.name === "status") {
      return <td>{tasksStatus.find((el) => el._id === row.status).value}</td>;
    }
    if (column.name === "creator") {
      return (
        <td>
          {users.find((el) => el._id === row.creator).firstName +
            " " +
            users.find((el) => el._id === row.creator).secondName}
        </td>
      );
    }
    if (column.name === "responsible") {
      if ((row.responsible !== undefined) & (row.responsible !== "")) {
        return (
          <td>
            {users.find((el) => el._id === row.responsible).firstName +
              " " +
              users.find((el) => el._id === row.responsible).secondName}
          </td>
        );
      }
    }
    if (column.name === "lead") {
      if ((row.lead !== undefined) & (row.lead !== "")) {
        return (
          <td>
            {leads.find((el) => el._id === row.lead).name +
              (leads.find((el) => el._id === row.lead).secondName
                ? (" " + leads.find((el) => el._id === row.lead).secondName)
                : "")}
          </td>
        );
      }
    }
    if (column.name === "student") {
      if ((row.student !== undefined) & (row.student !== "")) {
        return (
          <td>
            {students.find((el) => el._id === row.student).firstName +
              " " +
              students.find((el) => el._id === row.student).secondName}
          </td>
        );
      }
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(userContext.userId);
    props.setIsLoading(true);
    tasksApi
      .getTasks({
        p: currentPage + 1,
        s: pageSize,
        ...sorting,
        f_responsible: userContext.userId,
      })
      .then((res) => {
        setFinder({ ...finder, f_responsible: userContext.userId });
        setTasksStatus(res.directory.directory.taskStatus);
        setTasksPriorities(res.directory.directory.taskPriorities);
        setUsers(res.users);
        setData(res.tasks);
        setLeads(res.leads);
        setStudents(res.students);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const changePage = (page) => {
    props.setIsLoading(true);
    tasksApi
      .getTasks({
        p: page + 1,
        s: pageSize,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
        f_datef_to,
        f_datet_to,
      })
      .then((res) => {
        setData(res.tasks);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    tasksApi
      .getTasks({
        p: 1,
        s: size,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
        f_datef_to,
        f_datet_to,
      })
      .then((res) => {
        setData(res.tasks);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    tasksApi
      .getTasks({
        p: 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        f_datef_to,
        f_datet_to,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.tasks);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    tasksApi
      .getTasks({
        p: 1,
        s: pageSize,
        ...finder,
        ...sorting,
        f_datef,
        f_datet,
        f_datef_to,
        f_datet_to,
      })
      .then((res) => {
        setData(res.tasks);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    tasksApi
      .getTasks({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_title: "",
          f_lead: "",
          f_student: "",
          f_creator: "",
          f_responsible: "",
          f_status: "",
          f_priority: "",
        });
        setF_datef("");
        setF_datet("");
        setF_datef_to("");
        setF_datet_to("");
        setData(res.tasks);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "date", title: "Дата создания" },
    { name: "dateTo", title: "Срок" },
    { name: "title", title: "Название" },
    { name: "status", title: "Статус" },
    { name: "priority", title: "Приоритет" },
    { name: "lead", title: "Лид" },
    { name: "student", title: "Ученик" },
    { name: "creator", title: "Автор" },
    { name: "responsible", title: "Ответственный" },
  ];

  return (
    <Fade>
      <>
        <div className="d-flex justify-content-end">
          <Button
            className="m-2"
            variant="outline-primary"
            type="button"
            onClick={() => navigation("new")}
          >
            Создать
          </Button>
        </div>
        <Accordion className="mb-2">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Поиск</Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={tableFilter}>
                <Row className="mb-3">
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Дата создания с</Form.Label>
                      <Flatpickr
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={f_datef}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          dateFormat: "d.m.Y",
                          disableMobile: true,
                        }}
                        onChange={([date]) => {
                          setF_datef(new Date(date));
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Дата создания по</Form.Label>
                      <Flatpickr
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={f_datet}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          dateFormat: "d.m.Y",
                          disableMobile: true,
                        }}
                        onChange={([date]) => {
                          setF_datet(new Date(date));
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Срок с</Form.Label>
                      <Flatpickr
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={f_datef_to}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          dateFormat: "d.m.Y",
                          disableMobile: true,
                        }}
                        onChange={([date]) => {
                          setF_datef_to(new Date(date));
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Срок по</Form.Label>
                      <Flatpickr
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={f_datet_to}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          dateFormat: "d.m.Y",
                          disableMobile: true,
                        }}
                        onChange={([date]) => {
                          setF_datet_to(new Date(date));
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Название
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="f_title"
                      value={finder.f_title}
                      onChange={finderHandlerChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Ученик
                  </Form.Label>
                  <Col sm={10}>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет учеников"}
                      options={
                        students === null
                          ? {}
                          : students.map((el) => {
                              return {
                                value: el._id,
                                label: el.firstName + " " + el.secondName,
                              };
                            })
                      }
                      value={
                        finder.f_student === ""
                          ? {}
                          : {
                              value: students.find(
                                (el) => el._id === finder.f_student
                              )._id,
                              label:
                                students.find(
                                  (el) => el._id === finder.f_student
                                ).firstName +
                                " " +
                                students.find(
                                  (el) => el._id === finder.f_student
                                ).secondName,
                            }
                      }
                      type="text"
                      name="f_student"
                      onChange={(data) =>
                        setFinder({
                          ...finder,
                          f_student: data.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Лид
                  </Form.Label>
                  <Col sm={10}>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет лидов"}
                      options={
                        leads === null
                          ? {}
                          : leads.map((el) => {
                              return {
                                value: el._id,
                                label:
                                  el.name +
                                  (el.secondName ? (" " + el.secondName) : ""),
                              };
                            })
                      }
                      value={
                        finder.f_lead === ""
                          ? {}
                          : {
                              value: leads.find(
                                (el) => el._id === finder.f_lead
                              )._id,
                              label:
                                leads.find((el) => el._id === finder.f_lead)
                                  .name +
                                (leads.find((el) => el._id === finder.f_lead)
                                  .secondName
                                  ? (" " +
                                    leads.find((el) => el._id === finder.f_lead)
                                      .secondName)
                                  : ""),
                            }
                      }
                      type="text"
                      name="f_lead"
                      onChange={(data) =>
                        setFinder({
                          ...finder,
                          f_lead: data.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Приоритет
                  </Form.Label>
                  <Col sm={10}>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет приоритетов"}
                      options={
                        tasksPriorities === null
                          ? {}
                          : tasksPriorities.map((el) => {
                              return {
                                value: el._id,
                                label: el.value,
                              };
                            })
                      }
                      value={
                        finder.f_priority === ""
                          ? {}
                          : {
                              value: tasksPriorities.find(
                                (el) => el._id === finder.f_priority
                              )._id,
                              label: tasksPriorities.find(
                                (el) => el._id === finder.f_priority
                              ).value,
                            }
                      }
                      type="text"
                      name="f_priority"
                      onChange={(data) =>
                        setFinder({
                          ...finder,
                          f_priority: data.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Статус
                  </Form.Label>
                  <Col sm={10}>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет статусов"}
                      options={
                        tasksStatus === null
                          ? {}
                          : tasksStatus.map((el) => {
                              return {
                                value: el._id,
                                label: el.value,
                              };
                            })
                      }
                      value={
                        finder.f_status === ""
                          ? {}
                          : {
                              value: tasksStatus.find(
                                (el) => el._id === finder.f_status
                              )._id,
                              label: tasksStatus.find(
                                (el) => el._id === finder.f_status
                              ).value,
                            }
                      }
                      type="text"
                      name="f_status"
                      onChange={(data) =>
                        setFinder({
                          ...finder,
                          f_status: data.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Ответственный</Form.Label>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет пользователей"}
                    options={
                      users === null
                        ? {}
                        : users.map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                    }
                    value={
                      finder.f_responsible === ""
                        ? {}
                        : {
                            value: users.find(
                              (el) => el._id === finder.f_responsible
                            )._id,
                            label:
                              users.find(
                                (el) => el._id === finder.f_responsible
                              ).firstName +
                              " " +
                              users.find(
                                (el) => el._id === finder.f_responsible
                              ).secondName,
                          }
                    }
                    type="text"
                    name="responsible"
                    onChange={(data) => {
                      setFinder({
                        ...finder,
                        f_responsible: data.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Создатель</Form.Label>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет пользователей"}
                    options={
                      users === null
                        ? {}
                        : users.map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                    }
                    value={
                      finder.f_creator === ""
                        ? {}
                        : {
                            value: users.find(
                              (el) => el._id === finder.f_creator
                            )._id,
                            label:
                              users.find((el) => el._id === finder.f_creator)
                                .firstName +
                              " " +
                              users.find((el) => el._id === finder.f_creator)
                                .secondName,
                          }
                    }
                    type="text"
                    name="creator"
                    onChange={(data) => {
                      setFinder({
                        ...finder,
                        f_creator: data.value,
                      });
                    }}
                  />
                </Form.Group>
                <Button
                  className="me-2"
                  variant="outline-secondary"
                  type="button"
                  onClick={resetFilter}
                >
                  Очистить
                </Button>
                <Button variant="primary" type="submit">
                  Поиск
                </Button>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="card">
          <Grid rows={data} columns={columns}>
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={changePage}
              pageSize={pageSize}
              onPageSizeChange={changeSize}
            />
            <SortingState onSortingChange={tableSort} />
            <CustomPaging totalCount={totalCount} />
            <Table
              cellComponent={Cell}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
          </Grid>
        </div>
      </>
    </Fade>
  );
};

export default TasksTable;
