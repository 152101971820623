import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { api as accountApi } from "../../../utils/api/api.accounts";
import { api as abonementsApi } from "../../../utils/api/api.abonements";

const StudentModal = (props) => {
  const [form, setForm] = useState({
    student: "",
    account: "",
    visited: false,
    importantPass: false,
    rate: "",
    comment: "",
  });
  const [accounts, setAccounts] = useState(null);
  const [abonements, setAbonements] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    if (props.isStudentActionSuccess) {
      handleClose();
      props.setIsStudentActionSuccess(null);
    }
  }, [props.isStudentActionSuccess]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    props.submitHandler(form);
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    setDeletePopup(false);
    props.deleteHandler(form);
  };

  const handleClose = () => {
    setForm({
      student: "",
      account: "",
      visited: false,
      importantPass: false,
      rate: 0,
      comment: "",
    });
    setAccounts(null);
    setAbonements(null);
    props.setIsModalOpen(false);
    props.setSelectedStudent(null);
  };

  useEffect(() => {
    if (
      (props.selectedStudent !== null) &
      (props.selectedStudent !== undefined)
    ) {
      setForm({
        student: props.selectedStudent.student,
        account: props.selectedStudent.account,
        visited: props.selectedStudent.visited,
        importantPass: props.selectedStudent.importantPass,
        rate: props.selectedStudent.rate,
        comment: props.selectedStudent.comment,
      });
    }
    if ((props.selectedStudent !== null) & (props.data !== undefined)) {
      props.setIsLoading(true);
      Promise.all([
        accountApi.getSuitableAccount({
          student: props.selectedStudent.student,
          dance: props.data.dance,
        }),
        abonementsApi.getAbonements({ p: 1, s: 1000000000 }),
      ])
        .then((res) => {
          setAccounts(res[0].accounts);
          setAbonements(res[1].abonements);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, [props.selectedStudent]);

  return (
    <>
      <Modal show={props.isModalOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {(props.selectedStudent !== null) &
            (props.selectedStudent !== undefined)
              ? props.students.find(
                  (el) => el._id === props.selectedStudent.student
                ).firstName +
                " " +
                props.students.find(
                  (el) => el._id === props.selectedStudent.student
                ).secondName
              : "Новый ученик"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Фамилия Имя
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных учеников"}
                options={
                  (props.students !== null) & (props.students !== undefined)
                    ? props.data.students.length > 0
                      ? props.students
                          .filter((el) => {
                            return !props.data.students.some(
                              (obj) => obj.student === el._id
                            );
                          })
                          .map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                      : props.students.map((el) => {
                          return {
                            value: el._id,
                            label: el.firstName + " " + el.secondName,
                          };
                        })
                    : []
                }
                value={
                  (form.student !== undefined) &
                  (form.student !== "") &
                  (props.students !== undefined) &
                  (props.students !== null)
                    ? {
                        value: props.students.find(
                          (el) => el._id === form.student
                        )._id,
                        label:
                          props.students.find((el) => el._id === form.student)
                            .firstName +
                          " " +
                          props.students.find((el) => el._id === form.student)
                            .secondName,
                      }
                    : ""
                }
                type="text"
                name="student"
                onChange={(data) => {
                  setForm({
                    ...form,
                    student: data.value,
                  });
                  if (props.data !== undefined) {
                    props.setIsLoading(true);
                    Promise.all([
                      accountApi.getSuitableAccount({
                        student: data.value,
                        dance: props.data.dance,
                      }),
                      abonementsApi.getAbonements({ p: 1, s: 1000000000 }),
                    ])
                      .then((res) => {
                        setAccounts(res[0].accounts);
                        setAbonements(res[1].abonements);
                      })
                      .catch((rej) => {
                        props.setIsError(true);
                        props.setError(rej);
                      })
                      .finally(() => props.setIsLoading(false));
                  }
                }}
                isDisabled={
                  props.status === 2 || props.data.isComplete ? true : false
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Абонемент
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных абонементов"}
                options={
                  (accounts !== null) &
                  (accounts !== undefined) &
                  (abonements !== null) &
                  (abonements !== undefined)
                    ? accounts.map((el) => {
                        return {
                          value: el._id,
                          label: abonements.find(
                            (elem) => elem._id === el.abonement
                          ).name,
                        };
                      })
                    : []
                }
                value={
                  (form.account !== undefined) &
                  (form.account !== "") &
                  (accounts !== undefined) &
                  (accounts !== null) &
                  (abonements !== null) &
                  (abonements !== undefined)
                    ? {
                        value: accounts.find((el) => el._id === form.account)
                          ._id,
                        label: abonements.find(
                          (el) =>
                            el._id ===
                            accounts.find(
                              (element) => element._id === form.account
                            ).abonement
                        ).name,
                      }
                    : ""
                }
                type="text"
                name="account"
                onChange={(data) => {
                  setForm({
                    ...form,
                    account: data.value,
                  });
                }}
                isDisabled={
                  props.status === 2 || props.data.isComplete ? true : false
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Посещение
            </Form.Label>
            <Col sm={9} className="align-items-center d-flex">
              <Form.Check
                disabled={
                  props.status === 2 || form.importantPass ? true : false
                }
                type="switch"
                name="visited"
                onChange={(e) => setForm({ ...form, visited: !form.visited })}
                checked={form.visited}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Уважительная причина
            </Form.Label>
            <Col sm={9} className="align-items-center d-flex">
              <Form.Check
                disabled={
                  props.status === 2 || props.data.isComplete ? true : false
                }
                type="switch"
                name="importantPass"
                onChange={(e) =>
                  setForm({ ...form, importantPass: !form.importantPass })
                }
                checked={form.importantPass}
              />
            </Col>
          </Form.Group>
          {props.data.isMethodic ? (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Оценка
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="rate"
                  value={form.rate}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      rate: e.target.value,
                    })
                  }
                  disabled={props.status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
          ) : (
            ""
          )}

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Комментарий</Accordion.Header>
              <Accordion.Body>
                <Form.Control
                  type="text"
                  name="comment"
                  value={form.comment}
                  as="textarea"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      comment: e.target.value,
                    })
                  }
                  disabled={props.status === 2 ? true : false}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant="danger"
            onClick={() => setDeletePopup(true)}
            className={
              props.status === 2 || props.data.isComplete ? "d-none" : "d-block"
            }
          >
            Удалить
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            className={props.status === 2 ? "d-none" : "d-block"}
          >
            Сохранить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deletePopup} onHide={() => setDeletePopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удалить ученика из занятия</Modal.Title>
        </Modal.Header>
        <Modal.Body>Подтвердите удаление</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeletePopup(false)}>
            Закрыть
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StudentModal;
