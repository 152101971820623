import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import weekSelectPlugin from "flatpickr/dist/plugins/weekSelect/weekSelect";

const CustomInput = ({ inputRef, dateFrom, dateTo, ...props }) => {
  return (
    <>
      <p ref={inputRef} className="form-control">{`${new Date(
        dateFrom
      ).toLocaleString("ru-RU", {
        month: "numeric",
        day: "numeric",
      })} - ${new Date(dateTo).toLocaleString("ru-RU", {
        month: "numeric",
        day: "numeric",
      })}`}</p>
    </>
  );
};

export default function WeekSelector(props) {
  const [dateFrom, setDateFrom] = useState(props.dateFrom);
  const [dateTo, setDateTo] = useState(props.dateTo);

  useEffect(() => {
    setDateFrom(props.dateFrom);
    setDateTo(props.dateTo);
  }, [props.dateFrom, props.dateTo]);

  return (
    <div className="d-flex justify-content-center">
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4} className="fw-bold">
          Неделя
        </Form.Label>
        <Col sm={8}>
          <Flatpickr
            options={{
              locale: Russian,
              plugins: [new weekSelectPlugin({})],
              dateFormat: "d.m.Y",
              disableMobile: true,
            }}
            name="weekSelector"
            value={new Date(props.dateFrom)}
            onChange={([date]) => {
              props.setDateFrom(
                new Date(
                  new Date(date).setDate(
                    new Date(date).getDate() -
                      new Date(date).getDay() +
                      (new Date(date).getDay() === 0 ? -6 : 1)
                  )
                )
              );
              props.setDateTo(
                new Date(
                  new Date(date).setDate(
                    new Date(date).getDate() -
                      new Date(date).getDay() +
                      (new Date(date).getDay() === 0 ? -6 : 1) +
                      6
                  )
                )
              );
              props.setNeedUpdate(true);
            }}
            render={({ defaultValue, value, ...props }, ref) => {
              return (
                <CustomInput
                  inputRef={ref}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              );
            }}
          />
        </Col>
      </Form.Group>
    </div>
  );
}
