import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { api as lessonsApi } from "../../../utils/api/api.lessons";
import { api as teachersApi } from "../../../utils/api/api.teachers";
import { api as groupsApi } from "../../../utils/api/api.groups";
import { UserContext } from "../../../context/UserContext";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import StudentModal from "./StudentModal";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

const LessonForm = (props) => {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - создать, 2 - просмотр,  3 - изменить
  const [dances, setDances] = useState(null);
  const [date, setDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [teachers, setTeachers] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [studentsVistings, setStudentsVistings] = useState([]);
  const [groups, setGroups] = useState(null);
  const [students, setStudents] = useState(null);
  const [percompliteLesson, setPercompliteLesson] = useState(null);
  const [owner, setOwner] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [modalStudent, setModalStudent] = useState(false);
  const [form, setForm] = useState({
    _id: "",
    date: new Date(),
    endDate: "",
    group: "",
    dance: "",
    typeGroup: 2,
    teacher: "",
    salary: 0,
    room: "",
    isMethodic: false,
    theme: "",
    description: "",
    homework: "",
    students: [],
    isComplete: false,
  });
  const [isStudentActionSuccess, setIsStudentActionSuccess] = useState(null);

  const navigation = useNavigate();

  const { id } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    if (needUpdate) {
      if (id !== undefined) {
        if (id === "new") {
          setStatus(1);
          props.setIsLoading(true);
          lessonsApi
            .getCreationData()
            .then((res) => {
              const { lesson } = res;
              setForm({
                _id: "",
                date: new Date(),
                endDate: "",
                group: "",
                dance: "",
                typeGroup: 2,
                teacher: "",
                salary: 0,
                room: "",
                isMethodic: false,
                theme: "",
                description: "",
                homework: "",
                students: [],
                isComplete: false,
              });
              setDances(res.dances);
              setTeachers(res.teachers);
              setRooms(res.rooms[0].directory.rooms);
              setGroups(res.groups);
              setStudents(res.studentsInfo);
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            })
            .finally(() => props.setIsLoading(false));
        } else {
          setStatus(2);
          props.setIsLoading(true);
          lessonsApi
            .getLesson(id)
            .then((res) => {
              const { lesson } = res;
              setForm({
                _id: lesson._id,
                date: new Date(lesson.date),
                endDate: lesson.endDate,
                group: lesson.group,
                dance: lesson.dance,
                typeGroup: lesson.typeGroup,
                teacher: lesson.teacher,
                salary: lesson.salary,
                room: lesson.room,
                isMethodic: lesson.isMethodic,
                theme: lesson.theme,
                description: lesson.description,
                homework: lesson.homework,
                students: lesson.students,
                isComplete: lesson.isComplete,
              });
              setDate(new Date(lesson.date));
              setEndDate(new Date(lesson.endDate));
              setDances(res.dances);
              setTeachers(res.teachers);
              setRooms(res.rooms[0].directory.rooms);
              setGroups(res.groups);
              setOwner(res.users.find((user) => user._id === lesson.owner));
              setStudentsVistings(
                lesson.students
                  .filter((el) => el.visited === true)
                  .map((el) => el.student)
              );
              setStudents(res.studentsInfo);
              lessonsApi
                .percompliteLesson({ lessonId: lesson._id })
                .then((res) => {
                  setPercompliteLesson(res.info);
                })
                .catch((rej) => {
                  props.setIsError(true);
                  props.setError(rej);
                });
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            })
            .finally(() => {
              props.setIsLoading(false);
              setNeedUpdate(false);
            });
        }
      }
    }
  }, [needUpdate]);

  const studentSubmitHandler = (data) => {
    if (status === 1) {
      if (selectedStudent === null) {
        setForm({ ...form, students: [...form.students, data] });
        setModalStudent(false);
        setIsStudentActionSuccess(true);
      } else if (selectedStudent !== null) {
        setForm({
          ...form,
          students: form.students.map((item) => {
            if (item.student === selectedStudent.student) {
              return data;
            } else {
              return item;
            }
          }),
        });
        setModalStudent(false);
        setIsStudentActionSuccess(true);
      }
    } else if ((status === 3) & (form.isComplete === false)) {
      if (selectedStudent === null) {
        props.setIsLoading(true);
        lessonsApi
          .postStudent({ lesson: form._id, student: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalStudent(false);
            setIsStudentActionSuccess(true);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      } else if (selectedStudent !== null) {
        props.setIsLoading(true);
        lessonsApi
          .editStudent({ lesson: form._id, student: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalStudent(false);
            setIsStudentActionSuccess(true);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      }
    } else if ((status === 3) & (form.isComplete === true)) {
      if (selectedStudent !== null) {
        props.setIsLoading(true);
        lessonsApi
          .editStudent({ lesson: form._id, student: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalStudent(false);
            setIsStudentActionSuccess(true);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      }
    }
  };

  const studentDeleteHandler = (data) => {
    if (status === 1) {
      setForm({
        ...form,
        students: form.students.filter((item) => item.student !== data.student),
      });
    } else if ((status === 3) & (form.isComplete === false)) {
      props.setIsLoading(true);
      lessonsApi
        .deleteStudent({ lesson: form._id, student: data.student })
        .then((res) => {
          setNeedUpdate(true);
          setModalStudent(false);
          setIsStudentActionSuccess(true);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  };

  const passStudentHandler = (data, visited) => {
    props.setIsLoading(true);
    lessonsApi
      .editStudent({
        lesson: form._id,
        student: {
          student: data.student,
          visited: !data.visited,
          importantPass: data.importantPass,
          account: data.account,
          comment: data.comment,
        },
      })
      .then((res) => {
        setNeedUpdate(true);
        setIsStudentActionSuccess(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (status === 1) {
      props.setIsLoading(true);
      lessonsApi
        .postLesson({ ...form, date, endDate })
        .then((res) => {
          pathname.slice(0, -3);
          navigation(pathname.slice(0, -3) + res.newLesson._id);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    } else {
      props.setIsLoading(true);
      lessonsApi
        .editLesson({ ...form, date, endDate })
        .then((res) => {
          setNeedUpdate(true);
          setModalStudent(false);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  };

  const deleteHandler = () => {
    props.setIsLoading(true);
    lessonsApi
      .deleteLesson({ lesson: form._id })
      .then((res) => {
        navigation("/admin/lessons/table");
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const compliteLesson = () => {
    props.setIsLoading(true);
    lessonsApi
      .compliteLesson({ lessonId: form._id })
      .then((res) => {
        setNeedUpdate(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const uncompliteLesson = () => {
    props.setIsLoading(true);
    lessonsApi
      .uncompliteLesson({ lessonId: form._id })
      .then((res) => {
        setNeedUpdate(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const closeModal = () => navigation(-1);

  return (
    <Modal show={true} fullscreen onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(status !== 1) &
          (form.dance !== "") &
          (dances !== null) &
          (form.date !== "")
            ? form.date.toLocaleString("ru-RU", {
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }) +
              " " +
              dances.find((el) => el._id === form.dance).name
            : "Новое занятия"}
          {form.isComplete ? (
            <Badge className="ms-3" bg="danger">
              Проведено
            </Badge>
          ) : (
            ""
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <i
            className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
              status === 2 ? "d-flex" : "d-none"
            }`}
            style={{ fontSize: 30, cursor: "pointer", zIndex: 999 }}
            onClick={() => setStatus(3)}
          ></i>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Дата
            </Form.Label>
            <Col sm={9}>
              {/* <Flatpickr
                className={`form-control ${
                  (status === 2 || form.isComplete) && "form-control-plaintext"
                }`}
                style={{ backgroundColor: "transparent" }}
                value={date}
                options={{
                  enableTime: true,
                  locale: Russian,
                  disableMobile: true,
                  dateFormat: "d.m.Y H:i",
                }}
                name="date"
                onChange={([date]) => {
                  setDate(date);
                }}
                disabled={status === 2 || form.isComplete ? true : false}
                data-enable-time
              /> */}
              <DatePicker
                selected={new Date(date)}
                locale="ru"
                showTimeSelect
                dateFormat="dd.MM.yyyy H:mm"
                onChange={(date) => {
                  setDate(date);
                }}
                disabled={status === 2 || form.isComplete ? true : false}
                customInput={
                  <Form.Control
                    value={date}
                    type="text"
                    name="date"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Дата завершения
            </Form.Label>
            <Col sm={9}>
              {/* <Flatpickr
                className={`form-control ${
                  (status === 2 || form.isComplete) && "form-control-plaintext"
                }`}
                style={{ backgroundColor: "transparent" }}
                value={endDate}
                options={{
                  enableTime: true,
                  time_24hr: true,
                  locale: Russian,
                  disableMobile: true,
                  dateFormat: "d.m.Y H:i",
                }}
                name="date"
                onChange={([date]) => {
                  setEndDate(date);
                }}
                disabled={status === 2 || form.isComplete ? true : false}
              /> */}
              <DatePicker
                selected={new Date(endDate)}
                locale="ru"
                showTimeSelect
                dateFormat="dd.MM.yyyy H:mm"
                onChange={(date) => {
                  setEndDate(date);
                }}
                disabled={status === 2 || form.isComplete ? true : false}
                customInput={
                  <Form.Control
                    value={endDate}
                    type="text"
                    name="endDate"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Группа
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных групп"}
                options={
                  groups === null
                    ? {}
                    : groups.map((el) => {
                        return {
                          value: el._id,
                          label: el.name,
                        };
                      })
                }
                value={
                  (form.group !== undefined) &
                  (groups !== null) &
                  (form.group !== "")
                    ? {
                        value: groups.find((el) => el._id === form.group)._id,
                        label: groups.find((el) => el._id === form.group).name,
                      }
                    : ""
                }
                type="text"
                name="group"
                onChange={(data) => {
                  props.setIsLoading(true);
                  groupsApi
                    .getGroup(data.value)
                    .then((res) => {
                      setForm({
                        ...form,
                        group: data.value,
                        dance: res.groupInfo.dance,
                        teacher: res.groupInfo.recommendedTeacher,
                        salary: res.groupInfo.recommendedSalary,
                        room: res.groupInfo.recommendedRoom,
                        isMethodic: res.groupInfo.isMethodic,
                        typeGroup: 1,
                        students: res.groupInfo.students.map((el) => {
                          return {
                            student: el.student,
                            account: el.accountId,
                            visited: false,
                            importantPass: false,
                            rate: "",
                            comment: "",
                          };
                        }),
                      });
                    })
                    .catch((rej) => {
                      props.setIsError(true);
                      props.setError(rej);
                    })
                    .finally(() => {
                      props.setIsLoading(false);
                    });
                }}
                isDisabled={status === 2 || form.isComplete ? true : false}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Направление
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных направлений"}
                options={
                  dances === null
                    ? {}
                    : dances.map((el) => {
                        return {
                          value: el._id,
                          label: el.name,
                        };
                      })
                }
                value={
                  (form.dance !== undefined) &
                  (dances !== null) &
                  (form.dance !== "")
                    ? {
                        value: dances.find((el) => el._id === form.dance)._id,
                        label: dances.find((el) => el._id === form.dance).name,
                      }
                    : ""
                }
                type="text"
                name="dance"
                onChange={(data) =>
                  setForm({
                    ...form,
                    dance: data.value,
                  })
                }
                isDisabled={status === 2 || form.isComplete ? true : false}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Тип
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных типов"}
                options={[
                  { value: 1, label: "Групповое" },
                  { value: 2, label: "Индивидуальное" },
                  { value: 3, label: "Другое" },
                ]}
                value={
                  form.typeGroup !== undefined
                    ? {
                        value: [
                          { value: 1, label: "Групповое" },
                          { value: 2, label: "Индивидуальное" },
                          { value: 3, label: "Другое" },
                        ].find((el) => el.value === form.typeGroup).value,
                        label: [
                          { value: 1, label: "Групповое" },
                          { value: 2, label: "Индивидуальное" },
                          { value: 3, label: "Другое" },
                        ].find((el) => el.value === form.typeGroup).label,
                      }
                    : ""
                }
                type="text"
                name="typeGroup"
                onChange={(data) =>
                  setForm({
                    ...form,
                    typeGroup: data.value,
                  })
                }
                isDisabled={status === 2 || form.isComplete ? true : false}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Педагог
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных педагогов"}
                options={
                  teachers === null
                    ? {}
                    : teachers.map((el) => {
                        return {
                          value: el._id,
                          label: el.firstName + " " + el.secondName,
                        };
                      })
                }
                value={
                  (form.teacher !== undefined) &
                  (teachers !== null) &
                  (form.teacher !== "")
                    ? {
                        value: teachers.find((el) => el._id === form.teacher)
                          ._id,
                        label:
                          teachers.find((el) => el._id === form.teacher)
                            .firstName +
                          " " +
                          teachers.find((el) => el._id === form.teacher)
                            .secondName,
                      }
                    : ""
                }
                type="text"
                name="teacher"
                onChange={(data) => {
                  if (
                    form.typeGroup === 1 ||
                    form.typeGroup === 2 ||
                    form.typeGroup === 3
                  ) {
                    teachersApi
                      .recommendedSalary(data.value)
                      .then((res) => {
                        setForm({
                          ...form,
                          teacher: data.value,
                          salary:
                            res[
                              form.typeGroup === 1
                                ? "group"
                                : form.typeGroup === 2
                                ? "individual"
                                : "other"
                            ],
                        });
                      })
                      .catch((rej) => {
                        props.setIsError(true);
                        props.setError(rej);
                      })
                      .finally(() => props.setIsLoading(false));
                  }
                }}
                isDisabled={status === 2 || form.isComplete ? true : false}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className={`mb-3 ${
              userContext.access.salaries ? "d-flex" : "d-none"
            }`}
          >
            <Form.Label column sm={3}>
              Зарплата за урок
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                name="salary"
                value={form.salary}
                onChange={(e) =>
                  setForm({
                    ...form,
                    salary: e.target.value,
                  })
                }
                disabled={status === 2 || form.isComplete ? true : false}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Зал
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных залов"}
                required
                options={
                  rooms === null
                    ? {}
                    : rooms.map((el) => {
                        return {
                          value: el._id,
                          label: el.value,
                        };
                      })
                }
                value={
                  (form.room !== undefined) &
                  (rooms !== null) &
                  (form.room !== "")
                    ? {
                        value: rooms.find((el) => el._id === form.room)._id,
                        label: rooms.find((el) => el._id === form.room).value,
                      }
                    : ""
                }
                type="text"
                name="room"
                onChange={(data) =>
                  setForm({
                    ...form,
                    room: data.value,
                  })
                }
                isDisabled={status === 2 || form.isComplete ? true : false}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Использовать методичку
            </Form.Label>
            <Col sm={9} className="align-items-center d-flex">
              <Form.Check
                disabled={status === 2 ? true : false}
                type="switch"
                name="isMethodic"
                onChange={(e) =>
                  setForm({ ...form, isMethodic: !form.isMethodic })
                }
                checked={form.isMethodic}
              />
            </Col>
          </Form.Group>
          <Accordion className={form.isMethodic ? "d-block" : "d-none"}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Методичка</Accordion.Header>
              <Accordion.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Тема
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="theme"
                      value={form.theme}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          theme: e.target.value,
                        })
                      }
                      disabled={status === 2 ? true : false}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Описание
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="description"
                      value={form.description}
                      as="textarea"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          description: e.target.value,
                        })
                      }
                      disabled={status === 2 ? true : false}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Домашнее задание
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="homework"
                      value={form.homework}
                      as="textarea"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          homework: e.target.value,
                        })
                      }
                      disabled={status === 2 ? true : false}
                    />
                  </Col>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey={["0"]} alwaysOpen className="mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Ученики</Accordion.Header>
              <Accordion.Body style={{ overflow: "scroll" }}>
                <Table striped>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Имя</th>
                      <th>Фамилия</th>
                      {status !== 1 ? (
                        <th className="text-center">Посещение</th>
                      ) : (
                        ""
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(students !== null) & (form.students !== [])
                      ? form.students.map((student) => {
                          return (
                            <tr key={student._id}>
                              <td>
                                <i
                                  className="bi bi-eye-fill"
                                  style={{ cursor: "pointer", fontSize: 24 }}
                                  onClick={() => {
                                    setSelectedStudent(student);
                                    setModalStudent(true);
                                  }}
                                />
                              </td>
                              <td>
                                {
                                  students.find(
                                    (el) => el._id === student.student
                                  ).firstName
                                }
                              </td>
                              <td>
                                {
                                  students.find(
                                    (el) => el._id === student.student
                                  ).secondName
                                }
                              </td>
                              {(status !== 1) & (percompliteLesson !== null) ? (
                                <td className="text-center">
                                  <Form.Check
                                    name="visited"
                                    disabled={
                                      (percompliteLesson.filter(
                                        (el) =>
                                          (el.studentId === student.student) &
                                          (el.status === "frozen" ||
                                            el.status === "important")
                                      ).length ===
                                        0) &
                                      (form.isComplete === false)
                                        ? false
                                        : true
                                    }
                                    checked={student.visited}
                                    onChange={(e) => {
                                      passStudentHandler(student);
                                    }}
                                  />
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                {percompliteLesson !== null
                                  ? percompliteLesson.filter(
                                      (el) => el.studentId === student.student
                                    ).length === 0
                                    ? ""
                                    : percompliteLesson
                                        .filter(
                                          (el) =>
                                            el.studentId === student.student
                                        )
                                        .map((el) => {
                                          const status = el.status;
                                          return (
                                            <Badge
                                              bg={
                                                status === "credit"
                                                  ? "danger"
                                                  : status === "frozen"
                                                  ? "info"
                                                  : status === "important"
                                                  ? "warning"
                                                  : "success"
                                              }
                                            >
                                              {status === "credit"
                                                ? "Долг"
                                                : status === "frozen"
                                                ? "Заморожен"
                                                : status === "important"
                                                ? "Ув. причина"
                                                : "success"}
                                            </Badge>
                                          );
                                        })
                                  : ""}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end mt-2">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => {
                      setModalStudent(true);
                      setSelectedStudent(null);
                      console.log(form);
                    }}
                    className={`m-2 ${
                      status === 2 || form.isComplete ? "d-none" : "d-block"
                    }`}
                  >
                    Добавить ученика
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group className={status === 2 ? "d-block" : "d-none"}>
            <Form.Label className="mt-3 mb-0">Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              className="mt-1 p-0"
              disabled
              plaintext
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {(status === 3) & !form.isComplete ? (
          <Button variant="danger" onClick={deleteHandler}>
            Удалить
          </Button>
        ) : (
          ""
        )}
        {status === 2 ? (
          form.isComplete ? (
            <Button variant="danger" onClick={uncompliteLesson}>
              Отменить проведение
            </Button>
          ) : (form.students.length > 0) & (studentsVistings.length > 0) ? (
            <Button variant="success" onClick={compliteLesson}>
              Провести
            </Button>
          ) : (
            ""
          )
        ) : status === 1 || status === 3 ? (
          <Button variant="primary" onClick={submitHandler}>
            Сохранить
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
      <StudentModal
        isStudentActionSuccess={isStudentActionSuccess}
        setIsStudentActionSuccess={setIsStudentActionSuccess}
        isModalOpen={modalStudent}
        setIsModalOpen={setModalStudent}
        students={students}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
        status={status}
        submitHandler={studentSubmitHandler}
        deleteHandler={studentDeleteHandler}
        data={form}
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        needUpdate={needUpdate}
        setNeedUpdate={setNeedUpdate}
      />
    </Modal>
  );
};

export default LessonForm;
