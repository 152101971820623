import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate, Link } from "react-router-dom";
import { api as leadsApi } from "../../../utils/api/api.leads";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputMask from "react-input-mask";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/themes/light.css";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import ActionsTable from "./Actions/ActionsTable";
import { api as studentsApi } from "../../../utils/api/api.students";
import Fade from "react-reveal/Fade";
import Modal from "react-bootstrap/Modal";
import TasksTable from "./TasksTable";

export default function LeadsForm(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [lead, setLead] = useState({
    _id: "",
    name: "",
    secondName: "",
    firstNameStudent: "",
    secondNameStudent: "",
    student: "",
    mobile: "",
    email: "",
    anotherContacts: [{ mobile: "", name: "" }],
    comment: "",
    source: "",
    takeWap: true,
    status: "",
    owner: "",
  });
  const [students, setStudents] = useState(null);
  const [creationDate, setCreationDate] = useState(null);
  const [owner, setOwner] = useState({});
  const [needUpdate, setNeedUpdate] = useState(true);
  const navigation = useNavigate();
  const [leadsStatus, setLeadsStatus] = useState(null);
  const [leadsSources, setLeadsSources] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [studentsFieldsActive, setStudentsFieldsActive] = useState(false);

  const { id } = useParams();

  const handleChange = (e) => {
    if (
      (e.target.name === "name" || e.target.name === "secondName") &
      (e.target.value.length === 1)
    ) {
      return setLead({
        ...lead,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    }
    setLead({ ...lead, [e.target.name]: e.target.value });
  };

  const promtRequest = (mobile) => {
    leadsApi
      .getLeads({
        p: 1,
        s: 10,
        f_mobile: mobile
          .replace(/(\+7|8)/, "")
          .replace(/\(/, "")
          .replace(/\)/, "")
          .replace(/\-/, "")
          .replace(/_/g, ""),
      })
      .then((res) => {
        if (res.leads.length > 0) {
          setPrompt(res.leads);
        }
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
      props.setIsLoading(true);
      leadsApi
        .getCreationData(id)
        .then((res) => {
          setLeadsStatus(res.directory.directory.leadStatus);
          setLeadsSources(res.directory.directory.leadSources);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      if (needUpdate) {
        setStatus(2);
        props.setIsLoading(true);
        leadsApi
          .getLead(id)
          .then((res) => {
            setLead({
              _id: res.lead._id,
              name: res.lead.name,
              secondName: res.lead.secondName,
              firstNameStudent: res.lead.firstNameStudent,
              secondNameStudent: res.lead.secondNameStudent,
              student: res.lead.student,
              mobile: res.lead.mobile,
              email: res.lead.email,
              anotherContacts: res.lead.anotherContacts,
              comment: res.lead.comment,
              source: res.lead.source,
              takeWap: res.lead.takeWap,
              status: res.lead.status,
              owner: res.lead.owner,
            });
            if (
              (res.lead.secondNameStudent !== "") &
                (res.lead.secondNameStudent !== undefined) ||
              (res.lead.firstNameStudent !== "") &
                (res.lead.firstNameStudent !== undefined)
            )
              setStudentsFieldsActive(true);
            else setStudentsFieldsActive(false);
            setCreationDate(res.lead.creationDate);
            setOwner(res.users.find((user) => user._id === res.lead.owner));
            setStudents(res.students);
            setLeadsStatus(res.directory.directory.leadStatus);
            setLeadsSources(res.directory.directory.leadSources);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
        setNeedUpdate(false);
      }
    }
  }, [needUpdate, id]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    if (status === 1) {
      leadsApi
        .createLead({ ...lead })
        .then((res) => {
          navigation("/admin/leads/table/" + res.newLead._id);
          setStatus(2);
          setLead({
            ...lead,
            _id: res.newLead._id,
          });
          if (
            (res.lead.secondNameStudent !== "") &
              (res.lead.secondNameStudent !== undefined) ||
            (res.lead.firstNameStudent !== "") &
              (res.lead.firstNameStudent !== undefined)
          )
            setStudentsFieldsActive(true);
          else setStudentsFieldsActive(false);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else if (status === 3) {
      leadsApi
        .updateLead({ ...lead })
        .then((res) => {
          leadsApi
            .updateLead(lead)
            .then((res) => {
              setLead({
                ...lead,
                name: res.lead.name,
                secondName: res.lead.secondName,
                firstNameStudent: res.lead.firstNameStudent,
                secondNameStudent: res.lead.secondNameStudent,
                student: res.lead.student,
                mobile: res.lead.mobile,
                email: res.lead.email,
                anotherContacts: res.lead.anotherContacts,
                comment: res.lead.comment,
                source: res.lead.source,
                takeWap: res.lead.takeWap,
                status: res.lead.status,
                owner: res.lead.owner,
              });
              if (
                (res.lead.secondNameStudent !== "") &
                  (res.lead.secondNameStudent !== undefined) ||
                (res.lead.firstNameStudent !== "") &
                  (res.lead.firstNameStudent !== undefined)
              )
                setStudentsFieldsActive(true);
              else setStudentsFieldsActive(false);
              setCreationDate(res.lead.creationDate);
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            });
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };

  const createStudent = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    studentsApi
      .createStudent({
        firstName:
          (lead.firstNameStudent !== "") & (lead.firstNameStudent !== undefined)
            ? lead.firstNameStudent
            : lead.name,
        secondName:
          (lead.secondNameStudent !== "") &
          (lead.secondNameStudent !== undefined)
            ? lead.secondNameStudent
            : "",
        anotherContacts: lead.anotherContacts,
        mobile: lead.mobile,
        email: lead.email,
        comment: lead.comment,
        lead: lead._id,
      })
      .then((res) => {
        leadsApi
          .updateLead({ _id: lead._id, student: res.newStudent._id })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          });
        navigation("/admin/students/" + res.newStudent._id);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    leadsApi
      .deleteLead(lead._id)
      .then((res) => {
        navigation("/admin/leads/table");
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <Fade>
      <Accordion className="mb-2" defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Лид</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={onSubmit}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <Row className="g-2" style={{ maxWidth: 630 }}>
                      <Col sm>
                        <Form.Group
                          className="mb-3"
                          style={{
                            color:
                              lead.status === "" || lead.status === undefined
                                ? "black"
                                : leadsStatus.find(
                                    (el) => el._id === lead.status
                                  ).color,
                          }}
                        >
                          <Form.Label>Имя</Form.Label>
                          <Form.Control
                            value={lead.name}
                            style={{
                              color:
                                lead.status === "" || lead.status === undefined
                                  ? "black"
                                  : leadsStatus.find(
                                      (el) => el._id === lead.status
                                    ).color,
                            }}
                            type="text"
                            name="name"
                            placeholder="Имя"
                            onChange={handleChange}
                            disabled={status === 2 ? true : false}
                            plaintext={status === 2 ? true : false}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          style={{
                            color:
                              lead.status === "" || lead.status === undefined
                                ? "black"
                                : leadsStatus.find(
                                    (el) => el._id === lead.status
                                  ).color,
                          }}
                        >
                          <Form.Label>Фамилия</Form.Label>
                          <Form.Control
                            value={lead.secondName}
                            style={{
                              color:
                                lead.status === "" || lead.status === undefined
                                  ? "black"
                                  : leadsStatus.find(
                                      (el) => el._id === lead.status
                                    ).color,
                            }}
                            type="text"
                            name="secondName"
                            placeholder="Фамилия"
                            onChange={handleChange}
                            disabled={status === 2 ? true : false}
                            plaintext={status === 2 ? true : false}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm className="position-relative">
                        <Form.Group className="mb-3">
                          <Form.Label>Номер телефона</Form.Label>
                          <InputMask
                            mask="+7(999)999-9999"
                            value={lead.mobile}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value
                                .replace(/(\+7|8)/, "")
                                .replace(/\(/, "")
                                .replace(/\)/, "")
                                .replace(/\-/, "")
                                .replace(/_/g, "").length > 2) {
                                promtRequest(e.target.value);
                              } else {
                                setPrompt(null);
                              }
                            }}
                            onBlur={() =>
                              setTimeout(() => setPrompt(null), 400)
                            }
                            onFocus={(e) => {
                              if (
                                e.target.value
                                  .replace(/(\+7|8)/, "")
                                  .replace(/\(/, "")
                                  .replace(/\)/, "")
                                  .replace(/\-/, "")
                                  .replace(/_/g, "").length > 2
                              ) {
                                promtRequest(e.target.value);
                              } else {
                                setPrompt(null);
                              }
                            }}
                            disabled={status === 2 ? true : false}
                          >
                            {(inputProps) => (
                              <Form.Control
                                {...inputProps}
                                type="text"
                                name="mobile"
                                placeholder="Телефон"
                                autocomplete={
                                  /chrome/.test(
                                    navigator.userAgent.toLowerCase()
                                  )
                                    ? "disabled"
                                    : "off"
                                }
                                plaintext={status === 2 ? true : false}
                              />
                            )}
                          </InputMask>
                        </Form.Group>
                        {prompt ? (
                          <div
                            className="position-absolute bg-white p-2"
                            style={{
                              right: 0,
                              left: 0,
                              zIndex: 15,
                              border: "1px solid #ced4da",
                              borderRadius: 5,
                            }}
                          >
                            {prompt.map((item, index) => (
                              <Fade key={item._id}>
                                <div>
                                  <div
                                    className="fs-6 f-flex"
                                    style={{
                                      cursor: "pointer",
                                      color: "#00589b",
                                    }}
                                    onClick={() => {
                                      setPrompt(null);
                                      navigation(
                                        "/admin/leads/table/" + item._id
                                      );
                                      setStatus(2);
                                    }}
                                  >
                                    <p>
                                      {index + 1}. {item.name} ({item.mobile})
                                    </p>
                                  </div>
                                </div>
                              </Fade>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <div className="position-absolute top-0 end-0 d-flex">
                      <i
                        className="bi bi-house-door m-1"
                        style={{
                          fontSize: 36,
                          cursor: "pointer",
                          position: "relative",
                          top: -3,
                        }}
                        onClick={() => {
                          navigation("/admin/leads/table");
                        }}
                      />
                      <i
                        className={`bi bi-pencil-square m-1 ${
                          status === 2 ? "d-flex" : "d-none"
                        }`}
                        style={{ fontSize: 36, cursor: "pointer" }}
                        onClick={() => setStatus(3)}
                      />
                      <i
                        className={`bi bi-x-lg m-1 ${
                          userContext.access.superAdmin ? "d-flex" : "d-none"
                        }`}
                        style={{ fontSize: 36, cursor: "pointer" }}
                        onClick={() => setDeletePopup(true)}
                      ></i>
                    </div>
                  </Card.Title>
                  <Card.Text>
                    <Form.Group
                      as={Row}
                      className={`mb-3 ${status === 2 ? "d-flex" : "d-none"}`}
                    >
                      <Form.Label column sm={2}>
                        Ученик
                      </Form.Label>
                      <Col sm={10}>
                        {lead.student === undefined || lead.student === "" ? (
                          <Button variant="primary" onClick={createStudent}>
                            Создать ученика
                          </Button>
                        ) : (
                          <Link to={"/admin/students/" + lead.student}>
                            {students.find((el) => el._id === lead.student)
                              .firstName + " "}{" "}
                            {students.find((el) => el._id === lead.student)
                              .secondName !== undefined
                              ? students.find((el) => el._id === lead.student)
                                  .secondName
                              : ""}
                          </Link>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Ученик не лид
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          disabled={
                            status === 2 ||
                            (lead.firstNameStudent !== "") &
                              (lead.firstNameStudent !== undefined) ||
                            (lead.secondNameStudent !== "") &
                              (lead.secondNameStudent !== undefined) ||
                            (lead.student !== "") & (lead.student !== undefined)
                              ? true
                              : false
                          }
                          type="switch"
                          name="anotherStudentName"
                          onChange={() => {
                            setStudentsFieldsActive(!studentsFieldsActive);
                          }}
                          checked={studentsFieldsActive === true ? "on" : ""}
                        />
                      </Col>
                    </Form.Group>
                    {studentsFieldsActive ? (
                      <>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm={2}>
                            Имя ученика
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="text"
                              name="firstNameStudent"
                              onChange={handleChange}
                              value={lead.firstNameStudent}
                              disabled={
                                status === 2 ||
                                (lead.student !== "") &
                                  (lead.student !== undefined)
                                  ? true
                                  : false
                              }
                              plaintext={
                                status === 2 ||
                                (lead.student !== "") &
                                  (lead.student !== undefined)
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm={2}>
                            Фамилия ученика
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="text"
                              name="secondNameStudent"
                              onChange={handleChange}
                              value={lead.secondNameStudent}
                              disabled={
                                status === 2 ||
                                (lead.student !== "") &
                                  (lead.student !== undefined)
                                  ? true
                                  : false
                              }
                              plaintext={
                                status === 2 ||
                                (lead.student !== "") &
                                  (lead.student !== undefined)
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Form.Group>
                      </>
                    ) : (
                      ""
                    )}
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Статус
                      </Form.Label>
                      <Col sm={10}>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет статусов"}
                          options={
                            leadsStatus === null
                              ? {}
                              : leadsStatus.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.value,
                                  };
                                })
                          }
                          value={
                            (lead.status !== undefined) &
                            (lead.status !== "") &
                            (leadsStatus !== null)
                              ? {
                                  value: leadsStatus.find(
                                    (el) => el._id === lead.status
                                  )._id,
                                  label: leadsStatus.find(
                                    (el) => el._id === lead.status
                                  ).value,
                                }
                              : ""
                          }
                          type="text"
                          name="status"
                          onChange={(data) =>
                            setLead({
                              ...lead,
                              status: data.value,
                            })
                          }
                          isDisabled={status === 2 ? true : false}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Источник
                      </Form.Label>
                      <Col sm={10}>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет источников"}
                          options={
                            leadsSources === null
                              ? {}
                              : leadsSources.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.value,
                                  };
                                })
                          }
                          value={
                            (lead.source !== undefined) &
                            (lead.source !== "") &
                            (leadsSources !== null)
                              ? {
                                  value: leadsSources.find(
                                    (el) => el._id === lead.source
                                  )._id,
                                  label: leadsSources.find(
                                    (el) => el._id === lead.source
                                  ).value,
                                }
                              : ""
                          }
                          type="text"
                          name="source"
                          onChange={(data) =>
                            setLead({
                              ...lead,
                              source: data.value,
                            })
                          }
                          isDisabled={status === 2 ? true : false}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        E-mail
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="email"
                          onChange={handleChange}
                          value={lead.email}
                          disabled={status === 2 ? true : false}
                          plaintext={status === 2 ? true : false}
                        />
                      </Col>
                    </Form.Group>
                    <Row className="mb-3">
                      <Col sm={2}>Доп. телефоны:</Col>
                      <Col sm={10}>
                        {lead.anotherContacts.map((element, index) => (
                          <Row key={index} className="mb-1">
                            <Col sm={4}>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Имя"
                                onChange={(e) => {
                                  setLead({
                                    ...lead,
                                    anotherContacts: lead.anotherContacts.map(
                                      (extraMobile, ind) => {
                                        if (ind === index) {
                                          return {
                                            ...extraMobile,
                                            name: e.target.value,
                                          };
                                        } else {
                                          return extraMobile;
                                        }
                                      }
                                    ),
                                  });
                                }}
                                value={lead.anotherContacts[index].name}
                                disabled={status === 2 ? true : false}
                                plaintext={status === 2 ? true : false}
                              />
                            </Col>
                            <Col sm={4}>
                              <Form.Control
                                type="text"
                                name="mobile"
                                placeholder="Телефон"
                                onChange={(e) => {
                                  setLead({
                                    ...lead,
                                    anotherContacts: lead.anotherContacts.map(
                                      (extraMobile, ind) => {
                                        if (ind === index) {
                                          return {
                                            ...extraMobile,
                                            mobile: e.target.value,
                                          };
                                        } else {
                                          return extraMobile;
                                        }
                                      }
                                    ),
                                  });
                                }}
                                value={lead.anotherContacts[index].mobile}
                                disabled={status === 2 ? true : false}
                                plaintext={status === 2 ? true : false}
                              />
                            </Col>
                            {status === 2 ? (
                              ""
                            ) : (
                              <Col sm={3}>
                                <Button
                                  type="button"
                                  variant="secondary"
                                  onClick={(e) => {
                                    setLead({
                                      ...lead,
                                      anotherContacts:
                                        lead.anotherContacts.filter(
                                          (extraMobile, ind) => ind !== index
                                        ),
                                    });
                                  }}
                                >
                                  Удалить
                                </Button>
                              </Col>
                            )}
                          </Row>
                        ))}
                        {status === 2 ? (
                          ""
                        ) : (
                          <Button
                            type="button"
                            variant="primary"
                            className="mt-3"
                            onClick={(e) => {
                              setLead({
                                ...lead,
                                anotherContacts: !Array.isArray(
                                  lead.anotherContacts
                                )
                                  ? [
                                      {
                                        name: "",
                                        mobile: "",
                                      },
                                    ]
                                  : [
                                      ...lead.anotherContacts,
                                      {
                                        name: "",
                                        mobile: "",
                                      },
                                    ],
                              });
                            }}
                          >
                            Добавить
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Принимать WhatsApp
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          disabled={status === 2 ? true : false}
                          type="switch"
                          name="takeWap"
                          onChange={() => setLead({...lead, takeWap: !lead.takeWap})}
                          checked={lead.takeWap === true ? "on" : ""}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`mb-3 ${status === 2 ? "d-flex" : "d-none"}`}
                    >
                      <Form.Label column sm={2}>
                        Дата создания
                      </Form.Label>
                      <Col sm={10}>
                        <Flatpickr
                          className={`form-control ${
                            status === 2 && "form-control-plaintext"
                          }`}
                          style={{ backgroundColor: "transparent" }}
                          value={creationDate}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            disableMobile: true,
                            dateFormat: "d.m.Y",
                          }}
                          name="creationDate"
                          onChange={([date]) => {
                            setCreationDate(date);
                          }}
                          disabled={true}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Комментарий
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="textarea"
                          type="text"
                          name="comment"
                          onChange={handleChange}
                          value={lead.comment}
                          disabled={status === 2 ? true : false}
                          plaintext={status === 2 ? true : false}
                        />
                      </Col>
                    </Form.Group>
                  </Card.Text>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      type="submit"
                      className={`${status === 2 ? "d-none" : "d-block"}`}
                    >
                      Сохранить
                    </Button>
                  </div>
                </Card.Body>
                <Form.Group
                  className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}
                >
                  <Form.Label>Создал пользователь</Form.Label>
                  <Form.Control
                    value={
                      owner !== {}
                        ? owner.firstName + " " + owner.secondName
                        : ""
                    }
                    type="text"
                    name="owner"
                    disabled
                    plaintext
                  />
                </Form.Group>
              </Card>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        {userContext.access.actionsCRM & (status === 2) ? (
          <Accordion.Item eventKey="1">
            <Accordion.Header>Действия по лиду</Accordion.Header>
            <Accordion.Body>
              <ActionsTable
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
                lead={lead._id}
              />
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          ""
        )}
        {userContext.access.tasks & (status === 2) ? (
          <Accordion.Item eventKey="2">
            <Accordion.Header>Задачи по лиду</Accordion.Header>
            <Accordion.Body>
              <TasksTable
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
                lead={lead._id}
              />
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          ""
        )}
      </Accordion>
      <Modal show={deletePopup} onHide={() => setDeletePopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление лида</Modal.Title>
        </Modal.Header>
        <Modal.Body>Подтвердите удаление</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeletePopup(false)}>
            Закрыть
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </Fade>
  );
}
