import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate, Link } from "react-router-dom";
import { api as studentsApi } from "../../../utils/api/api.students";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import InputMask from "react-input-mask";
import "flatpickr/dist/themes/light.css";
import Accordion from "react-bootstrap/Accordion";
import MoneyOperations from "./Operationos/Money/MoneyOperations";
import AccountsTable from "./Operationos/Accounts/AccountsTable";
import CreditsTable from "./Operationos/Credits/CreditsTable";

export default function StudentsForm(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [student, setStudent] = useState({
    _id: "",
    firstName: "",
    secondName: "",
    cardId: "",
    photo: "",
    email: "",
    student: "",
    mobile: "",
    user: "",
    isActive: "",
    moneyBalance: "",
    bonuceBalance: "",
    personalDiscount: "",
    owner: "",
    сomment: "",
    anotherContacts: [],
  });
  const [birthday, setBirthday] = useState("");
  const [users, setUsers] = useState([]);
  const [students, setstudents] = useState([]);
  const [owner, setOwner] = useState({});
  const [needUpdate, setNeedUpdate] = useState(true);
  const [setupBonuces, setSetupBonuces] = useState({});
  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    if (
      (e.target.name === "firstName" || e.target.name === "secondName") &
      (e.target.value.length === 1)
    ) {
      return setStudent({
        ...student,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    }
    setStudent({ ...student, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
    } else {
      if (needUpdate) {
        setStatus(2);
        props.setIsLoading(true);
        studentsApi
          .getStudent(id)
          .then((res) => {
            setStudent({
              _id: res.student._id,
              firstName: res.student.firstName,
              secondName: res.student.secondName,
              cardId: res.student.cardId,
              photo: res.student.photo,
              email: res.student.email,
              student: res.student.student,
              mobile: res.student.mobile,
              user: res.student.user,
              isActive: res.student.isActive,
              moneyBalance: res.student.moneyBalance,
              bonuceBalance: res.student.bonuceBalance,
              personalDiscount: res.student.personalDiscount,
              owner: res.student.owner,
              comment: res.student.comment,
              anotherContacts: res.student.anotherContacts,
            });
            setBirthday(res.student.birthday);
            setUsers(res.users);
            setstudents(res.students);
            setOwner(res.users.find((user) => user._id === res.student.owner));
            setSetupBonuces(res.setupBonuces[0].setups.bonuces);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
        setNeedUpdate(false);
      }
    }
  }, [needUpdate]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    if (status === 1) {
      studentsApi
        .createStudent({ ...student, birthday: birthday })
        .then((res) => {
          navigation("/admin/students/" + res.newStudent._id);
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else if (status === 3) {
      studentsApi
        .updateStudent({ ...student, birthday: birthday })
        .then((res) => {
          studentsApi
            .getStudent(student._id)
            .then((res) => {
              setStudent({
                _id: res.student._id,
                firstName: res.student.firstName,
                secondName: res.student.secondName,
                cardId: res.student.cardId,
                photo: res.student.photo,
                email: res.student.email,
                birthday: res.student.birthday,
                student: res.student.student,
                mobile: res.student.mobile,
                user: res.student.user,
                isActive: res.student.isActive,
                moneyBalance: res.student.moneyBalance,
                bonuceBalance: res.student.bonuceBalance,
                personalDiscount: res.student.personalDiscount,
                owner: res.student.owner,
                comment: res.student.comment,
                anotherContacts: res.student.anotherContacts,
              });
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            });
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };

  return (
    <Accordion className="mb-2" defaultActiveKey={["0"]} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Ученик</Accordion.Header>
        <Accordion.Body>
          <Form onSubmit={onSubmit}>
            <Card>
              <Card.Body>
                <Card.Title>
                  {/* <i
                    className="bi bi-chevron-compact-left me-3"
                    style={{ fontSize: 30, cursor: "pointer" }}
                    onClick={() => navigation(-1)}
                  /> */}
                  <Row className="g-2" style={{ maxWidth: 630 }}>
                    <Col sm>
                      <Form.Group className="mb-3">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                          value={student.firstName}
                          type="text"
                          name="firstName"
                          placeholder="Имя"
                          onChange={handleChange}
                          disabled={status === 2 ? true : false}
                          plaintext={status === 2 ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm>
                      <Form.Group className="mb-3">
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control
                          type="text"
                          name="secondName"
                          placeholder="Фамилия"
                          onChange={handleChange}
                          value={student.secondName}
                          disabled={status === 2 ? true : false}
                          plaintext={status === 2 ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <i
                    className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                      status === 2 ? "d-flex" : "d-none"
                    }`}
                    style={{ fontSize: 36, cursor: "pointer" }}
                    onClick={() => setStatus(3)}
                  ></i>
                </Card.Title>
                <Card.Text>
                  <Form.Group
                    as={Row}
                    className={`mb-3 ${status === 2 ? "d-flex" : "d-none"}`}
                  >
                    <Form.Label column sm={2}>
                      Баланс
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        value={student.moneyBalance}
                        name="moneyBalance"
                        plaintext
                        disabled
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className={`mb-3 ${
                      setupBonuces !== {} && setupBonuces.isOn
                        ? "d-flex"
                        : "d-none"
                    }`}
                  >
                    <Form.Label column sm={2}>
                      Бонусы
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        value={student.bonuceBalance}
                        name="bonuceBalance"
                        disabled
                        plaintext
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Персональная скидка
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        value={student.personalDiscount}
                        onChange={handleChange}
                        name="personalDiscount"
                        disabled={
                          status === 2
                            ? true
                            : userContext.access.discounts
                            ? false
                            : true
                        }
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Card
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="cardId"
                        value={student.cardId}
                        onChange={handleChange}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      E-mail
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="email"
                        onChange={handleChange}
                        value={student.email}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      День рождения
                    </Form.Label>
                    <Col sm={10}>
                      <Flatpickr
                        className={`form-control ${
                          status === 2 && "form-control-plaintext"
                        }`}
                        style={{ backgroundColor: "transparent" }}
                        value={birthday}
                        options={{
                          time_24hr: true,
                          locale: Russian,
                          disableMobile: true,
                          dateFormat: "d.m.Y",
                        }}
                        name="birthday"
                        onChange={([date]) => {
                          setBirthday(date);
                        }}
                        disabled={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Номер телефона
                    </Form.Label>
                    <Col sm={10}>
                      <InputMask
                        mask="+7(999)999-9999"
                        onChange={handleChange}
                        value={student.mobile}
                        disabled={status === 2 ? true : false}
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            type="text"
                            name="mobile"
                            plaintext={status === 2 ? true : false}
                          />
                        )}
                      </InputMask>
                    </Col>
                  </Form.Group>
                  {(student.student !== "") &
                  (student.student !== null) &
                  (student.student !== undefined) ? (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Лид
                      </Form.Label>
                      <Col sm={10}>
                        <Link to={"/admin/students/table/" + student.student}>
                          Перейти
                        </Link>
                      </Col>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                  {/* <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Пользователь
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="user"
                        onChange={handleChange}
                        value={student.user}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group> */}
                  <Row className="mb-3">
                    <Col sm={2}>Доп. телефоны:</Col>
                    <Col sm={10}>
                      {student.anotherContacts.map((element, index) => (
                        <Row key={index} className="mb-1">
                          <Col sm={4}>
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Имя"
                              onChange={(e) => {
                                setStudent({
                                  ...student,
                                  anotherContacts: student.anotherContacts.map(
                                    (extraMobile, ind) => {
                                      if (ind === index) {
                                        return {
                                          ...extraMobile,
                                          name: e.target.value,
                                        };
                                      } else {
                                        return extraMobile;
                                      }
                                    }
                                  ),
                                });
                              }}
                              value={student.anotherContacts[index].name}
                              disabled={status === 2 ? true : false}
                              plaintext={status === 2 ? true : false}
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Control
                              type="text"
                              name="mobile"
                              placeholder="Телефон"
                              onChange={(e) => {
                                setStudent({
                                  ...student,
                                  anotherContacts: student.anotherContacts.map(
                                    (extraMobile, ind) => {
                                      if (ind === index) {
                                        return {
                                          ...extraMobile,
                                          mobile: e.target.value,
                                        };
                                      } else {
                                        return extraMobile;
                                      }
                                    }
                                  ),
                                });
                              }}
                              value={student.anotherContacts[index].mobile}
                              disabled={status === 2 ? true : false}
                              plaintext={status === 2 ? true : false}
                            />
                          </Col>
                          {status === 2 ? (
                            ""
                          ) : (
                            <Col sm={3}>
                              <Button
                                type="button"
                                variant="secondary"
                                onClick={(e) => {
                                  setStudent({
                                    ...student,
                                    anotherContacts:
                                      student.anotherContacts.filter(
                                        (extraMobile, ind) => ind !== index
                                      ),
                                  });
                                }}
                              >
                                Удалить
                              </Button>
                            </Col>
                          )}
                        </Row>
                      ))}
                      {status === 2 ? (
                        ""
                      ) : (
                        <Button
                          type="button"
                          variant="primary"
                          className="mt-3"
                          onClick={(e) => {
                            setStudent({
                              ...student,
                              anotherContacts: !Array.isArray(
                                student.anotherContacts
                              )
                                ? [
                                    {
                                      name: "",
                                      mobile: "",
                                    },
                                  ]
                                : [
                                    ...student.anotherContacts,
                                    {
                                      name: "",
                                      mobile: "",
                                    },
                                  ],
                            });
                          }}
                        >
                          Добавить
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Комментарий
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="textarea"
                        type="text"
                        name="comment"
                        onChange={handleChange}
                        value={student.comment}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Активность
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Select
                        name="isActive"
                        value={student.isActive}
                        onChange={handleChange}
                        disabled={status === 2 ? true : false}
                        plaintext={status === 2 ? true : false}
                      >
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Card.Text>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    className={`${status === 2 ? "d-none" : "d-block"}`}
                  >
                    Сохранить
                  </Button>
                </div>
              </Card.Body>
              <Form.Group
                className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}
              >
                <Form.Label>Создал пользователь</Form.Label>
                <Form.Control
                  value={
                    owner !== {} ? owner.firstName + " " + owner.secondName : ""
                  }
                  type="text"
                  name="owner"
                  disabled
                  plaintext
                />
              </Form.Group>
            </Card>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
      {userContext.access.moneyOperations &
      userContext.access.lessonsOperations &
      (status === 2) ? (
        <Accordion.Item eventKey="1">
          <Accordion.Header>Операции</Accordion.Header>
          <Accordion.Body>
            <MoneyOperations
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              studentId={student._id}
              setNeedUpdate={setNeedUpdate}
            />
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        ""
      )}
      {userContext.access.moneyOperations &
      userContext.access.lessonsOperations &
      userContext.access.accounts &
      (status === 2) ? (
        <Accordion.Item eventKey="2">
          <Accordion.Header>Счета</Accordion.Header>
          <Accordion.Body>
            <AccountsTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              studentId={student._id}
              setNeedUpdate={setNeedUpdate}
            />
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        ""
      )}
      {userContext.access.moneyOperations &
      userContext.access.lessonsOperations &
      userContext.access.accounts &
      userContext.access.creditOperations &
      (status === 2) ? (
        <Accordion.Item eventKey="3">
          <Accordion.Header>Долги</Accordion.Header>
          <Accordion.Body>
            <CreditsTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              studentId={student._id}
              setNeedUpdate={setNeedUpdate}
            />
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        ""
      )}
    </Accordion>
  );
}
