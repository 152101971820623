import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { api as salariesApi } from "../../../utils/api/api.salaries";
import SalariesForm from "./SalariesForm";

export default function MoneyForm(props) {
  const [type, setType] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [data, setData] = useState(null);

  const navigation = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (id === "new") {
      setIsNew(true);
    } else {
      salariesApi
        .getOperation(id)
        .then((res) => {
          setData(res.operationInfo._id);
          setType(res.operationInfo.type);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, []);

  return (
    <>
      {isNew ? (
        <Modal show={true} onHide={() => navigation(-1)} fullscreen>
          <Modal.Header closeButton>
            <Modal.Title>Выберите тип операции</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Select value={type} onChange={(e) => {
                if (e.target.value !== 0) {
                    setIsNew(false);
                }
                setType(e.target.value)
                }}>
              <option value=""> </option>
              <option value={1}>Начисление</option>
              <option value={2}>Выплата</option>
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => navigation(-1)}>
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <SalariesForm
          setIsLoading={props.setIsLoading}
          setIsError={props.setIsError}
          setError={props.setError}
          data={data}
          modalType={Number(type)}
          navigation={navigation}
        />
      )}
    </>
  );
}
