class Api {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  getDances(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if ((params[key] !== undefined) & (params[key] !== "") & (params[key] !== null)) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/dances/" + querry, {
      method: "GET",
      headers: {
        Authorization: 
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createDance(dance) {
    const keys = Object.keys(dance);
    keys.forEach((key) => {
      if (dance[key] === undefined || dance[key] === "") {
        delete dance[key];
      }
    });
    return fetch("/api/dances/", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dance),
    }).then((res) => this._checkResponse(res));
  }

  getDance(id) {
    return fetch("/api/dances/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  updateDance(dance) {
    return fetch("/api/dances/", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dance),
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
