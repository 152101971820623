import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Kanban from "../../../vendor/kanban/index";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import { api as tasksApi } from "../../../utils/api/api.tasks";

export default function TasksKanBan(props) {
  const [leads, setLeads] = useState(null);
  const [students, setStudents] = useState(null);
  const [tasksPriorities, setTasksPriorities] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [finder, setFinder] = useState({
    f_title: "",
    f_lead: "",
    f_student: "",
    f_creator: "",
    f_responsible: "",
    f_priority: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [f_datef_to, setF_datef_to] = useState("");
  const [f_datet_to, setF_datet_to] = useState("");

  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const navigation = useNavigate();

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (needUpdate) {
      props.setIsLoading(true);
      tasksApi
        .getTasks({ p: 1, s: 1000000000000000 })
        .then((res) => {
          const kanban = {};
          kanban.cards = res.tasks.map((item) => {
            return {
              id: item._id,
              title: item.title,
              priority: item.priority,
              date: item.date,
              dateTo: item.dateTo,
              status: item.status,
            };
          });
          kanban.columns = {};
          res.directory.directory.taskStatus.forEach((item) => {
            kanban.columns[item._id] = {
              id: item._id,
              title: item.value,
              cardIds: res.tasks
                .filter((task) => task.status === item._id)
                .map((task) => task._id),
            };
          });
          kanban.columnOrder = res.directory.directory.taskStatus.map(
            (item) => item._id
          );
          setData(kanban);
          setLeads(res.leads);
          setStudents(res.students);
          setTasksPriorities(res.directory.directory.taskPriorities);
          setTasks(res.tasks);
          setStatuses(res.directory.directory.taskStatus);
          setUsers(res.users);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          setNeedUpdate(false);
        });
    }
  }, [needUpdate]);

  const statusChange = (taskInfo) => {
    tasksApi
      .actionInTask({
        _id: taskInfo.draggableId,
        status: taskInfo.destination.droppableId,
      })
      .then(() => setNeedUpdate(true))
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    tasksApi
      .getTasks({
        p: 1,
        s: 100000000000000000,
        ...finder,
        f_datef,
        f_datet,
        f_datef_to,
        f_datet_to,
      })
      .then((res) => {
        const kanban = {};
        kanban.cards = res.tasks.map((item) => {
          return {
            id: item._id,
            title: item.title,
            priority: item.priority,
            date: item.date,
            dateTo: item.dateTo,
            status: item.status,
          };
        });
        kanban.columns = {};
        res.directory.directory.taskStatus.forEach((item) => {
          kanban.columns[item._id] = {
            id: item._id,
            title: item.value,
            cardIds: res.tasks
              .filter((task) => task.status === item._id)
              .map((task) => task._id),
          };
        });
        kanban.columnOrder = res.directory.directory.taskStatus.map(
          (item) => item._id
        );
        setData(kanban);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    tasksApi
      .getTasks({
        p: 1,
        s: 100000000000000000,
      })
      .then((res) => {
        setFinder({
          f_title: "",
          f_lead: "",
          f_student: "",
          f_creator: "",
          f_responsible: "",
          f_status: "",
          f_priority: "",
        });
        setF_datef("");
        setF_datet("");
        setF_datef_to("");
        setF_datet_to("");
        const kanban = {};
        kanban.cards = res.tasks.map((item) => {
          return {
            id: item._id,
            title: item.title,
            priority: item.priority,
            date: item.date,
            dateTo: item.dateTo,
            status: item.status,
          };
        });
        kanban.columns = {};
        res.directory.directory.taskStatus.forEach((item) => {
          kanban.columns[item._id] = {
            id: item._id,
            title: item.value,
            cardIds: res.tasks
              .filter((task) => task.status === item._id)
              .map((task) => task._id),
          };
        });
        kanban.columnOrder = res.directory.directory.taskStatus.map(
          (item) => item._id
        );
        setData(kanban);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <>
      {data !== null ? (
        <Fade>
          <Accordion className="mb-2 mt-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Поиск</Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={tableFilter}>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Дата создания с</Form.Label>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datef}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datef(new Date(date));
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Дата создания по</Form.Label>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datet}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datet(new Date(date));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Срок с</Form.Label>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datef_to}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datef_to(new Date(date));
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Срок по</Form.Label>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datet_to}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datet_to(new Date(date));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Название
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="f_title"
                        value={finder.f_title}
                        onChange={finderHandlerChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Ученик
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет учеников"}
                        options={
                          students === null
                            ? {}
                            : students.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.firstName + " " + el.secondName,
                                };
                              })
                        }
                        value={
                          finder.f_student === ""
                            ? {}
                            : {
                                value: students.find(
                                  (el) => el._id === finder.f_student
                                )._id,
                                label:
                                  students.find(
                                    (el) => el._id === finder.f_student
                                  ).firstName +
                                  " " +
                                  students.find(
                                    (el) => el._id === finder.f_student
                                  ).secondName,
                              }
                        }
                        type="text"
                        name="f_student"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_student: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Лид
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет лидов"}
                        options={
                          leads === null
                            ? {}
                            : leads.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.name +
                                  (el.secondName
                                    ? " " + el.secondName
                                    : ""),
                                };
                              })
                        }
                        value={
                          finder.f_lead === ""
                            ? {}
                            : {
                                value: leads.find(
                                  (el) => el._id === finder.f_lead
                                )._id,
                                label: leads.find(
                                  (el) => el._id === finder.f_lead
                                ).name +
                                (leads.find((el) => el._id === finder.f_lead
                                  .secondName
                                  ? " " +
                                    leads.find((el) => el._id === finder.f_lead)
                                      .secondName
                                  : ""))
                              }
                        }
                        type="text"
                        name="f_lead"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_lead: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Приоритет
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет приоритетов"}
                        options={
                          tasksPriorities === null
                            ? {}
                            : tasksPriorities.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.value,
                                };
                              })
                        }
                        value={
                          finder.f_priority === ""
                            ? {}
                            : {
                                value: tasksPriorities.find(
                                  (el) => el._id === finder.f_priority
                                )._id,
                                label: tasksPriorities.find(
                                  (el) => el._id === finder.f_priority
                                ).value,
                              }
                        }
                        type="text"
                        name="f_priority"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_priority: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Ответственный</Form.Label>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет пользователей"}
                      options={
                        users === null
                          ? {}
                          : users.map((el) => {
                              return {
                                value: el._id,
                                label: el.firstName + " " + el.secondName,
                              };
                            })
                      }
                      value={
                        finder.f_responsible === ""
                          ? {}
                          : {
                              value: users.find(
                                (el) => el._id === finder.f_responsible
                              )._id,
                              label:
                                users.find(
                                  (el) => el._id === finder.f_responsible
                                ).firstName +
                                " " +
                                users.find(
                                  (el) => el._id === finder.f_responsible
                                ).secondName,
                            }
                      }
                      type="text"
                      name="responsible"
                      onChange={(data) => {
                        setFinder({
                          ...finder,
                          f_responsible: data.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Создатель</Form.Label>
                    <Select
                      placeholder=""
                      noOptionsMessage={() => "Нет пользователей"}
                      options={
                        users === null
                          ? {}
                          : users.map((el) => {
                              return {
                                value: el._id,
                                label: el.firstName + " " + el.secondName,
                              };
                            })
                      }
                      value={
                        finder.f_creator === ""
                          ? {}
                          : {
                              value: users.find(
                                (el) => el._id === finder.f_creator
                              )._id,
                              label:
                                users.find((el) => el._id === finder.f_creator)
                                  .firstName +
                                " " +
                                users.find((el) => el._id === finder.f_creator)
                                  .secondName,
                            }
                      }
                      type="text"
                      name="creator"
                      onChange={(data) => {
                        setFinder({
                          ...finder,
                          f_creator: data.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Button
                    className="me-2"
                    variant="outline-secondary"
                    type="button"
                    onClick={resetFilter}
                  >
                    Очистить
                  </Button>
                  <Button variant="primary" type="submit">
                    Поиск
                  </Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Kanban
            data={data}
            onDragEnd={(taskInfo) => statusChange(taskInfo)}
            renderer={(card) => {
              return (
                <Card key={card.id}>
                  <Card.Body className="p-0">
                    <Card.Title
                      className="p-0"
                      style={{
                        color: tasksPriorities.find((el) => el._id === card.priority)
                          .color,
                      }}
                    >
                      <i
                        className="bi bi-eye-fill"
                        style={{ cursor: "pointer", fontSize: 18 }}
                        onClick={() => navigation(card.id)}
                      />{" "}
                      {card.title}
                    </Card.Title>
                    {new Date(card.date).toLocaleString("ru-RU", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                    {(card.dateTo !== undefined) &
                    (card.dateTo !== "") &
                    (card.dateTo !== null)
                      ? " - " +
                        new Date(card.dateTo).toLocaleString("ru-RU", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      : ""}
                    <p className="m-0">
                      {tasksPriorities.find((el) => el._id === card.priority).value}
                    </p>
                  </Card.Body>
                </Card>
              );
            }}
          ></Kanban>
        </Fade>
      ) : (
        ""
      )}
    </>
  );
}
