import React, { useState, useEffect } from "react";
import { api as actionsApi } from "../../../utils/api/api.actionscrm";
import { Card, ListGroup, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function ActionsCRM(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [types, setTypes] = useState(null);
  const [leads, setLeads] = useState(null);

  const navigator = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    actionsApi
      .getActions({ mode_dash: 1 })
      .then((res) => {
        setTypes(res.directory.directory.crmActions);
        setLeads(res.leads);
        setData(res.actions);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Card>
      <h5>Действия в CRM: </h5>
      {data ? (
        data.length !== 0 ? (
          <ListGroup>
            {data
              .filter((el, ind) => ind < 4)
              .map((el) => (
                <ListGroup.Item>
                  {types.find((le) => le._id === el.type).value +
                    " " +
                    new Date(el.date).toLocaleString("ru-RU", {
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }) +
                    " | " +
                    leads.find((le) => le._id === el.lead).name +
                    " " +
                    (leads.find((le) => le._id === el.lead).secondName
                      ? leads.find((le) => le._id === el.lead).secondName
                      : "")}
                  <i
                    className="bi bi-eye-fill ms-2"
                    style={{ cursor: "pointer", fontSize: 16 }}
                    onClick={() =>
                      navigator("/admin/actions-crm/calendar" + el._id)
                    }
                  />
                </ListGroup.Item>
              ))}
            {data.length > 4 ? (
              <ListGroup.Item>
                <Link to="/admin/actions-crm/calendar">Еще...</Link>
              </ListGroup.Item>
            ) : (
              ""
            )}
          </ListGroup>
        ) : (
          <p>На сегодня действий нет.</p>
        )
      ) : (
        ""
      )}
      <div
        className={`position-absolute align-items-center justify-content-center bg-light bg-opacity-50 flex-column ${
          isLoading ? "d-flex" : "d-none"
        }`}
        style={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 1100 }}
      >
        <Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />
        <p className="fw-light fs-2 text-center mt-4">Загрузка...</p>
      </div>
    </Card>
  );
}
