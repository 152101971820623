class Api {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  getLeads(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        (params[key] !== undefined) &
        (params[key] !== "") &
        (params[key] !== null)
      ) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/leads/" + querry, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getLead(id) {
    return fetch("/api/leads/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createLead(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined || student[key] === "") {
        delete student[key];
      }
    });
    return fetch("/api/leads/", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  updateLead(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined) {
        delete student[key];
      }
    });
    return fetch("/api/leads/", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  deleteLead(id) {
    return fetch("/api/leads/", {
      method: "DELETE",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ leadId: id }),
    }).then((res) => this._checkResponse(res));
  }

  getCreationData() {
    return fetch("/api/leads/create-data", {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getFunnel(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        (params[key] !== undefined) &
        (params[key] !== "") &
        (params[key] !== null)
      ) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/leads/funnel" + querry, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
