import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { api as moneyApi } from "../../../../utils/api/api.money";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const DateFormatter = (obj) => {
  return new Date(obj.value).toLocaleString("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const MoneyTable = (props) => {
  const [finder, setFinder] = useState({
    f_paymentt: "",
    f_type: "",
    f_sum: "",
    f_totalsum: "",
    f_student: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [data, setData] = useState([]);
  const [students, setStudents] = useState(null);
  const [payments, setPayments] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50, 100]);
  const [sorting, setSorting] = useState({ sort_by: "date", sort_dir: -1 });
  const [totalCount, setTotalCount] = useState(0);
  const [dateColumns] = useState(["date"]);
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "student", width: 150 },
    { columnName: "date", width: 150 },
    { columnName: "sum", width: 120 },
    { columnName: "totalSum", width: 160 },
  ]);

  const navigation = useNavigate();

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => {
              navigation(row._id);
            }}
          />
        </td>
      );
    }
    if (column.name === "student") {
      return (
        <td>
          {students.find((el) => el._id === row.student).firstName +
            " " +
            students.find((el) => el._id === row.student).secondName}
        </td>
      );
    }
    if (column.name === "sum") {
      if (row.sum > 0) {
        return (
          <td>
            <p className="text-success fw-bold">{row.sum}</p>
          </td>
        );
      } else if (row.sum < 0) {
        return (
          <td>
            <p className="text-danger fw-bold">{row.sum}</p>
          </td>
        );
      }
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    moneyApi
      .getOperations({
        p: currentPage + 1,
        s: pageSize,
        ...sorting,
      })
      .then((res) => {
        setStudents(res.students);
        setData(res.moneyOperations);
        setTotalCount(res.totalDocs);
        setPayments(res.paymentTypes[0].directory.paymentTypes);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  }, []);

  const changePage = (page) => {
    props.setIsLoading(true);
    moneyApi
      .getOperations({
        p: page + 1,
        s: pageSize,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.moneyOperations);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    moneyApi
      .getOperations({
        p: 1,
        s: size,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.moneyOperations);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    moneyApi
      .getOperations({
        p: 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.moneyOperations);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    moneyApi
      .getOperations({
        p: 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        ...sorting,
      })
      .then((res) => {
        setData(res.moneyOperations);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    moneyApi
      .getOperations({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_paymentt: "",
          f_type: "",
          f_sum: "",
          f_totalsum: "",
          f_student: "",
        });
        setF_datef("");
        setF_datet("");
        setData(res.moneyOperations);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "student", title: "Ученик" },
    { name: "date", title: "Дата" },
    { name: "sum", title: "Сумма" },
    { name: "totalSum", title: "Сумма со скидками" },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата c
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datef}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      dateFormat: "d.m.Y",
                      disableMobile: true,
                    }}
                    onChange={([date]) => {
                      setF_datef(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата по
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datet}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      dateFormat: "d.m.Y",
                      disableMobile: true,
                    }}
                    onChange={([date]) => {
                      setF_datet(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Ученик
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет доступных учеников"}
                    options={
                      (students !== null) & (students !== undefined)
                        ? students.map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                        : []
                    }
                    value={
                      (students !== undefined) &
                      (students !== null) &
                      (finder.f_student !== "")
                        ? {
                            value: students.find(
                              (el) => el._id === finder.f_student
                            )._id,
                            label:
                              students.find((el) => el._id === finder.f_student)
                                .firstName +
                              " " +
                              students.find((el) => el._id === finder.f_student)
                                .secondName,
                          }
                        : ""
                    }
                    type="text"
                    name="student"
                    onChange={(data) => {
                      setFinder({
                        ...finder,
                        f_student: data.value,
                      });
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_type"
                    value={finder.f_type}
                    onChange={finderHandlerChange}
                  >
                    <option value=""> </option>
                    <option value="1">Приход</option>
                    <option value="2">Расход</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Способ оплаты
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_paymentt"
                    value={finder.f_paymentt}
                    onChange={finderHandlerChange}
                  >
                    <option value=""> </option>
                    {payments[0] !== undefined &&
                      payments.map((paymentType) => (
                        <option key={paymentType._id} value={paymentType._id}>
                          {paymentType.value}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Сумма
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_sum"
                    value={finder.f_sum}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <DateTypeProvider for={dateColumns} />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
    </>
  );
};

export default MoneyTable;
