import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { api as creditApi } from "../../../../../utils/api/api.credits";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Link } from "react-router-dom";
import CreditsForm from "./CreditsForm";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const CreditsTable = (props) => {
  const [finder, setFinder] = useState({
    f_isPayed: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [data, setData] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 20]);
  const [sorting, setSorting] = useState({ sort_by: "date", sort_dir: -1 });
  const [totalCount, setTotalCount] = useState(0);
  const [dateColumns] = useState(["date"]);
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "date", width: 150 },
    { columnName: "lessonDate", width: 120 },
    { columnName: "lesson", width: 100 },
    { columnName: "isPayed", width: 160 },
  ]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => {
              setIsModelOpen(true);
              setSelectedOperation(row._id);
            }}
          />
        </td>
      );
    }

    if (column.name === "date") {
      return (
        <td>
          {new Date(row.date).toLocaleString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      );
    }

    if (column.name === "lessonDate") {
      return (
        <td>
          {new Date(row.lessonDate).toLocaleString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      );
    }

    if (column.name === "isPayed") {
      return (
        <td className={row.isPayed ? "text-success fw-bold" : "text-danger fw-bold"}>
          {row.isPayed ? "Оплачен" : "Не оплачен"}
        </td>
      );
    }

    if (column.name === "lesson") {
      return (
        <td>
          <Link to={"/admin/lessons/table/" + row.lesson}>Урок</Link>
        </td>
      );
    }

    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      (props.studentId !== undefined) &
      (props.studentId !== "") &
      needUpdate
    ) {
      props.setIsLoading(true);
      creditApi
        .getOperations({
          p: currentPage + 1,
          s: pageSize,
          f_student: props.studentId,
          ...sorting,
        })
        .then((res) => {
          setData(res.creditOperations);
          setTotalCount(res.totalDocs);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          setNeedUpdate(false);
          props.setNeedUpdate(true);
          props.setIsLoading(false);
        });
    }
  }, [needUpdate, props.studentId]);

  const changePage = (page) => {
    props.setIsLoading(true);
    creditApi
      .getOperations({
        p: page + 1,
        s: pageSize,
        f_student: props.studentId,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.creditOperations);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    creditApi
      .getOperations({
        p: 1,
        s: size,
        f_student: props.studentId,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.creditOperations);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    creditApi
      .getOperations({
        p: 1,
        s: pageSize,
        f_student: props.studentId,
        ...finder,
        f_datef,
        f_datet,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.creditOperations);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    creditApi
      .getOperations({
        p: 1,
        s: pageSize,
        f_student: props.studentId,
        ...finder,
        f_datef,
        f_datet,
        ...sorting,
      })
      .then((res) => {
        setData(res.creditOperations);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    creditApi
      .getOperations({
        p: 1,
        s: pageSize,
        f_student: props.studentId,
      })
      .then((res) => {
        setFinder({
          f_paymentt: "",
          f_type: "",
          f_sum: "",
          f_totalsum: "",
        });
        setF_datef("");
        setF_datet("");
        setData(res.creditOperations);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "date", title: "Дата" },
    { name: "lessonDate", title: "Дата проведения" },
    { name: "lesson", title: "Урок" },
    { name: "isPayed", title: "Статус оплаты" },
  ];

  return (
    <>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата c
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datef}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                    }}
                    onChange={([date]) => {
                      setF_datef(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата по
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datet}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                    }}
                    onChange={([date]) => {
                      setF_datet(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Статус оплаты
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_isPayed"
                    value={finder.f_isPayed}
                    onChange={finderHandlerChange}
                  >
                    <option value=""> </option>
                    <option value="true">Оплачено</option>
                    <option value="false">Не оплачено</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
      <CreditsForm
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        modalAdd={isModelOpen}
        setModalAdd={setIsModelOpen}
        studentId={props.studentId}
        data={selectedOperation}
        setNeedUpdate={setNeedUpdate}
        setSelectedOperation={setSelectedOperation}
      />
    </>
  );
};

export default CreditsTable;
