import React, { useEffect, useState } from "react";
import LessonsSheduler from "./LessonsSheduler";
import { api as lessonsApi } from "../../../utils/api/api.lessons";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import WeekSelector from "./WeekSelector";
import DaySelector from "./DaySelector";

export default function LessonSchule(props) {
  const [data, setData] = useState(null);
  const [viewMode, setViewMode] = useState("Day");
  const [needUpdate, setNeedUpdate] = useState(!props.scheduleState);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [timeSetups, setTimeSetups] = useState([]);

  const navigation = useNavigate();

  useEffect(() => {
    if (!props.scheduleState) return;
    setDateFrom(props.scheduleState.dateFrom);
    setDateTo(props.scheduleState.dateTo);
    setViewMode(props.scheduleState.viewMode);
    setNeedUpdate(true);
  }, [props.scheduleState]);

  useEffect(() => {
    if (needUpdate) {
      props.setIsLoading(true);
      lessonsApi
        .getLessons({
          p: 1,
          s: 100000000000000,
          f_datef: new Date(dateFrom),
          f_datet: new Date(dateTo),
          time_setups: true,
        })
        .then((res) => {
          setData({
            lessons: res.lessons.map((lesson) => {
              return {
                ...lesson,
                title: `${
                  res.dances.find((dance) => dance._id === lesson.dance).name
                }, ${
                  lesson.group !== undefined
                    ? res.groups.find((group) => group._id === lesson.group)
                        .name
                    : ""
                }`,
                startDate: new Date(lesson.date),
                endDate: new Date(lesson.endDate),
                id: lesson._id,
                roomId: lesson.room,
                typeId: lesson.typeGroup,
                teacherId: lesson.teacher,
              };
            }),
            resources: [
              {
                fieldName: "roomId",
                title: "Зал",
                instances: res.rooms[0].directory.rooms.map((room) => {
                  return {
                    text: room.value,
                    id: room._id,
                  };
                }),
              },
              {
                fieldName: "teacherId",
                title: "Педагог",
                instances: res.teachers.map((teacher) => {
                  return {
                    text: teacher.firstName + " " + teacher.secondName,
                    id: teacher._id,
                  };
                }),
              },
              {
                fieldName: "typeId",
                title: "Тип",
                instances: [
                  { text: "Группа", id: 1 },
                  { text: "Индивидуально", id: 2 },
                  { text: "Другое", id: 3 },
                ],
              },
            ],
          });
          setTimeSetups(res.timeSettings.setups);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          setNeedUpdate(false);
        });
    }
  }, [needUpdate]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Nav
          className="m-2"
          variant="pills"
          activeKey={viewMode}
          onSelect={(selectedKey) => {
            setViewMode(selectedKey);
            if (selectedKey === "Week") {
              setDateFrom(
                new Date(
                  new Date(dateFrom).setDate(
                    new Date(dateFrom).getDate() -
                      new Date(dateFrom).getDay() +
                      (new Date(dateFrom).getDay() === 0 ? -6 : 1)
                  )
                )
              );
              setDateTo(
                new Date(
                  new Date(dateFrom).setDate(
                    new Date(dateFrom).getDate() -
                      new Date(dateFrom).getDay() +
                      (new Date(dateFrom).getDay() === 0 ? -6 : 1) +
                      6
                  )
                )
              );
              setNeedUpdate(true);
            }
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="Day">День</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Week">Неделя</Nav.Link>
          </Nav.Item>
        </Nav>
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      {viewMode === "Day" ? (
        <DaySelector
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          setNeedUpdate={setNeedUpdate}
        />
      ) : viewMode === "Week" ? (
        <WeekSelector
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          setNeedUpdate={setNeedUpdate}
          dateTo={dateTo}
        />
      ) : (
        ""
      )}
      {data !== null ? (
        <LessonsSheduler
          data={data}
          viewMode={viewMode}
          dateFrom={dateFrom}
          timeSetups={timeSetups}
          setScheduleState={props.setScheduleState}
          scheduleState={{ dateFrom, dateTo, viewMode }}
        />
      ) : (
        ""
      )}
    </div>
  );
}
