import React from "react";
import MoneyTable from "./MoneyTable";
import { UserContext } from "../../../../context/UserContext";
import { Routes, Route } from "react-router-dom";
import MoneyForm from "./MoneyForm";

const Money = (props) => {
const userContext = React.useContext(UserContext);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <MoneyTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <MoneyForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Money;
