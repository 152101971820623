import React from "react";
import Toast from "react-bootstrap/Toast";

function Error(props) {
  const [message, setMessage] = React.useState({});

  React.useEffect(() => {
    if ((props.error !== null) & (props.error !== undefined)) {
      if (props.error.message !== undefined) {
        setMessage(props.error);
      } else {
        if(props.error.status === 406) {
            props.logOut()
        }
        props.error.json().then((res) => setMessage(res));
      }
    }
  }, [props.error]);

  return (
    <>
      <Toast
        show={props.isError}
        onClose={props.closeError}
        className="position-fixed"
        style={{ top: 20, left: "calc(50% - 175px)", zIndex: 1200, }}
      >
        <Toast.Header>
          <strong className="me-auto text-danger">Ошибка</strong>
        </Toast.Header>
        <Toast.Body>{message.message}</Toast.Body>
      </Toast>
    </>
  );
}

export default Error;
