import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Flatpickr from "react-flatpickr";
import { useParams, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { api as docsApi } from "../../../utils/api/api.documents";
import Table from "react-bootstrap/Table";
import SubmitModal from "./SubmitModal";

export default function DocumentsForm(props) {
  const [status, setStatus] = useState(0);
  const [students, setStudents] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [owner, setOwner] = useState(null);
  const [types, setTypes] = useState(null);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [date, setDate] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    _id: "",
    name: "",
    type: "",
    student: "",
    fields: [],
    comment: "",
  });

  const { id } = useParams();

  const navigation = useNavigate();

  useEffect(() => {
    if (needUpdate) {
      if (id === "new") {
        setStatus(1);
        props.setIsLoading(true);
        docsApi
          .getCreationData()
          .then((res) => {
            setStudents(res.students);
            setTypes(res.docsTypes[0].directory.docsTypes);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
            setNeedUpdate(false);
          });
      } else {
        setStatus(2);
        props.setIsLoading(true);
        docsApi
          .getDoc(id)
          .then((res) => {
            setOwner(res.users.find((user) => user._id === res.doc.owner));
            setData({
              _id: res.doc._id,
              name: res.doc.name,
              type: res.doc.type,
              student: res.doc.student,
              fields: res.doc.fields,
              comment: res.doc.comment,
            });
            setDate(new Date(res.doc.date));
            setStudents(res.students);
            setTypes(res.docsTypes[0].directory.docsTypes);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
            setNeedUpdate(false);
          });
      }
    }
  }, [needUpdate]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    if (e.currentTarget.checkValidity() === true) {
      e.preventDefault();
      if (status === 1) {
        props.setIsLoading(true);
        docsApi
          .postDoc({ ...data, date })
          .then((res) => {
            navigation("/admin/documents/" + res.newDoc._id);
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else if (status === 3) {
        props.setIsLoading(true);
        docsApi
          .editDoc({ ...data, date })
          .then((res) => {
            setNeedUpdate(true);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    } else {
      e.preventDefault();
    }
  };

  const deleteHandler = (e) => {
    props.setIsLoading(true);
    docsApi
      .deleteDoc({ docId: data._id })
      .then((res) => {
        navigation("/admin/documents");
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };
  return (
    <>
      <Form onSubmit={onSubmit} validated={validated}>
        <Card>
          <Card.Body>
            <Card.Title>
              <Row className="g-2" style={{ maxWidth: 630 }}>
                <Col sm>
                  <Form.Group className="mb-3">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                      required
                      value={data.name}
                      type="text"
                      name="name"
                      onChange={handleChange}
                      disabled={status === 2 ? true : false}
                      plaintext={status === 2 ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <i
                className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                  status === 2 ? "d-flex" : "d-none"
                }`}
                style={{ fontSize: 36, cursor: "pointer" }}
                onClick={() => setStatus(3)}
              ></i>
            </Card.Title>
            <Card.Text>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    required
                    className={`form-control ${
                      status === 2 && "form-control-plaintext"
                    }`}
                    style={{ backgroundColor: "transparent" }}
                    value={date}
                    options={{
                      locale: Russian,
                      disableMobile: true,
                      dateFormat: "d.m.Y",
                    }}
                    name="date"
                    onChange={([date]) => {
                      setDate(date);
                    }}
                    disabled={status === 2 ? true : false}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Ученик
                </Form.Label>
                <Col sm={10}>
                  <Select
                    required
                    placeholder=""
                    noOptionsMessage={() => "Нет учеников"}
                    options={
                      students === null
                        ? []
                        : students.map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                    }
                    value={
                      data.student !== ""
                        ? {
                            value: students.find(
                              (el) => el._id === data.student
                            )._id,
                            label:
                              students.find((el) => el._id === data.student)
                                .firstName +
                              " " +
                              students.find((el) => el._id === data.student)
                                .secondName,
                          }
                        : {}
                    }
                    isDisabled={status === 2 ? true : false}
                    type="text"
                    name="student"
                    onChange={(info) =>
                      setData({
                        ...data,
                        student: info.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет типов документов"}
                    isDisabled={status === 2 ? true : false}
                    options={
                      types === null
                        ? []
                        : types.map((el) => {
                            return {
                              value: el._id,
                              label: el.name,
                            };
                          })
                    }
                    value={
                      (data.type !== "") &
                      (data.type !== null) &
                      (data.type !== undefined)
                        ? {
                            value: types.find((el) => el._id === data.type)._id,
                            label: types.find((el) => el._id === data.type)
                              .name,
                          }
                        : {}
                    }
                    type="text"
                    name="type"
                    onChange={(info) => {
                      setData({
                        ...data,
                        type: info.value,
                        fields: types
                          .find((el) => el._id === info.value)
                          .fileds.map((element) => {
                            return {
                              name: element.name,
                              value: "",
                            };
                          }),
                      });
                    }}
                  />
                </Col>
              </Form.Group>
              <Table className="mt-5">
                <tbody>
                  {data.fields.map((el, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            value={data.fields[index].name}
                            type="text"
                            name={`fields[${index}].name`}
                            onChange={(e) =>
                              setData({
                                ...data,
                                fields: data.fields.map((el, i) =>
                                  i === index
                                    ? { ...el, name: e.target.value }
                                    : el
                                ),
                              })
                            }
                            disabled={status === 2 ? true : false}
                            plaintext={status === 2 ? true : false}
                          />
                        </td>
                        <td>
                          <Form.Control
                            value={data.fields[index].value}
                            type="text"
                            name={`fields[${index}].value`}
                            onChange={(e) =>
                              setData({
                                ...data,
                                fields: data.fields.map((el, i) =>
                                  i === index
                                    ? { ...el, value: e.target.value }
                                    : el
                                ),
                              })
                            }
                            disabled={status === 2 ? true : false}
                          />
                        </td>
                        <td>
                          <i
                            className={`bi bi-x m-1 ${
                              status === 2 ? "d-none" : "d-flex"
                            }`}
                            style={{ fontSize: 24, cursor: "pointer" }}
                            onClick={() => {
                              setData({
                                ...data,
                                fields: data.fields.filter(
                                  (el, i) => i !== index
                                ),
                              });
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {status !== 2 && (
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    setData({
                      ...data,
                      fields: [...data.fields, { name: "", value: "" }],
                    });
                  }}
                  disabled={status === 2 ? true : false}
                >
                  Добавить поле
                </Button>
              )}
              <Form.Group as={Row} className="mb-3 mt-5">
                <Form.Label column sm={2}>
                  Комментарий
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="comment"
                    onChange={handleChange}
                    value={data.comment}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Col>
              </Form.Group>
            </Card.Text>
            <div className="d-flex justify-content-end">
              <Button
                variant="danger"
                type="button"
                onClick={() => setSubmitDelete(true)}
                className={`me-3 ${status === 3 ? "d-block" : "d-none"}`}
              >
                Удалить
              </Button>
              <Button
                variant="primary"
                type="submit"
                className={`${status === 2 ? "d-none" : "d-block"}`}
              >
                Сохранить
              </Button>
            </div>
          </Card.Body>
          <Form.Group className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}>
            <Form.Label>Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              disabled
              plaintext
            />
          </Form.Group>
        </Card>
      </Form>
      <SubmitModal
        isOpen={submitDelete}
        close={setSubmitDelete}
        text="Удалить документ?"
        action={deleteHandler}
      />
    </>
  );
}
