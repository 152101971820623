import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Flatpickr from "react-flatpickr";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { api as accountsApi } from "../../../../../utils/api/api.accounts";

export default function FreezeModal(props) {
  const [date, setDate] = useState(new Date());
  const [price, setPrice] = useState(0);

  const closeModal = () => {
    props.close(false);
  };
  const submit = () => {
    props.submit(date);
  };

  useEffect(() => {
    if (props.data !== undefined & props.data !== null) {
      accountsApi
        .getCreationData()
        .then((res) => {
          setPrice(
            res.abonements.find((el) => el._id === props.data.abonement).price
          );
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  }, [props.data]);

  return (
    <Modal show={props.isOpen} onHide={closeModal}>
      {(props.data !== undefined) & (props.data !== null) ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Пополнить счет</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата c
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={date}
                    options={{
                      minDate:
                        props.data.period.length === 0
                          ? undefined
                          : new Date(
                              new Date(
                                props.data.period[
                                  props.data.period.length - 1
                                ].startDate
                              ).setDate(
                                new Date(
                                  props.data.period[
                                    props.data.period.length - 1
                                  ].startDate
                                ).getDate() +
                                  props.data.period[
                                    props.data.period.length - 1
                                  ].amountDays
                              )
                            ),
                      time_24hr: true,
                      locale: Russian,
                      disableMobile: true,
                      dateFormat: "d.m.Y",
                    }}
                    name="birthday"
                    onChange={([date]) => {
                      setDate(date);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Text muted>Со счета будет списано {price}</Form.Text>
            </Form>
          </Modal.Body>
        </>
      ) : (
        ""
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Закрыть
        </Button>
        <Button variant="success" onClick={submit}>
          Пополнить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
