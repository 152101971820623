import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { api as docsApi } from "../../../utils/api/api.documents";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Flatpickr from "react-flatpickr";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const DocumentsTable = (props) => {
  const [finder, setFinder] = useState({
    f_name: "",
    f_student: "",
    f_type: "",
  });
  const [data, setData] = useState([]);
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [students, setStudents] = useState([]);
  const [types, setTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState({});
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "date", width: 150 },
    { columnName: "name", width: 150 },
    { columnName: "student", width: 200 },
    { columnName: "type", width: 150 },
  ]);
  const navigation = useNavigate();

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => navigation(row._id)}
          />
        </td>
      );
    }

    if (column.name === "date") {
      return (
        <td>
          {new Date(row.date).toLocaleString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      );
    }

    if (column.name === "student") {
      return (
        <td>
          <p>
            {students.find((el) => el._id === row.student).firstName +
              " " +
              students.find((el) => el._id === row.student).secondName}
          </p>
        </td>
      );
    }

    if (column.name === "type") {
      return (
        <td>
          <p>{types !== null & row.type !== undefined ? types.find((el) => el._id === row.type).name : ""}</p>
        </td>
      );
    }

    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    docsApi
      .getDocs({
        p: currentPage + 1,
        s: pageSize,
        f_datef,
        f_datet,
        ...sorting,
      })
      .then((res) => {
        setData(res.docs);
        setTotalCount(res.totalDocs);
        setStudents(res.students);
        setTypes(res.docsTypes[0].directory.docsTypes);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const changePage = (page) => {
    props.setIsLoading(true);
    docsApi
      .getDocs({
        p: page + 1,
        s: pageSize,
        f_datef,
        f_datet,
        ...sorting,
        ...finder,
      })
      .then((res) => {
        setData(res.docs);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    docsApi
      .getDocs({ p: 1, s: size, ...sorting, f_datef, f_datet, ...finder })
      .then((res) => {
        setData(res.docs);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    docsApi
      .getDocs({
        p: 1,
        s: pageSize,
        f_datef,
        f_datet,
        ...finder,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.docs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    docsApi
      .getDocs({
        p: 1,
        s: pageSize,
        f_datef,
        f_datet,
        ...finder,
        ...sorting,
      })
      .then((res) => {
        setData(res.docs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    docsApi
      .getDocs({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_name: "",
          f_student: "",
          f_type: "",
        });
        setF_datef(null);
        setF_datet(null);
        setData(res.docs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "date", title: "Дата" },
    { name: "name", title: "Название" },
    { name: "student", title: "Ученик" },
    { name: "type", title: "Тип" },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата c
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datef}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      disableMobile: true,
                      dateFormat: "d.m.Y",
                    }}
                    onChange={([date]) => {
                      setF_datef(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата по
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datet}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      disableMobile: true,
                      dateFormat: "d.m.Y",
                    }}
                    onChange={([date]) => {
                      setF_datet(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Название
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    value={finder.f_name}
                    type="text"
                    name="f_name"
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Ученик
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет учеников"}
                    options={
                      students === null
                        ? []
                        : students.map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                    }
                    value={
                      finder.f_student !== ""
                        ? {
                            value: students.find((el) => el._id === finder.f_student)._id,
                            label:
                              students.find((el) => el._id === finder.f_student).firstName +
                              " " +
                              students.find((el) => el._id === finder.f_student).secondName,
                          }
                        : {}
                    }
                    type="text"
                    name="f_student"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_student: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип
                </Form.Label>
                <Col sm={10}>
                <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет типов документов"}
                    options={
                      types === null
                        ? []
                        : types.map((el) => {
                            return {
                              value: el._id,
                              label: el.name,
                            };
                          })
                    }
                    value={
                      finder.f_type !== ""
                        ? {
                            value: types.find((el) => el._id === finder.f_type)._id,
                            label: types.find((el) => el._id === finder.f_type).name,
                          }
                        : {}
                    }
                    type="text"
                    name="f_type"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_type: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
    </>
  );
};

export default DocumentsTable;
