import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { api as salariesApi } from "../../../../utils/api/api.salaries";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

export default function SalariesForm(props) {
  const [status, setStatus] = useState(0); // 1 - просмотр 2 - добавление
  const [balance, setBalance] = useState(0);
  const [payments, setPayments] = useState(null);
  const [data, setData] = useState({
    _id: "",
    lesson: "",
    comment: "",
    owner: "",
    paymentType: "",
    teacher: "",
    sum: "",
    type: props.modalType,
  });
  const [date, setDate] = useState("");
  const [owner, setOwner] = useState(null);

  const handleChange = (e) => {
    if (
      (e.target.name === "sum") &
      (e.target.value > balance) &
      (props.modalType === 2)
    ) {
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    props.setSelectedOperation(null);
    props.setModal(false);
    setData({
      _id: "",
      lesson: "",
      comment: "",
      owner: "",
      paymentType: "",
      teacher: "",
      sum: 0,
      type: 0,
    });
  };

  useEffect(() => {
    if (props.data !== null) {
      setStatus(1);
      props.setIsLoading(true);
      salariesApi
        .getOperation(props.data)
        .then((res) => {
          setData(res.operationInfo);
          setDate(res.operationInfo.date);
          setPayments(res.paymentTypes[0].directory.paymentTypes);
          setOwner(
            res.users.find((user) => user._id === res.operationInfo.owner)
          );
          setBalance(
            res.teachers.find((el) => el._id === props.teacherId).moneyBalance
          );
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      if ((props.teacherId !== undefined) & (props.teacherId !== "")) {
        setStatus(2);
        setDate(new Date());
        salariesApi
          .getCreationData(props.teacherId)
          .then((res) => {
            setPayments(res.paymentTypes[0].directory.paymentTypes);
            setData({
              ...data,
              teacher: props.teacherId,
              type: props.modalType,
            });
            setBalance(
              res.teachers.find((data) => data._id === props.teacherId)
                .moneyBalance
            );
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    }
  }, [props.isOpen, props.teacherId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.modalType === 2) {
      props.setIsLoading(true);
      salariesApi
        .createMinuscOperation({ ...data, date: date })
        .then((res) => handleClose())
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          props.setNeedUpdate(true);
        });
    } else if (props.modalType === 1) {
      props.setIsLoading(true);
      salariesApi
        .createPlusOperation({ ...data, date: date })
        .then((res) => handleClose())
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          props.setNeedUpdate(true);
        });
    }
  };

  const createLinks = (text) => {
    if (!text) return <></>
    return (
      <>
        {text.replace(/урок\s\S{24}/, '')}{text.match(/урок\s\S{24}/) ? <Link to={"/admin/lessons/table/" + data.lesson}>урок</Link> : ''}
      </>
    );
  };

  return (
    <Modal show={props.isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          className={
            props.modalType === 1
              ? "text-success fw-bold"
              : props.modalType === 2
              ? "text-danger fw-bold"
              : "fw-bold"
          }
        >
          {props.modalType === 1
            ? "Начисление"
            : props.modalType === 2
            ? "Списание"
            : "Операция"}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Дата</Form.Label>
            <Flatpickr
              data-enable-time
              className={`form-control ${
                status === 1 && "form-control-plaintext"
              }`}
              style={{ backgroundColor: "transparent" }}
              value={date}
              options={{
                time_24hr: true,
                locale: Russian,
                dateFormat: "d.m.Y H:i",
              }}
              name="date"
              onChange={([date]) => {
                setDate(date);
              }}
              disabled={status === 1 ? true : false}
            />
          </Form.Group>
          <InputGroup className="mb-3" size="lg">
            <InputGroup.Text>
              {props.modalType === 1 ? "+" : props.modalType === 2 ? "-" : ""}
            </InputGroup.Text>
            <Form.Control
              name="sum"
              value={data.sum}
              className={
                props.modalType === 1
                  ? "text-success fw-bold"
                  : props.modalType === 2
                  ? "text-danger fw-bold"
                  : "fw-bold"
              }
              disabled={status === 1 ? true : false}
              onChange={handleChange}
            />
            <InputGroup.Text>₽</InputGroup.Text>
          </InputGroup>
          <Form.Text muted className={status === 1 ? "d-none" : "d-block"}>
            Баланс преподавателя: {balance}
          </Form.Text>
          <Form.Group>
            <Form.Select
              name="paymentType"
              value={data.paymentType}
              onChange={handleChange}
              disabled={status === 1 ? true : false}
            >
              <option value="">Выберите тип оплаты</option>
              {payments !== null &&
                payments.map((paymentType) => (
                  <option key={paymentType._id} value={paymentType._id}>
                    {paymentType.value}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Accordion className="mt-2 mb-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Комментарий</Accordion.Header>
              <Accordion.Body>
                {status === 1 ? (
                  <div>
                    {createLinks(data.comment)}
                  </div>
                ) : (
                  <Form.Control
                    name="comment"
                    value={data.comment}
                    onChange={handleChange}
                    as="textarea"
                    disabled={status === 1 ? true : false}
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group>
            <InputGroup size="lg">
              <InputGroup.Text>
                Будет{" "}
                {props.modalType === 1
                  ? "начислено"
                  : props.modalType === 2
                  ? "выплачено"
                  : "сумма"}{" "}
                :
              </InputGroup.Text>
              <Form.Control
                name="totalSum"
                value={data.sum}
                className={
                  props.modalType === 1
                    ? "text-success fw-bold"
                    : props.modalType === 2
                    ? "text-danger fw-bold"
                    : "fw-bold"
                }
                disabled={true}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className={status === 2 ? "d-none" : "d-block"}>
            <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              className="mt-1 p-0"
              disabled
              plaintext
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant={
              props.modalType === 1
                ? "success"
                : props.modalType === 2
                ? "danger"
                : "secondary"
            }
            className={
              status === 1 || props.modalType === 0 ? "d-none" : "d-block"
            }
            type="submit"
          >
            {props.modalType === 1
              ? "Начислить"
              : props.modalType === 2
              ? "Списать"
              : "Сохранить"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
