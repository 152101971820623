import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Flatpickr from "react-flatpickr";



const CompliterModal = (props) => {

    const [weekdays, setWeekdays] = useState([]);
    const [dateTo, setDateTo] = useState(null);
    const WeekDays = [
        { value: 1, label: "Понедельник" },
        { value: 2, label: "Вторник" },
        { value: 3, label: "Среда" },
        { value: 4, label: "Четверг" },
        { value: 5, label: "Пятница" },
        { value: 6, label: "Суббота" },
        { value: 0, label: "Воскресенье" },
      ]
  //body {dateTo, dateFrom, time{hours: HH, minutes: MM}, weekdays, groupId}
    const onSubmit = (e) => {
        e.preventDefault();
        props.submitHandler({
            weekdays: weekdays,
            timeStart: props.timeStart,
            timeEnd: props.timeEnd,
            dateTo: dateTo,
        })
    }

    return (
        <Modal show={props.isModalOpen} onHide={() => props.setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Заполнить расписание</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                C
              </Form.Label>
              <Col sm={9}>
                <Flatpickr
                  className="form-control form-control-plaintext"
                  style={{ backgroundColor: "transparent" }}
                  value={props.data.dateTo === null ? props.dateFrom : new Date(props.data.dateTo).setDate(new Date(props.data.dateTo).getDate() + 1)}
                  options={{
                    locale: Russian,
                    disableMobile: true,
                    dateFormat: "d.m.Y",
                  }}
                  name="dateFrom"
                  disabled={true}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                По
              </Form.Label>
              <Col sm={9}>
                <Flatpickr
                  className="form-control"
                  style={{ backgroundColor: "transparent" }}
                  value={dateTo}
                  options={{
                    minDate: props.data.dateTo === null ? props.dateFrom : new Date(props.data.dateTo).setDate(new Date(props.data.dateTo).getDate() + 1),
                    locale: Russian,
                    disableMobile: true,
                    dateFormat: "d.m.Y",
                  }}
                  name="endDate"
                  onChange={([date]) => setDateTo(date)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Время начала
              </Form.Label>
              <Col sm={9}>
                <Flatpickr
                  data-enable-time
                  className="form-control"
                  style={{ backgroundColor: "transparent" }}
                  value={props.timeStart}
                  options={{
                    time_24hr: true,
                    locale: Russian,
                    enableTime: true,
                    noCalendar: true,
                    disableMobile: true,
                  }}
                  name="date"
                  onChange={([date]) => {
                    props.setTimeStart(date);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Время окончания
              </Form.Label>
              <Col sm={9}>
                <Flatpickr
                  data-enable-time
                  className="form-control"
                  style={{ backgroundColor: "transparent" }}
                  value={props.timeEnd}
                  options={{
                    time_24hr: true,
                    locale: Russian,
                    enableTime: true,
                    noCalendar: true,
                    disableMobile: true,
                  }}
                  name="date"
                  onChange={([date]) => {
                    props.setTimeEnd(date);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Дни недели
              </Form.Label>
              <Col sm={9}>
                <Select
                  placeholder=""
                  noOptionsMessage={() => "Нет дней. Хз как, но нет."}
                  options={WeekDays}
                  value={
                    weekdays.map((id) => {
                          return {
                            value: WeekDays.find((el) => el.value === id).value,
                            label: WeekDays.find((el) => el.value === id).label,
                          };
                        })
                  }
                  type="text"
                  name="dances"
                  isMulti={true}
                  onChange={(data) =>
                    setWeekdays([...data.map((item) => item.value)])
                  }
                />
              </Col>
            </Form.Group>
            </Form>   
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setIsModalOpen(false)}>
            Закрыть
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Заполнить
          </Button>
        </Modal.Footer>
      </Modal>
    );
}

export default CompliterModal;
