import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export function Redirect(props) {
  const navigation = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!location.pathname.includes(props.to)) {
    navigation(props.to);
    }
  });

  return <></>;
}
