import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { api as groupsApi } from "../../../utils/api/api.groups";
import { api as teachersApi } from "../../../utils/api/api.teachers";
import { UserContext } from "../../../context/UserContext";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import StudentModal from "./StudentModal";
import Card from "react-bootstrap/Card";
import CompliterModal from "./CompliterModal";
import SubmitModal from "./SubmitModal";
import LessonsTable from "./LessonTable";

const GroupForm = (props) => {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - создать, 2 - просмотр,  3 - изменить
  const [dances, setDances] = useState(null);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [groupsWithUncomplitedLessons, setGroupsWithUncomplitedLessons] =
    useState(null);
  const [timeStart, setTimeStart] = useState(new Date());
  const [timeEnd, setTimeEnd] = useState(new Date());
  const [teachers, setTeachers] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [students, setStudents] = useState(null);
  const [submitDeleter, setSubmitDeleter] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [owner, setOwner] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [modalStudent, setModalStudent] = useState(false);
  const [modalCompliter, setModalCompliter] = useState(false);
  const [form, setForm] = useState({
    _id: "",
    name: "",
    dateTo: "",
    dance: "",
    warning: "",
    recommendedTeacher: "",
    recommendedSalary: 0,
    recommendedRoom: "",
    isMethodic: false,
    students: [],
    isActive: false,
  });

  const navigation = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (needUpdate) {
      if (id !== undefined) {
        if (id === "new") {
          setStatus(1);
          props.setIsLoading(true);
          groupsApi
            .getCreationData()
            .then((res) => {
              setForm({
                _id: "",
                name: "",
                dance: "",
                dateTo: "",
                recommendedTeacher: "",
                recommendedSalary: 0,
                recommendedRoom: "",
                isMethodic: false,
                students: [],
                isActive: false,
                warning: "",
              });
              setDateFrom(new Date());
              setTimeStart(null);
              setTimeEnd(null);
              setDances(res.dances);
              setTeachers(res.teachers);
              setRooms(res.rooms[0].directory.rooms);
              setStudents(res.students);
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            })
            .finally(() => props.setIsLoading(false));
        } else {
          setStatus(2);
          props.setIsLoading(true);
          groupsApi
            .getGroup(id)
            .then((res) => {
              const { groupInfo } = res;
              setForm({
                _id: groupInfo._id,
                name: groupInfo.name,
                dance: groupInfo.dance,
                dateTo: groupInfo.dateTo,
                recommendedTeacher: groupInfo.recommendedTeacher,
                recommendedSalary: groupInfo.recommendedSalary,
                recommendedRoom: groupInfo.recommendedRoom,
                isMethodic: groupInfo.isMethodic,
                students: groupInfo.students,
                isActive: groupInfo.isActive,
                warning: groupInfo.warning,
              });
              setDateFrom(new Date());
              setTimeStart(groupInfo.timeStart);
              setTimeEnd(groupInfo.timeEnd);
              setOwner(res.users.find((user) => user._id === groupInfo.owner));
              setDances(res.dances);
              setTeachers(res.teachers);
              setRooms(res.rooms[0].directory.rooms);
              setStudents(res.studentsInfo);
              setGroupsWithUncomplitedLessons(res.groupsWithUncomplitedLessons);
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            })
            .finally(() => {
              props.setIsLoading(false);
            });
        }
      }
    }
  }, [needUpdate]);

  const studentSubmitHandler = (data) => {
    if (status === 1) {
      if (selectedStudent === null) {
        setForm({ ...form, students: [...form.students, data] });
        setModalStudent(false);
      } else if (selectedStudent !== null) {
        setForm({
          ...form,
          students: form.students.map((item) => {
            if (item.student === selectedStudent.student) {
              return data;
            } else {
              return item;
            }
          }),
        });
        setModalStudent(false);
      }
    } else if ((status === 3) & (form.dateTo === null)) {
      if (selectedStudent === null) {
        props.setIsLoading(true);
        groupsApi
          .postStudent({ group: form._id, student: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalStudent(false);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      } else if (selectedStudent !== null) {
        props.setIsLoading(true);
        groupsApi
          .editStudent({ group: form._id, student: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalStudent(false);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      }
    } else if ((status === 3) & (form.dateTo !== null)) {
      if (selectedStudent !== null) {
        props.setIsLoading(true);
        groupsApi
          .adderStudent({ group: form._id, student: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalStudent(false);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      }
    }
  };

  const studentDeleteHandler = (data) => {
    if (status === 1) {
      setForm({
        ...form,
        students: form.students.filter((item) => item.student !== data.student),
      });
    } else if ((status === 3) & (form.dateTo === null)) {
      props.setIsLoading(true);
      groupsApi
        .deleteStudent({ group: form._id, student: data.student })
        .then((res) => {
          setNeedUpdate(true);
          setModalStudent(false);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    } else if ((status === 3) & (form.dateTo !== null)) {
      props.setIsLoading(true);
      groupsApi
        .deleterStudent({ group: form._id, student: data.student })
        .then((res) => {
          setNeedUpdate(true);
          setModalStudent(false);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (status === 1) {
      props.setIsLoading(true);
      groupsApi
        .postGroup({ ...form, dateFrom, timeStart, timeEnd })
        .then((res) => {
          navigation(res.newGroup._id);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    } else {
      props.setIsLoading(true);
      groupsApi
        .editGroup({ ...form, dateFrom, timeStart, timeEnd })
        .then((res) => {
          setNeedUpdate(true);
          setModalStudent(false);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  };

  const deleteHandler = () => {
    props.setIsLoading(true);
    groupsApi
      .deleteGroup({ groupId: form._id })
      .then((res) => {
        navigation("/admin/groups/");
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const compliteGroup = (data) => {
    props.setIsLoading(true);
    groupsApi
      .compliteGroup({
        groupId: form._id,
        dateFrom:
          form.dateTo === null
            ? dateFrom
            : new Date(form.dateTo).setDate(
                new Date(form.dateTo).getDate() + 1
              ),
        ...data,
      })
      .then((res) => {
        setModalCompliter(false);
        setNeedUpdate(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const deleterGroup = () => {
    props.setIsLoading(true);
    groupsApi
      .deleterGroup({ groupId: form._id })
      .then((res) => {
        setSubmitDeleter(false);
        setNeedUpdate(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  return (
    <Form onSubmit={submitHandler}>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row className="g-2" style={{ maxWidth: 630 }}>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Название{" "}
                    {(form.warning !== null) & (form.warning !== "") & (form.warning !== 0) ? (
                      <Badge className="m-1" bg="warning">
                        {form.warning === 1 ? 'Не заполнено расписание' : form.warning === 2 ? 'В расписании неактуальные данные' : form.warning}
                      </Badge>
                    ) : (
                      ""
                    )}
                  </Form.Label>
                  <Form.Control
                    value={form.name}
                    type="text"
                    name="name"
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                status === 2 ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Дата начала
              </Form.Label>
              <Col sm={9}>
                <Flatpickr
                  className={`form-control ${
                    (status === 2 || form.dateTo !== null) && "form-control-plaintext"
                  }`}
                  style={{ backgroundColor: "transparent" }}
                  value={dateFrom}
                  options={{
                    locale: Russian,
                    disableMobile: true,
                    dateFormat: "d.m.Y",
                  }}
                  name="date"
                  onChange={([date]) => {
                    setDateFrom(date);
                  }}
                  disabled={status === 2 || form.dateTo !== null ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Заполнено по
              </Form.Label>
              <Col sm={9}>
                <Flatpickr
                  className="form-control form-control-plaintext"
                  style={{ backgroundColor: "transparent" }}
                  value={form.dateTo}
                  options={{
                    time_24hr: true,
                    locale: Russian,
                    disableMobile: true,
                    dateFormat: "d.m.Y",
                  }}
                  name="dateTo"
                  disabled={true}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Направление
              </Form.Label>
              <Col sm={9}>
                <Select
                  placeholder=""
                  noOptionsMessage={() => "Нет доступных направлений"}
                  options={
                    dances === null
                      ? {}
                      : dances.map((el) => {
                          return {
                            value: el._id,
                            label: el.name,
                          };
                        })
                  }
                  value={
                    (form.dance !== undefined) &
                    (dances !== null) &
                    (form.dance !== "")
                      ? {
                          value: dances.find((el) => el._id === form.dance)._id,
                          label: dances.find((el) => el._id === form.dance)
                            .name,
                        }
                      : ""
                  }
                  type="text"
                  name="dance"
                  onChange={(data) =>
                    setForm({
                      ...form,
                      dance: data.value,
                    })
                  }
                  isDisabled={status === 2 || (form.dateTo !== null & form.dateTo !=="") ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Педагог
              </Form.Label>
              <Col sm={9}>
                <Select
                  placeholder=""
                  noOptionsMessage={() => "Нет доступных педагогов"}
                  options={
                    teachers === null
                      ? {}
                      : teachers.map((el) => {
                          return {
                            value: el._id,
                            label: el.firstName + " " + el.secondName,
                          };
                        })
                  }
                  value={
                    (form.recommendedTeacher !== undefined) &
                    (teachers !== null) &
                    (form.recommendedTeacher !== "")
                      ? {
                          value: teachers.find(
                            (el) => el._id === form.recommendedTeacher
                          )._id,
                          label:
                            teachers.find(
                              (el) => el._id === form.recommendedTeacher
                            ).firstName +
                            " " +
                            teachers.find(
                              (el) => el._id === form.recommendedTeacher
                            ).secondName,
                        }
                      : ""
                  }
                  type="text"
                  name="teacher"
                  onChange={(data) => {
                    teachersApi
                      .recommendedSalary(data.value)
                      .then((res) => {
                        setForm({
                          ...form,
                          recommendedTeacher: data.value,
                          recommendedSalary:
                            res[
                              dances.find((el) => el._id === form.dance)
                                .typeGroup === 1
                                ? "group"
                                : dances.find((el) => el._id === form.dance)
                                    .typeGroup === 2
                                ? "individual"
                                : "other"
                            ],
                        });
                      })
                      .catch((rej) => {
                        props.setIsError(true);
                        props.setError(rej);
                      })
                      .finally(() => props.setIsLoading(false));
                  }}
                  isDisabled={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className={`mb-3 ${
                userContext.access.salaries ? "d-flex" : "d-none"
              }`}
            >
              <Form.Label column sm={3}>
                Зарплата за урок
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="salary"
                  value={form.recommendedSalary}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      salary: e.target.value,
                    })
                  }
                  disabled={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Зал
              </Form.Label>
              <Col sm={9}>
                <Select
                  placeholder=""
                  noOptionsMessage={() => "Нет доступных залов"}
                  required
                  options={
                    rooms === null
                      ? {}
                      : rooms.map((el) => {
                          return {
                            value: el._id,
                            label: el.value,
                          };
                        })
                  }
                  value={
                    (form.recommendedRoom !== undefined) &
                    (rooms !== null) &
                    (form.recommendedRoom !== "")
                      ? {
                          value: rooms.find(
                            (el) => el._id === form.recommendedRoom
                          )._id,
                          label: rooms.find(
                            (el) => el._id === form.recommendedRoom
                          ).value,
                        }
                      : ""
                  }
                  type="text"
                  name="recommendedRoom"
                  onChange={(data) =>
                    setForm({
                      ...form,
                      recommendedRoom: data.value,
                    })
                  }
                  isDisabled={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Использовать методичку
              </Form.Label>
              <Col sm={9} className="align-items-center d-flex">
                <Form.Check
                  disabled={status === 2 ? true : false}
                  type="switch"
                  name="isMethodic"
                  onChange={(e) =>
                    setForm({ ...form, isMethodic: !form.isMethodic })
                  }
                  checked={form.isMethodic}
                />
              </Col>
            </Form.Group>
            <Accordion defaultActiveKey={["0"]} alwaysOpen className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Ученики</Accordion.Header>
                <Accordion.Body>
                  <Table striped>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Имя</th>
                        <th>Фамилия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(students !== null) & (form.students !== [])
                        ? form.students.map((student) => {
                            return (
                              <tr key={student.student}>
                                <td>
                                  <i
                                    className="bi bi-eye-fill"
                                    style={{ cursor: "pointer", fontSize: 24 }}
                                    onClick={() => {
                                      setSelectedStudent(student);
                                      setModalStudent(true);
                                    }}
                                  />
                                </td>
                                <td>
                                  {
                                    students.find(
                                      (el) => el._id === student.student
                                    ).firstName
                                  }
                                </td>
                                <td>
                                  {
                                    students.find(
                                      (el) => el._id === student.student
                                    ).secondName
                                  }
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        setSelectedStudent(null);
                        setModalStudent(true);
                      }}
                      className={`m-2 ${status === 2 ? "d-none" : "d-block"}`}
                    >
                      Добавить ученика
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Занятия</Accordion.Header>
                <Accordion.Body>
                  <LessonsTable
                    group={form._id}
                    setIsLoading={props.setIsLoading}
                    setIsError={props.setIsError}
                    setError={props.setError}
                    needUpdate={needUpdate}
                    setNeedUpdate={setNeedUpdate}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Form.Group className={status === 2 ? "d-block" : "d-none"}>
              <Form.Label className="mt-3 mb-0">Создал пользователь</Form.Label>
              <Form.Control
                value={
                  owner !== null ? owner.firstName + " " + owner.secondName : ""
                }
                type="text"
                name="owner"
                className="mt-1 p-0"
                disabled
                plaintext
              />
            </Form.Group>
          </Card.Text>
        </Card.Body>
        <div className="d-flex justify-content-end">
          {(status === 3) & (form.dateTo === null) ? (
            <Button
              variant="danger"
              onClick={() => setSubmitDelete(true)}
              className="m-2"
            >
              Удалить
            </Button>
          ) : (
            ""
          )}
          {(status === 2) &
          (form.dateTo !== null) &
          (groupsWithUncomplitedLessons !== 0) ? (
            <Button
              variant="danger"
              onClick={() => setSubmitDeleter(true)}
              className="m-2"
            >
              Удалить непроведенные уроки
            </Button>
          ) : (
            ""
          )}
          {status === 2 ? (
            <Button
              variant="success"
              onClick={() => setModalCompliter(true)}
              className="m-2"
            >
              Заполнить
            </Button>
          ) : (
            ""
          )}
          {status !== 2 ? (
            <Button variant="primary" type="submit" className="m-2">
              Сохранить
            </Button>
          ) : (
            ""
          )}
        </div>
        <StudentModal
          isModalOpen={modalStudent}
          setIsModalOpen={setModalStudent}
          students={students}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          status={status}
          submitHandler={studentSubmitHandler}
          deleteHandler={studentDeleteHandler}
          data={form}
          setIsLoading={props.setIsLoading}
          setIsError={props.setIsError}
          setError={props.setError}
          needUpdate={needUpdate}
          setNeedUpdate={setNeedUpdate}
        />
        <CompliterModal
          isModalOpen={modalCompliter}
          setIsModalOpen={setModalCompliter}
          submitHandler={compliteGroup}
          data={form}
          setIsLoading={props.setIsLoading}
          setIsError={props.setIsError}
          setError={props.setError}
          needUpdate={needUpdate}
          setNeedUpdate={setNeedUpdate}
          setData={setForm}
          timeEnd={timeEnd}
          setTimeEnd={setTimeEnd}
          timeStart={timeStart}
          setTimeStart={setTimeStart}
          dateFrom={dateFrom}
        />
        <SubmitModal
          isOpen={submitDeleter}
          close={setSubmitDeleter}
          text="Удалить непроведенные уроки?"
          action={deleterGroup}
        />
        <SubmitModal
          isOpen={submitDelete}
          close={setSubmitDelete}
          text="Удалить группу?"
          action={deleteHandler}
        />
      </Card>
    </Form>
  );
};

export default GroupForm;
