import React, { useState, useEffect } from "react";
import { api as tasksApi } from "../../../utils/api/api.tasks";
import { Card, ListGroup, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function Tasks(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [tasksPriorities, setTasksPriorities] = useState(null);

  const navigator = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    tasksApi
      .getTasks({ mode_dash: 1 })
      .then((res) => {
        setTasksPriorities(res.directory.directory.taskPriorities);
        setData(res.tasks);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Card>
      <h5>Требуют реакции задачи: </h5>
      {data ? (
        data.length !== 0 ? (
          <ListGroup>
            {data
              .filter((el, ind) => ind < 4)
              .map((el) => (
                <ListGroup.Item
                  style={{
                    color: tasksPriorities.find((le) => le._id === el.priority)
                      .color,
                  }}
                >
                  {el.title}
                  <i
                    className="bi bi-eye-fill ms-2"
                    style={{ cursor: "pointer", fontSize: 16 }}
                    onClick={() => navigator("/admin/tasks/table/" + el._id)}
                  />
                </ListGroup.Item>
              ))}
            {data.length > 4 ? (
              <ListGroup.Item>
                <Link to="/admin/tasks/table/">Еще...</Link>
              </ListGroup.Item>
            ) : (
              ""
            )}
          </ListGroup>
        ) : (
          <p>Нет задач.</p>
        )
      ) : (
        ""
      )}
      <div
        className={`position-absolute align-items-center justify-content-center bg-light bg-opacity-50 flex-column ${
          isLoading ? "d-flex" : "d-none"
        }`}
        style={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 1100 }}
      >
        <Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />
        <p className="fw-light fs-2 text-center mt-4">Загрузка...</p>
      </div>
    </Card>
  );
}
