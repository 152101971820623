import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate, Link } from "react-router-dom";
import { api as tasksApi } from "../../../utils/api/api.tasks";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "flatpickr/dist/themes/light.css";
import Select from "react-select";
import Fade from "react-reveal/Fade";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

export default function TasksForm(props) {
  const userContext = React.useContext(UserContext);

  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [validated, setValidated] = useState(false);
  const [task, setTask] = useState({
    _id: "",
    title: "",
    lead: "",
    student: "",
    creator: "",
    responsible: "",
    status: "",
    priority: "",
    text: "",
    history: [],
  });
  const [date, setDate] = useState(new Date());
  const [dateTo, setDateTo] = useState("");
  const [leads, setLeads] = useState(null);
  const [students, setStudents] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [priorities, setPriorities] = useState(null);
  const [users, setUsers] = useState(null);

  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
      props.setIsLoading(true);
      tasksApi
        .getCreationData()
        .then((res) => {
          setTask({
            ...task,
            creator: userContext.userId,
            status: res.directory.directory.taskStatus[0]._id,
          });
          setLeads(res.leads);
          setStudents(res.students);
          setStatuses(res.directory.directory.taskStatus);
          setPriorities(res.directory.directory.taskPriorities);
          setUsers(res.users);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      setStatus(3);
      props.setIsLoading(true);
      tasksApi
        .getTask(id)
        .then((res) => {
          setTask({
            _id: res.task._id,
            title: res.task.title,
            lead: res.task.lead,
            student: res.task.student,
            creator: res.task.creator,
            responsible: res.task.responsible,
            status: res.task.status,
            priority: res.task.priority,
            history: res.task.history.reverse(),
            text: "",
          });
          setDate(new Date(res.task.date));
          setDateTo(res.task.dateTo ? new Date(res.task.dateTo) : "");
          setLeads(res.leads);
          setStudents(res.students);
          setStatuses(res.directory.directory.taskStatus);
          setPriorities(res.directory.directory.taskPriorities);
          setUsers(res.users);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, []);

  const onSubmit = (e) => {
    if (e.currentTarget.checkValidity() === true) {
      e.preventDefault();
      props.setIsLoading(true);
      if (status === 1) {
        tasksApi
          .createTask({ ...task, dateTo })
          .then((res) => {
            navigation("/admin/tasks/table/" + res.newTask._id);
            setStatus(3);
            setTask({
              _id: res.newTask._id,
              title: res.newTask.title,
              lead: res.newTask.lead,
              student: res.newTask.student,
              creator: res.newTask.creator,
              responsible: res.newTask.responsible,
              status: res.newTask.status,
              priority: res.newTask.priority,
              history: res.newTask.history.reverse(),
              text: "",
            });
            setDate(new Date(res.newTask.date));
            setDateTo(res.newTask.dateTo ? new Date(res.newTask.dateTo) : "");
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else if (status === 3) {
        tasksApi
          .actionInTask({ ...task, dateTo })
          .then((res) => {
            tasksApi
              .getTask(task._id)
              .then((res) => {
                setTask({
                  _id: res.task._id,
                  title: res.task.title,
                  lead: res.task.lead,
                  student: res.task.student,
                  creator: res.task.creator,
                  responsible: res.task.responsible,
                  status: res.task.status,
                  priority: res.task.priority,
                  history: res.task.history.reverse(),
                  text: "",
                });
                setDate(new Date(res.task.date));
                setDateTo(res.task.dateTo ? new Date(res.task.dateTo) : "");
              })
              .catch((rej) => {
                props.setIsError(true);
                props.setError(rej);
              });
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <Form onSubmit={onSubmit} validated={validated}>
      <Card>
        <Card.Body>
          <Card.Title>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                status === 2 ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Row>
              <Col md={7}>
                <Form.Control
                  style={{
                    color:
                      (task.priority !== "") & (task.priority !== undefined)
                        ? priorities.find((el) => el._id === task.priority)
                            .color
                        : "#000",
                  }}
                  required
                  value={task.title}
                  type="text"
                  name="title"
                  size="lg"
                  placeholder="Название"
                  onChange={handleChange}
                />
                <Form.Control
                  as="textarea"
                  style={{ minHeight: "100px" }}
                  className="mt-2"
                  value={task.text}
                  type="text"
                  name="text"
                  onChange={handleChange}
                />
                <div className="d-flex justify-content-end mt-2">
                  <Button variant="outline-primary" type="submit">
                    Сохранить
                  </Button>
                </div>

                <div>
                  {task.history.map((el, i) => (
                    <Card
                      className={`mt-3 ${
                        i === task.history.length - 1 ? "mb-3" : ""
                      }`}
                      key={el._id}
                    >
                      <Card.Body>
                        <Card.Text>
                          <h3>
                            {users.find((us) => us._id === el.author)
                              .firstName +
                              " " +
                              users.find((us) => us._id === el.author)
                                .secondName}
                          </h3>
                          <p className="fw-light mb-1">
                            {new Date(el.date).toLocaleString("ru-RU", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                          </p>
                          {(el.text !== undefined) & (el.text !== "") ? (
                            el.text.match(/http?s:\S{1,}\S/) ? (
                              <p
                                className="mb-2"
                                style={{ whiteSpace: "pre-wrap" }}
                                dangerouslySetInnerHTML={{
                                  __html: el.text.replace(
                                    /http[s]:\S{1,}\S/,
                                    `<a href="${
                                      el.text.match(/http?s:\S{1,}\S/)[0]
                                    }">${
                                      el.text.match(/http?s:\S{1,}\S/)[0]
                                    }</a>`
                                  ),
                                }}
                              />
                            ) : (
                              <p
                                className="mb-2"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                {el.text}
                              </p>
                            )
                          ) : (
                            ""
                          )}
                          <div className="lh-sm mt-1">
                            {el.responsible !== undefined ? (
                              <p
                                className="fw-light m-0"
                                style={{ fontSize: 14 }}
                              >
                                Ответственный изменен на:{" "}
                                {users.find((us) => us._id === el.responsible)
                                  .firstName +
                                  " " +
                                  users.find((us) => us._id === el.responsible)
                                    .secondName}
                              </p>
                            ) : (
                              ""
                            )}
                            {el.status !== undefined ? (
                              <p
                                className="fw-light m-0"
                                style={{ fontSize: 14 }}
                              >
                                Статус изменен на:{" "}
                                {
                                  statuses.find((us) => us._id === el.status)
                                    .value
                                }
                              </p>
                            ) : (
                              ""
                            )}
                            {el.priority !== undefined ? (
                              <p
                                className="fw-light m-0"
                                style={{
                                  fontSize: 14,
                                  color: priorities.find(
                                    (us) => us._id === el.priority
                                  ).color,
                                }}
                              >
                                Приоритет изменен на:{" "}
                                {
                                  priorities.find(
                                    (us) => us._id === el.priority
                                  ).value
                                }
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Col>
              <Col md={5}>
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <Form.Group className="mb-3">
                        <Form.Label>Дата создания</Form.Label>
                        <Flatpickr
                          className="form-control form-control-plaintext"
                          style={{ backgroundColor: "transparent" }}
                          value={date}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          disabled={true}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Срок</Form.Label>
                        <Flatpickr
                          className={`form-control ${
                            statuses !== null
                              ? statuses.find((el) => el._id === task.status)
                                  .isClosed === 0
                                ? ""
                                : "form-control-plaintext"
                              : "form-control-plaintext"
                          }`}
                          style={{ backgroundColor: "transparent" }}
                          value={dateTo}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => setDateTo(date)}
                          disabled={
                            statuses !== null
                              ? statuses.find((el) => el._id === task.status)
                                  .isClosed === 0
                                ? false
                                : true
                              : true
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Приоритет</Form.Label>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет приоритетов"}
                          options={
                            priorities === null
                              ? {}
                              : priorities.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.value,
                                  };
                                })
                          }
                          value={
                            task.priority === "" || task.priority === undefined
                              ? {}
                              : {
                                  value: priorities.find(
                                    (el) => el._id === task.priority
                                  )._id,
                                  label: priorities.find(
                                    (el) => el._id === task.priority
                                  ).value,
                                }
                          }
                          type="text"
                          name="priority"
                          onChange={(data) =>
                            setTask({
                              ...task,
                              priority: data.value,
                            })
                          }
                          isDisabled={
                            statuses !== null
                              ? statuses.find((el) => el._id === task.status)
                                  .isClosed === 0
                                ? false
                                : true
                              : true
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Статус</Form.Label>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет статусов"}
                          options={
                            statuses === null
                              ? {}
                              : statuses.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.value,
                                  };
                                })
                          }
                          value={
                            task.status === "" || task.status === undefined
                              ? {}
                              : {
                                  value: statuses.find(
                                    (el) => el._id === task.status
                                  )._id,
                                  label: statuses.find(
                                    (el) => el._id === task.status
                                  ).value,
                                }
                          }
                          type="text"
                          name="status"
                          onChange={(data) =>
                            setTask({
                              ...task,
                              status: data.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Ответственный</Form.Label>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет пользователей"}
                          options={
                            users === null
                              ? {}
                              : users.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.firstName + " " + el.secondName,
                                  };
                                })
                          }
                          value={
                            task.responsible === "" ||
                            task.responsible === undefined
                              ? {}
                              : {
                                  value: users.find(
                                    (el) => el._id === task.responsible
                                  )._id,
                                  label:
                                    users.find(
                                      (el) => el._id === task.responsible
                                    ).firstName +
                                    " " +
                                    users.find(
                                      (el) => el._id === task.responsible
                                    ).secondName,
                                }
                          }
                          type="text"
                          name="responsible"
                          onChange={(data) => {
                            setTask({
                              ...task,
                              responsible: data.value,
                            });
                          }}
                          isDisabled={
                            statuses !== null
                              ? statuses.find((el) => el._id === task.status)
                                  .isClosed === 0
                                ? false
                                : true
                              : true
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Создатель</Form.Label>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет пользователей"}
                          options={
                            users === null
                              ? {}
                              : users.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.firstName + " " + el.secondName,
                                  };
                                })
                          }
                          value={
                            task.creator === "" || task.creator === undefined
                              ? {}
                              : {
                                  value: users.find(
                                    (el) => el._id === task.creator
                                  )._id,
                                  label:
                                    users.find((el) => el._id === task.creator)
                                      .firstName +
                                    " " +
                                    users.find((el) => el._id === task.creator)
                                      .secondName,
                                }
                          }
                          type="text"
                          name="creator"
                          isDisabled={true}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Лид</Form.Label>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет лидов"}
                          options={
                            leads === null
                              ? {}
                              : leads.map((el) => {
                                  return {
                                    value: el._id,
                                    label:
                                      el.name +
                                      (el.secondName
                                        ? " " + el.secondName
                                        : ""),
                                  };
                                })
                          }
                          value={
                            task.lead === "" || task.lead === undefined
                              ? {}
                              : {
                                  value: leads.find(
                                    (el) => el._id === task.lead
                                  )._id,
                                  label:
                                    leads.find((el) => el._id === task.lead)
                                      .name +
                                    (leads.find((el) => el._id === task.lead)
                                      .secondName
                                      ? " " +
                                        leads.find((el) => el._id === task.lead)
                                          .secondName
                                      : ""),
                                }
                          }
                          type="text"
                          name="lead"
                          onChange={(data) =>
                            setTask({
                              ...task,
                              lead: data.value,
                            })
                          }
                          isDisabled={
                            statuses !== null
                              ? statuses.find((el) => el._id === task.status)
                                  .isClosed === 0
                                ? false
                                : true
                              : true
                          }
                        />
                        {(task.lead !== "") &
                        (task.lead !== undefined) &
                        (task.lead !== null) ? (
                          <Form.Label>
                            <Link to={"/admin/leads/table/" + task.lead}>
                              Перейти
                            </Link>
                          </Form.Label>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Ученик</Form.Label>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет учеников"}
                          options={
                            students === null
                              ? {}
                              : students.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.firstName + " " + el.secondName,
                                  };
                                })
                          }
                          value={
                            task.student === "" || task.student === undefined
                              ? {}
                              : {
                                  value: students.find(
                                    (el) => el._id === task.student
                                  )._id,
                                  label:
                                    students.find(
                                      (el) => el._id === task.student
                                    ).firstName +
                                    " " +
                                    students.find(
                                      (el) => el._id === task.student
                                    ).secondName,
                                }
                          }
                          type="text"
                          name="student"
                          onChange={(data) =>
                            setTask({
                              ...task,
                              student: data.value,
                            })
                          }
                          isDisabled={
                            statuses !== null
                              ? statuses.find((el) => el._id === task.status)
                                  .isClosed === 0
                                ? false
                                : true
                              : true
                          }
                        />
                        {(task.student !== "") &
                        (task.student !== undefined) &
                        (task.student !== null) ? (
                          <Form.Label>
                            <Link to={"/admin/students/" + task.student}>
                              Перейти
                            </Link>
                          </Form.Label>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </Form>
  );
}
