import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import { api as whatsAppApi } from "../../../utils/api/api.whatsapp";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import "flatpickr/dist/themes/light.css";
import Accordion from "react-bootstrap/Accordion";
import UserModal from "./UserModal";
import UsersModal from "./UsersModal";

export default function WhatsappForm(props) {
  const userContext = React.useContext(UserContext);

  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState({
    _id: "",
    name: "",
    status: 1,
    text: "",
    users: [],
    comment: "",
    owner: "",
  });
  const [owner, setOwner] = useState({});
  const [leads, setLeads] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserActionSuccess, setIsUserActionSuccess] = useState(null);
  const [modalUser, setModalUser] = useState(false);
  const [sources, setSources] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [modalUsers, setModalUsers] = useState(false);
  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (needUpdate) {
      if (id === "new") {
        setStatus(1);
        props.setIsLoading(true);
        whatsAppApi
          .getCreationData()
          .then((res) => {
            setLeads(res.leads);
            setStatuses(res.settings.directory.leadStatus);
            setSources(res.settings.directory.leadSource);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else {
        setStatus(2);
        props.setIsLoading(true);
        whatsAppApi
          .getMessage(id)
          .then((res) => {
            setMessage({
              _id: res.message._id,
              name: res.message.name,
              status: res.message.status,
              text: res.message.text,
              users: res.message.users,
              comment: res.message.comment,
            });
            setOwner(res.users.find((user) => user._id === res.message.owner));
            setLeads(res.leads);
            setStatuses(res.settings.directory.leadStatus);
            setSources(res.settings.directory.leadSources);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
      setNeedUpdate(false);
    }
  }, [needUpdate]);

  const onSubmit = (e) => {
    if (e.currentTarget.checkValidity() === true) {
      e.preventDefault();
      props.setIsLoading(true);
      if (status === 1) {
        whatsAppApi
          .createMessage(message)
          .then((res) => {
            navigation("/admin/whatsapp/" + res.newMessage._id);
            setMessage({
              ...message,
              _id: res.newMessage._id,
              owner: res.newMessage.owner,
            });
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else if (status === 3) {
        whatsAppApi
          .updateMessage(message)
          .then((res) => {
            whatsAppApi
              .getMessage(res.editMessage._id)
              .then((res) => {
                setMessage({
                  _id: res.message._id,
                  name: res.message.name,
                  status: res.message.status,
                  text: res.message.text,
                  users: res.message.users,
                  comment: res.message.comment,
                });
              })
              .catch((rej) => {
                props.setIsError(true);
                props.setError(rej);
              });
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    } else {
      e.preventDefault();
    }
  };

  const userDeleteHandler = (data) => {
    if (status === 1) {
      setMessage({
        ...message,
        users: message.users.filter((item) => item.mobile !== data.mobile),
      });
    } else if ((status === 3) & (message.status === 1)) {
      props.setIsLoading(true);
      whatsAppApi
        .deleteUser({ message: message._id, user: data })
        .then((res) => {
          setNeedUpdate(true);
          setModalUser(false);
          setIsUserActionSuccess(true);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  };

  const userSubmitHandler = (data) => {
    if (status === 1) {
      if (selectedUser === null) {
        setMessage({ ...message, users: [...message.users, data] });
        setModalUser(false);
        setIsUserActionSuccess(true);
      } else if (selectedUser !== null) {
        setMessage({
          ...message,
          message: message.users.map((item) => {
            if (item.mobile === selectedUser.mobile) {
              return data;
            } else {
              return item;
            }
          }),
        });
        setModalUser(false);
        setIsUserActionSuccess(true);
      }
    } else if ((status === 3) & (message.status === 1)) {
      if (selectedUser === null) {
        props.setIsLoading(true);
        whatsAppApi
          .postUser({ message: message._id, user: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalUser(false);
            setIsUserActionSuccess(true);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      } else if (selectedUser !== null) {
        props.setIsLoading(true);
        whatsAppApi
          .editUser({ message: message._id, user: data })
          .then((res) => {
            setNeedUpdate(true);
            setModalUser(false);
            setIsUserActionSuccess(true);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      }
    }
  };

  const usersSubmitHandler = (data) => {
    props.setIsLoading(true);
    whatsAppApi
      .addManyUsers({ message: message._id, ...data })
      .then((res) => {
        setNeedUpdate(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  return (
    <Form onSubmit={onSubmit} validated={validated}>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row className="g-2" style={{ maxWidth: 630 }}>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Название</Form.Label>
                  <Form.Control
                    required
                    value={message.name}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                (status === 2) & (message.status === 1) ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Статус
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  required
                  name="status"
                  value={message.status}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                >
                  <option value={1}>Черновик</option>
                  <option value={2}>Готова к отправке</option>
                  <option value={3} disabled>
                    Отправляется
                  </option>
                  <option value={4} disabled>
                    Завершена
                  </option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Текст
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="text"
                  onChange={handleChange}
                  value={message.text}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
                <Form.Label>#NAME# - имя</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Комментарий
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="comment"
                  onChange={handleChange}
                  value={message.comment}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
          </Card.Text>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className={`${status === 2 ? "d-none" : "d-block"}`}
            >
              Сохранить
            </Button>
          </div>
        </Card.Body>
        <Form.Group className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}>
          <Form.Label>Создал пользователь</Form.Label>
          <Form.Control
            value={owner !== {} ? owner.firstName + " " + owner.secondName : ""}
            type="text"
            name="owner"
            disabled
            plaintext
          />
        </Form.Group>
        <Accordion defaultActiveKey={["0"]} alwaysOpen className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Получатели</Accordion.Header>
            <Accordion.Body style={{ overflow: "scroll" }}>
              <Table striped>
                <thead>
                  <tr>
                    <th></th>
                    <th>Имя</th>
                    <th>Телефон</th>
                    <th>Лид</th>
                    <th>Статус</th>
                  </tr>
                </thead>
                <tbody>
                  {message.users !== []
                    ? message.users.map((user) => {
                        return (
                          <tr key={user._id}>
                            <td>
                              <i
                                className="bi bi-eye-fill"
                                style={{ cursor: "pointer", fontSize: 24 }}
                                onClick={() => {
                                  setSelectedUser(user);
                                  setModalUser(true);
                                }}
                              />
                            </td>
                            <td>{user.name}</td>
                            <td>{user.mobile}</td>
                            <td>
                              {(user.leadId !== "") & (user.leadId !== undefined)
                                ? leads.find((el) => el._id === user.leadId)
                                    .name + (leads.find((el) => el._id === user.leadId)
                                    .secondName ? (" " + leads.find((el) => el._id === user.leadId)
                                    .secondName) : "" ) +
                                  ", " +
                                  leads.find((el) => el._id === user.leadId)
                                    .mobile
                                : ""}
                            </td>
                            <td>
                              {user.status.code === 1
                                ? "Не отправлено"
                                : user.status.code === 5
                                ? "Ошибка"
                                : "Отправлено"}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    setSelectedUser(null);
                    setModalUser(true);
                  }}
                  className={`m-2 ${
                    status === 2 || message.status !== 1 ? "d-none" : "d-block"
                  }`}
                >
                  Добавить получателя
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    setModalUsers(true);
                  }}
                  className={`m-2 ${
                    status !== 3 || message.status !== 1 ? "d-none" : "d-block"
                  }`}
                >
                  Добавить получателей
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
      <UserModal
        data={message}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        isModalOpen={modalUser}
        setIsModalOpen={setModalUser}
        leads={leads}
        isUserActionSuccess={isUserActionSuccess}
        setIsUserActionSuccess={setIsUserActionSuccess}
        status={status}
        submitHandler={userSubmitHandler}
        deleteHandler={userDeleteHandler}
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        needUpdate={needUpdate}
        setNeedUpdate={setNeedUpdate}
      />
      <UsersModal
        isModalOpen={modalUsers}
        setIsModalOpen={setModalUsers}
        statuses={statuses}
        sources={sources}
        submitHandler={usersSubmitHandler}
      />
    </Form>
  );
}
