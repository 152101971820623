import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const UserModal = (props) => {
  const [form, setForm] = useState({
    source: "",
    status: "",
  });

  const submitForm = (e) => {
    e.preventDefault();
    props.submitHandler(form);
    handleClose();
  };

  const handleClose = () => {
    setForm({
        source: "",
        status: "",
    });
    props.setIsModalOpen(false);
  };

  return (
    <>
      <Modal show={props.isModalOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Массовое добавление
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Статус
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных статусов"}
                options={ props.statuses !== null & props.statuses !== undefined ? props.statuses.map(el => { return {
                  value: el._id,
                  label: el.value,
                }}) : [] }
                value={form.status !== "" ? {
                  value:  props.statuses.find(el => el._id === form.status)._id,
                  label: props.statuses.find(el => el._id === form.status).value,
                }
                : {}}
                type="text"
                name="status"
                onChange={(data) => {
                  setForm({
                    ...form,
                    status: data.value,
                  });
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Источник
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных источников"}
                options={ props.sources !== null & props.sources !== undefined ? props.sources.map(el => { return {
                  value: el._id,
                  label: el.value,
                }}) : [] }
                value={form.source !== "" ? {
                  value:  props.sources.find(el => el._id === form.source)._id,
                  label: props.sources.find(el => el._id === form.source).value,
                }
                : {}}
                type="text"
                name="source"
                onChange={(data) => {
                  setForm({
                    ...form,
                    source: data.value,
                  });
                }}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            className={props.status === 2 ? "d-none" : "d-block"}
          >
            Сохранить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModal;
