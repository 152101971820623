import React, { useContext, useState, useEffect } from "react";
import { api as meApi } from "../../../utils/api/api.user";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/themes/light.css";

export default function UsersForm(props) {
  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [user, setUser] = useState({
    _id: "",
    login: "",
    password: "",
    firstName: "",
    secondName: "",
    photo: "",
    email: "",
    type: 1,
    job: "",
    unit: "",
    notifications: "",
    isActive: "",
    owner: "",
  });
  const [birthday, setBirthday] = useState("");
  const [needUpdate, setNeedUpdate] = useState(true);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (needUpdate) {
      setStatus(2);
      props.setIsLoading(true);
      meApi
        .getUser()
        .then((res) => {
          setUser({
            _id: res.user._id,
            login: res.user.login,
            password: "",
            firstName: res.user.firstName,
            secondName: res.user.secondName,
            photo: res.user.photo,
            email: res.user.email,
            type: res.user.type,
            job: res.user.job,
            unit: res.user.unit,
            notifications: res.user.notifications,
            isActive: res.user.isActive,
            owner: res.user.owner,
          });
          setBirthday(res.user.birthday);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
      setNeedUpdate(false);
    }
  }, [needUpdate]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    if (status === 3) {
      meApi
        .updateUser({ ...user, birthday: birthday })
        .then((res) => {
          meApi
            .getUser()
            .then((res) => {
              setUser({
                _id: res.user._id,
                login: res.user.login,
                password: "",
                firstName: res.user.firstName,
                secondName: res.user.secondName,
                photo: res.user.photo,
                email: res.user.email,
                type: res.user.type,
                job: res.user.job,
                unit: res.user.unit,
                notifications: res.user.notifications,
                isActive: res.user.isActive,
                owner: res.user.owner,
              });
            })
            .catch((rej) => {
              props.setIsError(true);
              props.setError(rej);
            });
          setStatus(2);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Card>
        <Card.Body>
          <Card.Title>
            {/* <i
                    className="bi bi-chevron-compact-left me-3"
                    style={{ fontSize: 30, cursor: "pointer" }}
                    onClick={() => navigation(-1)}
                  /> */}
            <Row className="g-2" style={{ maxWidth: 630 }}>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Имя</Form.Label>
                  <Form.Control
                    value={user.firstName}
                    type="text"
                    name="firstName"
                    placeholder="Имя"
                    onChange={handleChange}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Фамилия</Form.Label>
                  <Form.Control
                    type="text"
                    name="secondName"
                    placeholder="Фамилия"
                    onChange={handleChange}
                    value={user.secondName}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                status === 2 ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Тип
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="isActive"
                  value={user.type}
                  disabled={true}
                  plaintext={true}
                >
                  <option value=" "> </option>
                  <option value="1">Администратор</option>
                  <option value="2">Ученик</option>
                  <option value="3">Родитель</option>
                  <option value="4">Система</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Логин
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="login"
                  value={user.login}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Пароль
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                E-mail
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={user.email}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                День рождения
              </Form.Label>
              <Col sm={10}>
                <Flatpickr
                  className={`form-control ${
                    status === 2 && "form-control-plaintext"
                  }`}
                  style={{ backgroundColor: "transparent" }}
                  value={birthday}
                  options={{
                    time_24hr: true,
                    locale: Russian,
                    disableMobile: true,
                    dateFormat: "d.m.Y",
                  }}
                  name="birthday"
                  onChange={([date]) => {
                    setBirthday(date);
                  }}
                  disabled={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Должность
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="job"
                  onChange={handleChange}
                  value={user.job}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Активность
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="isActive"
                  value={user.isActive}
                  disabled={true}
                  plaintext={true}
                >
                  <option value="true">Да</option>
                  <option value="false">Нет</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Card.Text>
          <div className="d-flex justify-content-end">
          <Button
              variant="danger"
              className="me-2"
              onClick={() => props.userLogOut()}
            >
              Выход
            </Button>
            <Button
              variant="primary"
              type="submit"
              className={`${status === 2 || user.login === 'test1000' ? "d-none" : "d-block"}`}
            >
              Сохранить
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Form>
  );
}
