import React, { useState, useEffect } from "react";
import { api as creditApi } from "../../../utils/api/api.credits";
import { Card, ListGroup, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function Tasks(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [students, setStudents] = useState(null);

  const navigator = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    creditApi
      .getOperations({
        mode_dash: 1,
      })
      .then((res) => {
        setData(res.creditOperations);
        setStudents(res.students);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <Card>
      <h5>Неоплаченные долги: </h5>
      {data ? (
        data.length !== 0 ? (
          <ListGroup>
            {data
              .filter((el, ind) => ind < 4)
              .map((el) => (
                <ListGroup.Item>
                  {new Date(el.date).toLocaleString("ru-RU", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                  {" | "}
                  {students.find((le) => le._id === el.student).firstName +
                    " " +
                    students.find((le) => le._id === el.student).secondName}
                  <i
                    className="bi bi-eye-fill ms-2"
                    style={{ cursor: "pointer", fontSize: 16 }}
                    onClick={() =>
                      navigator("/admin/operation/credits/" + el._id)
                    }
                  />
                </ListGroup.Item>
              ))}
            {data.length > 4 ? (
              <ListGroup.Item>
                <Link to="/admin/operation/credits">Еще...</Link>
              </ListGroup.Item>
            ) : (
              ""
            )}
          </ListGroup>
        ) : (
          <p>Нет долгов</p>
        )
      ) : (
        ""
      )}
      <div
        className={`position-absolute align-items-center justify-content-center bg-light bg-opacity-50 flex-column ${
          isLoading ? "d-flex" : "d-none"
        }`}
        style={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 1100 }}
      >
        <Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />
        <p className="fw-light fs-2 text-center mt-4">Загрузка...</p>
      </div>
    </Card>
  );
}
