import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";

import { api as financeReport } from "../../../utils/api/Reports/api.finance";

export default function Finance(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    financeReport
      .getShortReport()
      .then(({ report }) => {
        setData(report);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {data ? (
        <Card>
          <h5>Финансы за месяц: </h5>
          <div className="d-flex" style={{ width: "100%" }}>
            <Card
              style={{ width: "50%" }}
              className="justify-content-center flex-column align-items-center"
            >
              <h5 className="fw-light text-success">Доходы</h5>
              <h5 className="fw-bold text-success">
                {data.total.plus.toFixed(2)}
              </h5>
            </Card>
            <Card
              style={{ width: "50%" }}
              className="justify-content-center flex-column align-items-center"
            >
              <h5 className="fw-light text-danger">Расходы</h5>
              <h5 className="fw-bold text-danger">
                {data.total.minus.toFixed(2)}
              </h5>
            </Card>
          </div>
          <Card
            style={{ width: "100%" }}
            className="justify-content-center flex-column align-items-center"
          >
            <h5
              className={`fw-light text-${
                data.total.total > 0 ? "success" : "danger"
              }`}
            >
              Прибыль
            </h5>
            <h5
              className={`fw-bold text-${
                data.total.total > 0 ? "success" : "danger"
              }`}
            >
              {data.total.total.toFixed(2)}
            </h5>
          </Card>
          <div
            className={`position-absolute align-items-center justify-content-center bg-light bg-opacity-50 flex-column ${
              isLoading ? "d-flex" : "d-none"
            }`}
            style={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 1100 }}
          >
            <Spinner
              animation="grow"
              style={{ width: "4rem", height: "4rem" }}
            />
            <p className="fw-light fs-2 text-center mt-4">Загрузка...</p>
          </div>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}
