class Api {

    _checkResponse(res) {
        if (res.ok) {
            return res.json()
        }
        return Promise.reject(res)
    }

    getDocs(params) {
        let querry = "?";
        const keys = Object.keys(params);
        keys.forEach((key) => {
          if (
            (params[key] !== undefined) &
            (params[key] !== "") &
            (params[key] !== null)
          ) {
            querry += `${key}=${params[key]}&`;
          }
        });
        return fetch("/api/documents/" + querry, {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
        }).then((res) => this._checkResponse(res));
      }
    
      getDoc(id) {
        return fetch("/api/documents/" + id, {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
        }).then((res) => this._checkResponse(res));
      }
    
      getCreationData() {
        return fetch("/api/documents/create-data", {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
        }).then((res) => this._checkResponse(res));
      }
    
      postDoc(data) {
        const keys = Object.keys(data);
        keys.forEach((key) => {
          if (data[key] === undefined || data[key] === "") {
            delete data[key];
          }
        });
        return fetch("/api/documents/", {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((res) => this._checkResponse(res));
      }
    
      editDoc(data) {
        const keys = Object.keys(data);
        keys.forEach((key) => {
          if (data[key] === undefined) {
            delete data[key];
          }
        });
        return fetch("/api/documents/", {
          method: "PATCH",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((res) => this._checkResponse(res));
      }
    
      deleteDoc(data) {
        const keys = Object.keys(data);
        keys.forEach((key) => {
          if (data[key] === undefined || data[key] === "") {
            delete data[key];
          }
        });
        return fetch("/api/documents/", {
          method: "DELETE",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((res) => this._checkResponse(res));
      }
    
      postField(field) {
        const keys = Object.keys(field);
        keys.forEach((key) => {
          if (field[key] === undefined || field[key] === "") {
            delete field[key];
          }
        });
        return fetch("/api/documents/field", {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(field),
        }).then((res) => this._checkResponse(res));
      }
    
      editField(field) {
        const keys = Object.keys(field);
        keys.forEach((key) => {
          if (field[key] === undefined || field[key] === "") {
            delete field[key];
          }
        });
        return fetch("/api/documents/field", {
            method: "PATCH",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(field),
        }).then((res) => this._checkResponse(res));
      }
    
      deleteField(field) {
        const keys = Object.keys(field);
        keys.forEach((key) => {
          if (field[key] === undefined || field[key] === "") {
            delete field[key];
          }
        });
        return fetch("/api/documents/field", {
            method: "DELETE",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(field),
        }).then((res) => this._checkResponse(res));
      }

}

const api = new Api()

export {api}