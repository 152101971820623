import React from 'react'
import Flatpickr from "react-flatpickr";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Russian } from "flatpickr/dist/l10n/ru.js";

export default function DaySelector(props) {
  return (
    <div className="d-flex justify-content-center">
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={4} className="fw-bold">
        Дата
      </Form.Label>
      <Col sm={8}>
        <Flatpickr
          className="form-control"
          style={{ backgroundColor: "transparent" }}
          value={new Date(props.dateFrom)}
          options={{
            disableMobile: true,
            locale: Russian,
            dateFormat: "d.m.Y",
          }}
          name="daySelector"
          onChange={([date]) => {
            props.setDateFrom(new Date(date));
            props.setDateTo(new Date(date));
            props.setNeedUpdate(true);
          }}
        />
      </Col>
    </Form.Group>
  </div>
  )
}
