import React from "react";
import { Routes, Route } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useLocation, useNavigate } from "react-router-dom";
import TasksKanBan from "./TasksKanBan";
import TasksTable from "./TasksTable";
import TasksForm from "./TasksForm";

const Tasks = (props) => {
  const navigation = useNavigate();
  const location = useLocation();

  const handleSelect = (eventKey) => {
    navigation(eventKey);
  };
  return (
    <>
      <Routes>
        <Route
          path="/kanban"
          element={
            <>
              <Nav
                variant="tabs"
                onSelect={handleSelect}
                defaultActiveKey="table"
                activeKey={
                  location.pathname.includes("kanban")
                    ? "kanban"
                    : location.pathname.includes("table")
                    ? "table"
                    : ""
                }
              >
                <Nav.Item>
                  <Nav.Link eventKey="table">Таблица</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="kanban">Канбан</Nav.Link>
                </Nav.Item>
              </Nav>
              <TasksKanBan
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            </>
          }
        />
        <Route
          path="/table"
          element={
            <>
              <Nav
                variant="tabs"
                onSelect={handleSelect}
                defaultActiveKey="table"
                activeKey={
                  location.pathname.includes("kanban")
                    ? "kanban"
                    : location.pathname.includes("table")
                    ? "table"
                    : ""
                }
              >
                <Nav.Item>
                  <Nav.Link eventKey="table">Таблица</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="kanban">Канбан</Nav.Link>
                </Nav.Item>
              </Nav>
              <TasksTable
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            </>
          }
        />
        <Route
          path="/table/:id"
          element={
            <TasksForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/kanban/:id"
          element={
            <TasksForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Tasks;
