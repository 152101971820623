import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import LessonSchule from "./LessonSchule";
import LessonsTable from "./LessonsTable";
import LessonForm from "./LessonForm";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

const Lessons = (props) => {
  const [needUpdate, setNeedUpdate] = React.useState(true);
  const [tableState, setTableState] = useState(null);
  const [scheduleState, setScheduleState] = useState(null);

  const navigation = useNavigate();
  const location = useLocation();

  const handleSelect = (eventKey) => {
    navigation(eventKey);
  };

  return (
    <>
      <Nav
        variant="tabs"
        onSelect={handleSelect}
        defaultActiveKey="schedule"
        activeKey={
          location.pathname.includes("schedule")
            ? "schedule"
            : location.pathname.includes("table")
            ? "table"
            : ""
        }
      >
        <Nav.Item>
          <Nav.Link eventKey="schedule">Расписание</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="table">Таблица</Nav.Link>
        </Nav.Item>
      </Nav>
      <Routes>
        <Route
          path="/schedule/*"
          element={
            <LessonSchule
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              needUpdate={needUpdate}
              setNeedUpdate={setNeedUpdate}
              scheduleState={scheduleState}
              setScheduleState={setScheduleState}
            />
          }
        />
        <Route
          path="/table/*"
          element={
            <LessonsTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              needUpdate={needUpdate}
              setNeedUpdate={setNeedUpdate}
              setTableState={setTableState}
              tableState={tableState}
            />
          }
        />
        <Route
          path="/schedule/:id"
          element={
            <LessonForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              needUpdate={needUpdate}
              setNeedUpdate={setNeedUpdate}
            />
          }
        />
        <Route
          path="/table/:id"
          element={
            <LessonForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              needUpdate={needUpdate}
              setNeedUpdate={setNeedUpdate}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Lessons;
