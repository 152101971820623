import React from "react";
import { UserContext } from "../../../context/UserContext";
import { Routes, Route } from "react-router-dom";
import DiscountsTable from "./DiscountsTable";
import DiscountsForm from "./DiscountsForm";

const Dances = (props) => {
const userContext = React.useContext(UserContext);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <DiscountsTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <DiscountsForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Dances;
