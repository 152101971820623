import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import { api as discountsApi } from "../../../utils/api/api.discounts";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "flatpickr/dist/themes/light.css";

export default function DiscountsForm(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [discount, setDiscounts] = useState({
    _id: "",
    name: "",
    money: "",
    percent: "",
    comment: "",
    isActive: "",
    owner: "",
  });
  const [owner, setOwner] = useState({});
  const [validated, setValidated] = useState(false);
  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    setDiscounts({ ...discount, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
    } else {
      setStatus(2);
      props.setIsLoading(true);
      discountsApi
        .getDiscount(id)
        .then((res) => {
          setDiscounts({
            _id: res.discount._id,
            name: res.discount.name,
            money: res.discount.money,
            percent: res.discount.percent,
            comment: res.discount.comment,
            isActive: res.discount.isActive,
            owner: res.discount.owner,
          });
          setOwner(res.users.find((user) => user._id === res.discount.owner));
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, []);

  const onSubmit = (e) => {
    if (e.currentTarget.checkValidity() === true) {
      e.preventDefault();
      props.setIsLoading(true);
      if (status === 1) {
        discountsApi
          .createDiscount(discount)
          .then((res) => {
            navigation("/admin/discounts/" + res.newDiscount._id);
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else if (status === 3) {
        discountsApi
          .updateDiscount(discount)
          .then((res) => {
            discountsApi
              .getDiscount(res.newDiscount._id)
              .then((res) => {
                setDiscounts({
                  _id: res.discount._id,
                  name: res.discount.name,
                  money: res.discount.money,
                  percent: res.discount.percent,
                  comment: res.discount.comment,
                  isActive: res.discount.isActive,
                  owner: res.discount.owner,
                });
              })
              .catch((rej) => {
                props.setIsError(true);
                props.setError(rej);
              });
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <Form onSubmit={onSubmit} validated={validated}>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row className="g-2" style={{ maxWidth: 630 }}>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Название</Form.Label>
                  <Form.Control
                    required
                    value={discount.name}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                status === 2 ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Размер в деньгах
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="money"
                  value={discount.money}
                  onChange={handleChange}
                  disabled={
                    status === 2 ||
                    (discount.percent !== "") & (discount.percent !== undefined)
                      ? true
                      : false
                  }
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Размер в процентах
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="percent"
                  value={discount.percent}
                  onChange={handleChange}
                  disabled={
                    status === 2 ||
                    (discount.money !== "") & (discount.money !== undefined)
                      ? true
                      : false
                  }
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Комментарий
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="comment"
                  onChange={handleChange}
                  value={discount.comment}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Активность
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="isActive"
                  value={discount.isActive}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                >
                  <option value="true">Да</option>
                  <option value="false">Нет</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Card.Text>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className={`${status === 2 ? "d-none" : "d-block"}`}
            >
              Сохранить
            </Button>
          </div>
        </Card.Body>
        <Form.Group className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}>
          <Form.Label>Создал пользователь</Form.Label>
          <Form.Control
            value={owner !== {} ? owner.firstName + " " + owner.secondName : ""}
            type="text"
            name="owner"
            disabled
            plaintext
          />
        </Form.Group>
      </Card>
    </Form>
  );
}
