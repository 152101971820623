import "../blocks/App.scss";
import { UserContext } from "../context/UserContext";
import React from "react";
import { useState } from "react";
import Error from "./Error";
import Spinner from "react-bootstrap/Spinner";
import { Routes, Route } from "react-router-dom";
import { Redirect } from "./Redirect";

import Auth from "./Auth";
import Admin from "./admin/Admin";

function App() {
  const [user, setUser] = React.useState(undefined);
  const [isError, setIsError] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const userAuth = (user) => {
    setUser(user);
    localStorage.setItem("userData", JSON.stringify(user));
  };

  const userLogOut = () => {
    setUser(null);
    localStorage.removeItem("userData");
  };

  const closeError = () => {
    setIsError(false);
  };

  React.useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")) || null);
    // Если старая авторизация просим перезайти
    (JSON.parse(localStorage.getItem("userData")) !== undefined) &
      (JSON.parse(localStorage.getItem("userData")) !== null) &&
      JSON.parse(localStorage.getItem("userData")).userType === undefined &&
      userLogOut();
  }, []);

  return (
    <>
      {user !== undefined ? (
        <div>
          <UserContext.Provider value={user}>
            <Routes>
              <Route path="/" element={<Redirect to="/auth/" />} />{" "}
              {/* при отстутсвия сайта на домене сразу отправляем на админку */}
              <Route
                path="/admin/*"
                element={
                  user !== null ? (
                    user.userType === 1 ? (
                      <Admin
                        setIsLoading={setIsLoading}
                        setIsError={setIsError}
                        setError={setError}
                        userLogOut={userLogOut}
                      />
                    ) : user.userType === 2 ? (
                      <h2>ЛК тренера</h2>
                    ) : user.userType === 3 ? (
                      <h2>ЛК Ученика</h2>
                    ) : (
                      ""
                    )
                  ) : (
                    <Redirect to="/auth" />
                  )
                }
              />
              <Route
                path="/auth"
                element={
                  user !== null ? (
                    user.userType === 1 ? (
                      <Redirect to="/admin" />
                    ) : user.userType === 2 ? (
                      <h2>ЛК тренера</h2>
                    ) : user.userType === 3 ? (
                      <h2>ЛК Ученика</h2>
                    ) : (
                      ""
                    )
                  ) : (
                    <Auth
                      setIsLoading={setIsLoading}
                      setIsError={setIsError}
                      setError={setError}
                      userAuth={userAuth}
                    />
                  )
                }
              />
            </Routes>
            <Error
              isError={isError}
              error={error}
              closeError={closeError}
              logOut={userLogOut}
            />
            <div
              className={`position-fixed align-items-center justify-content-center bg-light bg-opacity-50 flex-column ${
                isLoading ? "d-flex" : "d-none"
              }`}
              style={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 1100 }}
            >
              <Spinner
                animation="grow"
                style={{ width: "4rem", height: "4rem" }}
              />
              <p className="fw-light fs-2 text-center mt-4">Загрузка...</p>
            </div>
          </UserContext.Provider>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default App;
