import React, { useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import LeadsTable from "./LeadsTable";
import LeadsForm from "./LeadsForm";
import LeadsKanBan from "./LeadsKanBan";
import Nav from "react-bootstrap/Nav";
import LeadsFunnel from "./LeadsFunnel";

const Leads = (props) => {
  const userContext = React.useContext(UserContext);
  const navigation = useNavigate();
  const location = useLocation();
  const [tableState, setTableState] = useState(null)

  const handleSelect = (eventKey) => {
    navigation(eventKey);
  };

  return (
    <>
      <Routes>
        <Route
          path="/table"
          element={
            <>
              <Nav
                variant="tabs"
                onSelect={handleSelect}
                defaultActiveKey="table"
                activeKey={
                  location.pathname.includes("kanban")
                    ? "kanban"
                    : location.pathname.includes("table")
                    ? "table"
                    : location.pathname.includes("funnel")
                    ? "funnel"
                    : ""
                }
              >
                <Nav.Item>
                  <Nav.Link eventKey="table">Таблица</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="kanban">Канбан</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="funnel">Воронка</Nav.Link>
                </Nav.Item>
              </Nav>
              <LeadsTable
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
                setTableState={setTableState}
                tableState={tableState}
              />
            </>
          }
        />
        <Route
          path="/kanban"
          element={
            <>
              <Nav
                variant="tabs"
                onSelect={handleSelect}
                defaultActiveKey="table"
                activeKey={
                  location.pathname.includes("kanban")
                    ? "kanban"
                    : location.pathname.includes("table")
                    ? "table"
                    : location.pathname.includes("funnel")
                    ? "funnel"
                    : ""
                }
              >
                <Nav.Item>
                  <Nav.Link eventKey="table">Таблица</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="kanban">Канбан</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="funnel">Воронка</Nav.Link>
                </Nav.Item>
              </Nav>
              <LeadsKanBan
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            </>
          }
        />
        <Route
          path="/funnel"
          element={
            <>
              <Nav
                variant="tabs"
                onSelect={handleSelect}
                defaultActiveKey="table"
                activeKey={
                  location.pathname.includes("kanban")
                    ? "kanban"
                    : location.pathname.includes("table")
                    ? "table"
                    : location.pathname.includes("funnel")
                    ? "funnel"
                    : ""
                }
              >
                <Nav.Item>
                  <Nav.Link eventKey="table">Таблица</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="kanban">Канбан</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="funnel">Воронка</Nav.Link>
                </Nav.Item>
              </Nav>
              <LeadsFunnel
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            </>
          }
        />
        <Route
          path="/table/:id"
          element={
            <LeadsForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/kanban/:id"
          element={
            <LeadsForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Leads;
