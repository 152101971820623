import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const UserModal = (props) => {
  const [form, setForm] = useState({
    _id: "",
    name: "",
    mobile: "",
    leadId: "",
    status: {code: 1},
  });
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    if (props.isUserActionSuccess) {
      handleClose();
      props.setIsUserActionSuccess(null);
    }
  }, [props.isUserActionSuccess]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    props.submitHandler(form);
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    setDeletePopup(false);
    props.deleteHandler(form);
  };

  const handleClose = () => {
    setForm({
      _id: "",
      name: "",
      mobile: "",
      leadId: "",
      status: {code: 1},
    });
    props.setIsModalOpen(false);
    props.setSelectedUser(null);
  };

  useEffect(() => {
    if ((props.selectedUser !== null) & (props.selectedUser !== undefined)) {
      setForm({
        _id: props.selectedUser._id,
        name: props.selectedUser.name,
        mobile: props.selectedUser.mobile,
        leadId: props.selectedUser.leadId,
        status: props.selectedUser.status,
      });
    }
  }, [props.selectedUser]);

  return (
    <>
      <Modal show={props.isModalOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {(props.selectedUser !== null) & (props.selectedUser !== undefined)
              ? props.selectedUser.name
              : "Новый получатель"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Лид
            </Form.Label>
            <Col sm={9}>
              <Select
                placeholder=""
                noOptionsMessage={() => "Нет доступных лидов"}
                options={
                  (props.leads !== null) & (props.leads !== undefined)
                    ? props.data.users.length > 0
                      ? props.leads
                          .filter((el) => {
                            return !props.data.users.some(
                              (obj) => obj.leadId === el._id
                            );
                          })
                          .map((el) => {
                            return {
                              value: el._id,
                              label: el.name + ", " + el.mobile,
                              name: el.name,
                              mobile: el.mobile,
                            };
                          })
                      : props.leads.map((el) => {
                          return {
                            value: el._id,
                            label: el.name + ", " + el.mobile,
                            name: el.name,
                            mobile: el.mobile,
                          };
                        })
                    : []
                }
                value={
                  (form.leadId !== undefined) &
                  (form.leadId !== "") &
                  (props.leads !== undefined) &
                  (props.leads !== null)
                    ? {
                        value: props.leads.find((el) => el._id === form.leadId)
                          ._id,
                        label:
                          props.leads.find((el) => el._id === form.leadId)
                            .name + (props.leads.find((el) => el._id === form.leadId)
                            .secondName ? (" " + props.leads.find((el) => el._id === form.leadId)
                            .secondName) : "" ) +
                          ", " +
                          props.leads.find((el) => el._id === form.leadId)
                            .mobile,
                        name: props.leads.find((el) => el._id === form.leadId).name,
                        mobile: props.leads.find((el) => el._id === form.leadId).mobile,
                      }
                    : ""
                }
                type="text"
                name="leadId"
                onChange={(data) => {
                  setForm({
                    ...form,
                    leadId: data.value,
                    name: data.name,
                    mobile: data.mobile,
                  });
                  
                }}
                isDisabled={
                  props.status === 2 ? true : false
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Имя
            </Form.Label>
            <Col sm={9} className="align-items-center d-flex">
              <Form.Control
                required
                value={form.name}
                type="text"
                name="name"
                onChange={handleChange}
                disabled={props.status === 2 ? true : false}
                plaintext={props.status === 2 ? true : false}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Телефон
            </Form.Label>
            <Col sm={9} className="align-items-center d-flex">
              <Form.Control
                required
                value={form.mobile}
                type="text"
                name="mobile"
                onChange={handleChange}
                disabled={props.status === 2 ? true : false}
                plaintext={props.status === 2 ? true : false}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant="danger"
            onClick={() => setDeletePopup(true)}
            className={
              props.status === 2 || props.data.isComplete ? "d-none" : "d-block"
            }
          >
            Удалить
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            className={props.status === 2 ? "d-none" : "d-block"}
          >
            Сохранить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deletePopup} onHide={() => setDeletePopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удалить получателя из рассылки</Modal.Title>
        </Modal.Header>
        <Modal.Body>Подтвердите удаление</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeletePopup(false)}>
            Закрыть
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModal;
