import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Kanban from "../../../vendor/kanban/index";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import { api as leadsApi } from "../../../utils/api/api.leads";

export default function LeadsKanBan(props) {
  const [leads, setLeads] = useState(null);
  const [leadsStatus, setLeadsStatus] = useState(null);
  const [leadsSources, setLeadsSources] = useState(null);
  const [data, setData] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [finder, setFinder] = useState({
    f_name: "",
    f_mobile: "",
    f_email: "",
    f_source: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");

  const navigation = useNavigate();

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (needUpdate) {
      props.setIsLoading(true);
      leadsApi
        .getLeads({ p: 1, s: 1000000000000000 })
        .then((res) => {
          const kanban = {};
          kanban.cards = res.leads.map((item) => {
            return {
              id: item._id,
              title: item.name + (item.secondName ? " " + item.secondName : ""),
              status: item.status,
              source: item.source,
              creationDate: item.creationDate,
            };
          });
          kanban.columns = {};
          res.directory.directory.leadStatus.forEach((item) => {
            kanban.columns[item._id] = {
              id: item._id,
              title: item.value,
              cardIds: res.leads
                .filter((le) => le.status === item._id)
                .map((le) => le._id),
            };
          });
          kanban.columnOrder = res.directory.directory.leadStatus.map(
            (item) => item._id
          );
          setData(kanban);
          setLeads(res.leads);
          setLeadsStatus(res.directory.directory.leadStatus);
          setLeadsSources(res.directory.directory.leadSources);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          setNeedUpdate(false);
        });
    }
  }, [needUpdate]);

  const statusChange = (leadInfo) => {
    leadsApi
      .updateLead({
        _id: leadInfo.draggableId,
        status: leadInfo.destination.droppableId,
      })
      .then(() => setNeedUpdate(true))
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    leadsApi
      .getLeads({
        ...finder,
        p: 1,
        s: 1000000000000000,
        f_datet, f_datef,
      })
      .then((res) => {
        const kanban = {};
        kanban.cards = res.leads.map((item) => {
          return {
            id: item._id,
            title: item.name,
            status: item.status,
            source: item.source,
            creationDate: item.creationDate,
          };
        });
        kanban.columns = {};
        res.directory.directory.leadStatus.forEach((item) => {
          kanban.columns[item._id] = {
            id: item._id,
            title: item.value,
            cardIds: res.leads
              .filter((le) => le.status === item._id)
              .map((le) => le._id),
          };
        });
        kanban.columnOrder = res.directory.directory.leadStatus.map(
          (item) => item._id
        );
        setData(kanban);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    leadsApi
      .getLeads({
        p: 1,
        s: 1000000000000000,
      })
      .then((res) => {
        setFinder({
          f_name: "",
          f_mobile: "",
          f_email: "",
          f_source: "",
        });
        setF_datef("");
        setF_datet("");
        const kanban = {};
        kanban.cards = res.leads.map((item) => {
          return {
            id: item._id,
            title: item.name,
            status: item.status,
            source: item.source,
            creationDate: item.creationDate,
          };
        });
        kanban.columns = {};
        res.directory.directory.leadStatus.forEach((item) => {
          kanban.columns[item._id] = {
            id: item._id,
            title: item.value,
            cardIds: res.leads
              .filter((le) => le.status === item._id)
              .map((le) => le._id),
          };
        });
        kanban.columnOrder = res.directory.directory.leadStatus.map(
          (item) => item._id
        );
        setData(kanban);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <>
      {data !== null ? (
        <Fade>
          <>
            <Accordion className="mb-2 mt-2">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Поиск</Accordion.Header>
                <Accordion.Body>
                  <Form onSubmit={tableFilter}>
                    {/* <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Имя
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="f_name"
                          value={finder.f_name}
                          onChange={finderHandlerChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Телефон
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="f_mobile"
                          value={finder.f_mobile}
                          onChange={finderHandlerChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        E-mail
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="f_email"
                          value={finder.f_email}
                          onChange={finderHandlerChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Телефон
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="f_mobile"
                          value={finder.f_mobile}
                          onChange={finderHandlerChange}
                        />
                      </Col>
                    </Form.Group> */}
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Источник
                      </Form.Label>
                      <Col sm={10}>
                        <Select
                          placeholder=""
                          noOptionsMessage={() => "Нет источников"}
                          options={
                            leadsSources === null
                              ? {}
                              : leadsSources.map((el) => {
                                  return {
                                    value: el._id,
                                    label: el.value,
                                  };
                                })
                          }
                          value={
                            finder.f_source === ""
                              ? {}
                              : {
                                  value: leadsSources.find(
                                    (el) => el._id === finder.f_source
                                  )._id,
                                  label: leadsSources.find(
                                    (el) => el._id === finder.f_source
                                  ).value,
                                }
                          }
                          type="text"
                          name="f_source"
                          onChange={(data) =>
                            setFinder({
                              ...finder,
                              f_source: data.value,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Дата создания c
                      </Form.Label>
                      <Col sm={10}>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datef}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datef(new Date(date));
                          }}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Дата по
                      </Form.Label>
                      <Col sm={10}>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datet}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datet(new Date(date));
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Button
                      className="me-2"
                      variant="outline-secondary"
                      type="button"
                      onClick={resetFilter}
                    >
                      Очистить
                    </Button>
                    <Button variant="primary" type="submit">
                      Поиск
                    </Button>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Kanban
              data={data}
              onDragEnd={(leadsStatus) => statusChange(leadsStatus)}
              renderer={(card) => {
                return (
                  <Card key={card.id}>
                    <Card.Body className="p-0">
                      <Card.Title
                        className="p-0"
                        style={{
                          color: leadsStatus.find(
                            (el) => el._id === card.status
                          ).color,
                        }}
                      >
                        <i
                          className="bi bi-eye-fill"
                          style={{ cursor: "pointer", fontSize: 18 }}
                          onClick={() => navigation(card.id)}
                        />{" "}
                        {card.title}
                      </Card.Title>
                      {new Date(card.creationDate).toLocaleString("ru-RU", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                      <p className="m-0">
                        {(card.source !== "") & (card.source !== undefined)
                          ? leadsSources.find((el) => el._id === card.source)
                              .value
                          : ""}
                      </p>
                    </Card.Body>
                  </Card>
                );
              }}
            ></Kanban>
          </>
        </Fade>
      ) : (
        ""
      )}
    </>
  );
}
