import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Flatpickr from "react-flatpickr";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { api as accountsApi } from "../../../../../utils/api/api.accounts";

export default function FreezeModal(props) {
  const [minDate, setMinDate] = useState(undefined);
  const [date, setDate] = useState(new Date());

  const closeModal = () => {
    props.close(false);
  };
  const submit = () => {
    props.submit(date);
  };

  useEffect(() => {
    if ((props.data !== undefined) & (props.data !== null)) {
      if (props.data.isFrozen === false) {
        props.setIsLoading(true);
        accountsApi
          .prefreezeAccount({
            accountId: props.data._id,
          })
          .then((res) => {
            setMinDate(new Date(res.lastLessonDate));
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => {
            props.setIsLoading(false);
          });
      } else {
        setMinDate(0);
      }
    }
  }, [props.data]);

  return (
    <Modal show={props.isOpen} onHide={closeModal}>
      {(props.data !== undefined) & (props.data !== null) ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {props.data.isFrozen ? "Разморозить счет" : "Заморозить счет"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {minDate !== undefined ? (
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Дата c
                  </Form.Label>
                  <Col sm={10}>
                    <Flatpickr
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={date}
                      options={{
                        minDate: minDate === 0 ? undefined : minDate,
                        time_24hr: true,
                        locale: Russian,
                        disableMobile: true,
                        dateFormat: "d.m.Y",
                      }}
                      name="birthday"
                      onChange={([date]) => {
                        setDate(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              ) : (
                ""
              )}
            </Form>
          </Modal.Body>
        </>
      ) : (
        ""
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Закрыть
        </Button>
        <Button variant="info" onClick={submit}>
          Подтвердить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
