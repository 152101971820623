import React from "react";
import DocumentsTable from "./DocumentsTable";
import { Routes, Route } from "react-router-dom";
import DocumentsForm from "./DocumentsForm";

const Documents = (props) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <DocumentsTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <DocumentsForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Documents;
