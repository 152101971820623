import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {api as authApi} from "../utils/api/api.auth";
import classer from '../images/classer_logo.svg'

const Auth = (props) => {
  const [form, setForm] = React.useState({
    login: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true)
    authApi.login(form)
    .then(res => {
        props.userAuth(res)
    })
    .catch(rej => {
        props.setIsError(true)
        props.setError(rej)
    })
    .finally(() => props.setIsLoading(false))
  }

  return (
    <div
      className="position-absolute start-50 translate-middle-x d-flex flex-column justify-content-center align-items-center"
      style={{ top: "20%" }}
    >
      <img src={classer} alt="Classer" style={{width: 280, margin: '0 auto 20px'}}/>
      <div className="card" style={{ width: 300 }}>
        <Form className="d-flex flex-column" onSubmit={formSubmit}>
          <FloatingLabel label="Логин" className="m-3">
            <Form.Control
              type="text"
              placeholder="Логин"
              name="login"
              onChange={handleChange}
              value={form.login}
            />
          </FloatingLabel>
          <FloatingLabel label="Пароль" className="m-3">
            <Form.Control
              type="password"
              placeholder="Пароль"
              name="password"
              onChange={handleChange}
              value={form.password}
            />
          </FloatingLabel>
          <Button
            variant="primary"
            type="submit"
            className="ms-auto me-auto mb-3"
          >
            Войти
          </Button>
        </Form>
      </div>
      <p className="text-center mt-3 fw-lighter">Версия: 0.2.5 alpha</p>
    </div>
  );
};

export default Auth;
