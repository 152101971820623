import React, { useState, useEffect } from "react";
import { api as lessonsApi } from "../../../utils/api/api.lessons";
import { Card, ListGroup, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function Lessons(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [groups, setGroups] = useState(null);
  const [dances, setDances] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [rooms, setRooms] = useState(null);

  const navigator = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    lessonsApi
      .getLessons({
        p: 1,
        s: 100,
        sort_by: "date",
        sort_dir: 1,
        f_datef: new Date(),
        f_datet: new Date(),
      })
      .then((res) => {
        setData(
          res.lessons.filter(
            (el) => new Date(el.date).getTime() >= new Date().getTime() - 900000
          )
        );
        setRooms(res.rooms[0].directory.rooms);
        setGroups(res.groups);
        setDances(res.dances);
        setTeachers(res.teachers);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Card>
      <h5>Занятия сегодня: </h5>
      {data ? (
        data.length !== 0 ? (
          <ListGroup>
            {data
              .filter((el, ind) => ind < 4)
              .map((el) => (
                <ListGroup.Item>
                  {new Date(el.date).toLocaleString("ru-RU", {
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }) +
                    " " +
                    (el.typeGroup === 1
                      ? "Группа "
                      : el.typeGroup === 2
                      ? "Инд. Занятие "
                      : "") +
                    dances.find((le) => le._id === el.dance).name}
                  <i
                    className="bi bi-eye-fill ms-2"
                    style={{ cursor: "pointer", fontSize: 16 }}
                    onClick={() =>
                      navigator("/admin/lessons/schedule/" + el._id)
                    }
                  />
                </ListGroup.Item>
              ))}
            {data.length > 4 ? (
              <ListGroup.Item>
                <Link to="/admin/lessons/schedule">Еще...</Link>
              </ListGroup.Item>
            ) : (
              ""
            )}
          </ListGroup>
        ) : (
          <p>На сегодня занятий нет.</p>
        )
      ) : (
        ""
      )}
      <div
        className={`position-absolute align-items-center justify-content-center bg-light bg-opacity-50 flex-column ${
          isLoading ? "d-flex" : "d-none"
        }`}
        style={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 1100 }}
      >
        <Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />
        <p className="fw-light fs-2 text-center mt-4">Загрузка...</p>
      </div>
    </Card>
  );
}
