import React, { useState } from "react";
import StudentsTable from "./StudentsTable";
import { UserContext } from "../../../context/UserContext";
import StudentsForm from "./StudentsForm";
import { Routes, Route } from "react-router-dom";

const Students = (props) => {
  const userContext = React.useContext(UserContext);
  const [tableState, setTableState] = useState(null);
  
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <StudentsTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
              setTableState={setTableState}
              tableState={tableState}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <StudentsForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Students;
