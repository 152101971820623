import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../../../../context/UserContext";
import { api as moneyApi } from "../../../../../utils/api/api.money";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

export default function SellMoney(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - просмотр 2 - добавление
  const [balance, setBalance] = useState("");
  const [data, setData] = useState({
    bonuce: "",
    comment: "",
    discount: 0,
    discountAdmin: 0,
    owner: "",
    paymentType: "",
    personalDiscount: 0,
    student: "",
    sum: 0,
    totalSum: 0,
    type: 2,
  });
  const [date, setDate] = useState("");
  const [owner, setOwner] = useState(null);

  useEffect(() => {
    setData({ ...data, totalSum: data.sum });
  }, [data.sum]);

  const handleChange = (e) => {
    if ((e.target.name === "sum") & (e.target.value > balance)) {
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    props.setSelectedOperation(null);
    props.setModalSell(false);
    setData({
      bonuce: 0,
      comment: "",
      discount: 0,
      discountAdmin: 0,
      paymentType: "",
      personalDiscount: 0,
      student: "",
      sum: 0,
      totalSum: 0,
      type: 2,
      preTotalsum: 0,
    });
  };

  useEffect(() => {
    if (props.data !== null) {
      setStatus(1);
      props.setIsLoading(true);
      moneyApi
        .getOperation(props.data)
        .then((res) => {
          setData(res.operationInfo);
          setDate(res.operationInfo.date);
          setOwner(
            res.users.find((user) => user._id === res.operationInfo.owner)
          );
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      if ((props.studentId !== undefined) & (props.studentId !== "")) {
        setStatus(2);
        setDate(new Date());
        moneyApi
          .getCreationData(props.studentId)
          .then((res) => {
            setData({
              ...data,
              student: props.studentId,
            });
            setBalance(
              res.students.find((student) => student._id === props.studentId)
                .moneyBalance
            );
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    }
  }, [props.modalSell, props.studentId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    moneyApi
      .createMinuscOperation({ ...data, date: date })
      .then((res) => handleClose())
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
        props.setNeedUpdate(true);
      });
  };

  return (
    <Modal show={props.modalSell} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Списание с баланса</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Дата</Form.Label>
            <Flatpickr
              data-enable-time
              className={`form-control ${
                status === 1 && "form-control-plaintext"
              }`}
              style={{ backgroundColor: "transparent" }}
              value={date}
              options={{
                time_24hr: true,
                locale: Russian,
                disableMobile: true,
                dateFormat: "d.m.Y H:i",
              }}
              name="date"
              onChange={([date]) => {
                setDate(date);
              }}
              disabled={status === 1 ? true : false}
            />
          </Form.Group>
          <InputGroup className="mb-3" size="lg">
            <InputGroup.Text>-</InputGroup.Text>
            <Form.Control
              name="sum"
              value={data.sum}
              className="text-danger fw-bold"
              disabled={status === 1 ? true : false}
              onChange={handleChange}
            />
            <InputGroup.Text>₽</InputGroup.Text>
          </InputGroup>
          <Form.Text muted className={status === 1 ? "d-none" : "d-block"}>
            Баланс ученика: {balance}
          </Form.Text>
          <Accordion className="mt-2 mb-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Комментарий</Accordion.Header>
              <Accordion.Body>
                <Form.Control
                  name="comment"
                  value={data.comment}
                  onChange={handleChange}
                  as="textarea"
                  disabled={status === 1 ? true : false}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group>
            <InputGroup size="lg">
              <InputGroup.Text>Будет списано:</InputGroup.Text>
              <Form.Control
                name="totalSum"
                value={data.totalSum}
                className="text-danger fw-bold"
                disabled={true}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className={status === 2 ? "d-none" : "d-block"}>
            <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              className="mt-1 p-0"
              disabled
              plaintext
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant="danger"
            className={status === 1 ? "d-none" : "d-block"}
            type="submit"
          >
            Списать
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
