import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { api as usersApi } from "../../../utils/api/api.users";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const DateFormatter = (obj) => {
  return new Date(obj.value).toLocaleString("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const UsersTable = (props) => {
  const [finder, setFinder] = useState({
    f_firstname: "",
    f_secondname: "",
    f_login: "",
    f_active: "",
    f_type: "",
  });
  const [data, setData] = useState([]);
  const [dateColumns] = useState(["birthday"]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50, 100]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState({ sort_by: "firstName", sort_dir: 1 });
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "login", width: 150 },
    { columnName: "firstName", width: 150 },
    { columnName: "secondName", width: 170 },
    { columnName: "type", width: 140 },
    { columnName: "isActive", width: 150 },
  ]);
  const navigation = useNavigate();

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => navigation(row._id)}
          />
        </td>
      );
    }
    if (column.name === "isActive") {
      if (row.isActive) {
        return (
          <td className="text-center">
            <i className="bi bi-patch-plus" style={{ fontSize: 24 }} />
          </td>
        );
      } else {
        return (
          <td className="text-center">
            <i className="bi bi-patch-minus" style={{ fontSize: 24 }} />
          </td>
        );
      }
    }
    if (column.name === "type") {
      switch (row.type) {
        case 1:
          return (
            <td>
              <p>Администратор</p>
            </td>
          );
        case 2:
          return (
            <td>
              <p>Тренер</p>
            </td>
          );
        case 3:
          return (
            <td>
              <p>Ученик</p>
            </td>
          );
        case 4:
          return (
            <td>
              <p>Система</p>
            </td>
          );
      }
    }

    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    usersApi
      .getUsers({ p: currentPage + 1, s: pageSize, ...sorting })
      .then((res) => {
        setData(res.users);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const changePage = (page) => {
    props.setIsLoading(true);
    usersApi
      .getUsers({ p: page + 1, s: pageSize, ...sorting, ...finder })
      .then((res) => {
        setData(res.users);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    usersApi
      .getUsers({ p: 1, s: size, ...sorting, ...finder })
      .then((res) => {
        setData(res.users);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    usersApi
      .getUsers({
        p: 1,
        s: pageSize,
        ...finder,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.users);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    usersApi
      .getUsers({
        p: 1,
        s: pageSize,
        ...finder,
        ...sorting,
      })
      .then((res) => {
        setData(res.users);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    usersApi
      .getUsers({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_firstname: "",
          f_secondname: "",
          f_email: "",
          f_mobile: "",
          f_active: "",
        });
        setData(res.users);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "login", title: "Логин" },
    { name: "firstName", title: "Имя" },
    { name: "secondName", title: "Фамилия" },
    { name: "type", title: "Тип" },
    { name: "isActive", title: "Активность" },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Логин
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="f_login"
                      value={finder.f_login}
                      onChange={finderHandlerChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Label column sm={2}>
                  Имя
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_firstname"
                    value={finder.f_firstname}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Фамилия
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_secondname"
                    value={finder.f_secondname}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_type"
                    value={finder.f_type}
                    onChange={finderHandlerChange}
                  >
                    <option value=" "> </option>
                    <option value="1">Администратор</option>
                    <option value="2">Ученик</option>
                    <option value="3">Родитель</option>
                    <option value="4">Система</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Активен
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_active"
                    value={finder.f_active}
                    onChange={finderHandlerChange}
                  >
                    <option value="true">Да</option>
                    <option value="false">Нет</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <DateTypeProvider for={dateColumns} />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
    </>
  );
};

export default UsersTable;
