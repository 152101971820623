import React, { useEffect, useState } from "react";

import { Card, Spinner } from "react-bootstrap";

import { api as timeApi } from "../../../utils/api/Reports/api.worktime";

export default function TotalTime(props) {
    const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState({
    s: 0,
    m: 0,
    h: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    timeApi
      .getReportMe()
      .then(({ total }) => setTime(total))
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const timeRender = ({ s, m, h, d }) => {
    return `${h ? h + " часов " : ""}${m ? m + " минут " : ""}${
      s ? s + " секунд " : ""
    }`;
  };

  return (
    <Card>
      <p className="fw-light m-0">Вы провели в системе:</p>{" "}
      <p className="m-0">{time ? timeRender(time) : 0}</p>
      <div
        className={`position-absolute align-items-center justify-content-center bg-light bg-opacity-50 flex-column ${
          isLoading ? "d-flex" : "d-none"
        }`}
        style={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 1100 }}
      >
        <Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />
        <p className="fw-light fs-2 text-center mt-4">Загрузка...</p>
      </div>
    </Card>
  );
}
