import React, { useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { api as settingsApi } from "../../utils/api/api.settings";
import classer from "../../images/classer_logo.svg";

const NavBar = (props) => {
  const userContext = React.useContext(UserContext);
  const [setups, setSetups] = useState(null);

  const navigation = useNavigate();

  const handleSelect = (eventKey) => {
    navigation(eventKey);
  };
  const navigateDashboard = () => {
    navigation("/admin/");
  };

  useEffect(() => {
    props.setIsLoading(true);
    settingsApi
      .getSetups()
      .then((res) => {
        setSetups(res.setups);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  }, []);

  return (
    <>
      {setups !== null ? (
        <Navbar collapseOnSelect expand="lg" onSelect={handleSelect}>
          <Container>
            <Navbar.Brand
              onClick={navigateDashboard}
              style={{
                cursor: "pointer",
              }}
              className="fw-bold"
            >
              <img src={classer} alt="Classer" style={{ width: 130 }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                {userContext.access.students || userContext.access.documents ? (
                  <NavDropdown title="Ученики">
                    {userContext.access.students ? (
                      <NavDropdown.Item eventKey="students">
                        Ученики
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.documents ? (
                      <NavDropdown.Item eventKey="documents">
                        Документы
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.lessons || userContext.access.groups ? (
                  <NavDropdown title="Занятия">
                    {userContext.access.lessons ? (
                      <NavDropdown.Item eventKey="lessons/schedule">
                        Занятия
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.groups ? (
                      <NavDropdown.Item eventKey="groups">
                        Группы
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.leads ||
                userContext.access.tasks ||
                userContext.access.actionsCRM ? (
                  <NavDropdown title="CRM">
                    {userContext.access.leads ? (
                      <NavDropdown.Item eventKey="leads/table">
                        Лиды
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.actionsCRM ? (
                      <NavDropdown.Item eventKey="actions-crm/calendar">
                        Действия по лидам
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.tasks ? (
                      <NavDropdown.Item eventKey="tasks/table">
                        Задачи
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.bonuceOperations ||
                userContext.access.creditOperations ||
                userContext.access.moneyOperations ||
                userContext.access.lessonsOperations ||
                userContext.access.financeOperations ? (
                  <NavDropdown title="Операции">
                    {userContext.access.moneyOperations ? (
                      <NavDropdown.Item eventKey="operation/money">
                        Деньги
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.creditOperations ? (
                      <NavDropdown.Item eventKey="operation/credits">
                        Долги
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.bonuceOperations &
                    setups.bonuces.isOn ? (
                      <NavDropdown.Item eventKey="operation/bonuces">
                        Бонусы
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.financeOperations ? (
                      <NavDropdown.Item eventKey="operation/finance">
                        Финансы
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.teachers || userContext.access.salaries ? (
                  <NavDropdown title="Педагоги">
                    {userContext.access.teachers ? (
                      <NavDropdown.Item eventKey="teachers">
                        Педагоги
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.salaries ? (
                      <NavDropdown.Item eventKey="salaries">
                        Начисления
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.whatsapp ? (
                  <NavDropdown title="Рассылки">
                    {userContext.access.whatsapp ? (
                      <NavDropdown.Item eventKey="whatsapp">
                        WhatsApp
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.dances ||
                userContext.access.abonements ||
                userContext.access.discounts ? (
                  <NavDropdown title="Справочники">
                    {userContext.access.dances ? (
                      <NavDropdown.Item eventKey="dances">
                        Направления
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.abonement ? (
                      <NavDropdown.Item eventKey="abonements">
                        Абонементы
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.discounts ? (
                      <NavDropdown.Item eventKey="discounts">
                        Скидки
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.REPworkTime ||
                userContext.access.REPfinanceStatistic ? (
                  <NavDropdown title="Отчеты">
                    {userContext.access.REPworkTime ? (
                      <NavDropdown.Item eventKey="reports/worktime">
                        Отчет рабочего времени
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.REPfinanceStatistic ? (
                      <NavDropdown.Item eventKey="reports/finance">
                        Финансовый отчет
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
                {userContext.access.users || userContext.access.settings ? (
                  <NavDropdown title="Настройки">
                    {userContext.access.users ? (
                      <NavDropdown.Item eventKey="users">
                        Пользователи
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {userContext.access.settings ? (
                      <NavDropdown.Item eventKey="settings">
                        Настройки
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                ) : (
                  ""
                )}
              </Nav>
              <Nav>
                <Nav.Link eventKey="me">
                  {userContext.userName !== undefined
                    ? userContext.userName
                    : "Пользователь"}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        ""
      )}
    </>
  );
};

export default NavBar;
