import React from "react";
import { UserContext } from "../../../context/UserContext";
import GroupTable from "./GroupTable";
import GroupForm from "./GroupForm";
import { Routes, Route } from "react-router-dom";

const Dances = (props) => {
const userContext = React.useContext(UserContext);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <GroupTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <GroupForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Dances;
