import React from "react";
import { UserContext } from "../../context/UserContext";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import Students from "./Students/Students";
import Lessons from "./Lessons/Lessons";
import Dances from "./Dances/Dances";
import Abonements from "./Abonements/Abonements";
import Groups from "./Groups/Groups";
import Money from "./Operations/Money/Money";
import Documents from "./Documents/Documents";
import Salaries from "./Salaries/Salaries";
import Users from "./Users/Users";
import User from "./User/User";
import NavBar from "./NavBar";
import Teachers from "./Teachers/Teachers";
import Discounts from "./Discounts/Discounts";
import Credits from "./Operations/Credits/Credits";
import Settings from "./Settings/Settings";
import Bonuces from "./Operations/Bonuces/Bonuces";
import Leads from "./Leads/Leads";
import Tasks from "./Tasks/Tasks";
import WhatsApp from "./Whatsapp/Whatsapp";
import ActionCRM from "./ActionCRM/ActionCRM"

import Worktime from "./Reports/Worktime/Worktime";
import Finance from "./Operations/Finance/Finance";
import FinanceStatistic from "./Reports/FinanceStatistic/FinanceStatistic";
import Dashboard from "./Dashboard/Dashboard";


export default function Admin(props) {
  const userContext = React.useContext(UserContext);

  return (
    <>
      <NavBar
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
      />
      <div className="p-2">
        <Routes>
        <Route
            path="/"
            element={
              <Dashboard
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/students/*"
            element={
              <Students
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/lessons/*"
            element={
              <Lessons
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/dances/*"
            element={
              <Dances
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/abonements/*"
            element={
              <Abonements
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/groups/*"
            element={
              <Groups
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/operation/money/*"
            element={
              <Money
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/documents/*"
            element={
              <Documents
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/teachers/*"
            element={
              <Teachers
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/salaries/*"
            element={
              <Salaries
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/users/*"
            element={
              <Users
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/me/"
            element={
              <User
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
                userLogOut={props.userLogOut}
              />
            }
          />
          <Route
            path="/discounts/*"
            element={
              <Discounts
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/operation/credits/*"
            element={
              <Credits
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/operation/finance/*"
            element={
              <Finance
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/settings/*"
            element={
              <Settings
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/operation/bonuces/*"
            element={
              <Bonuces
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/leads/*"
            element={
              <Leads
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/tasks/*"
            element={
              <Tasks
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/whatsapp/*"
            element={
              <WhatsApp
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/actions-crm/*"
            element={
              <ActionCRM
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="/reports/worktime/"
            element={
              <Worktime
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
          <Route
            path="reports/finance/"
            element={
              <FinanceStatistic
                setIsLoading={props.setIsLoading}
                setIsError={props.setIsError}
                setError={props.setError}
              />
            }
          />
        </Routes>
      </div>
      <div className="d-flex justify-content-end m-3 fw-light fs-7 flex-wrap">
        Поддержка: <a href="mailto:support@classer.ru">support@classer.ru </a>&nbsp;© Classer {new Date().getYear() + 1900}
      </div>
    </>
  );
}
