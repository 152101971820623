class Api {
    _checkResponse(res) {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    }
  
    getMessages(params) {
      let querry = "?";
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if (
          (params[key] !== undefined) &
          (params[key] !== "") &
          (params[key] !== null)
        ) {
          querry += `${key}=${params[key]}&`;
        }
      });
      return fetch("/api/int/wap/" + querry, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    getMessage(id) {
      return fetch("/api/int/wap/" + id, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    createMessage(message) {
      const keys = Object.keys(message);
      keys.forEach((key) => {
        if (message[key] === undefined || message[key] === "") {
          delete message[key];
        }
      });
      message.users.forEach(item => {
        if (item.leadId === "") {
          item.leadId = undefined;
        }
        if (item._id === "") {
          item._id = undefined;
        }
      })
      return fetch("/api/int/wap/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      }).then((res) => this._checkResponse(res));
    }
  
    updateMessage(message) {
      const keys = Object.keys(message);
      keys.forEach((key) => {
        if (message[key] === undefined) {
          delete message[key];
        }
      });
      return fetch("/api/int/wap/", {
        method: "PATCH",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      }).then((res) => this._checkResponse(res));
    }
  
    getCreationData() {
      return fetch("/api/int/wap/create-data", {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }

    postNewMessage(message) {
      const keys = Object.keys(message);
      keys.forEach((key) => {
        if (message[key] === undefined || message[key] === "") {
          delete message[key];
        }
      });
        return fetch("/api/int/wap/new-message", {
          method: "PUT",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(message),
        }).then((res) => this._checkResponse(res));
    }

    addManyUsers(data) {
      return fetch("/api/int/wap/add-many", {
        method: "PUT",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => this._checkResponse(res));
  }

    getQr() {
        return fetch("/api/int/wap/getqr", {
          method: "PUT",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }).then((res) => this._checkResponse(res));
    }

    postUser(user) {
      const keys = Object.keys(user);
      keys.forEach((key) => {
        if (user[key] === undefined || user[key] === "") {
          delete user[key];
        }
      });
      return fetch("/api/int/wap/user", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }).then((res) => this._checkResponse(res));
    }
  
    editUser(user) {
      const keys = Object.keys(user);
      keys.forEach((key) => {
        if (user[key] === undefined) {
          delete user[key];
        }
      });
      return fetch("/api/int/wap/user", {
        method: "PATCH",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }).then((res) => this._checkResponse(res));
    }
  
    deleteUser(user) {
      const keys = Object.keys(user);
      keys.forEach((key) => {
        if (user[key] === undefined || user[key] === "") {
          delete user[key];
        }
      });
      return fetch("/api/int/wap/user", {
        method: "DELETE",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }).then((res) => this._checkResponse(res));
    }
  }
  
  const api = new Api();
  
  export { api };
  