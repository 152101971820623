import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../../../../context/UserContext";
import { api as moneyApi } from "../../../../../utils/api/api.money";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

export default function AddMoney(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - просмотр 2 - добавление
  const [payments, setPayments] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [bonuce, setBonuce] = useState({});
  const [maxBonuce, setMaxBonuce] = useState(0);
  const [bonuceBalance, setBonuceBalance] = useState({});
  const [activeDiscount, setActiveDiscount] = useState({});
  const [totalSum, setTotalSum] = useState(0);
  const [owner, setOwner] = useState(null);
  const [data, setData] = useState({
    bonuce: "",
    comment: "",
    discount: 0,
    discountAdmin: 0,
    owner: "",
    paymentType: "",
    personalDiscount: 0,
    student: "",
    sum: 0,
    totalSum: 0,
    type: 1,
  });
  const [date, setDate] = useState("");

  useEffect(() => {
    let discAdm = data.discountAdmin !== 0 ? data.discountAdmin : 0;
    let disc =
      activeDiscount.money !== undefined
        ? activeDiscount.money
        : activeDiscount.percent !== undefined
        ? data.sum * (activeDiscount.percent / 100)
        : 0;
    let discPers = data.sum * (data.personalDiscount / 100);
    let biggestDisc = disc > discPers ? disc : discPers;
    let totalDisc = (discAdm !== 0) & (discAdm !== "") ? discAdm : biggestDisc;
    setTotalSum(data.sum - totalDisc);
    if (bonuce.settings !== undefined) {
      setMaxBonuce(
        bonuceBalance < (data.sum - totalDisc) * (bonuce.settings.maxPay / 100)
          ? bonuceBalance
          : (data.sum - totalDisc) * (bonuce.settings.maxPay / 100)
      );
    }
  }, [data, activeDiscount, bonuce]);

  const handleChange = (e) => {
    if (
      e.target.name === "discountAdmin" ||
      e.target.name === "discount" ||
      e.target.name === "sum"
    ) {
      setData({ ...data, bonuce: 0 });
    }
    if (e.target.name === "discountAdmin") {
      setData({ ...data, discount: "" });
      setActiveDiscount({});
    }
    if (e.target.name === "discount") {
      setData({ ...data, discountAdmin: 0 });
    }

    if ((e.target.name === "bonuce") & (e.target.value > maxBonuce)) {
    } else if (
      (e.target.name === "discountAdmin") &
      (e.target.value > data.sum - data.bonuce)
    ) {
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    props.setSelectedOperation(null);
    setData({
      bonuce: 0,
      comment: "",
      discount: 0,
      discountAdmin: 0,
      paymentType: "",
      personalDiscount: 0,
      student: "",
      sum: 0,
      totalSum: 0,
      type: 1,
      preTotalsum: 0,
    });
    props.setModalAdd(false);
  };

  useEffect(() => {
    if (props.data !== null) {
      setStatus(1);
      props.setIsLoading(true);
      moneyApi
        .getOperation(props.data)
        .then((res) => {
          setData(res.operationInfo);
          setDate(res.operationInfo.date);
          setPayments(res.paymentTypes[0].directory.paymentTypes);
          setOwner(
            res.users.find((user) => user._id === res.operationInfo.owner)
          );
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      if ((props.studentId !== undefined) & (props.studentId !== "")) {
        setStatus(2);
        setDate(new Date());
        moneyApi
          .getCreationData(props.studentId)
          .then((res) => {
            setData({
              ...data,
              student: props.studentId,
              personalDiscount: res.students.find(
                (el) => el._id === props.studentId
              ).personalDiscount,
            });
            setDiscounts(res.discounts);
            setPayments(res.paymentTypes[0].directory.paymentTypes);
            setBonuce(res.paymentTypes[0].setups.bonuces);

            setBonuceBalance(
              res.students.find((student) => student._id === props.studentId)
                .bonuceBalance
            );
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    }
  }, [props.modalAdd, props.studentId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    moneyApi
      .createPlusOperation({ ...data, date: date })
      .then((res) => handleClose())
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
        props.setNeedUpdate(true);
      });
  };

  return (
    <Modal show={props.modalAdd} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Пополнение баланса</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Дата</Form.Label>
            <Flatpickr
              data-enable-time
              className={`form-control ${
                status === 1 && "form-control-plaintext"
              }`}
              style={{ backgroundColor: "transparent" }}
              value={date}
              options={{
                time_24hr: true,
                locale: Russian,
                disableMobile: true,
                dateFormat: "d.m.Y H:i",
              }}
              name="date"
              onChange={([date]) => {
                setDate(date);
              }}
              disabled={status === 1 ? true : false}
            />
          </Form.Group>
          <InputGroup className="mb-3" size="lg">
            <InputGroup.Text>+</InputGroup.Text>
            <Form.Control
              name="sum"
              value={data.sum}
              className="text-success fw-bold"
              disabled={status === 1 ? true : false}
              onChange={handleChange}
            />
            <InputGroup.Text>₽</InputGroup.Text>
          </InputGroup>
          <Form.Group>
            <Form.Select
              name="paymentType"
              value={data.paymentType}
              onChange={handleChange}
              disabled={status === 1 ? true : false}
            >
              <option value="">Выберите тип оплаты</option>
              {payments[0] !== undefined &&
                payments.map((paymentType) => (
                  <option key={paymentType._id} value={paymentType._id}>
                    {paymentType.value}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Accordion className="mt-2 mb-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Скидки</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mt-2">
                  <Form.Label>Персональная скидка</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>-</InputGroup.Text>
                    <Form.Control
                      value={data.personalDiscount}
                      className="fw-bold"
                      disabled
                    />
                    <InputGroup.Text>
                      {status === 1 ? "₽" : "%"}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label>Скидка</Form.Label>
                  <Form.Select
                    name="discount"
                    value={data.discount}
                    onChange={(e) => {
                      handleChange(e);
                      e.target.value !== ""
                        ? setActiveDiscount(
                            discounts.find(
                              (discount) => discount._id === e.target.value
                            )
                          )
                        : setActiveDiscount({});
                    }}
                    disabled={status === 1 ? true : false}
                  >
                    <option value=""> </option>
                    {discounts !== [] &&
                      discounts.map((discount) => (
                        <option key={discount._id} value={discount._id}>
                          {discount.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <InputGroup className="mt-1">
                  <InputGroup.Text>-</InputGroup.Text>
                  <Form.Control
                    value={
                      activeDiscount !== {}
                        ? activeDiscount.money !== undefined
                          ? activeDiscount.money
                          : activeDiscount.percent !== undefined
                          ? activeDiscount.percent
                          : 0
                        : 0
                    }
                    className="fw-bold"
                    disabled
                  />
                  <InputGroup.Text>
                    {activeDiscount.money !== undefined
                      ? "₽"
                      : activeDiscount.percent !== undefined
                      ? "%"
                      : ""}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Group
                  className={`mt-2 ${
                    userContext.access.discounts ? "d-block" : "d-none"
                  }`}
                >
                  <Form.Label>Ручная скидка</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>-</InputGroup.Text>
                    <Form.Control
                      name="discountAdmin"
                      value={data.discountAdmin}
                      onChange={handleChange}
                      className="fw-bold"
                      disabled={
                        status === 1
                          ? true
                          : userContext.access.discounts
                          ? false
                          : true
                      }
                    />
                    <InputGroup.Text>₽</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  className={`mt-2 ${
                    bonuce !== {} && bonuce.isOn ? "d-block" : "d-none"
                  }`}
                >
                  <Form.Label>Оплата бонусами</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>-</InputGroup.Text>
                    <Form.Control
                      name="bonuce"
                      value={data.bonuce}
                      onChange={handleChange}
                      className="fw-bold"
                      disabled={status === 1 ? true : false}
                    />
                  </InputGroup>
                  <Form.Text
                    muted
                    className={status === 1 ? "d-none" : "d-block"}
                  >
                    Доступно бонусов к списанию: {maxBonuce}
                  </Form.Text>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Комментарий</Accordion.Header>
              <Accordion.Body>
                <Form.Control
                  name="comment"
                  value={data.comment}
                  onChange={handleChange}
                  as="textarea"
                  disabled={status === 1 ? true : false}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group>
            <InputGroup size="lg">
              <InputGroup.Text>К оплате:</InputGroup.Text>
              <Form.Control
                name="totalSum"
                value={status === 1 ? data.totalSum : totalSum - data.bonuce}
                className="text-success fw-bold"
                disabled={true}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className={status === 2 ? "d-none" : "d-block"}>
            <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              className="mt-1 p-0"
              disabled
              plaintext
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant="success"
            className={status === 1 ? "d-none" : "d-block"}
            type="submit"
          >
            Пополнить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
