class Api {

    _checkResponse(res) {
        if (res.ok) {
            return res.json()
        }
        return Promise.reject(res)
    }

    login({login, password}){
        return fetch('/api/auth/login',{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: login,
                password: password
            })
        })
        .then(res => this._checkResponse(res))
    }

}

const api = new Api()

export {api}