import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import { api as dancesApi } from "../../../utils/api/api.dances";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "flatpickr/dist/themes/light.css";

export default function StudentsForm(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - new, 2 - open, 3 - edit
  const [dance, setDance] = useState({
    _id: "",
    name: "",
    age: "",
    type: "",
    comment: "",
    typeGroup: "",
    isActive: "",
    owner: "",
  });
  const [owner, setOwner] = useState({});
  const [validated, setValidated] = useState(false);
  const navigation = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    setDance({ ...dance, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id === "new") {
      setStatus(1);
    } else {
      setStatus(2);
      props.setIsLoading(true);
      dancesApi
        .getDance(id)
        .then((res) => {
          setDance({
            _id: res.dance._id,
            name: res.dance.name,
            age: res.dance.age,
            type: res.dance.type,
            comment: res.dance.comment,
            typeGroup: res.dance.typeGroup,
            isActive: res.dance.isActive,
            owner: res.dance.owner,
          });
          setOwner(res.users.find((user) => user._id === res.dance.owner));
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, []);

  const onSubmit = (e) => {
    if (e.currentTarget.checkValidity() === true) {
      e.preventDefault();
      props.setIsLoading(true);
      if (status === 1) {
        dancesApi
          .createDance(dance)
          .then((res) => {
            navigation("/admin/dances/" + res.newDance._id);
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      } else if (status === 3) {
        dancesApi
          .updateDance(dance)
          .then((res) => {
            dancesApi
              .getDance(dance._id)
              .then((res) => {
                setDance({
                  _id: res.dance._id,
                  name: res.dance.name,
                  age: res.dance.age,
                  type: res.dance.type,
                  comment: res.dance.comment,
                  typeGroup: res.dance.typeGroup,
                  isActive: res.dance.isActive,
                  owner: res.dance.owner,
                });
              })
              .catch((rej) => {
                props.setIsError(true);
                props.setError(rej);
              });
            setStatus(2);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <Form onSubmit={onSubmit} validated={validated}>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row className="g-2" style={{ maxWidth: 630 }}>
              <Col sm>
                <Form.Group className="mb-3">
                  <Form.Label>Название</Form.Label>
                  <Form.Control
                    required
                    value={dance.name}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    disabled={status === 2 ? true : false}
                    plaintext={status === 2 ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <i
              className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                status === 2 ? "d-flex" : "d-none"
              }`}
              style={{ fontSize: 36, cursor: "pointer" }}
              onClick={() => setStatus(3)}
            ></i>
          </Card.Title>
          <Card.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Возраст
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="age"
                  value={dance.age}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Тип направления
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  required
                  name="typeGroup"
                  value={dance.typeGroup}
                  onChange={handleChange}
                  disabled={status !== 1 ? true : false}
                  plaintext={status !== 1 ? true : false}
                >
                  <option value=""></option>
                  <option value="1">Групповое</option>
                  <option value="2">Индивидуальное</option>
                  <option value="3">Другое</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Комментарий
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="comment"
                  onChange={handleChange}
                  value={dance.comment}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Активность
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  required
                  name="isActive"
                  value={dance.isActive}
                  onChange={handleChange}
                  disabled={status === 2 ? true : false}
                  plaintext={status === 2 ? true : false}
                >
                  <option value="true">Да</option>
                  <option value="false">Нет</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Card.Text>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className={`${status === 2 ? "d-none" : "d-block"}`}
            >
              Сохранить
            </Button>
          </div>
        </Card.Body>
        <Form.Group className={`m-3 ${status === 2 ? "d-block" : "d-none"}`}>
          <Form.Label>Создал пользователь</Form.Label>
          <Form.Control
            value={owner !== {} ? owner.firstName + " " + owner.secondName : ""}
            type="text"
            name="owner"
            disabled
            plaintext
          />
        </Form.Group>
      </Card>
    </Form>
  );
}
