import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../../../context/UserContext";
import { api as financeApi } from "../../../../utils/api/api.financeOperations";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";

export default function AddMoney(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - добавление 2 - редактирование
  const [payments, setPayments] = useState([]);
  const [users, setUsers] = useState(null);
  const [owner, setOwner] = useState(null);
  const [data, setData] = useState({
    paymentType: "",
    sum: "",
    comment: "",
  });
  const [date, setDate] = useState(new Date());
  const [typeOp, setTypeOp] = useState(0);

  const { id } = useParams();
  const navigation = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    data.sum >= 0 ? setTypeOp(1) : setTypeOp(2);
  }, [data]);

  const handleClose = () => {
    setData({
      paymentType: "",
      sum: "",
      comment: "",
    });
    setDate("");
    navigation(-1);
  };

  useEffect(() => {
    if (id !== "new") {
      setStatus(2);
      props.setIsLoading(true);
      financeApi
        .getOperation(id)
        .then(({ operationInfo, users, paymentTypes }) => {
          setData(operationInfo);
          setDate(operationInfo.date);
          setPayments(paymentTypes[0].directory.paymentTypes);
          setUsers(users);
          setOwner(users.find((user) => user._id === operationInfo.owner));
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      setStatus(1);
      setDate(new Date());
      financeApi
        .getCreationData()
        .then((res) => {
          setUsers(res.users);
          setPayments(res.paymentTypes[0].directory.paymentTypes);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, [id]);

  const handleSubmit = (e) => {
    if (status === 1) {
      e.preventDefault();
      props.setIsLoading(true);
      financeApi
        .createOperation({ ...data, date: date })
        .then((res) => handleClose())
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    } else if (status === 2) {
      e.preventDefault();
      props.setIsLoading(true);
      financeApi
        .editOperation({ ...data, date: date })
        .then((res) => handleClose())
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  };

  return (
    <Modal show={true} onHide={handleClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Добавление операции</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Дата</Form.Label>
          <Flatpickr
            data-enable-time
            className={`form-control`}
            style={{ backgroundColor: "transparent" }}
            value={date}
            options={{
              time_24hr: true,
              locale: Russian,
              disableMobile: true,
            }}
            name="date"
            onChange={([date]) => {
              setDate(date);
            }}
          />
        </Form.Group>
        <InputGroup className="mb-3" size="lg">
          <Form.Control
            name="sum"
            value={data.sum}
            className={`${
              typeOp === 1 ? "text-success" : typeOp === 2 ? "text-danger" : ""
            } fw-bold`}
            onChange={handleChange}
          />
          <InputGroup.Text>₽</InputGroup.Text>
        </InputGroup>
        <Form.Group>
          <Form.Select
            name="paymentType"
            value={data.paymentType}
            onChange={handleChange}
          >
            <option value="">Выберите тип оплаты</option>
            {payments[0] !== undefined &&
              payments.map((paymentType) => (
                <option key={paymentType._id} value={paymentType._id}>
                  {paymentType.value}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Accordion
          className="mt-2 mb-2"
          defaultActiveKey={["0"]}
          alwaysOpen={"0"}
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Назначение</Accordion.Header>
            <Accordion.Body>
              <Form.Control
                name="comment"
                value={data.comment}
                onChange={handleChange}
                as="textarea"
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Form.Group className={status === 2 ? "d-none" : "d-block"}>
          <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
          <Form.Control
            value={
              owner !== null ? owner.firstName + " " + owner.secondName : ""
            }
            type="text"
            name="owner"
            className="mt-1 p-0"
            disabled
            plaintext
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
        <Button
          variant={typeOp === 1 ? "success" : typeOp === 2 ? "danger" : ""}
          type="submit"
          onClick={handleSubmit}
        >
          {status === 1 ? 'Создать' : 'Сохранить'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
