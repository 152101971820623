import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { api as studentsApi } from "../../../utils/api/api.students";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const StudentsTable = (props) => {
  const [finder, setFinder] = useState({
    f_firstname: "",
    f_secondname: "",
    f_email: "",
    f_card: "",
    f_mobile: "",
    f_active: "true",
  });
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50, 100]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState({ sort_by: "firstName", sort_dir: 1 });
  const [needUpdate, setNeedUpdate] = useState(true);
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "firstName", width: 150 },
    { columnName: "secondName", width: 150 },
    { columnName: "email", width: 170 },
    { columnName: "cardId", width: 150 },
    { columnName: "birthday", width: 140 },
    { columnName: "mobile", width: 170 },
    { columnName: "isActive", width: 150 },
  ]);
  const navigation = useNavigate();

  useEffect(() => {
    if (!props.tableState) return;
    setCurrentPage(props.tableState.currentPage);
    setPageSize(props.tableState.pageSize);
    setSorting(props.tableState.sorting);
    setFinder(props.tableState.finder);
    setNeedUpdate(true);
  }, [props.tableState]);

  const goToStudent = (id) => {
    navigation(id);
    props.setTableState({
      currentPage,
      pageSize,
      sorting,
      finder,
    });
  };

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => goToStudent(row._id)}
          />
        </td>
      );
    }

    if (column.name === "birthday") {
      if (row.birthday) {
        return (
          <td>
            {(row.birthday !== null) &
            (row.birthday !== undefined) &
            (row.birthday !== "")
              ? new Date(row.birthday).toLocaleString("ru-RU", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              : ""}
          </td>
        );
      }
    }

    if (column.name === "isActive") {
      if (row.isActive) {
        return (
          <td className="text-center">
            <i className="bi bi-patch-plus" style={{ fontSize: 24 }} />
          </td>
        );
      } else {
        return (
          <td className="text-center">
            <i className="bi bi-patch-minus" style={{ fontSize: 24 }} />
          </td>
        );
      }
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    studentsApi
      .getStudents({ p: currentPage + 1, s: pageSize, ...sorting })
      .then((res) => {
        setData(res.students);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
        setNeedUpdate(false);
      });
  }, [needUpdate]);

  const changePage = (page) => {
    props.setIsLoading(true);
    studentsApi
      .getStudents({ p: page + 1, s: pageSize, ...sorting, ...finder })
      .then((res) => {
        setData(res.students);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    studentsApi
      .getStudents({ p: 1, s: size, ...sorting, ...finder })
      .then((res) => {
        setData(res.students);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    studentsApi
      .getStudents({
        p: 1,
        s: pageSize,
        ...finder,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.students);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    studentsApi
      .getStudents({
        p: 1,
        s: pageSize,
        ...finder,
        ...sorting,
      })
      .then((res) => {
        setData(res.students);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    studentsApi
      .getStudents({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_firstname: "",
          f_secondname: "",
          f_email: "",
          f_card: "",
          f_mobile: "",
          f_active: "",
        });
        setData(res.students);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "firstName", title: "Имя" },
    { name: "secondName", title: "Фамилия" },
    { name: "email", title: "E-mail" },
    { name: "cardId", title: "Card" },
    { name: "birthday", title: "День рождения" },
    { name: "mobile", title: "Номер телефона" },
    { name: "isActive", title: "Активность" },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      <Accordion className="mb-2">
        {/* <Accordion.Item eventKey="0">
          <Accordion.Header>Поиск по карте</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
            </Form>
          </Accordion.Body>
        </Accordion.Item> */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Имя
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_firstname"
                    value={finder.f_firstname}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Фамилия
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_secondname"
                    value={finder.f_secondname}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  E-mail
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_email"
                    value={finder.f_email}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Card
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_card"
                    value={finder.f_card}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Телефон
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_mobile"
                    value={finder.f_mobile}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Активен
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_active"
                    value={finder.f_active}
                    onChange={finderHandlerChange}
                  >
                    <option value="true">Да</option>
                    <option value="false">Нет</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
    </>
  );
};

export default StudentsTable;
