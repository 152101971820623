import React from "react";
import TeachersTable from "./TeachersTable";
import { UserContext } from "../../../context/UserContext";
import TeachersForm from "./TeachersForm";
import { Routes, Route } from "react-router-dom";

const Teachers = (props) => {
const userContext = React.useContext(UserContext);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <TeachersTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <TeachersForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Teachers;
