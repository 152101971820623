import React, { useContext, useState, useEffect } from "react";
import { api as settingsApi } from "../../../utils/api/api.settings";
import { api as wapApi } from "../../../utils/api/api.whatsapp";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/themes/light.css";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";

export default function Settings(props) {
  const [status, setStatus] = useState(1); // 1 - open, 2 - edit

  const [setups, setSetups] = useState(null);
  const [api, setApi] = useState(null);
  const [requisites, setRequisites] = useState(null);
  const [directory, setDirectory] = useState(null);
  const [email, setEmail] = useState(null);
  const [wapQr, setWapQr] = useState(null);

  const [users, setUsers] = useState(null);

  const [needUpdate, setNeedUpdate] = useState(true);
  const [showButtonDir, setShowButtonDir] = useState(null);
  useEffect(() => {
    if (needUpdate) {
      props.setIsLoading(true);
      settingsApi
        .getSettings()
        .then((res) => {
          setStatus(1);
          setSetups(res.settings.setups);
          setRequisites(res.settings.requisites);
          setDirectory(res.settings.directory);
          setEmail(res.settings.email);
          setApi(res.settings.api);
          setUsers(res.users);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
          setNeedUpdate(false);
        });
    }
  }, [needUpdate]);

  const handleChangeApi = (e) => {
    setApi({
      ...api,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeSetups = (e) => {
    setSetups({
      ...setups,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeEmail = (e) => {
    setEmail({
      ...email,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeRequisites = (e) => {
    setRequisites({
      ...requisites,
      [e.target.name]: e.target.value,
    });
  };

  const directorySave = (data) => {
    props.setIsLoading(true);
    settingsApi
      .editDirec(data)
      .then((res) => {
        setNeedUpdate(true);
        setShowButtonDir(null);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };
  const directoryAdd = (data) => {
    props.setIsLoading(true);
    settingsApi
      .postDirec(data)
      .then((res) => {
        setNeedUpdate(true);
        setShowButtonDir(null);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };
  const settingsSave = (data) => {
    props.setIsLoading(true);
    settingsApi
      .editSettings({ setups, requisites, email, api })
      .then((res) => {
        setNeedUpdate(true);
        setShowButtonDir(null);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };
  const addFieldDoc = (data) => {
    props.setIsLoading(true);
    settingsApi
      .addFieldDoc({ name: data.name, field: data.field })
      .then((res) => {
        setNeedUpdate(true);
        setShowButtonDir(null);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };
  return (
    <>
      {(setups !== null) & (requisites !== null) ? (
        <Form>
          <Card>
            <Card.Body>
              <Card.Title>
                {/* <i
                      className="bi bi-chevron-compact-left me-3"
                      style={{ fontSize: 30, cursor: "pointer" }}
                      onClick={() => navigation(-1)}
                    /> */}
                Настройки
                <i
                  className={`bi bi-pencil-square position-absolute top-0 end-0 m-3 ${
                    status === 1 ? "d-flex" : "d-none"
                  }`}
                  style={{ fontSize: 36, cursor: "pointer" }}
                  onClick={() => setStatus(2)}
                ></i>
              </Card.Title>
              <Card.Text>
                <Accordion defaultActiveKey={["0"]} alwaysOpen className="mt-3">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Основные</Accordion.Header>
                    <Accordion.Body>
                      <Accordion
                        defaultActiveKey={["0"]}
                        alwaysOpen
                        className="mt-3"
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Установки</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={6}>
                                Время начала дня в расписании
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  name="dayStart"
                                  onChange={handleChangeSetups}
                                  value={setups.dayStart}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={6}>
                                Время конца дня в расписании
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  name="dayEnd"
                                  onChange={handleChangeSetups}
                                  value={setups.dayEnd}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={6}>
                                Использовать методичку по умолчанию
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Check
                                  disabled={status === 1 ? true : false}
                                  type="switch"
                                  name="methodic"
                                  onChange={() => {
                                    setSetups({
                                      ...setups,
                                      methodic: !setups.methodic,
                                    });
                                  }}
                                  checked={setups.methodic === true ? "on" : ""}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={6}>
                                Использовать бонусную систему
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Check
                                  disabled={status === 1 ? true : false}
                                  type="switch"
                                  onChange={() => {
                                    setSetups({
                                      ...setups,
                                      bonuces: {
                                        ...setups.bonuces,
                                        isOn: !setups.bonuces.isOn,
                                      },
                                    });
                                  }}
                                  checked={
                                    setups.bonuces.isOn === true ? "on" : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={6}>
                                Сколько процентов начислять от суммы пополнения
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  onChange={(e) => {
                                    setSetups({
                                      ...setups,
                                      bonuces: {
                                        ...setups.bonuces,
                                        settings: {
                                          ...setups.bonuces.settings,
                                          add: e.target.value,
                                        },
                                      },
                                    });
                                  }}
                                  value={setups.bonuces.settings.add}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={6}>
                                Сколько процентов максимум можно оплатить
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  onChange={(e) => {
                                    setSetups({
                                      ...setups,
                                      bonuces: {
                                        ...setups.bonuces,
                                        settings: {
                                          ...setups.bonuces.settings,
                                          maxPay: e.target.value,
                                        },
                                      },
                                    });
                                  }}
                                  value={setups.bonuces.settings.maxPay}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={6}>
                                Сбрасывать баланс занятий при покупке абонемента
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Check
                                  disabled={status === 1 ? true : false}
                                  type="switch"
                                  onChange={() => {
                                    setSetups({
                                      ...setups,
                                      resetLessonsAbonementThree:
                                        !setups.resetLessonsAbonementThree,
                                    });
                                  }}
                                  checked={
                                    setups.resetLessonsAbonementThree === true
                                      ? "on"
                                      : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Реквизиты</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Название
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  onChange={handleChangeRequisites}
                                  value={requisites.name}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Адрес
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="text"
                                  name="adress"
                                  onChange={handleChangeRequisites}
                                  value={requisites.adress}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                ИНН
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="text"
                                  name="inn"
                                  onChange={handleChangeRequisites}
                                  value={requisites.inn}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Телефон
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="text"
                                  name="phones"
                                  onChange={handleChangeRequisites}
                                  value={requisites.phones}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Email</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Host
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="text"
                                  name="host"
                                  onChange={handleChangeEmail}
                                  value={email.host}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Port
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="text"
                                  name="port"
                                  onChange={handleChangeEmail}
                                  value={email.port}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                User
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="text"
                                  name="user"
                                  onChange={handleChangeEmail}
                                  value={email.user}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Password
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  onChange={handleChangeEmail}
                                  value={email.password}
                                  disabled={status === 1 ? true : false}
                                  plaintext={status === 1 ? true : false}
                                />
                              </Col>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        {api ? (
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Интеграции</Accordion.Header>
                            <Accordion.Body>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4}>
                                  Ответственный по заявкам с сайта
                                </Form.Label>
                                <Col sm={8}>
                                  <Select
                                    placeholder=""
                                    noOptionsMessage={() =>
                                      "Нет доступных пользователей"
                                    }
                                    options={
                                      users === null
                                        ? {}
                                        : users.map((el) => {
                                            return {
                                              value: el._id,
                                              label:
                                                el.firstName +
                                                " " +
                                                el.secondName,
                                            };
                                          })
                                    }
                                    value={
                                      (api !== undefined) &
                                      (api.requestResponsible !== undefined) &
                                      (users !== null) &
                                      (api.requestResponsible !== "")
                                        ? {
                                            value: users.find(
                                              (el) =>
                                                el._id ===
                                                api.requestResponsible
                                            )._id,
                                            label:
                                              users.find(
                                                (el) =>
                                                  el._id ===
                                                  api.requestResponsible
                                              ).firstName +
                                              " " +
                                              users.find(
                                                (el) =>
                                                  el._id ===
                                                  api.requestResponsible
                                              ).secondName,
                                          }
                                        : ""
                                    }
                                    type="text"
                                    name="requestResponsible"
                                    onChange={(data) => {
                                      setApi({
                                        ...api,
                                        requestResponsible: data.value,
                                      });
                                    }}
                                    isDisabled={status === 1 ? true : false}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4}>
                                  Принимать сообщения из WhatsApp
                                </Form.Label>
                                <Col sm={8}>
                                  <Form.Check
                                    disabled={status === 1 ? true : false}
                                    type="switch"
                                    onChange={() => {
                                      setApi({
                                        ...api,
                                        createActionsFromWap:
                                          !api.createActionsFromWap,
                                      });
                                    }}
                                    checked={
                                      api.createActionsFromWap === true
                                        ? "on"
                                        : ""
                                    }
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4}>
                                  Ответственный по заявкам из WhatsApp
                                </Form.Label>
                                <Col sm={8}>
                                  <Select
                                    placeholder=""
                                    noOptionsMessage={() =>
                                      "Нет доступных пользователей"
                                    }
                                    options={
                                      users === null
                                        ? {}
                                        : users.map((el) => {
                                            return {
                                              value: el._id,
                                              label:
                                                el.firstName +
                                                " " +
                                                el.secondName,
                                            };
                                          })
                                    }
                                    value={
                                      (api !== undefined) &
                                      (api.requestResponsibleWap !==
                                        undefined) &
                                      (users !== null) &
                                      (api.requestResponsibleWap !== "")
                                        ? {
                                            value: users.find(
                                              (el) =>
                                                el._id ===
                                                api.requestResponsibleWap
                                            )._id,
                                            label:
                                              users.find(
                                                (el) =>
                                                  el._id ===
                                                  api.requestResponsibleWap
                                              ).firstName +
                                              " " +
                                              users.find(
                                                (el) =>
                                                  el._id ===
                                                  api.requestResponsibleWap
                                              ).secondName,
                                          }
                                        : ""
                                    }
                                    type="text"
                                    name="requestResponsibleWap"
                                    onChange={(data) => {
                                      setApi({
                                        ...api,
                                        requestResponsibleWap: data.value,
                                      });
                                    }}
                                    isDisabled={status === 1 ? true : false}
                                  />
                                </Col>
                              </Form.Group>
                              <Button
                                variant="secondary"
                                onClick={(e) => {
                                  props.setIsLoading(true);
                                  wapApi
                                    .getQr()
                                    .then((res) => {
                                      setWapQr(res.qr);
                                    })
                                    .catch((rej) => {
                                      props.setIsError(true);
                                      props.setError(rej);
                                    })
                                    .finally(() => props.setIsLoading(false));
                                }}
                              >
                                Авторизоваться WhatsApp
                              </Button>
                              {wapQr !== null ? (
                                <img
                                  src={`
                              http://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                                wapQr
                              )}&size=300x300
                              `}
                                  alt="qr"
                                />
                              ) : (
                                ""
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : (
                          ""
                        )}
                      </Accordion>
                      <div className="d-flex m-3 justify-content-end">
                        <Button
                          variant="primary"
                          type="button"
                          className={`${status === 1 ? "d-none" : "d-block"}`}
                          onClick={settingsSave}
                        >
                          Сохранить
                        </Button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Справочники</Accordion.Header>
                    <Accordion.Body>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Статусы лидов</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group as={Row} className="mb-3 d-flex">
                              <Form.Label column sm={3}>
                                Название
                              </Form.Label>
                              <Col sm={2}>Цвет</Col>
                              <Col sm={2}>Воронка</Col>
                              <Col sm={2}>Позиция</Col>
                            </Form.Group>
                            {directory.leadStatus
                              .sort(function (a, b) {
                                if (a.position > b.position) {
                                  return 1;
                                }
                                if (a.position < b.position) {
                                  return -1;
                                }
                                return 0;
                              })
                              .map((el, index) => {
                                return (
                                  <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    key={el._id}
                                  >
                                    <Col sm={3}>
                                      <Form.Control
                                        type="text"
                                        onChange={(e) => {
                                          setShowButtonDir(el._id);
                                          setDirectory({
                                            ...directory,
                                            leadStatus:
                                              directory.leadStatus.map(
                                                (element) => {
                                                  if (element._id === el._id) {
                                                    return {
                                                      ...element,
                                                      value: e.target.value,
                                                    };
                                                  } else {
                                                    return element;
                                                  }
                                                }
                                              ),
                                          });
                                        }}
                                        value={
                                          directory.leadStatus[index].value
                                        }
                                      />
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Control
                                        type="color"
                                        onChange={(e) => {
                                          setShowButtonDir(el._id);
                                          setDirectory({
                                            ...directory,
                                            leadStatus:
                                              directory.leadStatus.map(
                                                (element) => {
                                                  if (element._id === el._id) {
                                                    return {
                                                      ...element,
                                                      color: e.target.value,
                                                    };
                                                  } else {
                                                    return element;
                                                  }
                                                }
                                              ),
                                          });
                                        }}
                                        value={
                                          directory.leadStatus[index].color
                                        }
                                      />
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Check
                                        type="checkbox"
                                        onChange={(e) => {
                                          setShowButtonDir(el._id);
                                          setDirectory({
                                            ...directory,
                                            leadStatus:
                                              directory.leadStatus.map(
                                                (element) => {
                                                  if (element._id === el._id) {
                                                    return {
                                                      ...element,
                                                      isFunnel: !Boolean(
                                                        element.isFunnel
                                                      ),
                                                    };
                                                  } else {
                                                    return element;
                                                  }
                                                }
                                              ),
                                          });
                                        }}
                                        checked={
                                          directory.leadStatus[index]
                                            .isFunnel === true
                                            ? "on"
                                            : ""
                                        }
                                      />
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Control
                                        type="text"
                                        onChange={(e) => {
                                          setShowButtonDir(el._id);
                                          setDirectory({
                                            ...directory,
                                            leadStatus:
                                              directory.leadStatus.map(
                                                (element) => {
                                                  if (element._id === el._id) {
                                                    return {
                                                      ...element,
                                                      position: e.target.value,
                                                    };
                                                  } else {
                                                    return element;
                                                  }
                                                }
                                              ),
                                          });
                                        }}
                                        value={
                                          directory.leadStatus[index].position
                                        }
                                      />
                                    </Col>
                                    <Col sm={3}>
                                      {showButtonDir === el._id ? (
                                        <Button
                                          variant="primary"
                                          onClick={() =>
                                            directorySave({
                                              directory: "leadStatus",
                                              _id: el._id,
                                              data: {
                                                value:
                                                  directory.leadStatus[index]
                                                    .value,
                                                color:
                                                  directory.leadStatus[index]
                                                    .color,
                                                isFunnel:
                                                  directory.leadStatus[index]
                                                    .isFunnel,
                                                position:
                                                  directory.leadStatus[index]
                                                    .position,
                                              },
                                            })
                                          }
                                        >
                                          Сохранить
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Form.Group>
                                );
                              })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "leadStatus",
                                  data: {
                                    value: " ",
                                    color: " ",
                                    position: directory.leadStatus.length + 1,
                                    isFunnel: true,
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Действия в CRM</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3 d-sm-flex d-none"
                            >
                              <Form.Label column sm={5}>
                                Название
                              </Form.Label>
                            </Form.Group>
                            {directory.crmActions.map((el, index) => {
                              return (
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  key={el._id}
                                >
                                  <Col sm={5}>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          crmActions: directory.crmActions.map(
                                            (element) => {
                                              if (element._id === el._id) {
                                                return {
                                                  ...element,
                                                  value: e.target.value,
                                                };
                                              } else {
                                                return element;
                                              }
                                            }
                                          ),
                                        });
                                      }}
                                      value={directory.crmActions[index].value}
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    {showButtonDir === el._id ? (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          directorySave({
                                            directory: "crmActions",
                                            _id: el._id,
                                            data: {
                                              value:
                                                directory.crmActions[index]
                                                  .value,
                                            },
                                          })
                                        }
                                      >
                                        Сохранить
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Form.Group>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "crmActions",
                                  data: {
                                    value: " ",
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            Статусы действий в CRM
                          </Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3 d-sm-flex d-none"
                            >
                              <Form.Label column sm={5}>
                                Название
                              </Form.Label>
                            </Form.Group>
                            {directory.crmStatus.map((el, index) => {
                              return (
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  key={el._id}
                                >
                                  <Col sm={5}>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          crmStatus: directory.crmStatus.map(
                                            (element) => {
                                              if (element._id === el._id) {
                                                return {
                                                  ...element,
                                                  value: e.target.value,
                                                };
                                              } else {
                                                return element;
                                              }
                                            }
                                          ),
                                        });
                                      }}
                                      value={directory.crmStatus[index].value}
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    {showButtonDir === el._id ? (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          directorySave({
                                            directory: "crmStatus",
                                            _id: el._id,
                                            data: {
                                              value:
                                                directory.crmStatus[index]
                                                  .value,
                                            },
                                          })
                                        }
                                      >
                                        Сохранить
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Form.Group>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "crmStatus",
                                  data: {
                                    value: " ",
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Приоритеты задач</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3 d-sm-flex d-none"
                            >
                              <Form.Label column sm={4}>
                                Название
                              </Form.Label>
                              <Col sm={5}>Цвет</Col>
                            </Form.Group>
                            {directory.taskPriorities.map((el, index) => {
                              return (
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  key={el._id}
                                >
                                  <Col sm={4}>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          taskPriorities:
                                            directory.taskPriorities.map(
                                              (element) => {
                                                if (element._id === el._id) {
                                                  return {
                                                    ...element,
                                                    value: e.target.value,
                                                  };
                                                } else {
                                                  return element;
                                                }
                                              }
                                            ),
                                        });
                                      }}
                                      value={
                                        directory.taskPriorities[index].value
                                      }
                                    />
                                  </Col>
                                  <Col sm={5}>
                                    <Form.Control
                                      type="color"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          taskPriorities:
                                            directory.taskPriorities.map(
                                              (element) => {
                                                if (element._id === el._id) {
                                                  return {
                                                    ...element,
                                                    color: e.target.value,
                                                  };
                                                } else {
                                                  return element;
                                                }
                                              }
                                            ),
                                        });
                                      }}
                                      value={
                                        directory.taskPriorities[index].color
                                      }
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    {showButtonDir === el._id ? (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          directorySave({
                                            directory: "taskPriorities",
                                            _id: el._id,
                                            data: {
                                              value:
                                                directory.taskPriorities[index]
                                                  .value,
                                              color:
                                                directory.taskPriorities[index]
                                                  .color,
                                            },
                                          })
                                        }
                                      >
                                        Сохранить
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Form.Group>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "taskPriorities",
                                  data: {
                                    value: " ",
                                    color: " ",
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>Статусы задач</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3 d-sm-flex d-none"
                            >
                              <Form.Label column sm={4}>
                                Название
                              </Form.Label>
                              <Col sm={5}>Закрыт</Col>
                            </Form.Group>
                            {directory.taskStatus.map((el, index) => {
                              return (
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  key={el._id}
                                >
                                  <Col sm={4}>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          taskStatus: directory.taskStatus.map(
                                            (element) => {
                                              if (element._id === el._id) {
                                                return {
                                                  ...element,
                                                  value: e.target.value,
                                                };
                                              } else {
                                                return element;
                                              }
                                            }
                                          ),
                                        });
                                      }}
                                      value={directory.taskStatus[index].value}
                                    />
                                  </Col>
                                  <Col sm={5}>
                                    <Form.Select
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          taskStatus: directory.taskStatus.map(
                                            (element) => {
                                              if (element._id === el._id) {
                                                return {
                                                  ...element,
                                                  isClosed: e.target.value,
                                                };
                                              } else {
                                                return element;
                                              }
                                            }
                                          ),
                                        });
                                      }}
                                      value={
                                        directory.taskStatus[index].isClosed
                                      }
                                    >
                                      <option value="0">Нет</option>
                                      <option value="1">Да</option>
                                    </Form.Select>
                                  </Col>
                                  <Col sm={3}>
                                    {showButtonDir === el._id ? (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          directorySave({
                                            directory: "taskStatus",
                                            _id: el._id,
                                            data: {
                                              value:
                                                directory.taskStatus[index]
                                                  .value,
                                              isClosed:
                                                directory.taskStatus[index]
                                                  .isClosed,
                                            },
                                          })
                                        }
                                      >
                                        Сохранить
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Form.Group>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "taskStatus",
                                  data: {
                                    value: " ",
                                    isClosed: 0,
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>Залы</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3 d-sm-flex d-none"
                            >
                              <Form.Label column sm={5}>
                                Название
                              </Form.Label>
                            </Form.Group>
                            {directory.rooms.map((el, index) => {
                              return (
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  key={el._id}
                                >
                                  <Col sm={5}>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          rooms: directory.rooms.map(
                                            (element) => {
                                              if (element._id === el._id) {
                                                return {
                                                  ...element,
                                                  value: e.target.value,
                                                };
                                              } else {
                                                return element;
                                              }
                                            }
                                          ),
                                        });
                                      }}
                                      value={directory.rooms[index].value}
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    {showButtonDir === el._id ? (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          directorySave({
                                            directory: "rooms",
                                            _id: el._id,
                                            data: {
                                              value:
                                                directory.rooms[index].value,
                                            },
                                          })
                                        }
                                      >
                                        Сохранить
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Form.Group>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "rooms",
                                  data: {
                                    value: " ",
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>Способы оплат</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3 d-sm-flex d-none"
                            >
                              <Form.Label column sm={5}>
                                Название
                              </Form.Label>
                            </Form.Group>
                            {directory.paymentTypes.map((el, index) => {
                              return (
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  key={el._id}
                                >
                                  <Col sm={5}>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          paymentTypes:
                                            directory.paymentTypes.map(
                                              (element) => {
                                                if (element._id === el._id) {
                                                  return {
                                                    ...element,
                                                    value: e.target.value,
                                                  };
                                                } else {
                                                  return element;
                                                }
                                              }
                                            ),
                                        });
                                      }}
                                      value={
                                        directory.paymentTypes[index].value
                                      }
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    {showButtonDir === el._id ? (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          directorySave({
                                            directory: "paymentTypes",
                                            _id: el._id,
                                            data: {
                                              value:
                                                directory.paymentTypes[index]
                                                  .value,
                                            },
                                          })
                                        }
                                      >
                                        Сохранить
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Form.Group>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "paymentTypes",
                                  data: {
                                    value: " ",
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                          <Accordion.Header>Типы документов</Accordion.Header>
                          <Accordion.Body>
                            {directory.docsTypes.map((el, index) => {
                              return (
                                <>
                                  <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    key={el._id}
                                  >
                                    <Col sm={5}>
                                      <Form.Control
                                        type="text"
                                        size="lg"
                                        onChange={(e) => {
                                          setShowButtonDir(el._id);
                                          setDirectory({
                                            ...directory,
                                            docsTypes: directory.docsTypes.map(
                                              (element) => {
                                                if (element._id === el._id) {
                                                  return {
                                                    ...element,
                                                    name: e.target.value,
                                                  };
                                                } else {
                                                  return element;
                                                }
                                              }
                                            ),
                                          });
                                        }}
                                        value={directory.docsTypes[index].name}
                                      />
                                      <p className="fs-5 mt-2">Поля:</p>
                                      {directory.docsTypes[index].fileds.map(
                                        (element, INDEX) => {
                                          return (
                                            <Form.Control
                                              key={element._id}
                                              type="text"
                                              onChange={(e) => {
                                                setShowButtonDir(el._id);
                                                setDirectory({
                                                  ...directory,
                                                  docsTypes:
                                                    directory.docsTypes.map(
                                                      (ob, num) => {
                                                        if (num === index) {
                                                          return {
                                                            ...ob,
                                                            fileds:
                                                              ob.fileds.map(
                                                                (obj, ber) => {
                                                                  if (
                                                                    ber ===
                                                                    INDEX
                                                                  ) {
                                                                    return {
                                                                      ...obj,
                                                                      name: e
                                                                        .target
                                                                        .value,
                                                                    };
                                                                  } else {
                                                                    return obj;
                                                                  }
                                                                }
                                                              ),
                                                          };
                                                        } else {
                                                          return ob;
                                                        }
                                                      }
                                                    ),
                                                });
                                              }}
                                              value={
                                                directory.docsTypes[index]
                                                  .fileds[INDEX].name
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </Col>
                                    <Col sm={3}>
                                      {showButtonDir === el._id ? (
                                        <Button
                                          variant="primary"
                                          onClick={() =>
                                            directorySave({
                                              directory: "docsTypes",
                                              _id: el._id,
                                              data: {
                                                name: directory.docsTypes[index]
                                                  .name,
                                                fileds:
                                                  directory.docsTypes[index]
                                                    .fileds,
                                              },
                                            })
                                          }
                                        >
                                          Сохранить
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Form.Group>
                                  <Button
                                    variant="secondary"
                                    className="mb-2"
                                    onClick={() => {
                                      addFieldDoc({
                                        name: directory.docsTypes[index].name,
                                        field: " ",
                                      });
                                    }}
                                  >
                                    Добавить
                                  </Button>
                                  <div
                                    style={{
                                      backgroundColor: "gray",
                                      width: "100%",
                                      height: 2,
                                      marginBottom: 20,
                                    }}
                                  ></div>
                                </>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "docsTypes",
                                  data: {
                                    name: " ",
                                    fileds: [{ name: " " }],
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                          <Accordion.Header>Источники лидов</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3 d-sm-flex d-none"
                            >
                              <Form.Label column sm={5}>
                                Название
                              </Form.Label>
                            </Form.Group>
                            {directory.leadSources.map((el, index) => {
                              return (
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  key={el._id}
                                >
                                  <Col sm={5}>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        setShowButtonDir(el._id);
                                        setDirectory({
                                          ...directory,
                                          leadSources:
                                            directory.leadSources.map(
                                              (element) => {
                                                if (element._id === el._id) {
                                                  return {
                                                    ...element,
                                                    value: e.target.value,
                                                  };
                                                } else {
                                                  return element;
                                                }
                                              }
                                            ),
                                        });
                                      }}
                                      value={directory.leadSources[index].value}
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    {showButtonDir === el._id ? (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          directorySave({
                                            directory: "leadSources",
                                            _id: el._id,
                                            data: {
                                              value:
                                                directory.leadSources[index]
                                                  .value,
                                            },
                                          })
                                        }
                                      >
                                        Сохранить
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Form.Group>
                              );
                            })}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                directoryAdd({
                                  directory: "leadSources",
                                  data: {
                                    value: " ",
                                  },
                                })
                              }
                            >
                              Добавить
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card.Text>
            </Card.Body>
          </Card>
        </Form>
      ) : (
        ""
      )}
    </>
  );
}
