import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { api as lessonsOperationsApi } from "../../../../../utils/api/api.lessonsOperation";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import LessonOperation from './LessonOperation'

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const DateFormatter = (obj) => {
  return new Date(obj.value).toLocaleString("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const LessonsTable = (props) => {
  const [finder, setFinder] = useState({
    f_type: "",
    f_count: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 20]);
  const [sorting, setSorting] = useState({ sort_by: "date", sort_dir: -1 });
  const [totalCount, setTotalCount] = useState(0);
  const [dateColumns] = useState(["date"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "date", width: 150 },
    { columnName: "count", width: 120 },
    { columnName: "type", width: 150 },
    { columnName: "daysCount", width: 120 },
  ]);
  const [selectedOperation, setSelectedOperation] = useState(null);

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => {
              setSelectedOperation(row._id);
              setIsModalOpen(true);
            }}
          />
        </td>
      );
    }
    if (column.name === "count") {
      if (row.count > 0) {
        return (
          <td>
            <p className="text-success fw-bold">{row.count}</p>
          </td>
        );
      } else if (row.count < 0) {
        return (
          <td>
            <p className="text-danger fw-bold">{row.count}</p>
          </td>
        );
      }
    }

    if (column.name === "type") {
      switch (row.type) {
        case 1:
          return (
            <td>
              <p>Пополнение</p>
            </td>
          );
        case 2:
          return (
            <td>
              <p>Заморозка</p>
            </td>
          );
        case 3:
          return (
            <td>
              <p>Разморозка</p>
            </td>
          );
        case 4:
          return (
            <td>
              <p>Списание занятия</p>
            </td>
          );
        case 5:
          return (
            <td>
              <p>Списание занятия уважительная причина</p>
            </td>
          );
        case 6:
          return (
            <td>
              <p>Возврат занятия</p>
            </td>
          );
      }
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if ((props.accountId !== undefined) & (props.accountId !== "")) {
      props.setIsLoading(true);
      lessonsOperationsApi
        .getOperations({
          p: currentPage + 1,
          s: pageSize,
          f_account: props.accountId,
          ...sorting,
        })
        .then((res) => {
          setData(res.lessonsOperations);
          setTotalCount(res.totalDocs);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    }
  }, [props.accountId, props.needUpdate]);

  const changePage = (page) => {
    props.setIsLoading(true);
    lessonsOperationsApi
      .getOperations({
        p: page + 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        f_account: props.accountId,
        ...sorting,
      })
      .then((res) => {
        setCurrentPage(res.currentPage - 1);
        setData(res.lessonsOperations);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    lessonsOperationsApi
      .getOperations({
        p: 1,
        s: size,
        f_account: props.accountId,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.lessonsOperations);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    lessonsOperationsApi
      .getOperations({
        p: 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        f_account: props.accountId,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.lessonsOperations);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    lessonsOperationsApi
      .getOperations({
        p: 1,
        s: pageSize,
        f_account: props.accountId,
        ...finder,
        f_datef,
        f_datet,
        ...sorting,
      })
      .then((res) => {
        setData(res.lessonsOperations);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    lessonsOperationsApi
      .getOperations({
        p: 1,
        s: pageSize,
        f_account: props.accountId,
      })
      .then((res) => {
        setFinder({
          f_type: "",
          f_count: "",
        });
        setF_datef("");
        setF_datet("");
        setData(res.lessonsOperations);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "date", title: "Дата" },
    { name: "count", title: "Значение" },
    { name: "type", title: "Тип" },
    { name: "daysCount", title: "Дней" },
  ];

  return (
    <>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Дата c
                </Form.Label>
                <Col sm={8}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datef}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                    }}
                    name="birthday"
                    onChange={([date]) => {
                      setF_datef(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Дата по
                </Form.Label>
                <Col sm={8}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datet}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                    }}
                    name="birthday"
                    onChange={([date]) => {
                      setF_datet(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Тип
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    name="f_type"
                    value={finder.f_type}
                    onChange={finderHandlerChange}
                  >
                    <option value=""> </option>
                    <option value="1">Пополнение</option>
                    <option value="2">Заморозка</option>
                    <option value="3">Разморозка</option>
                    <option value="4">Списание занятия</option>
                    <option value="5">Списание уважительная причина</option>
                    <option value="6">Возврат занятия</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Значение
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="f_count"
                    value={finder.f_count}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <DateTypeProvider for={dateColumns} />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
      <LessonOperation
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        studentId={props.studentId}
        data={selectedOperation}
        setSelectedOperation={setSelectedOperation}
      />
    </>
  );
};

export default LessonsTable;
