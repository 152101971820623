import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  DayView,
  Toolbar,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { styled } from "@mui/material/styles";
import moment from "moment";
import "moment/locale/ru";
import NavigateButton from "./NavigateButton";
import Fade from "react-reveal/Fade";

const classes = "dayScaleCell";

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)({
  [`&.${classes.dayScaleCell}`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const formatDayScaleDate = (date, options) => {
  moment.locale("ru");
  const momentDate = moment(date);
  const { weekday } = options;
  return momentDate.format(weekday ? "dddd" : "D");
};

const formatTimeScaleDate = (date) => moment(date).format("HH:mm");

const DayScaleCell = ({ formatDate, ...restProps }) => (
  <StyledWeekViewDayScaleCell
    {...restProps}
    formatDate={formatDayScaleDate}
    className={classes.dayScaleCell}
  />
);

const TimeScaleLabel = ({ formatDate, ...restProps }) => (
  <WeekView.TimeScaleLabel {...restProps} formatDate={formatTimeScaleDate} />
);

export default class LessonsSheduler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      grouping: [
        {
          resourceName: "roomId",
        },
      ],
      dateFrom: new Date(
        new Date().setDate(
          new Date().getDate() -
            new Date().getDay() +
            (new Date().getDay() === 0 ? -6 : 1)
        )
      ),
      dateTo: new Date(
        new Date().setDate(
          new Date().getDate() -
            new Date().getDay() +
            (new Date().getDay() === 0 ? -6 : 1) +
            6
        )
      ),
    };
  }

  Header = ({ children, appointmentData, onHide, ...restProps }) => {
    return (
      <div className="d-flex justify-content-end p-3 pb-0 align-items-center">
        <NavigateButton link={appointmentData} setScheduleState={this.props.setScheduleState} scheduleState={this.props.scheduleState}/>
        <AppointmentTooltip.CommandButton
          className="bi bi-x-lg"
          style={{ cursor: "pointer", fontSize: 28, color: "black" }}
          onClick={onHide}
        />
      </div>
    );
  };

  render() {
    return (
      <Fade>
        {this.props.data !== null ? (
          <Paper>
            <Scheduler
              data={this.props.data.lessons}
              firstDayOfWeek={1}
              locale={"ru-RU"}
            >
              <ViewState currentDate={this.props.dateFrom} currentViewName={this.props.viewMode}/>
              <GroupingState
                groupByDate={() => true}
                grouping={this.state.grouping}
              />
              <DayView
                displayName="День"
                startDayHour={this.props.timeSetups.dayStart}
                endDayHour={this.props.timeSetups.dayEnd}
                cellDuration={15}
                dayScaleCellComponent={DayScaleCell}
                timeScaleLabelComponent={TimeScaleLabel}
              />
              <WeekView
                displayName="Неделя"
                startDayHour={this.props.timeSetups.dayStart}
                endDayHour={this.props.timeSetups.dayEnd}
                cellDuration={15}
                today={true}
                dayScaleCellComponent={DayScaleCell}
                timeScaleLabelComponent={TimeScaleLabel}
              />
              <Appointments />
              <Resources
                data={this.props.data.resources}
                mainResourceName="roomId"
              />

              <IntegratedGrouping />
              <Appointments />
              <AppointmentTooltip
                showOpenButton
                showCloseButton
                headerComponent={this.Header}
              />
              <Toolbar />
              <GroupingPanel />
            </Scheduler>
          </Paper>
        ) : (
          ""
        )}
      </Fade>
    );
  }
}
