import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { api as lessonsApi } from "../../../utils/api/api.lessons";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Fade from "react-reveal/Fade";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const LessonsTable = (props) => {
  const [finder, setFinder] = useState({
    f_dance: "",
    f_group: "",
    f_typegroup: "",
    f_room: "",
    f_teacher: "",
    f_complete: "",
  });
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState(null);
  const [dances, setDances] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50, 100]);
  const [totalCount, setTotalCount] = useState(0);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [sorting, setSorting] = useState({ sort_by: "date", sort_dir: 1 });
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "date", width: 120 },
    { columnName: "endDate", width: 120 },
    { columnName: "group", width: 150 },
    { columnName: "dance", width: 150 },
    { columnName: "typeGroup", width: 140 },
    { columnName: "teacher", width: 170 },
    { columnName: "room", width: 150 },
    { columnName: "isComplete", width: 100 },
  ]);
  const navigation = useNavigate();

  useEffect(() => {
    if (!props.tableState) return;
    setCurrentPage(props.tableState.currentPage);
    setPageSize(props.tableState.pageSize);
    setSorting(props.tableState.sorting);
    setFinder(props.tableState.finder);
    setF_datef(props.tableState.f_datef);
    setF_datet(props.tableState.f_datet);
    setNeedUpdate(true);
  }, [props.tableState]);

  const goToLesson = (id) => {
    navigation(id);
    props.setTableState({
      currentPage,
      pageSize,
      sorting,
      finder,
      f_datef,
      f_datet,
    });
  };

  const Cell = (props) => {
    const { column, row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => goToLesson(row._id)}
          />
        </td>
      );
    }
    if (column.name === "isComplete") {
      if (row.isComplete) {
        return (
          <td>
            <i className="bi bi-patch-plus" style={{ fontSize: 24 }} />
          </td>
        );
      } else {
        return (
          <td>
            <i className="bi bi-patch-minus" style={{ fontSize: 24 }} />
          </td>
        );
      }
    }

    if (column.name === "date") {
      return (
        <td>
          {new Date(row.date).toLocaleString("ru-RU", {
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      );
    }

    if (column.name === "typeGroup") {
      return (
        <td>
          {row.typeGroup === 1
            ? "Групповое"
            : row.typeGroup === 2
            ? "Индивидуальное"
            : row.typeGroup === 3
            ? "Другое"
            : ""}
        </td>
      );
    }

    if (column.name === "endDate") {
      return (
        <td>
          {new Date(row.endDate).toLocaleString("ru-RU", {
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      );
    }

    if ((column.name === "group") & (row.group !== undefined)) {
      return <td>{groups.find((el) => el._id === row.group).name}</td>;
    }

    if (column.name === "room") {
      return <td>{rooms.find((el) => el._id === row.room).value}</td>;
    }

    if (column.name === "dance") {
      return <td>{dances.find((el) => el._id === row.dance).name}</td>;
    }

    if (column.name === "teacher") {
      return (
        <td>
          {teachers.find((el) => el._id === row.teacher).firstName +
            " " +
            teachers.find((el) => el._id === row.teacher).secondName}
        </td>
      );
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    lessonsApi
      .getLessons({ p: currentPage + 1, s: pageSize, ...sorting })
      .then((res) => {
        setData(res.lessons);
        setRooms(res.rooms[0].directory.rooms);
        setGroups(res.groups);
        setDances(res.dances);
        setTeachers(res.teachers);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
        setNeedUpdate(false);
      });
  }, [needUpdate]);

  const changePage = (page) => {
    props.setIsLoading(true);
    lessonsApi
      .getLessons({
        p: page + 1,
        s: pageSize,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.lessons);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    lessonsApi
      .getLessons({ p: 1, s: size, ...sorting, ...finder, f_datef, f_datet })
      .then((res) => {
        setData(res.lessons);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    lessonsApi
      .getLessons({
        p: 1,
        s: pageSize,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.lessons);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    lessonsApi
      .getLessons({
        p: 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        ...sorting,
      })
      .then((res) => {
        setData(res.lessons);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    lessonsApi
      .getLessons({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_dance: "",
          f_group: "",
          f_typegroup: "",
          f_room: "",
          f_teacher: "",
          f_complete: "",
        });
        setF_datef(null);
        setF_datet(null);
        setData(res.lessons);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "date", title: "Начало" },
    { name: "endDate", title: "Конец" },
    { name: "group", title: "Группа" },
    { name: "dance", title: "Направление" },
    { name: "typeGroup", title: "Тип" },
    { name: "teacher", title: "Педагог" },
    { name: "room", title: "Зал" },
    { name: "isComplete", title: "Статус" },
  ];

  return (
    <Fade>
      <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата c
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datef}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      disableMobile: true,
                      dateFormat: "d.m.Y",
                    }}
                    onChange={([date]) => {
                      setF_datef(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Дата по
                </Form.Label>
                <Col sm={10}>
                  <Flatpickr
                    className="form-control"
                    style={{ backgroundColor: "transparent" }}
                    value={f_datet}
                    options={{
                      time_24hr: true,
                      locale: Russian,
                      disableMobile: true,
                      dateFormat: "d.m.Y",
                    }}
                    onChange={([date]) => {
                      setF_datet(new Date(date));
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_typegroup"
                    value={finder.f_typegroup}
                    onChange={finderHandlerChange}
                  >
                    <option value={null}> </option>
                    <option value={1}>Групповое</option>
                    <option value={2}>Индивидуальное</option>
                    <option value={3}>Другое</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Группа
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет групп"}
                    options={
                      groups === null
                        ? {}
                        : groups.map((el) => {
                            return {
                              value: el._id,
                              label: el.name,
                            };
                          })
                    }
                    value={
                      finder.f_group === ""
                        ? ""
                        : {
                            value: groups.find(
                              (el) => el._id === finder.f_dance
                            )._id,
                            label: groups.find(
                              (el) => el._id === finder.f_dance
                            ).name,
                          }
                    }
                    type="text"
                    name="f_group"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_group: data.map((el) => el.value),
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Направление
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет направлений"}
                    options={
                      dances === null
                        ? {}
                        : dances.map((el) => {
                            return {
                              value: el._id,
                              label: el.name,
                            };
                          })
                    }
                    value={
                      finder.f_dance === ""
                        ? {}
                        : {
                            value: dances.find(
                              (el) => el._id === finder.f_dance
                            )._id,
                            label: dances.find(
                              (el) => el._id === finder.f_dance
                            ).name,
                          }
                    }
                    type="text"
                    name="f_group"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_dance: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Педагог
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет педагогов"}
                    options={
                      teachers === null
                        ? {}
                        : teachers.map((el) => {
                            return {
                              value: el._id,
                              label: el.firstName + " " + el.secondName,
                            };
                          })
                    }
                    value={
                      finder.f_teacher === ""
                        ? {}
                        : {
                            value: teachers.find(
                              (el) => el._id === finder.f_teacher
                            )._id,
                            label:
                              teachers.find((el) => el._id === finder.f_teacher)
                                .firstName +
                              " " +
                              teachers.find((el) => el._id === finder.f_teacher)
                                .secondName,
                          }
                    }
                    type="text"
                    name="f_teacher"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_teacher: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Зал
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет залов"}
                    options={
                      rooms === null
                        ? {}
                        : rooms.map((el) => {
                            return {
                              value: el._id,
                              label: el.value,
                            };
                          })
                    }
                    value={
                      finder.f_room === ""
                        ? {}
                        : {
                            value: rooms.find((el) => el._id === finder.f_room)
                              ._id,
                            label: rooms.find((el) => el._id === finder.f_room)
                              .value,
                          }
                    }
                    type="text"
                    name="f_rooms"
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_room: data.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Статус
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_complete"
                    value={finder.f_complete}
                    onChange={finderHandlerChange}
                  >
                    <option value=" "> </option>
                    <option value="true">Проведен</option>
                    <option value="false">Не проведен</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
    </Fade>
  );
};

export default LessonsTable;
