import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../../../../context/UserContext";
import { api as lessonsApi } from "../../../../../utils/api/api.lessonsOperation";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Select from "react-select";

export default function LessonOperation(props) {
  const userContext = React.useContext(UserContext);
  const [owner, setOwner] = useState(null);
  const [abonement, setAbonement] = useState(null);
  const [data, setData] = useState({
    _id: "",
    date: "",
    account: "",
    type: "",
    count: "",
    daysCount: 0,
    lesson: "",
    comment: "",
    owner: "",
  });

  const handleClose = () => {
    props.setSelectedOperation(null);
    setData({
      _id: "",
      date: "",
      account: "",
      type: "",
      count: "",
      daysCount: 0,
      lesson: "",
      comment: "",
      owner: "",
    });
    props.setIsOpen(false);
  };

  useEffect(() => {
    if (props.data !== null) {
      props.setIsLoading(true);
      lessonsApi
        .getOperation(props.data)
        .then((res) => {
          setOwner(
            res.users.find((user) => user._id === res.lessonsOperation.owner)
          );
          setData(res.lessonsOperation);
          setAbonement(res.abonement);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, [props.data]);

  return (
    <Modal show={props.isOpen} onHide={handleClose} style={{ zIndex: 1500 }}>
      <Modal.Header closeButton>
        <Modal.Title>Операция по счету</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Дата</Form.Label>
            <Flatpickr
              data-enable-time
              className="form-control form-control-plaintext p-2"
              style={{ backgroundColor: "transparent" }}
              value={data.date}
              options={{
                time_24hr: true,
                locale: Russian,
                dateFormat: "d.m.Y H:i",
              }}
              name="date"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Счет</Form.Label>
            <Form.Control
              name="account"
              value={abonement !== null ? abonement.name : ""}
              disabled={true}
              plaintext={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Тип</Form.Label>
            <Form.Select
              name="type"
              value={data.type}
              disabled={true}
              plaintext={true}
            >
              <option value=""> </option>
              <option value="1">Покупка</option>
              <option value="2">Заморозка</option>
              <option value="3">Разморозка</option>
              <option value="4">Списание занятия</option>
              <option value="5">Списание уважительная причина</option>
              <option value="5">Возврат занятия</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Значение</Form.Label>
            <Form.Control
              name="count"
              value={data.count}
              disabled={true}
              plaintext={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Значение дней</Form.Label>
            <Form.Control
              name="count"
              value={data.daysCount}
              disabled={true}
              plaintext={true}
            />
          </Form.Group>
          {(data.lesson !== "") & (data.lesson !== undefined) ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Урок</Form.Label>
              <p>
                {
                  <Link to={"/admin/lessons/table/" + data.lesson}>
                    Перейти
                  </Link>
                }
              </p>
            </Form.Group>
          ) : (
            ""
          )}

          <Accordion className="mt-2 mb-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Комментарий</Accordion.Header>
              <Accordion.Body>
                <Form.Control
                  name="comment"
                  value={data.comment}
                  as="textarea"
                  disabled={true}
                  plaintext={true}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group>
            <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              className="mt-1 p-0"
              disabled
              plaintext
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
