import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function SubmitModal(props) {

    const closeModal = () => {
        props.close(false)
    }

    const submit = () => {
        props.action()
    }
  return (
    <Modal show={props.isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Подтвердите удаление</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.text}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Закрыть
        </Button>
        <Button variant="danger" onClick={submit}>
          Удалить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
