import React from "react";
import { UserContext } from "../../../context/UserContext";
import WhatsappTable from "./WhatsappTable";
import WhatsappForm from "./WhatsappForm";
import { Routes, Route } from "react-router-dom";

const Whatsapp = (props) => {
const userContext = React.useContext(UserContext);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <WhatsappTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <WhatsappForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Whatsapp;
