class Api {
    _checkResponse(res) {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    }
  
    getOperations(params) {
      let querry = "?";
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if ((params[key] !== undefined) & (params[key] !== "") & (params[key] !== null)) {
          querry += `${key}=${params[key]}&`;
        }
      });
      return fetch("/api/operations/lessons" + querry, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }

    getOperation(id) {
      return fetch("/api/operations/lessons/" + id, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  }
  
  const api = new Api();
  
  export { api };
  