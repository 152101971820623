import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { api as abonementsApi } from "../../../utils/api/api.abonements";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const StudentsTable = (props) => {
  const [finder, setFinder] = useState({
    f_name: "",
    f_dances: "",
    f_type: "",
    f_price: "",
    f_days: "",
    f_count: "",
    f_active: "",
  });
  const [data, setData] = useState([]);
  const [dances, setDances] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState({ sort_by: "isActive", sort_dir: -1 });
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "name", width: 150 },
    { columnName: "dances", width: 200 },
    { columnName: "type", width: 150 },
    { columnName: "days", width: 110 },
    { columnName: "count", width: 150 },
    { columnName: "price", width: 100 },
    { columnName: "isActive", width: 130 },
  ]);
  const navigation = useNavigate();

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => navigation(row._id)}
          />
        </td>
      );
    }
    if (column.name === "type") {
      if (row.type === 1) {
        return (
          <td>
            <p>По кол-ву</p>
          </td>
        );
      } else if (row.type === 2) {
        return (
          <td>
            <p>По сроку</p>
          </td>
        );
      } else if (row.type === 3) {
        return (
          <td>
            <p>По сроку и по кол-ву</p>
          </td>
        );
      }
    }
    if (column.name === "isActive") {
      if (row.isActive) {
        return (
          <td className="text-center">
            <i className="bi bi-patch-plus" style={{ fontSize: 24 }} />
          </td>
        );
      } else {
        return (
          <td className="text-center">
            <i className="bi bi-patch-minus" style={{ fontSize: 24 }} />
          </td>
        );
      }
    }

    if (column.name === "dances") {
      if (row.dances.length <= 3) {
        return (
          <td>
            <p>
              {row.dances
                .map((element) => dances.find((el) => element === el._id).name)
                .join(", ")}
            </p>
          </td>
        );
      } else {
        return (
          <td>
            <p>
              {dances.find((el) => row.dances[0] === el._id).name +
                ", " +
                dances.find((el) => row.dances[1] === el._id).name +
                ", " +
                dances.find((el) => row.dances[2] === el._id).name +
                "..."}
            </p>
          </td>
        );
      }
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    abonementsApi
      .getAbonements({ p: currentPage + 1, s: pageSize, ...sorting })
      .then((res) => {
        setData(res.abonements);
        setTotalCount(res.totalDocs);
        setDances(res.dances);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const changePage = (page) => {
    props.setIsLoading(true);
    abonementsApi
      .getAbonements({ p: page + 1, s: pageSize, ...sorting, ...finder })
      .then((res) => {
        setData(res.abonements);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    abonementsApi
      .getAbonements({ p: 1, s: size, ...sorting, ...finder })
      .then((res) => {
        setData(res.abonements);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    abonementsApi
      .getAbonements({
        p: 1,
        s: pageSize,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
        ...finder
      })
      .then((res) => {
        setData(res.abonements);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    abonementsApi
      .getAbonements({
        p: 1,
        s: pageSize,
        ...finder,
        ...sorting,
      })
      .then((res) => {
        setData(res.abonements);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    abonementsApi
      .getAbonements({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_name: "",
          f_dances: "",
          f_type: "",
          f_price: "",
          f_days: "",
          f_count: "",
          f_active: "",
        });
        setData(res.abonements);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "name", title: "Название" },
    { name: "dances", title: "Направления" },
    { name: "type", title: "Тип" },
    { name: "days", title: "Дней" },
    { name: "count", title: "Кол-во занятий" },
    { name: "price", title: "Цена" },
    { name: "isActive", title: "Активность" },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Название
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    value={finder.f_name}
                    type="text"
                    name="f_name"
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Направления
                </Form.Label>
                <Col sm={10}>
                  <Select
                    placeholder=""
                    noOptionsMessage={() => "Нет групп"}
                    options={
                      dances === []
                        ? {}
                        : dances.map((el) => {
                            return {
                              value: el._id,
                              label: el.name,
                            };
                          })
                    }
                    value={
                      finder.f_dances === ""
                        ? ""
                        : finder.f_dances.map((id) => {
                            return {
                              value: dances.find((el) => el._id === id)._id,
                              label: dances.find((el) => el._id === id).name,
                            };
                          })
                    }
                    type="text"
                    name="f_dances"
                    isMulti={true}
                    onChange={(data) =>
                      setFinder({
                        ...finder,
                        f_dances: data.map((el) => el.value),
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип абонемента
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_type"
                    value={finder.f_type}
                    onChange={finderHandlerChange}
                  >
                    <option value=""></option>
                    <option value="1">По количеству</option>
                    <option value="2">По сроку</option>
                    <option value="3">По количеству и сроку</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className={`mb-3 ${
                  finder.f_type === "1" ||
                  finder.f_type === "3" ||
                  finder.f_type === 1 ||
                  finder.f_type === 3
                    ? "d-flex"
                    : "d-none"
                }`}
              >
                <Form.Label column sm={2}>
                  Количество занятий
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_count"
                    value={finder.f_count}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className={`mb-3 ${
                  finder.f_type === "2" ||
                  finder.f_type === "3" ||
                  finder.f_type === 2 ||
                  finder.f_type === 3
                    ? "d-flex"
                    : "d-none"
                }`}
              >
                <Form.Label column sm={2}>
                  Срок действия(дней)
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="days"
                    value={finder.f_days}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Цена
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="price"
                    value={finder.f_price}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Активность
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_active"
                    value={finder.f_active}
                    onChange={finderHandlerChange}
                  >
                    <option value="true">Да</option>
                    <option value="false">Нет</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
    </>
  );
};

export default StudentsTable;
