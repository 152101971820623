class Api {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  getGroups(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        (params[key] !== undefined) &
        (params[key] !== "") &
        (params[key] !== null)
      ) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/groups/" + querry, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getCreationData() {
    return fetch("/api/groups/create-data", {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getGroup(id) {
    return fetch("/api/groups/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  postGroup(data) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] === undefined || data[key] === "") {
        delete data[key];
      }
    });
    return fetch("/api/groups/", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  editGroup(data) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] === undefined) {
        delete data[key];
      }
    });
    return fetch("/api/groups/", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleteGroup(data) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] === undefined || data[key] === "") {
        delete data[key];
      }
    });
    return fetch("/api/groups/", {
      method: "DELETE",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  postStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined || student[key] === "") {
        delete student[key];
      }
    });
    return fetch("/api/groups/student", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  editStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined) {
        delete student[key];
      }
    });
    return fetch("/api/groups/student", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  deleteStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined || student[key] === "") {
        delete student[key];
      }
    });
    return fetch("/api/groups/student", {
      method: "DELETE",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }

  compliteGroup(data) {
    return fetch("/api/groups/compliter", {
      method: "PUT",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }
  
  deleterGroup(data) {
    return fetch("/api/groups/deleter", {
      method: "PUT",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  adderStudent(data) {
    return fetch("/api/groups/add-student", {
      method: "PUT",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleterStudent(data) {
    return fetch("/api/groups/delete-student", {
      method: "PUT",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
