class Api {
    _checkResponse(res) {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    }
  
    getActions(params) {
      let querry = "?";
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if ((params[key] !== undefined) & (params[key] !== "") & (params[key] !== null)) {
          querry += `${key}=${params[key]}&`;
        }
      });
      return fetch("/api/actionscrm/" + querry, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    getAction(id) {
      return fetch("/api/actionscrm/" + id, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    createAction(student) {
      const keys = Object.keys(student);
      keys.forEach((key) => {
        if (student[key] === undefined || student[key] === "") {
          delete student[key];
        }
      });
      return fetch("/api/actionscrm/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(student),
      }).then((res) => this._checkResponse(res));
    }
    
    updateAction(student) {
      const keys = Object.keys(student);
      keys.forEach((key) => {
        if (student[key] === undefined) {
          delete student[key];
        }
      });
      return fetch("/api/actionscrm/", {
        method: "PATCH",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(student),
      }).then((res) => this._checkResponse(res));
    }

    getCreationData() {
        return fetch("/api/actionscrm/create-data", {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
        }).then((res) => this._checkResponse(res));
    }

    deleteAction(id) {
        return fetch("/api/actionscrm/", {
          method: "DELETE",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ actionId: id })
        }).then((res) => this._checkResponse(res));
    }
  }
  
  const api = new Api();
  
  export { api };
  