import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { api as actionApi } from "../../../../utils/api/api.actionscrm";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/themes/light.css";
import Select from "react-select";
import { UserContext } from "../../../../context/UserContext";
import PlanModal from "./PlanModal";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export default function ActionForm(props) {
  const userContext = React.useContext(UserContext);
  const [data, setData] = useState({
    _id: "",
    type: "",
    lead: "",
    responsible: "",
    status: "",
    comment: "",
  });
  const [owner, setOwner] = useState(null);
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState(null);
  const [types, setTypes] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [users, setUsers] = useState(null);
  const [isPlanModal, setIsModalPlan] = useState(false);

  const closeModal = () => {
    props.setIsOpen(false);
    setData({
      _id: "",
      type: "",
      responsible: "",
      status: "",
      comment: "",
    });
    setOwner(null);
    setDate(new Date());
    setIsModalPlan(false);
    props.setSelectedAction(null);
  };

  useEffect(() => {
    if (props.data === null) {
      setStatus(1);
      props.setIsLoading(true);
      actionApi
        .getCreationData()
        .then((res) => {
          setData({
            _id: "",
            type: "",
            responsible: "",
            status: "",
            comment: "",
          });
          setDate(new Date());
          setTypes(res.directory.directory.crmActions);
          setStatuses(res.directory.directory.crmStatus);
          setUsers(res.users);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else if ((props.data !== null) & (props.data !== undefined)) {
      setStatus(2);
      props.setIsLoading(true);
      actionApi
        .getAction(props.data)
        .then((res) => {
          setData({
            _id: res.action._id,
            type: res.action.type,
            responsible: res.action.responsible,
            status: res.action.status,
            comment: res.action.comment,
          });
          setDate(res.action.date);
          setTypes(res.directory.directory.crmActions);
          setStatuses(res.directory.directory.crmStatus);
          setUsers(res.users);
          setOwner(res.users.find((user) => user._id === res.action.owner));
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, [props.data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    if (status === 1) {
      actionApi
        .createAction({ ...data, date: date, lead: props.lead })
        .then((res) => {
          closeModal();
          props.setNeedUpdate(true);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else if (status === 2) {
      actionApi
        .updateAction({ ...data, date: date, lead: props.lead })
        .then((res) => {
          closeModal();
          props.setNeedUpdate(true);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  };
  const handleDelete = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    actionApi
      .deleteAction(data._id)
      .then((res) => {
        closeModal();
        props.setNeedUpdate(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const handleCreatePlan = (planDate, planComment) => {
    props.setIsLoading(true);
    actionApi
      .createAction({
        ...data,
        date: planDate,
        lead: props.lead,
        status: statuses[0]._id,
        comment:
          (planComment !== null & planComment !== undefined  & planComment !== "") ? planComment : data.comment,
      })
      .then((res) => {
        closeModal();
        props.setNeedUpdate(true);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <Modal show={props.isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {status === 1
            ? "Новое действие"
            : status === 2
            ? (types !== null) & (data.type !== "")
              ? types.find((el) => el._id === data.type).value +
                " " +
                new Date(date).toLocaleString("ru-RU", {
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              : ""
            : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Дата</Form.Label>
            {/* <Flatpickr
              data-enable-time
              className="form-control"
              style={{ backgroundColor: "transparent" }}
              value={date}
              options={{
                time_24hr: true,
                locale: Russian,
                disableMobile: true,
                dateFormat: "d.m.Y H:i",
              }}
              name="date"
              onChange={([date]) => {
                setDate(date);
              }}
            /> */}
            <DatePicker
              selected={new Date(date)}
              locale="ru"
                showTimeSelect
                dateFormat="dd.MM.yyyy H:mm"
                onChange={(date) => {
                  setDate(date);
                }}
                customInput={
                  <Form.Control
                    value={date}
                    type="text"
                    name="date"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                }
              />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Действие</Form.Label>
            <Select
              placeholder=""
              noOptionsMessage={() => "Нет действий"}
              options={
                types === null
                  ? {}
                  : types.map((el) => {
                      return {
                        value: el._id,
                        label: el.value,
                      };
                    })
              }
              value={
                (data.type !== undefined) &
                (data.type !== "") &
                (types !== null)
                  ? {
                      value: types.find((el) => el._id === data.type)._id,
                      label: types.find((el) => el._id === data.type).value,
                    }
                  : ""
              }
              type="text"
              name="status"
              onChange={(r) =>
                setData({
                  ...data,
                  type: r.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Статус</Form.Label>
            <Select
              placeholder=""
              noOptionsMessage={() => "Нет статусов"}
              options={
                statuses === null
                  ? {}
                  : statuses.map((el) => {
                      return {
                        value: el._id,
                        label: el.value,
                      };
                    })
              }
              value={
                (data.status !== undefined) &
                (data.status !== "") &
                (statuses !== null)
                  ? {
                      value: statuses.find((el) => el._id === data.status)._id,
                      label: statuses.find((el) => el._id === data.status)
                        .value,
                    }
                  : ""
              }
              type="text"
              name="status"
              onChange={(r) =>
                setData({
                  ...data,
                  status: r.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Ответственный</Form.Label>
            <Select
              placeholder=""
              noOptionsMessage={() => "Нет статусов"}
              options={
                statuses === null
                  ? {}
                  : users.map((el) => {
                      return {
                        value: el._id,
                        label: el.firstName + " " + el.secondName,
                      };
                    })
              }
              value={
                (data.responsible !== undefined) &
                (data.responsible !== "") &
                (users !== null)
                  ? {
                      value: users.find((el) => el._id === data.responsible)
                        ._id,
                      label:
                        users.find((el) => el._id === data.responsible)
                          .firstName +
                        " " +
                        users.find((el) => el._id === data.responsible)
                          .secondName,
                    }
                  : ""
              }
              type="text"
              name="responsible"
              onChange={(r) =>
                setData({
                  ...data,
                  responsible: r.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Комментарий</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              name="comment"
              onChange={(e) => setData({ ...data, comment: e.target.value })}
              value={data.comment}
            />
          </Form.Group>
          <Form.Group className={status === 1 ? "d-none" : "d-block"}>
            <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              className="mt-1 p-0"
              disabled
              plaintext
            />
          </Form.Group>
          {status === 2 ? (
            <Button variant="secondary" type="button" className="mt-3" onClick={() => setIsModalPlan(true)}>
              Запланировать повтор
            </Button>
          ) : (
            ""
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Закрыть
        </Button>
        {(status === 2) & userContext.access.superAdmin ? (
          <Button variant="danger" onClick={handleDelete}>
            Удалить
          </Button>
        ) : (
          ""
        )}
        <Button variant="primary" onClick={handleSubmit}>
          Сохранить
        </Button>
      </Modal.Footer>
      <PlanModal
        isOpen={isPlanModal}
        setIsOpen={setIsModalPlan}
        handleCreatePlan={handleCreatePlan}
      />
    </Modal>
  );
}
