import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { api as actionsApi } from "../../../utils/api/api.actionscrm";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Fade from "react-reveal/Fade";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

function ActionCRMTable(props) {
  const [finder, setFinder] = useState({
    f_type: "",
    f_lead: "",
    f_responsible: "",
    f_status: "",
  });
  const [data, setData] = useState([]);
  const [f_datef, setF_datef] = useState("");
  const [f_datet, setF_datet] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50, 100]);
  const [statuses, setStatuses] = useState(null);
  const [types, setTypes] = useState(null);
  const [leads, setLeads] = useState(null);
  const [users, setUsers] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [sorting, setSorting] = useState({
    sort_by: "date",
    sort_dir: "-1",
  });

  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "date", width: 150 },
    { columnName: "type", width: 150 },
    { columnName: "lead", width: 170 },
    { columnName: "responsible", width: 170 },
    { columnName: "status", width: 140 },
    { columnName: "comment", width: 200 },
    { columnName: "owner", width: 150 },
  ]);

  const navigation = useNavigate();

  const pagingPanelMessages = {
    showAll: "Все",
    rowsPerPage: "Записей на странице",
    info: "",
  };

  useEffect(() => {
    if (!props.tableState) return;
    setCurrentPage(props.tableState.currentPage);
    setPageSize(props.tableState.pageSize);
    setSorting(props.tableState.sorting);
    setFinder(props.tableState.finder);
    setF_datef(props.tableState.f_datef);
    setF_datet(props.tableState.f_datet);
    setNeedUpdate(true);
  }, [props.tableState]);

  const goToLead = (id) => {
    navigation(id);
    props.setTableState({
      currentPage,
      pageSize,
      sorting,
      finder,
      f_datef,
      f_datet,
    });
  };

  const Cell = (props) => {
    const { column, row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => goToLead(row._id)}
          />
        </td>
      );
    }
    if (column.name === "date") {
      return (
        <td>
          {new Date(row.date).toLocaleString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      );
    }
    if (column.name === "type") {
      return (
        <td>
          {(row.type !== undefined) & (row.type !== "")
            ? types.find((el) => el._id === row.type).value
            : ""}
        </td>
      );
    }
    if (column.name === "comment") {
        return (
          <td style={{overflow: 'scroll'}}>
            {row.comment}
          </td>
        );
      }
    if (column.name === "status") {
      return (
        <td>
          {(row.status !== undefined) &
          (row.status !== "") &
          (statuses.find((el) => el._id === row.status) !== undefined)
            ? statuses.find((el) => el._id === row.status).value
            : ""}
        </td>
      );
    }

    if (column.name === "lead") {
      return (
        <td>
          {leads.find((el) => el._id === row.lead)
            ? `${leads.find((el) => el._id === row.lead).name}${
                leads.find((el) => el._id === row.lead).secondName
                  ? " " + leads.find((el) => el._id === row.lead).secondName
                  : ""
              }`
            : ""}
        </td>
      );
    }
    if (column.name === "responsible") {
      return (
        <td>
          {users.find((el) => el._id === row.responsible)
            ? `${users.find((el) => el._id === row.responsible).firstName} ${
                users.find((el) => el._id === row.responsible).secondName
              }`
            : ""}
        </td>
      );
    }
    if (column.name === "owner") {
      return (
        <td>
          {users.find((el) => el._id === row.owner)
            ? `${users.find((el) => el._id === row.owner).firstName} ${
                users.find((el) => el._id === row.owner).secondName
              }`
            : ""}
        </td>
      );
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    actionsApi
      .getActions({ p: currentPage + 1, s: pageSize, ...sorting, ...finder })
      .then((res) => {
        setStatuses(res.directory.directory.crmStatus);
        setTypes(res.directory.directory.crmActions);
        setUsers(res.users);
        setLeads(res.leads);
        setData(res.actions);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
        setNeedUpdate(false);
      });
  }, [needUpdate]);

  const changePage = (page) => {
    props.setIsLoading(true);
    actionsApi
      .getActions({
        p: page + 1,
        s: pageSize,
        ...sorting,
        ...finder,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.actions);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    actionsApi
      .getActions({ p: 1, s: size, ...sorting, ...finder, f_datef, f_datet })
      .then((res) => {
        setData(res.actions);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    actionsApi
      .getActions({
        p: 1,
        s: pageSize,
        ...finder,
        f_datef,
        f_datet,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.actions);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    actionsApi
      .getActions({
        p: 1,
        s: pageSize,
        ...finder,
        ...sorting,
        f_datef,
        f_datet,
      })
      .then((res) => {
        setData(res.actions);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    actionsApi
      .getActions({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_type: "",
          f_lead: "",
          f_responsible: "",
          f_status: "",
        });
        setF_datef("");
        setF_datet("");
        setData(res.actions);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "date", title: "Дата" },
    { name: "type", title: "Тип" },
    { name: "lead", title: "Лид" },
    { name: "responsible", title: "Ответственный" },
    { name: "status", title: "Статус" },
    { name: "comment", title: "Комментарий" },
    { name: "owner", title: "Автор" },
  ];

  return (
    <>
      <Fade>
        <>
          <div className="d-flex justify-content-end">
            <Button
              className="m-2"
              variant="outline-primary"
              type="button"
              onClick={() => navigation("new")}
            >
              Создать
            </Button>
          </div>
          <Accordion className="mb-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Поиск</Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={tableFilter}>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Срок с</Form.Label>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datef}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datef(new Date(date));
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Срок по</Form.Label>
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={f_datet}
                          options={{
                            time_24hr: true,
                            locale: Russian,
                            dateFormat: "d.m.Y",
                            disableMobile: true,
                          }}
                          onChange={([date]) => {
                            setF_datet(new Date(date));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Лид
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет лидов"}
                        options={
                          leads === null
                            ? {}
                            : leads.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.name + (el.secondName ? (" " + el.secondName) : ""),
                                };
                              })
                        }
                        value={
                          finder.f_lead === ""
                            ? {}
                            : {
                                value: leads.find(
                                  (el) => el._id === finder.f_lead
                                )._id,
                                label:
                                  leads.find((el) => el._id === finder.f_lead)
                                    .name +
                                  " " +
                                  (leads.find((el) => el._id === finder.f_lead)
                                    .secondName
                                    ? leads.find(
                                        (el) => el._id === finder.f_lead
                                      ).secondName
                                    : ""),
                              }
                        }
                        type="text"
                        name="f_lead"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_lead: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Тип действия
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет типов"}
                        options={
                          types === null
                            ? {}
                            : types.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.value,
                                };
                              })
                        }
                        value={
                          finder.f_type === ""
                            ? {}
                            : {
                                value: types.find(
                                  (el) => el._id === finder.f_type
                                )._id,
                                label: types.find(
                                  (el) => el._id === finder.f_type
                                ).value,
                              }
                        }
                        type="text"
                        name="f_type"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_type: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Статус
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет статус"}
                        options={
                          statuses === null
                            ? {}
                            : statuses.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.value,
                                };
                              })
                        }
                        value={
                          finder.f_status === ""
                            ? {}
                            : {
                                value: statuses.find(
                                  (el) => el._id === finder.f_status
                                )._id,
                                label: statuses.find(
                                  (el) => el._id === finder.f_status
                                ).value,
                              }
                        }
                        type="text"
                        name="f_status"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_status: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Ответственный
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        placeholder=""
                        noOptionsMessage={() => "Нет пользователей"}
                        options={
                          users === null
                            ? {}
                            : users.map((el) => {
                                return {
                                  value: el._id,
                                  label: el.firstName + " " + el.secondName,
                                };
                              })
                        }
                        value={
                          finder.f_responsible === ""
                            ? {}
                            : {
                                value: users.find(
                                  (el) => el._id === finder.f_responsible
                                )._id,
                                label:
                                  users.find(
                                    (el) => el._id === finder.f_responsible
                                  ).firstName +
                                  " " +
                                  users.find(
                                    (el) => el._id === finder.f_responsible
                                  ).secondName,
                              }
                        }
                        type="text"
                        name="f_responsible"
                        onChange={(data) =>
                          setFinder({
                            ...finder,
                            f_responsible: data.value,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Button
                    className="me-2"
                    variant="outline-secondary"
                    type="button"
                    onClick={resetFilter}
                  >
                    Очистить
                  </Button>
                  <Button variant="primary" type="submit">
                    Поиск
                  </Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="card">
            <Grid rows={data} columns={columns}>
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={changePage}
                pageSize={pageSize}
                onPageSizeChange={changeSize}
              />
              <SortingState onSortingChange={tableSort} />
              <CustomPaging totalCount={totalCount} />
              <Table
                cellComponent={Cell}
                columnExtensions={tableColumnExtensions}
              />
              <TableHeaderRow showSortingControls />
              <PagingPanel
                pageSizes={pageSizes}
                messages={pagingPanelMessages}
              />
            </Grid>
          </div>
        </>
      </Fade>
    </>
  );
}

export default ActionCRMTable;
