class Api {

    _checkResponse(res) {
        if (res.ok) {
            return res.json()
        }
        return Promise.reject(res)
    }

    getTeachers(params) {
      let querry = "?";
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if ((params[key] !== undefined) & (params[key] !== "") & (params[key] !== null)) {
          querry += `${key}=${params[key]}&`;
        }
      });
      return fetch("/api/teachers/" + querry, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }

    getTeacher(id) {
      return fetch("/api/teachers/" + id, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    getCreationData() {
      return fetch("/api/teachers/create-data", {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    postTeacher(data) {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        if (data[key] === undefined || data[key] === "") {
          delete data[key];
        }
      });
      return fetch("/api/teachers", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => this._checkResponse(res));
    }
  
    editTeacher(data) {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        if (data[key] === undefined) {
          delete data[key];
        }
      });
      return fetch("/api/teachers", {
        method: "PATCH",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => this._checkResponse(res));
    }
  
    deleteTeacher(data) {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        if (data[key] === undefined || data[key] === "") {
          delete data[key];
        }
      });
      return fetch("/api/teachers", {
        method: "DELETE",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => this._checkResponse(res));
    }

    recommendedSalary(id){
        return fetch("/api/teachers/" + id + '/salary', {
            method: "GET",
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
              "Content-Type": "application/json",
            },
          }).then((res) => this._checkResponse(res));
    }

}

const api = new Api()

export {api}