class Api {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  getOperations(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        (params[key] !== undefined) &
        (params[key] !== "") &
        (params[key] !== null)
      ) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/salaries/" + querry, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getOperation(id) {
    return fetch("/api/salaries/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getCreationData() {
    return fetch("/api/salaries/create-data", {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createPlusOperation(operation) {
    const keys = Object.keys(operation);
    keys.forEach((key) => {
      if (operation[key] === undefined || operation[key] === "") {
        delete operation[key];
      }
    });
    return fetch("/api/salaries/plus", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(operation),
    }).then((res) => this._checkResponse(res));
  }

  createMinuscOperation(operation) {
    const keys = Object.keys(operation);
    keys.forEach((key) => {
      if (operation[key] === undefined || operation[key] === "") {
        delete operation[key];
      }
    });
    return fetch("/api/salaries/minus", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(operation),
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
