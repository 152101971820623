class Api {
    _checkResponse(res) {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    }
  
    getUser() {
      return fetch("/api/user/", {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
    
    updateUser(user) {
      const keys = Object.keys(user);
      keys.forEach((key) => {
        if (user[key] === undefined) {
          delete user[key];
        }
        if (key === 'password' & user.password === '') {
            user.password = undefined;
          }
      });
      return fetch("/api/user/", {
        method: "PATCH",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }).then((res) => this._checkResponse(res));
    }
  }
  
  const api = new Api();
  
  export { api };
  