import React, { useState, useEffect } from "react";
import { api as accountsApi } from "../../../../../utils/api/api.accounts";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import AccountForm from "./AccountForm";
import Button from "react-bootstrap/Button";

const AccountsTable = (props) => {
  const [data, setData] = useState(null);
  const [abonements, setAbonements] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(true);

  useEffect(() => {
    if (
      (props.studentId !== undefined) &
      (props.studentId !== "") &
      (needUpdate === true)
    ) {
      props.setIsLoading(true);
      accountsApi
        .getAccounts({
          f_student: props.studentId,
          f_isactive: filterActive ? "" : true,
          sort_by: "name",
          sort_dir: 1,
          p: 1,
          s: 100000000000000,
        })
        .then((res) => {
          setData(res.accounts);
          setAbonements(res.abonements);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => {
          setNeedUpdate(false);
          props.setNeedUpdate(true);
          props.setIsLoading(false);
        });
    }
  }, [props.studentId, needUpdate]);

  const filterInactive = () => {
    props.setIsLoading(true);
    accountsApi
      .getAccounts({
        f_student: props.studentId,
        f_isactive: !filterActive ? "" : true,
        sort_by: "name",
        sort_dir: 1,
        p: 1,
        s: 100000000000000,
      })
      .then((res) => {
        setData(res.accounts);
        setFilterActive(!filterActive);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <>
      <div>
        <Form.Check
          type="switch"
          label="Показывать неактивные"
          onChange={filterInactive}
        />
        {data !== null
          ? data.map((element) => {
              return (
                <Card key={element._id} className="mt-2">
                  <Card.Body>
                    <Card.Title className="mt-2">
                      {
                        abonements.find((el) => el._id === element.abonement)
                          .name
                      }
                      <i
                        className="bi bi-eye-fill ms-2"
                        style={{ cursor: "pointer", fontSize: 18 }}
                        onClick={() => {
                          setSelectedAccount(element._id);
                          setModalOpen(true);
                        }}
                      />
                    </Card.Title>
                    <Card.Text className="fw-light mb-0">
                      Баланс:
                      {(element.type === 1 || element.type === 3) && (
                        <p className="fw-light m-0 mt-2">
                          Занятий:{" "}
                          <span
                            className={`fw-bold ${
                              element.balance === 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {element.balance}
                          </span>
                        </p>
                      )}
                      {(element.type === 2 || element.type === 3) && (
                        <p className="fw-light mt-0 mb-0">
                          Срок действия:{" "}
                          {element.period.length === 0 ? (
                            <span className="text-danger fw-bold">
                              Нет оплаченных периодов
                            </span>
                          ) : (
                            <span
                              className={`fw-bold ${
                                new Date() >
                                new Date(
                                  new Date(
                                    element.period[
                                      element.period.length - 1
                                    ].startDate
                                  ).setDate(
                                    new Date(
                                      element.period[
                                        element.period.length - 1
                                      ].startDate
                                    ).getDate() +
                                      element.period[element.period.length - 1]
                                        .amountDays
                                  )
                                )
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {new Date(
                                new Date(
                                  element.period[
                                    element.period.length - 1
                                  ].startDate
                                ).setDate(
                                  new Date(
                                    element.period[
                                      element.period.length - 1
                                    ].startDate
                                  ).getDate() +
                                    element.period[element.period.length - 1]
                                      .amountDays
                                )
                              ).toLocaleString("ru-RU", {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              })}
                            </span>
                          )}
                        </p>
                      )}
                    </Card.Text>
                    <div className="position-absolute top-0 end-0 m-2">
                      <Badge
                        className={`position-absolute top-0 end-0 m-3 ${
                          element.isFrozen ? "d-block" : "d-none"
                        }`}
                        bg="info"
                      >
                        Заморожен
                      </Badge>
                      <Badge
                        className={element.isActive ? "d-none" : "d-block"}
                        bg="danger"
                      >
                        Неактивен
                      </Badge>
                    </div>
                  </Card.Body>
                </Card>
              );
            })
          : ""}
        <div className="d-flex justify-content-end mt-2">
          <Button
            className="m-2"
            variant="primary"
            type="button"
            onClick={() => {
              setSelectedAccount(null);
              setModalOpen(true);
            }}
          >
            Создать
          </Button>
        </div>
      </div>
      <AccountForm
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        data={selectedAccount}
        setNeedUpdate={setNeedUpdate}
        setSelectedOperation={setSelectedAccount}
        studentId={props.studentId}
      />
    </>
  );
};

export default AccountsTable;
