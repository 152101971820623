import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { api as creditApi } from "../../../../utils/api/api.credits";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";

export default function CreditsForm(props) {
  const [owner, setOwner] = useState(null);
  const [comment, setComment] = useState("");
  const [student, setStudent] = useState(null);
  const [payForm, setPayForm] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [abonements, setAbonements] = useState(null);
  const [selectedAccountPay, setSelectedAccountPay] = useState(null);
  const [data, setData] = useState({
    _id: "",
    date: "",
    student: "",
    lessonDate: "",
    lesson: "",
    count: 0,
    isPayed: false,
    comment: "",
    owner: "",
    student: "",
  });

  const navigation = useNavigate();

  const { id } = useParams();

  const handleClose = () => {
    setData({
      _id: "",
      date: "",
      student: "",
      lessonDate: "",
      lesson: "",
      count: 0,
      isPayed: false,
      comment: "",
      owner: "",
      student: "",
    });
    setComment("");
    setSelectedAccountPay(null);
    setPayForm(null);
    navigation(-1);
  };

  useEffect(() => {
    props.setIsLoading(true);
    creditApi
      .getOperation(id)
      .then((res) => {
        setComment(res.operationInfo.comment);
        setOwner(
          res.users.find((user) => user._id === res.operationInfo.owner)
        );
        setData(res.operationInfo);
        setStudent(res.student);
        if (res.operationInfo.isPayed === false) {
          setAccounts(res.suitableAccounts);
          setAbonements(res.suitableAbonements);
        }
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, [props.data]);

  const handlePatch = () => {
    props.setIsLoading(true);
    creditApi
      .editCredit({ _id: data._id, comment: comment })
      .then(() => {
        handleClose();
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const handlePay = () => {
    props.setIsLoading(true);
    // creditId, abonementId, accountId, date,
    creditApi
      .payForCredit({
        creditId: data._id,
        accountId: selectedAccountPay,
        date: new Date(),
        abonementId: accounts.find(
          (account) => account._id === selectedAccountPay
        ).abonement,
      })
      .then(() => {
        handleClose();
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
        props.setNeedUpdate(true);
      });
  };

  return (
    <Modal show={true} fullscreen onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Долг</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Дата</Form.Label>
            <Flatpickr
              data-enable-time
              className="form-control form-control-plaintext p-2"
              style={{ backgroundColor: "transparent" }}
              value={data.date}
              options={{
                time_24hr: true,
                locale: Russian,
                dateFormat: "d.m.Y H:i",
              }}
              name="date"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Дата проведения</Form.Label>
            <Flatpickr
              data-enable-time
              className="form-control form-control-plaintext p-3"
              style={{ backgroundColor: "transparent" }}
              value={data.lessonDate}
              options={{
                time_24hr: true,
                locale: Russian,
                dateFormat: "d.m.Y H:i",
              }}
              name="lessonDate"
              disabled={true}
            />
          </Form.Group>
          {student !== null ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Ученик</Form.Label>
              <Form.Control
                name="student"
                value={student.firstName + " " + student.secondName}
                disabled={true}
                plaintext={true}
              />
            </Form.Group>
          ) : (
            ""
          )}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Урок</Form.Label>
            <p>
              {<Link to={"/admin/lessons/table/" + data.lesson}>Перейти</Link>}
            </p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Значение</Form.Label>
            <Form.Control
              name="count"
              value={data.count}
              disabled={true}
              plaintext={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Статус оплаты</Form.Label>
            <Form.Select
              name="siPayed"
              value={data.isPayed}
              disabled={true}
              plaintext={true}
            >
              <option value=""> </option>
              <option value="true">Оплачено</option>
              <option value="false">Не оплачено</option>
            </Form.Select>
          </Form.Group>
          <Accordion className="mt-2 mb-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Комментарий</Accordion.Header>
              <Accordion.Body>
                <Form.Control
                  name="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  as="textarea"
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group
            className={
              (payForm === true) & (data.isPayed === false)
                ? "d-block mb-3"
                : "d-none mb-3"
            }
          >
            <Form.Label>Счет для оплаты</Form.Label>
            <Select
              placeholder=""
              noOptionsMessage={() => "Нет доступных счетов"}
              options={
                (accounts !== null) & (accounts !== undefined)
                  ? accounts.map((el) => {
                      return {
                        value: el._id,
                        label: abonements.find(
                          (element) => element._id === el.abonement
                        ).name,
                      };
                    })
                  : []
              }
              value={
                (accounts !== undefined) &
                (accounts !== null) &
                (selectedAccountPay !== null)
                  ? {
                      value: accounts.find(
                        (el) => el._id === selectedAccountPay
                      )._id,
                      label: abonements.find(
                        (element) =>
                          element._id ===
                          accounts.find((el) => el._id === selectedAccountPay)
                            .abonement
                      ).name,
                    }
                  : ""
              }
              type="text"
              name="student"
              onChange={(info) => setSelectedAccountPay(info.value)}
            />
          </Form.Group>
          <Button
            variant="success"
            className={data.isPayed === true ? "d-none" : "d-block"}
            style={{ width: "100%" }}
            onClick={() => {
              if (payForm === true) {
                handlePay();
              } else if (payForm === false) {
                setPayForm(true);
              }
            }}
          >
            Оплатить
          </Button>
          <Form.Group>
            <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
            <Form.Control
              value={
                owner !== null ? owner.firstName + " " + owner.secondName : ""
              }
              type="text"
              name="owner"
              className="mt-1 p-0"
              disabled
              plaintext
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
        <Button variant="info" onClick={(e) => handlePatch()}>
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
