import React, { useState, useEffect } from "react";
import {
  PagingState,
  CustomPaging,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { api as dancesApi } from "../../../utils/api/api.dances";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";

const pagingPanelMessages = {
  showAll: "Все",
  rowsPerPage: "Записей на странице",
  info: "",
};

const StudentsTable = (props) => {
  const [finder, setFinder] = useState({
    f_name: "",
    f_gtype: "",
    f_comment: "",
    f_active: "",
  });
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 40, 50]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState({ sort_by: "name", sort_dir: 1 });
  const [tableColumnExtensions] = useState([
    { columnName: "watch", width: 50 },
    { columnName: "name", width: 150 },
    { columnName: "typeGroup", width: 150 },
    { columnName: "comment", width: 170 },
    { columnName: "isActive", width: 150 },
  ]);
  const navigation = useNavigate();

  const Cell = (props) => {
    const { column } = props;
    const { row } = props;
    if (column.name === "watch") {
      return (
        <td className="text-center">
          <i
            className="bi bi-eye-fill"
            style={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => navigation(row._id)}
          />
        </td>
      );
    }
    if (column.name === "typeGroup") {
      if (row.typeGroup === 1) {
        return (
          <td>
            <p>Групповое</p>
          </td>
        );
      } else if (row.typeGroup === 2) {
        return (
          <td>
            <p>Индивидуальное</p>
          </td>
        );
      } else if (row.typeGroup === 3) {
        return (
          <td>
            <p>Другое</p>
          </td>
        );
      }
    }
    if (column.name === "isActive") {
      if (row.isActive) {
        return (
          <td className="text-center">
            <i className="bi bi-patch-plus" style={{ fontSize: 24 }} />
          </td>
        );
      } else {
        return (
          <td className="text-center">
            <i className="bi bi-patch-minus" style={{ fontSize: 24 }} />
          </td>
        );
      }
    }
    return <Table.Cell {...props} />;
  };

  const finderHandlerChange = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    props.setIsLoading(true);
    dancesApi
      .getDances({ p: currentPage + 1, s: pageSize, ...sorting })
      .then((res) => {
        setData(res.dances);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  const changePage = (page) => {
    props.setIsLoading(true);
    dancesApi
      .getDances({ p: page + 1, s: pageSize, ...sorting, ...finder })
      .then((res) => {
        setData(res.dances);
        setCurrentPage(res.currentPage - 1);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const changeSize = (size) => {
    props.setIsLoading(true);
    dancesApi
      .getDances({ p: 1, s: size, ...sorting, ...finder })
      .then((res) => {
        setData(res.dances);
        setPageSize(size);
        setCurrentPage(0);
        setTotalCount(res.totalDocs);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableSort = (sort) => {
    props.setIsLoading(true);
    setSorting({
      sort_dir: sort[0].direction === "asc" ? -1 : 1,
      sort_by: sort[0].columnName,
    });
    dancesApi
      .getDances({
        p: 1,
        s: pageSize,
        ...finder,
        sort_by: sort[0].columnName,
        sort_dir: sort[0].direction === "asc" ? -1 : 1,
      })
      .then((res) => {
        setData(res.dances);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const tableFilter = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    dancesApi
      .getDances({
        p: 1,
        s: pageSize,
        ...finder,
        ...sorting,
      })
      .then((res) => {
        setData(res.dances);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSorting({});
    props.setIsLoading(true);
    dancesApi
      .getDances({
        p: 1,
        s: pageSize,
      })
      .then((res) => {
        setFinder({
          f_name: "",
          f_gtype: "",
          f_comment: "",
          f_active: "",
        });
        setData(res.dances);
      })
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => props.setIsLoading(false));
  };

  const columns = [
    { name: "watch", title: " " },
    { name: "name", title: "Название" },
    { name: "typeGroup", title: "Тип" },
    { name: "comment", title: "Комментарий" },
    { name: "isActive", title: "Активность" },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          type="button"
          onClick={() => navigation("new")}
        >
          Создать
        </Button>
      </div>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={tableFilter}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Название
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_name"
                    value={finder.f_name}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Тип
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_gtype"
                    value={finder.f_gtype}
                    onChange={finderHandlerChange}
                  >
                    <option value=""> </option>
                    <option value="1">Групповое</option>
                    <option value="2">Индивидуальное</option>
                    <option value="3">Другое</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Комментарий
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="f_comment"
                    value={finder.f_comment}
                    onChange={finderHandlerChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Активен
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    name="f_active"
                    value={finder.f_active}
                    onChange={finderHandlerChange}
                  >
                    <option value="true">Да</option>
                    <option value="false">Нет</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Button
                className="me-2"
                variant="outline-secondary"
                type="button"
                onClick={resetFilter}
              >
                Очистить
              </Button>
              <Button variant="primary" type="submit">
                Поиск
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card">
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            pageSize={pageSize}
            onPageSizeChange={changeSize}
          />
          <SortingState onSortingChange={tableSort} />
          <CustomPaging totalCount={totalCount} />
          <Table
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
        </Grid>
      </div>
    </>
  );
};

export default StudentsTable;
