import React from "react";
import BonucesTable from "./BonucesTable";
import { UserContext } from "../../../../context/UserContext";
import { Routes, Route } from "react-router-dom";
import BonucesForm from "./BonucesForm";

const Bonuces = (props) => {
const userContext = React.useContext(UserContext);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <BonucesTable
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
        {<Route
          path="/:id"
          element={
            userContext.access.superAdmin ?
            <BonucesForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
            : ''
          }
        />}
        <Route
          path="/:id"
          element={
            <BonucesForm
              setIsLoading={props.setIsLoading}
              setIsError={props.setIsError}
              setError={props.setError}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Bonuces;
