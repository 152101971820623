class Api {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  getStudents(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if ((params[key] !== undefined) & (params[key] !== "") & (params[key] !== null)) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/students/" + querry, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getStudent(id) {
    return fetch("/api/students/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined || student[key] === "") {
        delete student[key];
      }
    });
    return fetch("/api/students/", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }
  
  updateStudent(student) {
    const keys = Object.keys(student);
    keys.forEach((key) => {
      if (student[key] === undefined) {
        delete student[key];
      }
    });
    return fetch("/api/students/", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
