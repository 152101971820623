import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { api as actionApi } from "../../../../utils/api/api.actionscrm";
import ActionForm from "./ActionForm";

const ActionsTable = (props) => {
  const [data, setData] = useState(null);
  const [types, setTypes] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [users, setUsers] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [totally, setTotally] = useState(0);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (needUpdate) {
      if ((props.lead !== undefined) & (props.lead !== "")) {
        props.setIsLoading(true);
        actionApi
          .getActions({ p: 1, s: 1000000000, f_lead: props.lead })
          .then((res) => {
            setData(res.actions);
            setTypes(res.directory.directory.crmActions);
            setStatuses(res.directory.directory.crmStatus);
            setTotally(res.totalDocs);
            setUsers(res.users);
          })
          .catch((rej) => {
            props.setIsError(true);
            props.setError(rej);
          })
          .finally(() => props.setIsLoading(false));
        setNeedUpdate(false);
      }
    }
  }, [props.lead, needUpdate]);

  return (
    <>
      <div>
        {(data !== null) & (data !== [])
          ? data.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          }).map((item, index) => {
              return (
                <Card key={item._id} className="mt-2">
                  <Card.Body>
                    <Card.Title className="mb-2">
                      <i
                        className="bi bi-eye-fill"
                        style={{ cursor: "pointer", fontSize: 18 }}
                        onClick={() => {
                          setSelectedAction(item._id);
                          setIsModalOpen(true);
                        }}
                      />{" "}
                      {types.find((el) => el._id === item.type).value}{" "}
                      {new Date(item.date).toLocaleString("ru-RU", {
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </Card.Title>
                    <Card.Text>
                      <p className="mb-1">
                        Статус:{" "}
                        {statuses.find((el) => el._id === item.status).value}
                      </p>
                      <p className="mb-2">
                        Ответственный:{" "}
                        {
                          users.find((el) => el._id === item.responsible)
                            .firstName
                        }{" "}
                        {
                          users.find((el) => el._id === item.responsible)
                            .secondName
                        }
                      </p>
                      {(item.comment !== undefined) & (item.comment !== "") ? (
                        <p className="fw-light font-italic">{item.comment}</p>
                      ) : (
                        ""
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })
          : ""}
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            setSelectedAction(null);
            setIsModalOpen(true);
          }}
        >
          Создать
        </Button>
      </div>
      <ActionForm
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        setIsLoading={props.setIsLoading}
        setIsError={props.setIsError}
        setError={props.setError}
        data={selectedAction}
        setSelectedAction={setSelectedAction}
        lead={props.lead}
        setNeedUpdate={setNeedUpdate}
      />
    </>
  );
};

export default ActionsTable;
