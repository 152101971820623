import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../../../context/UserContext";
import { api as bonuceApi } from "../../../../utils/api/api.bonuces";
import Form from "react-bootstrap/Form";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import { Link } from "react-router-dom";

export default function AddMoney(props) {
  const userContext = React.useContext(UserContext);
  const [status, setStatus] = useState(0); // 1 - просмотр 2 - добавление
  const [students, setStudents] = useState(null);
  const [owner, setOwner] = useState(null);
  const [data, setData] = useState({
    comment: "",
    student: "",
    count: 0,
    type: 1,
    owner: "",
  });
  const [date, setDate] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setData({
      comment: "",
      student: "",
      count: 0,
      type: 1,
      owner: "",
    });
    props.navigation(-1);
  };

  useEffect(() => {
    if (props.data !== null) {
      setStatus(1);
      props.setIsLoading(true);
      bonuceApi
        .getOperation(props.data)
        .then((res) => {
          setData(res.operationInfo);
          setDate(res.operationInfo.date);
          setStudents(res.students);
          setOwner(
            res.users.find((user) => user._id === res.operationInfo.owner)
          );
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    } else {
      setStatus(2);
      setDate(new Date());
      bonuceApi
        .getCreationData()
        .then((res) => {
          setStudents(res.students);
        })
        .catch((rej) => {
          props.setIsError(true);
          props.setError(rej);
        })
        .finally(() => props.setIsLoading(false));
    }
  }, [props.data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    bonuceApi
      .createOperation({ ...data, date: date })
      .then((res) => handleClose())
      .catch((rej) => {
        props.setIsError(true);
        props.setError(rej);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const createLinks = (text) => {
    return (
      <>
        {text.replace(/операции\s\S{24}/, "")}
        {text.match(/операции\s\S{24}/) ? (
          <Link to={"/admin/operation/money/" + text.match(/операции\s\S{24}/)[0].match(/\S{24}/)[0]}>операции</Link>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <Modal show={true} onHide={handleClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Пополнение баланса бонусов</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Ученик</Form.Label>
          <Select
            placeholder=""
            noOptionsMessage={() => "Нет доступных учеников"}
            options={
              (students !== null) & (students !== undefined)
                ? students.map((el) => {
                    return {
                      value: el._id,
                      label: el.firstName + " " + el.secondName,
                    };
                  })
                : []
            }
            value={
              (students !== undefined) &
              (students !== null) &
              (data.student !== "")
                ? {
                    value: students.find((el) => el._id === data.student)._id,
                    label:
                      students.find((el) => el._id === data.student).firstName +
                      " " +
                      students.find((el) => el._id === data.student).secondName,
                  }
                : ""
            }
            type="text"
            name="student"
            onChange={(info) => {
              setData({
                ...data,
                student: info.value,
              });
            }}
            isDisabled={status === 1 ? true : false}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Дата</Form.Label>
          <Flatpickr
            data-enable-time
            className={`form-control ${
              status === 1 && "form-control-plaintext"
            }`}
            style={{ backgroundColor: "transparent" }}
            value={date}
            options={{
              time_24hr: true,
              locale: Russian,
            }}
            name="date"
            onChange={([date]) => {
              setDate(date);
            }}
            disabled={status === 1 ? true : false}
          />
        </Form.Group>
        <InputGroup className="mb-3" size="lg">
          <InputGroup.Text>+</InputGroup.Text>
          <Form.Control
            name="count"
            value={data.count}
            className="text-success fw-bold"
            disabled={status === 1 ? true : false}
            onChange={handleChange}
          />
        </InputGroup>
        <Accordion className="mt-2 mb-2" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Комментарий</Accordion.Header>
            <Accordion.Body>
              {status === 1 ? (
                <div>{createLinks(data.comment)}</div>
              ) : (
                <Form.Control
                  name="comment"
                  value={data.comment}
                  onChange={handleChange}
                  as="textarea"
                  disabled={status === 1 ? true : false}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Form.Group className={status === 2 ? "d-none" : "d-block"}>
          <Form.Label className="mt-2 mb-0">Создал пользователь</Form.Label>
          <Form.Control
            value={
              owner !== null ? owner.firstName + " " + owner.secondName : ""
            }
            type="text"
            name="owner"
            className="mt-1 p-0"
            disabled
            plaintext
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
        <Button
          variant="success"
          className={status === 1 ? "d-none" : "d-block"}
          type="submit"
          onClick={handleSubmit}
        >
          Пополнить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
