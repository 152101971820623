class Api {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  getUsers(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        (params[key] !== undefined) &
        (params[key] !== "") &
        (params[key] !== null)
      ) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return fetch("/api/users/" + querry, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getUser(id) {
    return fetch("/api/users/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createUser(user) {
    const keys = Object.keys(user);
    keys.forEach((key) => {
      if (user[key] === undefined || user[key] === "") {
        delete user[key];
      }
    });
    return fetch("/api/users/", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((res) => this._checkResponse(res));
  }
  
  updateUser(user) {
    const keys = Object.keys(user);
    keys.forEach((key) => {
      if (user[key] === undefined) {
        delete user[key];
      }
      if (key === 'password' & user.password === '') {
        user.password = undefined;
      }
    });
    return fetch("/api/users/", {
      method: "PATCH",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
