class Api {
    _checkResponse(res) {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    }
  
    getOperations(params) {
      let querry = "?";
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if (
          (params[key] !== undefined) &
          (params[key] !== "") &
          (params[key] !== null)
        ) {
          querry += `${key}=${params[key]}&`;
        }
      });
      return fetch("/api/operations/finance/" + querry, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    getOperation(id) {
      return fetch("/api/operations/finance/" + id, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    getCreationData() {
      return fetch("/api/operations/finance/create-data", {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
      }).then((res) => this._checkResponse(res));
    }
  
    createOperation(operation) {
      const keys = Object.keys(operation);
      keys.forEach((key) => {
        if (operation[key] === undefined || operation[key] === "") {
          delete operation[key];
        }
      });
      return fetch("/api/operations/finance/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(operation),
      }).then((res) => this._checkResponse(res));
    }

    editOperation(operation) {
        const keys = Object.keys(operation);
        keys.forEach((key) => {
          if (operation[key] === undefined || operation[key] === "") {
            delete operation[key];
          }
        });
        return fetch("/api/operations/finance/", {
          method: "PATCH",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("userData")).token || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(operation),
        }).then((res) => this._checkResponse(res));
      }
  }
  
  const api = new Api();
  
  export { api };
  